/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./confirmation-modal.component";
var styles_ConfirmationModalComponent = [];
var RenderType_ConfirmationModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConfirmationModalComponent, data: {} });
export { RenderType_ConfirmationModalComponent as RenderType_ConfirmationModalComponent };
export function View_ConfirmationModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["automationid", "Section_Header"], ["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "h3", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-question-circle-o"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Confirmation"])), (_l()(), i0.ɵeld(4, 0, null, null, 3, "button", [["aria-label", "Close"], ["automationid", "BtnClose"], ["class", "close"], ["mat-dialog-close", ""], ["tabindex", "1"], ["type", "button"]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5).dialogRef.close(i0.ɵnov(_v, 5).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 606208, null, 0, i1.MatDialogClose, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], { ariaLabel: [0, "ariaLabel"], dialogResult: [1, "dialogResult"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(8, 0, null, null, 2, "div", [["automationid", "Section_Body"], ["class", "modal-body"], ["id", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "p", [["class", "mb-0"]], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, ["", " "])), (_l()(), i0.ɵeld(11, 0, null, null, 9, "div", [["automationid", "Section_Footer"], ["class", "modal-footer"], ["style", "justify-content: space-between;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 4, "button", [["automationid", "BtnCancel"], ["class", "float-left pull-left dismiss"], ["mat-button", ""], ["mat-dialog-close", ""], ["tabindex", "1"], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 14).dialogRef.close(i0.ɵnov(_v, 14).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i0.ɵdid(13, 180224, null, 0, i3.MatButton, [i0.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i0.ɵdid(14, 606208, null, 0, i1.MatDialogClose, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i0.ɵeld(15, 0, null, 0, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-remove"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, 0, [" Cancel "])), (_l()(), i0.ɵeld(17, 0, null, null, 3, "button", [["aria-label", "Close"], ["automationid", "BtnConfirm"], ["class", "mat-reverse"], ["mat-button", ""], ["tabindex", "1"], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i0.ɵdid(18, 180224, null, 0, i3.MatButton, [i0.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵeld(19, 0, null, 0, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-check"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, 0, [" Confirm "]))], function (_ck, _v) { var currVal_1 = "Close"; var currVal_2 = ""; _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_7 = ""; _ck(_v, 14, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵnov(_v, 5).ariaLabel || null); _ck(_v, 4, 0, currVal_0); var currVal_3 = _co.data; _ck(_v, 10, 0, currVal_3); var currVal_4 = (i0.ɵnov(_v, 13).disabled || null); var currVal_5 = (i0.ɵnov(_v, 13)._animationMode === "NoopAnimations"); var currVal_6 = (i0.ɵnov(_v, 14).ariaLabel || null); _ck(_v, 12, 0, currVal_4, currVal_5, currVal_6); var currVal_8 = (i0.ɵnov(_v, 18).disabled || null); var currVal_9 = (i0.ɵnov(_v, 18)._animationMode === "NoopAnimations"); _ck(_v, 17, 0, currVal_8, currVal_9); }); }
export function View_ConfirmationModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-confirmation-modal", [], null, null, null, View_ConfirmationModalComponent_0, RenderType_ConfirmationModalComponent)), i0.ɵdid(1, 49152, null, 0, i7.ConfirmationModalComponent, [i1.MatDialogRef, i1.MAT_DIALOG_DATA, i1.MAT_DIALOG_DATA], null, null)], null, null); }
var ConfirmationModalComponentNgFactory = i0.ɵccf("app-confirmation-modal", i7.ConfirmationModalComponent, View_ConfirmationModalComponent_Host_0, {}, {}, []);
export { ConfirmationModalComponentNgFactory as ConfirmationModalComponentNgFactory };
