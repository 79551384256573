import { Store } from '@ngrx/store';
import { distinctUntilChanged } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
var UiSelectorsService = /** @class */ (function () {
    function UiSelectorsService(store) {
        var _this = this;
        this.store = store;
        this.saveState$ = this.store.select(function (store) { return store.ui.saveState; });
        this.modal$ = this.store.select(function (store) { return store.ui.modal; });
        this.formBuilder$ = this.store.select(function (store) { return store.ui.formBuilder; });
        this.config$ = this.store
            .select(function (store) { return store.ui.config; })
            // Hash the config and only emit on changes
            .pipe(distinctUntilChanged(function (prev, curr) { return JSON.stringify(prev).replace(/&#39;/gi, "'") === JSON.stringify(curr); }));
        this.tabActive$ = function (tabInstanceId) { return _this.store.select(function (store) { return store.ui.tabsActive[tabInstanceId]; }); };
        this.toggle$ = function (toggleProp) { return _this.store.select(function (store) { return store.ui.toggles[toggleProp]; }); };
    }
    UiSelectorsService.ngInjectableDef = i0.defineInjectable({ factory: function UiSelectorsService_Factory() { return new UiSelectorsService(i0.inject(i1.Store)); }, token: UiSelectorsService, providedIn: "root" });
    return UiSelectorsService;
}());
export { UiSelectorsService };
