var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges, AfterViewInit, ElementRef, Renderer2, } from '@angular/core';
import { ApiService } from '$api';
import { EconsentService } from '../../shared/services/econsent.service';
import { UIStoreService } from '$ui';
import { AppSettings } from '$shared';
import { TermsAndPrivacyService } from '../../shared/services/terms-and-privacy.service';
import { combineLatest } from 'rxjs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { TcpaVerbiageService } from 'src/app/shared/services/tcpa-verbiage.service';
import { ConsentStatusEnum, BorrowerContextTypeEnum } from 'src/app/shared/models';
var cloneDeep = require('lodash/cloneDeep');
var EconsentFormComponent = /** @class */ (function () {
    function EconsentFormComponent(api, econsentService, ref, ui, settings, renderer, tcpaVerbiageService, termsAndPrivacyService) {
        this.api = api;
        this.econsentService = econsentService;
        this.ref = ref;
        this.ui = ui;
        this.settings = settings;
        this.renderer = renderer;
        this.tcpaVerbiageService = tcpaVerbiageService;
        this.termsAndPrivacyService = termsAndPrivacyService;
        /** Show saving indicator */
        this.isSaving = false;
        /** Output the result of the user actions */
        this.eConsentResult = new EventEmitter();
        /** UI config from API */
        this.config$ = this.ui.select.config$;
        /** Currently active loan */
        this.loan$ = this.api.getApiStoreData(this.api.select.megaLoan$);
        /** List of borrower consent statuses before changes are made */
        this.originalEconsentStatuses = [];
        /** List of all consenting borrowers */
        this.borrowersHasConsented = [];
        /** List of all non-consenting borrowers */
        this.borrowersHasNotConsented = [];
        /** List of all non-consenting borrowers */
        this.borrowersToPick = [];
        /** Used in UI to compare */
        this.consentStatusEnum = ConsentStatusEnum;
        /** Toggle full / condensed view of the consent text */
        this.showAllText = false;
        /** Used to set a flag to Decline eConsent for all borrowers */
        this.declineEconsent = false;
        /** Used to show WordPress variables in template */
        this.showCommunicationConsentText = false;
        /** eConsent text shown in the UI */
        this.eConsentText = null;
        this.tcpaDisclaimerMarkup = null;
        /** eConsent joint borrowers config */
        this.eConsentJointBorrowersConfig = null;
        this.subscriptions = [];
    }
    EconsentFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(this.loan$, this.config$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var _b = __read(_a, 2), loan = _b[0], config = _b[1];
            _this.showCommunicationConsentText = config
                && config['config.area.tcpa.enabled']
                && config['config.area.tcpa.enabled'].value === true
                && loan
                && !loan.isLegalDisclaimerConfirmed;
            _this.eConsentText = config
                && config['sys.conf.econsent']
                && config['sys.conf.econsent'].value
                || null;
            _this.tcpaDisclaimerMarkup = config
                && config['sys.conf.tcpa']
                && config['sys.conf.tcpa'].value
                || null;
            _this.eConsentJointBorrowersConfig = _this.settings.config
                && config['cPOS.eConsent.JointApplicants.AskAllBorrowerseConsent'].value === true
                || false;
            _this.companyName = _this.settings.clientName;
        });
    };
    EconsentFormComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.showCommunicationConsentText) {
            var tcpa = this.tcpa.nativeElement;
            this.tcpaVerbiageService.applyVerbiage(this.renderer, tcpa, this.subscriptions, {
                onTermsClick: function () { return _this.onTermsClick(); },
                onPrivacyClick: function () { return _this.onPrivacyClick(); },
                onThirdPartyClick: function () { return _this.onThirdPartyClick(); },
                tcpaDisclaimerMarkup: this.tcpaDisclaimerMarkup,
                companyName: this.companyName
            });
        }
    };
    EconsentFormComponent.prototype.ngOnChanges = function (changes) {
        if (changes && changes.borrower || changes.coBorrower) {
            this.separateBorrowers(this.borrower, this.coBorrower);
        }
    };
    // Needed for .pipe(untilDestroyed(this))
    EconsentFormComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (s) { return s.unsubscribe(); });
    };
    EconsentFormComponent.prototype.separateBorrowers = function (borrower, coBorrower) {
        // New, empty array
        this.borrowersHasConsented = [];
        this.borrowersHasNotConsented = [];
        this.borrowersToPick = [];
        // Add borrower to appropriate array
        if (borrower) {
            this.econsentService.hasBorrowerEconsented(borrower)
                ? this.borrowersHasConsented.push(cloneDeep(borrower))
                : this.borrowersHasNotConsented.push(cloneDeep(borrower));
        }
        if (coBorrower) {
            // Add coBorrower to appropriate array
            this.econsentService.hasBorrowerEconsented(coBorrower)
                ? this.borrowersHasConsented.push(cloneDeep(coBorrower))
                : this.borrowersHasNotConsented.push(cloneDeep(coBorrower));
        }
        //if both users have consented, and seperate emails only pick the logged in one
        if (borrower && coBorrower && this.borrowersHasNotConsented.length === 0 && borrower.userAccount.username != coBorrower.userAccount.username) {
            var currentLoggedInUser = (coBorrower.userAccount.userAccountId == +this.settings.userId) ? BorrowerContextTypeEnum.CoBorrower : BorrowerContextTypeEnum.Borrower;
            currentLoggedInUser == (BorrowerContextTypeEnum.Borrower) ? this.borrowersToPick.push(cloneDeep(borrower)) : this.borrowersToPick.push(cloneDeep(coBorrower));
        }
        else {
            this.borrowersToPick = this.borrowersHasConsented;
        }
        // Automatically "select" if there is only one non-consenting borrower
        // Otherwise no borrower will show in the UI and there will be no way
        // to select one
        this.showBorrowerByIndex = this.borrowersHasNotConsented.length >= 1 ? 0 : null;
        //Auto select first borrower if eConsent config enabled for joint borrower
        if (this.eConsentJointBorrowersConfig === true && this.borrowersHasNotConsented.length) {
            this.showBorrowerByIndex = 0;
            if (this.econsentService.hasBorrowerEconsented(borrower)) {
                this.showBorrowerByIndex = 1;
            }
        }
        // Get original statuses for all non-consenting borrowers
        // This will be used later to see if the statuses have changed
        this.originalEconsentStatuses = this.mapBorrowerStatuses(this.borrowersHasNotConsented);
        this.ref.markForCheck();
    };
    /** Used to store original status values */
    EconsentFormComponent.prototype.mapBorrowerStatuses = function (borrowers) {
        return borrowers.map(function (borrower) {
            return borrower.eConsent && borrower.eConsent.consentStatus || null;
        });
    };
    /** Show more / hide text */
    EconsentFormComponent.prototype.onReadMoreClick = function () {
        this.showAllText = !this.showAllText;
        this.ref.markForCheck();
        return false;
    };
    /** Toggle saving flag */
    EconsentFormComponent.prototype.setIsSaving = function (isSaving) {
        if (isSaving === void 0) { isSaving = true; }
        this.isSaving = isSaving;
        this.ref.markForCheck();
    };
    /**
     * Handle terms of use link click
     */
    EconsentFormComponent.prototype.onTermsClick = function () {
        return this.termsAndPrivacyService.viewTermsOfUse();
    };
    /**
     * Handle privacy policy link click
     */
    EconsentFormComponent.prototype.onPrivacyClick = function () {
        return this.termsAndPrivacyService.viewPrivacyPolicy();
    };
    /**
     * Handle third party supplier link click
     */
    EconsentFormComponent.prototype.onThirdPartyClick = function () {
        return this.termsAndPrivacyService.viewThirdPartySuppliers();
    };
    /**
     * Show error message in UI
     * @param error Message to display in UI
     */
    EconsentFormComponent.prototype.setError = function (error) {
        if (error === void 0) { error = "An error occurred. Please try again."; }
        this.error = error;
        this.ref.markForCheck();
    };
    /**
     * Handle when save button is clicked
     * @param borrowers
     * @param selectedBorrowerIndex
     * @param originalStatuses
     */
    EconsentFormComponent.prototype.onSubmit = function () {
        var _this = this;
        var borrowers = this.borrowersHasNotConsented;
        var selectedBorrowerIndex = this.showBorrowerByIndex;
        var originalStatuses = this.originalEconsentStatuses;
        var selectedConsentStatus = this.selectedConsentStatus;
        // Clear errors
        this.setError(null);
        // Decline all checkbox is checked
        if (this.declineEconsent) {
            if (this.isForcedSingleBorrowerMode) {
                this.eConsentResult.emit({ showStep: 2 });
            }
            else {
                this.borrowersHasConsented = this.borrowersToPick;
                this.eConsentResult.emit({ borrowersToDecline: this.borrowersHasConsented });
            }
            return;
        }
        // Only update one borrower at a time
        var selectedBorrower = this.eConsentJointBorrowersConfig === true ? cloneDeep(borrowers[0]) : cloneDeep(borrowers[selectedBorrowerIndex]);
        if (selectedBorrower && selectedBorrower.eConsent) {
            selectedBorrower.eConsent.consentStatus = selectedConsentStatus;
        }
        // Supports multiple borowers, one at a time
        var selectedBorrowers = selectedBorrower ? [selectedBorrower] : [];
        var changedBorrowers = selectedBorrowers
            .filter(function (borrower, index) {
            var currentStatus = borrower
                && borrower.eConsent
                && borrower.eConsent.consentStatus || null;
            return currentStatus !== originalStatuses[index] || currentStatus === ConsentStatusEnum.Decline;
        })
            .map(function (borrower) {
            // Update the signed dates
            borrower.eConsent.statusAt = new Date().toISOString();
            borrower.eConsent.source = window.location.origin;
            return borrower;
        });
        var numberOfDecliningBorrowers = changedBorrowers.filter(function (borrower) {
            return borrower.eConsent.consentStatus === ConsentStatusEnum.Decline;
        }).length;
        var numberOfConsentingBorrowers = changedBorrowers.filter(function (borrower) {
            return borrower.eConsent.consentStatus === ConsentStatusEnum.Accept;
        }).length;
        // No one is eConsenting; show decline confirmation modal
        if (numberOfConsentingBorrowers === 0 && numberOfDecliningBorrowers > 0) {
            this.eConsentResult.emit({ borrowersToDecline: changedBorrowers });
            return;
        }
        else {
            // If nothing has changed, just go back to the dashboard
            if (changedBorrowers.length === 0) {
                this.eConsentResult.emit({ noChanges: true });
                return;
            }
            // We know there is at least one borrower eConsenting...
            this.setIsSaving(true);
            // Save the eConsent status
            this.econsentService.saveEconsentStatus(changedBorrowers, this.showCommunicationConsentText).subscribe(function () {
                //reset select button for coBorrower
                _this.selectedConsentStatus = 0;
                // Set loading state
                _this.setIsSaving(false);
                // Force reload of loan
                _this.api.megaLoan.get(true).subscribe();
                // Emit success
                _this.eConsentResult.emit({ wasEconsentSuccessful: true, isCoborrowerTurn: changedBorrowers[0].isCoBorrower });
            }, function () {
                // Show an error in the UI
                _this.setError("An error occurred while saving. Please try again.");
                // Set loading state
                _this.setIsSaving(false);
                // Emit failure
                _this.eConsentResult.emit({ wasEconsentSuccessful: false });
            });
        }
    };
    return EconsentFormComponent;
}());
export { EconsentFormComponent };
