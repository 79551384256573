import { ButtonActions, RuleExprOp } from 'src/app/features/cvFormBuilder/shared/cvFormBuilder.enums';
import { CPOSdateTypeEnum, CPOSEventsEnum, CPOSPageValidatorsEnum } from 'src/app/shared/models';

export const incomePages: CvFormBuilder.Pages = {
  guidPageA: {
    title: 'Income Intro Section',
    titleShow: false,
    pageId: 'start',
    showButtonNext: false,
    showButtonBack: false,
    fullscreen: true,
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'start',
        data: {
          sectionLabel: 'Income',
          sectionTitle: `Let's get some details about your <strong>income</strong>`,
          sectionImage: `/assets/img/application/income.png`,
        },
      },
    ],
  }, // end page

  guidPageB: {
    title: 'Select any <strong>income source</strong> you would like to add.',
    titleShow: false,
    pageId: 'income-source',
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html: '<h2 class="text-center mb-5">Select any <strong>income source</strong> you would like to add</h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[0].firstName}}</strong>, select any <strong>income source</strong> you would like to add</h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5"><strong>{{loan.$$custom.borrowerActive.firstName}}</strong>, select any <strong>income source</strong> you would like to add</h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<div role="alert" class="alert alert-danger">Before you can continue to the next section, you will need to add 2 years of continuous income. Please add a previous income source.</div>`,
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].isPrevious',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: '<h3 class="text-center">Please provide 2 years of income.</h3>',
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].cvIncomeTypeId', // incomeType
        formFieldType: 'iconGroup',
        // dataField: 'df-91',
        // Hard coding data for now since enums do not match what client wants
        formFieldData: [
          <any>{
            enumValue: 0,
            selected: false,
            text: 'Employed',
            value: 0,
            disabled: false,
            contextFlags: 0,
            stringValue: 'Employed',
            description: '',
            orderValue: 2,
            removed: false,
            label: 'Employment<br>(W-2)',
          },
          <any>{
            enumValue: 1,
            selected: false,
            text: 'Active Military Duty',
            value: 1,
            disabled: false,
            contextFlags: 0,
            stringValue: 'ActiveMilitaryDuty',
            description: '',
            orderValue: 1,
            removed: false,
            label: 'Military Pay',
          },
          <any>{
            enumValue: 2,
            selected: false,
            text: 'Social Security',
            value: 2,
            disabled: false,
            contextFlags: 0,
            stringValue: 'SocialSecurity',
            description: '',
            orderValue: 4,
            removed: false,
            label: 'Social Security',
          },
          <any>{
            enumValue: 3,
            selected: false,
            text: 'Retired',
            value: 3,
            disabled: false,
            contextFlags: 0,
            stringValue: 'Retired',
            description: '',
            orderValue: 4,
            removed: false,
            label: 'Pension Retirement',
          },

          <any>{
            enumValue: 4,
            selected: false,
            text: 'Self Employed',
            value: 4,
            disabled: false,
            contextFlags: 0,
            stringValue: 'SelfEmployed',
            description: '',
            orderValue: 3,
            removed: false,
            label: 'Self Employed',
          },
          <any>{
            enumValue: 5,
            selected: false,
            text: 'Other/Unemployed',
            value: 5,
            disabled: false,
            contextFlags: 0,
            stringValue: 'OtherUnemployed',
            description: '',
            orderValue: 5,
            removed: false,
            label: 'Other Income',
          },
          <any>{
            enumValue: 99,
            selected: false,
            text: 'No Income',
            value: 99,
            disabled: false,
            contextFlags: 0,
            stringValue: 'NoIncome',
            description: '',
            orderValue: 5,
            removed: false,
            label: `I don't have any income`,
          },
        ],

        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].isPrevious',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].cvIncomeTypeId', // incomeType
        formFieldType: 'iconGroup',
        // dataField: 'df-91',
        // Hard coding data for now since enums do not match what client wants
        formFieldData: [
          <any>{
            enumValue: 0,
            selected: false,
            text: 'Employed',
            value: 0,
            disabled: false,
            contextFlags: 0,
            stringValue: 'Employed',
            description: '',
            orderValue: 2,
            removed: false,
            label: 'Employment<br>(W-2)',
          },
          <any>{
            enumValue: 1,
            selected: false,
            text: 'Active Military Duty',
            value: 1,
            disabled: false,
            contextFlags: 0,
            stringValue: 'ActiveMilitaryDuty',
            description: '',
            orderValue: 1,
            removed: false,
            label: 'Military Pay',
          },
          <any>{
            enumValue: 2,
            selected: false,
            text: 'Social Security',
            value: 2,
            disabled: true,
            contextFlags: 0,
            stringValue: 'SocialSecurity',
            description: '',
            orderValue: 4,
            removed: false,
            label: 'Social Security',
          },
          <any>{
            enumValue: 3,
            selected: false,
            text: 'Retired',
            value: 3,
            disabled: false,
            contextFlags: 0,
            stringValue: 'Retired',
            description: '',
            orderValue: 4,
            removed: false,
            label: 'Pension Retirement',
          },

          <any>{
            enumValue: 4,
            selected: false,
            text: 'Self Employed',
            value: 4,
            disabled: false,
            contextFlags: 0,
            stringValue: 'SelfEmployed',
            description: '',
            orderValue: 3,
            removed: false,
            label: 'Self Employed',
          },
          <any>{
            enumValue: 5,
            selected: false,
            text: 'Other/Unemployed',
            value: 5,
            disabled: true,
            contextFlags: 0,
            stringValue: 'OtherUnemployed',
            description: '',
            orderValue: 5,
            removed: false,
            label: 'Other Income',
          },
          <any>{
            enumValue: 99,
            selected: false,
            text: 'No Income',
            value: 99,
            disabled: false,
            contextFlags: 0,
            stringValue: 'NoIncome',
            description: '',
            orderValue: 5,
            removed: false,
            label: `None / Unemployed`,
          },
        ],

        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].isPrevious',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `Why do we collect employment and income information?`,
          body: `
            <p>To check your qualification for loan products, we need to understand how large of a loan you can afford to pay, and income provides an important part of this understanding. In particular, we need to understand your past 2 years of employment and income history.</p>
            <h3>Employment (W-2)</h3>
            <p>You work for an employer earning a salary or hourly wages. In this case, your employer sends you a W-2 form annually to file with your taxes.</p>
            <h3>Military Pay</h3>
            <p>If you serve in the U.S military, you would select this option to describe your military pay.</p>
            <h3>Social Security</h3>
            <p>You may collect a regular Social Security check for retirement. This payment counts as an income source.</p>
            <h3>Retirement / Pension</h3>
            <p>If you may receive a regular payment from a retirement account, 401k or a pension plan, select this option to provide these payments as an income source.</p>
            <h3>Self Employed</h3>
            <p>Select this option to provide income information if you are a 1099 contractor, or if you own your own business or are a partner in a business.</p>
            <h3>Other Income</h3>
            <p>Other sources of income may include (but are not limited to): Child Alimony/Child Support, Disability, Interest/Dividends, Unemployment, and other miscellaneous income.</p>
            <h3>I don't have any income</h3>
            <p>If you do not collect any income at this time, please select the "I don't have any income" option.</p>
          `,
        },
      }, // end
    ],
  }, // end page

  guidPageEVOIEVOESelectA: {
    title: 'Hidden title',
    titleShow: false,
    pageId: 'evoi-evoe-or-manual',
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5">Please select how you would like to provide your <strong>income history</strong></h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5">{{loan.transactionInfo.borrowers[0].firstName}}, please select how you would like to provide your <strong>income history</strong></h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5">{{loan.$$custom.borrowerActive.firstName}}, please select how you would like to provide your <strong>income history</strong></h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `How does secure income verification work?`,
          body: `
            <h3>Verify Your Income Securely</h3>
            <p>Instead of gathering and uploading your Pay Stubs, W-2 Forms, and other income related documents, you can utilize our third party vendor, The Work Number to digitally retrieve your income and employment information instantly and securely.</p>
            <p>The Work Number is owned by Equifax.</p>
          `,
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.isEVerifyIncomeEmployment',
        formFieldType: 'iconGroup',
        size: 'large',
        formFieldData: [
          {
            label: 'Verify Income Securely',
            description: 'Retrieve your income history to speed up the loan process',
            value: true,
          },
          {
            label: 'Manually Enter Your Income',
            description: 'This method will require you to upload W-2, Paystubs, and etc.',
            value: false,
          },
        ],
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.isEVerifyIncomeEmployment',
        formFieldType: 'iconGroup',
        size: 'large',
        formFieldData: [
          {
            label: 'Verify Income Securely',
            description: 'Retrieve your income history to speed up the loan process',
            value: true,
          },
          {
            label: 'Manually Enter Your Income',
            description: 'This method will require you to upload W-2, Paystubs, and etc.',
            value: false,
          },
        ],
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
            {
              field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.isEVerifyIncomeEmploymentSpouse',
        formFieldType: 'iconGroup',
        size: 'large',
        formFieldData: [
          {
            label: 'Verify Income Securely',
            description: 'Retrieve your income history to speed up the loan process',
            value: true,
          },
          {
            label: 'Manually Enter Your Income',
            description: 'This method will require you to upload W-2, Paystubs, and etc.',
            value: false,
          },
        ],
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
            {
              field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
    ],
  }, // end page

  guidPageSocialSecurityBorrower1: {
    title: 'Please provide your <strong>social security number</strong> to automatically find your income history',
    titleShow: false,
    pageId: 'social-security',
    validatorId: CPOSPageValidatorsEnum.ssnEvoiEvoe,
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5">Please provide your <strong>social security number</strong> to automatically find your income history</h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5">{{loan.transactionInfo.borrowers[0].firstName}}, please provide your <strong>social security number</strong> to automatically find your income history</h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5">{{loan.$$custom.borrowerActive.firstName}}, please provide your <strong>social security number</strong> to automatically find your income history</h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `How does secure income verification work?`,
          body: `
            <h3>Verify Your Income Securely</h3>
            <p>Instead of gathering and uploading your Pay Stubs, W-2 Forms, and other income related documents, you can utilize our third party vendor, The Work Number to digitally retrieve your income and employment information instantly and securely.</p>
            <p>The Work Number is owned by Equifax.</p>
          `,
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Social Security Number',
        field: 'loan.transactionInfo.borrowers[0].ssn',
        suffix: '<i class="fa fa-lock fa-lg" aria-hidden="true"></i>',
        formFieldType: 'ssn',
        maxlength: 9,
        validators: {
          // minLength: 9,
          // maxLength: 9,
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Confirm Social Security',
        suffix: '<i class="fa fa-lock fa-lg" aria-hidden="true"></i>',
        field: 'loan.$$custom.ssn',
        formFieldType: 'ssn',
        maxlength: 9,
        validators: {
          // minLength: 9,
          // maxLength: 9,
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder:
          'I, {{loan.transactionInfo.borrowers[0].firstName}} {{loan.transactionInfo.borrowers[0].lastName}} authorize my lender or its designated representatives to submit a request to our third party data vendors to retrieve my income history to facilitate my mortgage loan application.',
        field: 'loan.$$custom.isRunEvoiEvoeAuthorized',
        formFieldType: 'checkbox',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Social Security Number',
        field: 'loan.transactionInfo.borrowers[0].ssn',
        suffix: '<i class="fa fa-lock fa-lg" aria-hidden="true"></i>',
        formFieldType: 'ssn',
        maxlength: 9,
        validators: {
          // minLength: 9,
          // maxLength: 9,
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Confirm Social Security',
        suffix: '<i class="fa fa-lock fa-lg" aria-hidden="true"></i>',
        field: 'loan.$$custom.ssn',
        formFieldType: 'ssn',
        maxlength: 9,
        validators: {
          // minLength: 9,
          // maxLength: 9,
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder:
          'I, {{loan.transactionInfo.borrowers[0].firstName}} {{loan.transactionInfo.borrowers[0].lastName}} authorize my lender or its designated representatives to submit a request to our third party data vendors to retrieve my income history to facilitate my mortgage loan application.',
        field: 'loan.$$custom.isRunEvoiEvoeAuthorized',
        formFieldType: 'checkbox',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Social Security Number',
        field: 'loan.transactionInfo.borrowers[0].ssn',
        suffix: '<i class="fa fa-lock fa-lg" aria-hidden="true"></i>',
        formFieldType: 'ssn',
        maxlength: 9,
        validators: {
          // minLength: 9,
          // maxLength: 9,
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
            {
              field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Confirm Social Security',
        suffix: '<i class="fa fa-lock fa-lg" aria-hidden="true"></i>',
        field: 'loan.$$custom.ssn',
        formFieldType: 'ssn',
        maxlength: 9,
        validators: {
          // minLength: 9,
          // maxLength: 9,
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
            {
              field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Social Security Number',
        field: 'loan.transactionInfo.borrowers[1].ssn',
        suffix: '<i class="fa fa-lock fa-lg" aria-hidden="true"></i>',
        formFieldType: 'ssn',
        maxlength: 9,
        validators: {
          // minLength: 9,
          // maxLength: 9,
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
            {
              field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Confirm Social Security',
        suffix: '<i class="fa fa-lock fa-lg" aria-hidden="true"></i>',
        field: 'loan.$$custom.ssnSpouse',
        formFieldType: 'ssn',
        maxlength: 9,
        validators: {
          // minLength: 9,
          // maxLength: 9,
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
            {
              field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder:
          'I, {{loan.$$custom.borrowerActive.firstName}} {{loan.$$custom.borrowerActive.lastName}} authorize my lender or its designated representatives to submit a request to our third party data vendors to retrieve my income history to facilitate my mortgage loan application.',
        field: 'loan.$$custom.isRunEvoiEvoeAuthorized',
        formFieldType: 'checkbox',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
            {
              field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder:
          'I, {{loan.$$custom.borrowerActive.firstName}} {{loan.$$custom.borrowerActive.lastName}} authorize my lender or its designated representatives to submit a request to our third party data vendors to retrieve my income history to facilitate my mortgage loan application.',
        field: 'loan.$$custom.isRunEvoiEvoeAuthorizedSpouse',
        formFieldType: 'checkbox',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
            {
              field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      }, // end
    ],
  }, // end page

  guidPageTWNFeatureBorrower1: {
    title: 'TWN Feature Page Borrower 1',
    titleShow: false,
    pageId: 'twn',
    showButtonNext: false,
    showButtonBack: false,
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'twn',
        data: {
          borrowerId: 'loan.transactionInfo.borrowers[0].borrowerId',
        },
      },
    ],
  }, // end page

  guidPageTWNFeatureBorrower2: {
    title: 'TWN Feature Page Borrower Active',
    titleShow: false,
    pageId: 'twn-active',
    showButtonNext: false,
    showButtonBack: false,
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'twn',
        data: {
          borrowerId: 'loan.$$custom.borrowerActive.borrowerId',
        },
      },
    ],
  }, // end page

  guidPageEmpA: {
    title: 'Tell us about your <strong>employer</strong>.',
    titleShow: false,
    pageId: 'employer',
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html: '<h2 class="text-center mb-5">Tell us about your <strong>employer</strong></h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[0].firstName}}</strong>, tell us about your <strong>employer</strong></h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.$$custom.borrowerActive.firstName}}</strong>, tell us about your <strong>employer</strong></h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<div role="alert" class="alert alert-danger text-center">We were unable to find any income records. Please enter your W-2 income manually.</div>`,
        visible: {
          rules: [
            {
              field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.twnFailed',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<div role="alert" class="alert alert-danger text-center">We were unable to find any income records. Please enter your W-2 income manually.</div>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
              operator: RuleExprOp.NE,
              value: true,
            },
            {
              field: 'loan.$$custom.twnFailedSpouse',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].employerInfo.name',
        placeholder: 'Company Name',
        formFieldType: 'text',
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'address-autocomplete',
        columns: 7,
        data: {
          placeholder: 'Company Street Address',
          addressPath: 'loan.$$custom.loan.employments[].employerInfo.address',
          cityPath: 'loan.$$custom.loan.employments[].employerInfo.city',
          statePath: 'loan.$$custom.loan.employments[].employerInfo.state',
          zipPath: 'loan.$$custom.loan.employments[].employerInfo.zip',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.employments[].employerInfo.address',
            validators: {},
          },
        ],
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].employerInfo.unitNumber',
        placeholder: 'Unit #',
        formFieldType: 'text',
        columns: 5,
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].employerInfo.city',
        placeholder: 'Company City',
        formFieldType: 'text',
        validators: {
          // required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].employerInfo.state',
        placeholder: 'Company State',
        formFieldType: 'select',
        dataField: 'df-219',
        columns: 6,
        validators: {
          // required: true,
        },
      },
      <CvFormBuilder.Feature>{
        id: 'state-lookup',
        type: 'feature',
        featureId: 'state-lookup',
        columns: 6,
        data: {
          cityPath: 'loan.$$custom.loan.employments[].employerInfo.city',
          statePath: 'loan.$$custom.loan.employments[].employerInfo.state',
          zipPath: 'loan.$$custom.loan.employments[].employerInfo.zip',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.employments[].employerInfo.zip',
            validators: {},
          },
        ],
      },

      /**
       <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].employerInfo.zip',
        placeholder: 'Company Zip',
        columns: 6,
        formFieldType: 'text',
        minlength: 5,
        maxlength: 5,
        validators: {
          // required: true,
        },
      },
       */
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].employerInfo.phone',
        placeholder: 'Company Phone Number',
        formFieldType: 'phoneNumber',
        validators: {
          minLength: 10,
          // required: true,
        },
      },
      <CvFormBuilder.Buttons>{
        type: 'button',
        buttons: [
          {
            label: 'Delete this income',
            class: 'mat-danger delete-btn',
            actions: [
              {
                type: ButtonActions.modalDelete,
                params: {
                  type: 'income',
                  field: 'loan.$$custom.loan.employments[]',
                  featureId: 'income-delete',
                  routeGoTo: 'guidRouteZ',
                },
              },
            ],
          },
        ],
        visible: {
          rules: [
            {
              field: 'loan.$$custom.isEditing',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
    ],
  }, // end page

  guidPageEmpB: {
    title: `Tell us about your job with your employer.`,
    titleShow: false,
    pageId: 'employer-job',
    validatorId: CPOSPageValidatorsEnum.startDateEndDate,
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5">Tell us about your job with <strong> {{loan.$$custom.loan.employments[].employerInfo.name}}</strong></h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[0].firstName}}</strong>, tell us about your job with <strong>{{loan.$$custom.loan.employments[].employerInfo.name}}</strong></h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.$$custom.borrowerActive.firstName}}</strong>, tell us about your job with <strong>{{loan.$$custom.loan.employments[].employerInfo.name}}</strong></h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].positionInfo.title',
        placeholder: 'Job Title',
        formFieldType: 'text',
        validators: {
          // required: true,
        },
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'date-control',
        placeholder: 'Start Date',
        hint: `If you don't know the exact date, give an estimate.`,
        data: {
          dateType: CPOSdateTypeEnum.start,
        },
        fields: [
          {
            field: 'loan.$$custom.loan.employments[].positionInfo.dateStart',
            validators: {
              required: true,
            },
          },
        ],
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'date-control',
        placeholder: 'End Date',
        hint: `If you don't know the exact date, give an estimate.`,
        data: {
          dateType: CPOSdateTypeEnum.end,
          startDate: 'loan.$$custom.loan.employments[].positionInfo.dateStart',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.employments[].positionInfo.dateEnd',
            validators: {
              // required: true
            },
          },
        ],
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].isPrevious',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: '<h3 class="text-left">Years in same profession</h3>',
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].isPrevious',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].positionInfo.yearsInPosition',
        placeholder: 'Years',
        formFieldType: 'text',
        columns: 6,
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].isPrevious',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].positionInfo.monthsInPosition',
        formFieldType: 'select',
        formFieldData: [
          {
            label: '0',
            value: 0,
          },
          {
            label: '1',
            value: 1,
          },
          {
            label: '2',
            value: 2,
          },
          {
            label: '3',
            value: 3,
          },
          {
            label: '4',
            value: 4,
          },
          {
            label: '5',
            value: 5,
          },
          {
            label: '6',
            value: 6,
          },
          {
            label: '7',
            value: 7,
          },
          {
            label: '8',
            value: 8,
          },
          {
            label: '9',
            value: 9,
          },
          {
            label: '10',
            value: 10,
          },
          {
            label: '11',
            value: 11,
          },
        ],
        placeholder: 'Months',
        columns: 6,
        validators: {
          // required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].isPrevious',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: "I am employed by a family member, property seller, real estate agent, or other party to the transaction",
        field: 'loan.$$custom.loan.employments[].isSpecialBorrowerEmployerRelationship',
        formFieldType: 'checkbox',
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].isPrevious',
              operator: RuleExprOp.NE,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            }
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: "I am employed by a family member, property seller, real estate agent, or other party to the transaction",
        field: 'loan.$$custom.loan.employments[].isSpecialBorrowerEmployerRelationship',
        formFieldType: 'checkbox',
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].isPrevious',
              operator: RuleExprOp.NE,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
            {
              field: 'loan.$$custom.borrowerActive.isCoBorrower',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: "{{loan.$$custom.borrowerActive.firstName}} is employed by a family member, property seller, real estate agent, or other party to the transaction",
        field: 'loan.$$custom.loan.employments[].isSpecialBorrowerEmployerRelationship',
        formFieldType: 'checkbox',
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].isPrevious',
              operator: RuleExprOp.NE,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
            {
              field: 'loan.$$custom.borrowerActive.isCoBorrower',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
    ],
  }, // end page

  guidPageEmpC: {
    title: `What is your income at your employer.`,
    titleShow: false,
    pageId: 'employer-income',
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5">What is your income at <strong>{{loan.$$custom.loan.employments[].employerInfo.name}}</strong>?</h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[0].firstName}}</strong>, what is your income at <strong>{{loan.$$custom.loan.employments[].employerInfo.name}}</strong>?`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.$$custom.borrowerActive.firstName}}</strong>, what is your income at <strong>{{loan.$$custom.loan.employments[].employerInfo.name}}</strong>?</h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.basePaymentPeriodId',
        formFieldType: 'iconGroup',
        formFieldData: [
          {
            label: 'Salaried',
            value: 2,
          },
          {
            label: 'Monthly',
            value: 1,
          },
          {
            label: 'Hourly',
            value: 7,
          },
        ],
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.baseAmount',
        placeholder: 'Annual Base Salary',
        formFieldType: 'currency',
        format: '1.2-2',
        hint: 'Add decimal point for cents',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].income.basePaymentPeriodId',
              operator: RuleExprOp.EQ,
              value: 2,
            },
          ],
        },
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.baseAmount',
        placeholder: 'Monthly Base Pay',
        formFieldType: 'currency',
        format: '1.2-2',
        hint: 'Add decimal point for cents',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].income.basePaymentPeriodId',
              operator: RuleExprOp.EQ,
              value: 1,
            },
          ],
        },
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.baseAmount',
        placeholder: 'Hourly Rate',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        formFieldType: 'currency',
        format: '1.2-2',
        hint: 'Add decimal point for cents',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].income.basePaymentPeriodId',
              operator: RuleExprOp.EQ,
              value: 7,
            },
          ],
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.baseHoursPerWeek',
        placeholder: 'Hours per week',
        formFieldType: 'currency',
        prefix: '<i class="fa fa-clock-o fa-lg" aria-hidden="true"></i>',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].income.basePaymentPeriodId',
              operator: RuleExprOp.EQ,
              value: 7,
            },
          ],
        },
      }, // end

      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.annualOvertime',
        placeholder: 'Annual Overtime',
        hint: 'If applicable. Add decimal point for cents',
        formFieldType: 'currency',
        format: '1.2-2',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.annualBonus',
        placeholder: 'Annual Bonus',
        hint: 'If applicable. Add decimal point for cents',
        formFieldType: 'currency',
        format: '1.2-2',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.annualCommission',
        placeholder: 'Annual Commission',
        hint: 'If applicable. Add decimal point for cents',
        formFieldType: 'currency',
        format: '1.2-2',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
      },
    ],
  }, // end page

  guidPageMilitaryA: {
    title: 'What is your <strong>military income</strong>?',
    titleShow: false,
    pageId: 'military-info',
    analyticsId: 'Military Position BLC',
    validatorId: CPOSPageValidatorsEnum.startDateEndDate,
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html: '<h2 class="text-center mb-5">Tell us about your <strong>military position</strong></h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[0].firstName}}</strong>, tell us about your <strong>military position</strong></h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.$$custom.borrowerActive.firstName}}</strong>, tell us about your <strong>military position</strong></h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].positionInfo.branch',
        placeholder: 'Branch',
        formFieldType: 'select',
        formFieldData: [
          {
            label: 'Air Force',
            value: 0,
          },
          {
            label: 'Army',
            value: 1,
          },
          {
            label: 'Marines',
            value: 2,
          },
          {
            label: 'Navy',
            value: 3,
          },
          {
            label: 'Other',
            value: 4,
          },
        ],
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].positionInfo.rank',
        placeholder: 'Rank',
        formFieldType: 'text',
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'date-control',
        placeholder: 'Start Date',
        hint: `If you don't know the exact date, give an estimate.`,
        data: {
          dateType: CPOSdateTypeEnum.start,
        },
        fields: [
          {
            field: 'loan.$$custom.loan.employments[].positionInfo.dateStart',
            validators: {
              required: true,
            },
          },
        ],
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'date-control',
        placeholder: 'Projected End of Service Date',
        hint: `If you don't know the exact date, give an estimate.`,
        data: {
          dateType: CPOSdateTypeEnum.projectedEndOfService,
        },
        fields: [
          {
            field: 'loan.$$custom.loan.employments[].positionInfo.expirationOfService',
            validators: {
              // required: true,
            },
          },
        ],
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].isPrevious',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'feature',
        featureId: 'date-control',
        placeholder: 'End Date',
        hint: `If you don't know the exact date, give an estimate.`,
        data: {
          dateType: CPOSdateTypeEnum.end,
          startDate: 'loan.$$custom.loan.employments[].positionInfo.dateStart',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.employments[].positionInfo.dateEnd',
            validators: {
              // required: true
            },
          },
        ],
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].isPrevious',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: '<h3 class="text-left">Years in same profession</h3>',
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].isPrevious',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].positionInfo.yearsInPosition',
        placeholder: 'Years',
        formFieldType: 'text',
        columns: 6,
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].isPrevious',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].positionInfo.monthsInPosition',
        formFieldType: 'select',
        formFieldData: [
          {
            label: '0',
            value: 0,
          },
          {
            label: '1',
            value: 1,
          },
          {
            label: '2',
            value: 2,
          },
          {
            label: '3',
            value: 3,
          },
          {
            label: '4',
            value: 4,
          },
          {
            label: '5',
            value: 5,
          },
          {
            label: '6',
            value: 6,
          },
          {
            label: '7',
            value: 7,
          },
          {
            label: '8',
            value: 8,
          },
          {
            label: '9',
            value: 9,
          },
          {
            label: '10',
            value: 10,
          },
          {
            label: '11',
            value: 11,
          },
        ],
        placeholder: 'Months',
        columns: 6,
        validators: {
          // required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].isPrevious',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Buttons>{
        type: 'button',
        buttons: [
          {
            label: 'Delete this income',
            class: 'mat-danger delete-btn',
            actions: [
              {
                type: ButtonActions.modalDelete,
                params: {
                  type: 'income',
                  field: 'loan.$$custom.loan.employments[]',
                  featureId: 'income-delete',
                  routeGoTo: 'guidRouteZ',
                },
              },
            ],
          },
        ],
        visible: {
          rules: [
            {
              field: 'loan.$$custom.isEditing',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
    ],
  },

  guidPageMilitaryB: {
    title: `What is your <strong>military income</strong>?`,
    titleShow: false,
    pageId: 'military-income',
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html: '<h2 class="text-center mb-5">What is your <strong>military income</strong>?</h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[0].firstName}}</strong>, what is your <strong>military income</strong>?</h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.$$custom.borrowerActive.firstName}}</strong>, what is your <strong>military income</strong>?</h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.basePaymentPeriodId',
        formFieldType: 'iconGroup',
        formFieldData: [
          {
            label: 'Salaried',
            value: 2,
          },
          {
            label: 'Monthly',
            value: 1,
          },
        ],
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.baseAmount',
        placeholder: 'Annual Base Salary',
        formFieldType: 'currency',
        format: '1.2-2',
        hint: 'Add decimal point for cents',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].income.basePaymentPeriodId',
              operator: RuleExprOp.EQ,
              value: 2,
            },
          ],
        },
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.baseAmount',
        placeholder: 'Monthly Base Pay',
        formFieldType: 'currency',
        format: '1.2-2',
        hint: 'Add decimal point for cents',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].income.basePaymentPeriodId',
              operator: RuleExprOp.EQ,
              value: 1,
            },
          ],
        },
      },
    ],
  }, // end page

  guidPageSocialA: {
    title: `What is your <strong>monthly social security</strong> income?`,
    titleShow: false,
    pageId: 'social',
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html: '<h2 class="text-center mb-5">What is your <strong>monthly social security</strong> income?</h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[0].firstName}}</strong>, what is your <strong>monthly social security</strong> income?</h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.$$custom.borrowerActive.firstName}}</strong>, what is your <strong>monthly social security</strong> income?</h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.monthlySocialSecurityPay',
        placeholder: 'Monthly Social Security',
        formFieldType: 'currency',
        format: '1.2-2',
        hint: 'Add decimal point for cents',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.Buttons>{
        type: 'button',
        buttons: [
          {
            label: 'Delete this income',
            class: 'mat-danger delete-btn',
            actions: [
              {
                type: ButtonActions.modalDelete,
                params: {
                  type: 'income',
                  field: 'loan.$$custom.loan.employments[]',
                  featureId: 'income-delete',
                  routeGoTo: 'guidRouteZ',
                },
              },
            ],
          },
        ],
        visible: {
          rules: [
            {
              field: 'loan.$$custom.isEditing',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
    ],
  }, // end page

  guidPagePensionA: {
    title: 'Please provide some details about your <strong>Pension / Retirement</strong> income',
    titleShow: false,
    pageId: 'pension',
    validatorId: CPOSPageValidatorsEnum.pension,
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5">Please provide some details about your <strong>Pension / Retirement</strong> income</h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[0].firstName}}</strong>, please provide some details about your <strong>Pension / Retirement</strong> income</h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.$$custom.borrowerActive.firstName}}</strong>, please provide some details about your <strong>Pension / Retirement</strong> income</h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<div role="alert" class="alert alert-danger text-center">We were unable to find any income records. Please enter your Pension/Retirement income manually.</div>`,
        visible: {
          rules: [
            {
              field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.twnFailed',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<div role="alert" class="alert alert-danger text-center">We were unable to find any income records. Please enter your W-2 income manually.</div>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive.isPrimaryBorrower',
              operator: RuleExprOp.NE,
              value: true,
            },
            {
              field: 'loan.$$custom.twnFailedSpouse',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.monthlyPension',
        placeholder: 'Monthly Pension/Retirement',
        formFieldType: 'currency',
        format: '1.2-2',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: "I receive this income from someone else's retirement",
        field: 'loan.$$custom.loan.employments[].income.retirementIncomeOther',
        formFieldType: 'checkbox',
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'date-control',
        placeholder: 'Retirement Date of Retiree',
        hint: `If you don't know the exact date, provide an estimate.`,
        data: {
          dateType: CPOSdateTypeEnum.start,
        },
        fields: [
          {
            field: 'loan.$$custom.loan.employments[].income.retirementStartDate',
            validators: {
              required: true,
            },
          },
        ],
         visible: {
           rules: [
             {
               field: 'loan.$$custom.loan.employments[].income.retirementIncomeOther',
               operator: RuleExprOp.EQ,
               value: false,
             },
           ],
         }
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'date-control',
        placeholder: 'Retirement End Date',
        hint: `If you don't know the exact date, give an estimate.`,
        data: {
          dateType: CPOSdateTypeEnum.end,
          startDate: 'loan.$$custom.loan.employments[].income.retirementStartDate',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.employments[].income.retirementEndDate',
            validators: {
              // required: true
            },
          },
        ],
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].isPrevious',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.loan.employments[].income.retirementIncomeOther',
              operator: RuleExprOp.EQ,
              value: false,
            },
          ],
        },
      },
      <CvFormBuilder.Buttons>{
        type: 'button',
        buttons: [
          {
            label: 'Delete this income',
            class: 'mat-danger delete-btn',
            actions: [
              {
                type: ButtonActions.modalDelete,
                params: {
                  type: 'income',
                  field: 'loan.$$custom.loan.employments[]',
                  featureId: 'income-delete',
                  routeGoTo: 'guidRouteZ',
                },
              },
            ],
          },
        ],
        visible: {
          rules: [
            {
              field: 'loan.$$custom.isEditing',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
    ],
  }, // end page

  guidPageBusinessA: {
    title: 'Tell us about your <strong>self employment</strong>',
    titleShow: false,
    pageId: 'business',
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html: '<h2 class="text-center mb-5">Tell us about your <strong>self employment</strong></h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[0].firstName}}</strong>, tell us about your <strong>self employment</strong></h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.$$custom.borrowerActive.firstName}}</strong>, tell us about your <strong>self employment</strong></h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].employerInfo.name',
        placeholder: 'Company Name',
        formFieldType: 'text',
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'address-autocomplete',
        columns: 7,
        data: {
          placeholder: 'Company Street Address',
          addressPath: 'loan.$$custom.loan.employments[].employerInfo.address',
          cityPath: 'loan.$$custom.loan.employments[].employerInfo.city',
          statePath: 'loan.$$custom.loan.employments[].employerInfo.state',
          zipPath: 'loan.$$custom.loan.employments[].employerInfo.zip',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.employments[].employerInfo.address',
            validators: {},
          },
        ],
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].employerInfo.unitNumber',
        placeholder: 'Unit #',
        formFieldType: 'text',
        columns: 5,
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].employerInfo.city',
        placeholder: 'Company City',
        formFieldType: 'text',
        validators: {
          // required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].employerInfo.state',
        placeholder: 'Company State',
        formFieldType: 'select',
        dataField: 'df-219',
        columns: 6,
        validators: {
          // required: true,
        },
      },
      <CvFormBuilder.Feature>{
        id: 'state-lookup',
        type: 'feature',
        featureId: 'state-lookup',
        columns: 6,
        data: {
          cityPath: 'loan.$$custom.loan.employments[].employerInfo.city',
          statePath: 'loan.$$custom.loan.employments[].employerInfo.state',
          zipPath: 'loan.$$custom.loan.employments[].employerInfo.zip',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.employments[].employerInfo.zip',
            validators: {},
          },
        ],
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].employerInfo.phone',
        placeholder: 'Company Phone Number',
        formFieldType: 'phoneNumber',
        validators: {
          minLength: 10,
          // required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].employerInfo.cloverLegalEntityType',
        placeholder: 'Legal Entity Type',
        columns: 12,
        formFieldType: 'select',
        formFieldData: [
          {
            label: 'C-Corp',
            value: 1,
          },
          {
            label: 'Partnership',
            value: 2,
          },
          {
            label: 'S-Corp',
            value: 3,
          },
          {
            label: 'Sole Proprietorship',
            value: 4,
          },
          {
            label: 'LLC',
            value: 5,
          },
        ],
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].employerInfo.cloverLegalEntityLLC',
        placeholder: 'How are you classified as an LLC?',
        columns: 12,
        formFieldType: 'select',
        formFieldData: [
          {
            label: 'C-Corp',
            value: 1,
          },
          {
            label: 'Partnership',
            value: 2,
          },
          {
            label: 'S-Corp',
            value: 3,
          },
          {
            label: 'Sole Proprietorship',
            value: 4,
          },
        ],
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].employerInfo.cloverLegalEntityType',
              operator: RuleExprOp.EQ,
              value: 5,
            },
          ],
        },
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.Buttons>{
        type: 'button',
        buttons: [
          {
            label: 'Delete this income',
            class: 'mat-danger delete-btn',
            actions: [
              {
                type: ButtonActions.modalDelete,
                params: {
                  type: 'income',
                  field: 'loan.$$custom.loan.employments[]',
                  featureId: 'income-delete',
                  routeGoTo: 'guidRouteZ',
                },
              },
            ],
          },
        ],
        visible: {
          rules: [
            {
              field: 'loan.$$custom.isEditing',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
    ],
  }, // end page

  guidPageBusinessB: {
    title: 'Tell us about your job with your <strong> employer </strong>.',
    titleShow: false,
    pageId: 'employer-job2',
    validatorId: CPOSPageValidatorsEnum.startDateEndDate,
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5">Tell us about your job with <strong> {{loan.$$custom.loan.employments[].employerInfo.name}} </strong></h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[0].firstName}}</strong>, tell us about your job with <strong>{{loan.$$custom.loan.employments[].employerInfo.name}}</h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.$$custom.borrowerActive.firstName}}</strong>, tell us about your job with <strong>{{loan.$$custom.loan.employments[].employerInfo.name}}</h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].positionInfo.title',
        placeholder: 'Job Title',
        formFieldType: 'text',
        validators: {
          // required: true,
        },
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'date-control',
        placeholder: 'Start Date',
        hint: `If you don't know the exact date, give an estimate.`,
        data: {
          dateType: CPOSdateTypeEnum.start,
        },
        fields: [
          {
            field: 'loan.$$custom.loan.employments[].positionInfo.dateStart',
            validators: {
              required: true,
            },
          },
        ],
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'date-control',
        placeholder: 'End Date',
        hint: `If you don't know the exact date, give an estimate.`,
        data: {
          dateType: CPOSdateTypeEnum.end,
          startDate: 'loan.$$custom.loan.employments[].positionInfo.dateStart',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.employments[].positionInfo.dateEnd',
            validators: {
              // required: true
            },
          },
        ],
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].isPrevious',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: '<h3 class="text-left">Years in same profession</h3>',
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].isPrevious',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].positionInfo.yearsInPosition',
        placeholder: 'Years',
        formFieldType: 'text',
        columns: 6,
        validators: {
          // required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].isPrevious',
              operator: RuleExprOp.EQ,
              value: false,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].positionInfo.monthsInPosition',
        formFieldType: 'select',
        formFieldData: [
          {
            label: '0',
            value: 0,
          },
          {
            label: '1',
            value: 1,
          },
          {
            label: '2',
            value: 2,
          },
          {
            label: '3',
            value: 3,
          },
          {
            label: '4',
            value: 4,
          },
          {
            label: '5',
            value: 5,
          },
          {
            label: '6',
            value: 6,
          },
          {
            label: '7',
            value: 7,
          },
          {
            label: '8',
            value: 8,
          },
          {
            label: '9',
            value: 9,
          },
          {
            label: '10',
            value: 10,
          },
          {
            label: '11',
            value: 11,
          },
        ],
        placeholder: 'Months',
        columns: 6,
        validators: {
          // required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].isPrevious',
              operator: RuleExprOp.EQ,
              value: false,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].positionInfo.OwnershipInterestType',
        formFieldType: 'select',
        formFieldData: [
          {
            label: '25% or more',
            value: 1,
          },
          {
            label: 'Less than 25%',
            value: 2,
          },
        ],
        placeholder: 'Ownership Share',
        validators: {
          // required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].isPrevious',
              operator: RuleExprOp.EQ,
              value: false,
            },
          ],
        },
      },
    ],
  }, // end page

  guidPageBusinessC: {
    title: 'What is your income at <strong>your employer</strong>?',
    titleShow: false,
    pageId: 'business-income',
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5">What is your income at <strong>{{loan.$$custom.loan.employments[].employerInfo.name}}</strong>?</h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[0].firstName}}</strong>, what is your income at <strong>{{loan.$$custom.loan.employments[].employerInfo.name}}</strong>?</h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.$$custom.borrowerActive.firstName}}</strong>, what is your income at <strong>{{loan.$$custom.loan.employments[].employerInfo.name}}</strong>?</h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.basePaymentPeriodId',
        formFieldType: 'iconGroup',
        formFieldData: [
          {
            label: 'Annual',
            value: 2,
          },
          {
            label: 'Monthly',
            value: 1,
          },
        ],
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.baseAmount',
        placeholder: 'Annual Base Salary',
        formFieldType: 'currency',
        format: '1.2-2',
        hint: 'Add decimal point for cents',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].income.basePaymentPeriodId',
              operator: RuleExprOp.EQ,
              value: 2,
            },
          ],
        },
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.baseAmount',
        placeholder: 'Monthly Base Pay',
        formFieldType: 'currency',
        format: '1.2-2',
        hint: 'Add decimal point for cents',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].income.basePaymentPeriodId',
              operator: RuleExprOp.EQ,
              value: 1,
            },
          ],
        },
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'If loss, check here',
        field: 'loan.$$custom.loan.employments[].income.isLoss',
        formFieldType: 'checkbox',
      },

      <CvFormBuilder.Html>{
        type: 'html',
        html:
          `<h2 class="text-left mb-5">Annual total (or Loss): <strong style="color:green">{{loan.$$custom.loan.employments[].income.baseAmount | currency}}</strong></h2>`,
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].income.basePaymentPeriodId',
              operator: RuleExprOp.EQ,
              value: 2,
            },
            {
              field: 'loan.$$custom.loan.employments[].income.isLoss',
              operator: RuleExprOp.EQ,
              value: false,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-left mb-5">Annual total (or Loss): <strong style="color:red">-{{loan.$$custom.loan.employments[].income.baseAmount | currency}}</strong></h2>',
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].income.basePaymentPeriodId',
              operator: RuleExprOp.EQ,
              value: 2,
            },
            {
              field: 'loan.$$custom.loan.employments[].income.isLoss',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-left mb-5">Monthly total (or Loss): <strong style="color:green">{{loan.$$custom.loan.employments[].income.baseAmount | currency}}</strong></h2>',
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].income.basePaymentPeriodId',
              operator: RuleExprOp.EQ,
              value: 1,
            },
            {
              field: 'loan.$$custom.loan.employments[].income.isLoss',
              operator: RuleExprOp.EQ,
              value: false,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-left mb-5">Monthly total (or Loss): <strong style="color:red">-{{loan.$$custom.loan.employments[].income.baseAmount | currency}}</strong></h2>',
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].income.basePaymentPeriodId',
              operator: RuleExprOp.EQ,
              value: 1,
            },
            {
              field: 'loan.$$custom.loan.employments[].income.isLoss',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
    ],
  }, // end page

  guidPageOtherA: {
    title: 'Please provide some details about your <strong>other source</strong> of income',
    titleShow: false,
    pageId: 'other',
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html:
          '<h2 class="text-center mb-5">Please provide some details about your <strong>other source</strong> of income</h2>',
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.transactionInfo.borrowers[0].firstName}}</strong>, please provide some details about your <strong>other source</strong> of income</h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.EQ,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<h2 class="text-center mb-5"><strong>{{loan.$$custom.borrowerActive.firstName}}</strong>, please provide some details about your <strong>other source</strong> of income</h2>`,
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
            {
              field: 'loan.$$custom.borrowerActive',
              operator: RuleExprOp.NE,
              value: null,
            },
          ],
        },
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: 'About other sources of income',
          body: `
            <h3>Alimony</h3>
            <p>If receiving alimony income, we will need a copy of a Divorce Decree or Separation Agreement that states the amount and period of time for which the alimony will be received.</p>
            <h3>Automobile Allowance</h3>
            <p>A car allowance is what an employer gives an employee for business use of their personal vehicle. It needs to be a stable income and you must have received payments for at least 2 years.</p>
            <h3>Boarder Income</h3>
            <p>Applies if you have a disability and receive rental income from a live-in personal assistant. The income typically provided by Medicaid Waiver funds must be stable in an amount up to 30% of your total gross income.</p>
            <h3>Capital Gains</h3>
            <p>Although this is not considered a stable monthly income, if selecting capital gains you will need to show evidence that you own these assets, and that these assets can be sold if extra money is needed for a mortgage payment.</p>
            <h3>Child Support</h3>
            <p>If receiving child support, you will need to show proof that you will have this income for at least 3 years after the date of your mortgage application.</p>
            <h3>Disability</h3>
            <p>Long-term disability income (not Social Security Income), can be used as a form of income. If selected, we will need a copy of the Disability Policy or Benefits Statement.</p>
            <h3>Employment Related Assets</h3>
            <p>These assets must be something you own and can be accessed. These can include a severance package, or lump sum retirement package.</p>
            <h3>Foreign Income</h3>
            <p>Any foreign income you receive from either a foreign corporation or government. The foreign income must be reported on your Federal tax returns.</p>
            <h3>Foster Care</h3>
            <p>Income received from state or county-sponsored organization for providing temporary care for 1 or more children.</p>
            <h3>Housing or Parsonage</h3>
            <p>Parsonage allowance is provided by a governing board of a church to its clergy and ministers. This is also known as rental allowance and housing allowance.</p>
            <h3>Interest and Dividends</h3>
            <p>Dividends (1099-DIV) are corporate earnings paid to their stockholders. Interest (1099-INT) can be income coming from your savings and checking account, CD, Federal and State Tax Returns, US Treasury Bonds, and annuity contracts are some common examples.</p>
            <h3>Misc. Business Income</h3>
            <p>Miscellaneous business income coming from property, assets, royalty, rent, prizes, interest and dividends where you file a 1099-MISC on your taxes.</p>
            <h3>Mortgage Credit Certificate</h3>
            <p>States and municipalities can issue Mortgage Credit Certificates in place of their authority to issue mortgage revenue bonds.</p>
            <h3>Mortgage Differential Payments</h3>
            <p>An employer may subsidize an employee's mortgage payments by paying all or part of the interest differential between the employee's present and proposed mortgage payments.</p>
            <h3>Notes Receivable</h3>
            <p>Notes receivable is a balance sheet item, that records the value of promissory notes that a business is owed and should receive payment for.</p>
            <h3>Other</h3>
            <p>If you have other consistent income sources, you can select this option and provide a description.</p>
            <h3>Public Assistance</h3>
            <p>Cash assistance provided from government entities to individuals and low-income families. There are 2 types of programs: social welfare programs and social insurance programs.</p>
            <h3>Royalty Payments</h3>
            <p>If you allow someone or a business to use your property (such as: patents, copyrighted works, natual resources, music, video, etc.) and receive income from their use, this is royalty income.</p>
            <h3>Seasonal Income</h3>
            <p>Income received during certain time of the year. You need to be able to provide proof of at least 2 years of income.</p>
            <h3>Separate Maintenance</h3>
            <p>This is a decree requiring a spouse to make payments for the support or maintenance of the other spouse under a separation agreement.</p>
            <h3>Temporary Leave</h3>
            <p>Temporary leave from work is generally short in duration and for reasons of maternity or parental leave. You may get paid during their absence from work. If you're on leave at the time of closing, you will need to inform your lender.</p>
            <h3>Tip Income</h3>
            <p>Service providers (hairstylist, waitress, taxi drivers, hotel workers, etc) rely heavily on tips. If you select this, your lender will need to verify you recived this in the last 2 years along with your verification of employment.</p>
            <h3>Trust</h3>
            <p>Any income earned from the principal of a trust. A trust may contain stocks, interest earned from bank accounts and bonds, rental income, earnings received from businesses. If selected, you will need to provide the trust agreement or the trustee's statement confirming the amount, frequency and duration of payments.</p>
            <h3>Unemployment Benefits</h3>
            <p>Unemployment compensation cannot be used unless it is clearly associated with seasonal employment. You will need to verify your season employment from the last 2 years of your federal income tax returns.</p>
            <h3>VA Compensation</h3>
            <p>VA disability compensation is a payment to Veterans who got sick or injured while serving in the military. If you are a surviving spouse of a deceased military Veteran, and receive the VA Dependency and Indemnity Compensation (VA DIC), this can be another source of income.</p>
          `,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.otherIncomeTypeId',
        placeholder: 'Income Type',
        formFieldType: 'select',
        formFieldData: [
          {
            label: 'Alimony',
            value: 8,
          },
          {
            label: 'Automobile Allowance',
            value: 9,
          },
          {
            label: 'Boarder Income',
            value: 29,
          },
          {
            label: 'Capital Gains',
            value: 30,
          },
          {
            label: 'Child Support',
            value: 40,
          },
          {
            label: 'Disability',
            value: 15,
          },
          {
            label: 'Employment Related Assets',
            value: 31,
          },
          {
            label: 'Foreign Income',
            value: 32,
          },
          {
            label: 'Foster Care',
            value: 10,
          },
          {
            label: 'Housing or Parsonage',
            value: 41,
          },
          {
            label: 'Interest and Dividends',
            value: 4,
          },
          {
            label: 'Misc Business Income',
            value: 39,
          },
          {
            label: 'Mortgage Credit Certificate',
            value: 33,
          },
          {
            label: 'Mortgage Differential Payments',
            value: 34,
          },
          {
            label: 'Notes Receivable',
            value: 12,
          },
          {
            label: 'Other',
            value: 6,
          },
          {
            label: 'Public Assistance',
            value: 42,
          },
          {
            label: 'Royalty Payments',
            value: 35,
          },
          {
            label: 'Seasonal Income',
            value: 36,
          },
          {
            label: 'Separate Maintenance',
            value: 43,
          },
          {
            label: 'Temporary Leave',
            value: 37,
          },
          {
            label: 'Tip Income',
            value: 38,
          },
          {
            label: 'Trust',
            value: 7,
          },
          {
            label: 'Unemployment Benefits',
            value: 27,
          },
          {
            label: 'VA Compensation',
            value: 28,
          },
        ],
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.otherIncomeDescription',
        placeholder: 'Description for Other',
        formFieldType: 'text',
        validators: {
          // required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].income.otherIncomeTypeId',
              operator: RuleExprOp.EQ,
              value: 6,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.otherIncomeAmount',
        placeholder: 'Amount',
        formFieldType: 'currency',
        format: '1.2-2',
        columns: 6,
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.employments[].income.otherPaymentPeriodId',
        placeholder: 'Pay Period',
        columns: 6,
        formFieldType: 'select',
        formFieldData: [
          {
            label: 'Annual',
            value: 2,
          },
          {
            label: 'Monthly',
            value: 1,
          },
          {
            label: 'Weekly',
            value: 3,
          },
        ],
      },
      <CvFormBuilder.Html>{
        id: 'alimony-description',
        type: 'html',
        html:
          '<div class="alert alert-info icon">Notice: Alimony, child support, or separate maintenance income need not be revealed if the borrower or co-borrower does not choose to have it considered for repaying this loan</div>',
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.employments[].income.otherIncomeTypeId',
              operator: RuleExprOp.EQ,
              value: 8,
            },
          ],
        },
      },
      <CvFormBuilder.Buttons>{
        type: 'button',
        buttons: [
          {
            label: 'Delete this income',
            class: 'mat-danger delete-btn',
            actions: [
              {
                type: ButtonActions.modalDelete,
                params: {
                  type: 'income',
                  field: 'loan.$$custom.loan.employments[]',
                  featureId: 'income-delete',
                  routeGoTo: 'guidRouteZ',
                },
              },
            ],
          },
        ],
        visible: {
          rules: [
            {
              field: 'loan.$$custom.isEditing',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
    ],
  }, // end page

  guidPageZ: {
    title: 'Income Review Section',
    titleShow: false,
    pageId: 'summary',
    eventIds: {
      onNext: CPOSEventsEnum.incomeSummary,
    },
    isLastPage: true,
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'summary-income',
      },
      /**
       <CvFormBuilder.Buttons>{
        type: 'button',
        buttons: [
          {
            label: 'Add another income source',
            actions: [
              {
                type: ButtonActions.modelAdd,
                params: {
                  model: 'loan.$$custom.loan.employments[]',
                  data: {
                    // borrowerId: 'test'
                  }
                },
              },
              {
                type: ButtonActions.routeGoTo,
                params: 'guidRouteB',
              },
            ],
          },
        ],
      }, // end
       */
    ],
  }, // end page
};
