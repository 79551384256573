import * as i0 from "@angular/core";
var Form1003Guard = /** @class */ (function () {
    function Form1003Guard() {
    }
    // Used only for the 1003. Can be used in the future for any 1003-specific logic
    Form1003Guard.prototype.canActivate = function () {
        return true;
    };
    Form1003Guard.ngInjectableDef = i0.defineInjectable({ factory: function Form1003Guard_Factory() { return new Form1003Guard(); }, token: Form1003Guard, providedIn: "root" });
    return Form1003Guard;
}());
export { Form1003Guard };
