/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loan-lock.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../shared/pipes/phone-number.pipe";
import * as i4 from "../../../shared/directives/focus-contain.directive";
import * as i5 from "../../../shared/directives/focus.directive";
import * as i6 from "./loan-lock.component";
import * as i7 from "@angular/material/dialog";
import * as i8 from "@angular/router";
import * as i9 from "../../../shared/stores/api/api.store.service";
import * as i10 from "../../../shared/services/analytics.service";
var styles_LoanLockModalComponent = [i0.styles];
var RenderType_LoanLockModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoanLockModalComponent, data: {} });
export { RenderType_LoanLockModalComponent as RenderType_LoanLockModalComponent };
function View_LoanLockModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "loan-team-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "loan-team-field"], ["id", "team-member-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "loan-team-field"], ["id", "team-member-position"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "a", [["class", "loan-team-field"], ["id", "team-member-number"]], [[8, "href", 4]], null, null, null, null)), i1.ɵppd(7, 2), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "a", [["class", "loan-team-field"], ["id", "team-member-email"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "span", [], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.name); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.getLoanContactTypeText(((_v.context.$implicit == null) ? null : _v.context.$implicit.loanContactType)); _ck(_v, 5, 0, currVal_1); var currVal_2 = ("tel:" + i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.context.$implicit.phone, true))); _ck(_v, 6, 0, currVal_2); var currVal_3 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.phone); _ck(_v, 8, 0, currVal_3); var currVal_4 = ("mailto:" + _v.context.$implicit.email); _ck(_v, 9, 0, currVal_4); var currVal_5 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.email); _ck(_v, 10, 0, currVal_5); }); }
function View_LoanLockModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "info-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["If you have any questions, please contact your Loan Team:"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoanLockModalComponent_3)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = ((_v.parent.context.ngIf == null) ? null : _v.parent.context.ngIf.loanContacts); _ck(_v, 4, 0, currVal_0); }, null); }
function View_LoanLockModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoanLockModalComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (((_v.context.ngIf == null) ? null : ((_v.context.ngIf.loanContacts == null) ? null : _v.context.ngIf.loanContacts.length)) > 0); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_LoanLockModalComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i3.PhoneNumberPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 18, "div", [["aria-describedby", "modal-title"], ["aria-modal", "true"], ["automationid", "Page_LoanLock"], ["focusContain", ""], ["id", "modal"], ["role", "alertdialog"]], null, [[null, "keydown"]], function (_v, en, $event) { var ad = true; if (("keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i4.FocusContainDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["automationid", "Section_Header"], ["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h2", [["class", "modal-title color-primary"], ["id", "modal-title"], ["tabindex", "1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["YOUR LOAN IS BEING WORKED ON"])), (_l()(), i1.ɵeld(6, 0, null, null, 9, "div", [["class", "modal-body"], ["id", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "info-text"], ["id", "primary-info-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" It appears your Loan Team is currently working on your loan. Please come back later. "])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "loan-lock-icon-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "icon color-primary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "cvi cvi-loan-locked"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 3, null, View_LoanLockModalComponent_1)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpod(15, { loanContacts: 0 }), (_l()(), i1.ɵeld(16, 0, null, null, 3, "div", [["class", "close-button-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 2, "button", [["appFocus", ""], ["aria-label", "Close"], ["class", "btn btn-outline-secondary w-50"], ["tabindex", "1"]], null, [[null, "click"], [null, "keydown.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.determineSessionStatus() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.enter" === en)) {
        var pd_1 = (_co.determineSessionStatus() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(18, 4210688, null, 0, i5.FocusDirective, [i1.ElementRef], { appFocus: [0, "appFocus"] }, null), (_l()(), i1.ɵted(-1, null, [" Close "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 15, 0, i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform(_co.loanContacts$))); _ck(_v, 13, 0, currVal_0); var currVal_1 = ""; _ck(_v, 18, 0, currVal_1); }, null); }
export function View_LoanLockModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loan-lock", [], null, null, null, View_LoanLockModalComponent_0, RenderType_LoanLockModalComponent)), i1.ɵdid(1, 245760, null, 0, i6.LoanLockModalComponent, [i7.MatDialogRef, i7.MAT_DIALOG_DATA, i7.MAT_DIALOG_DATA, i8.Router, i9.ApiService, i10.AnalyticsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoanLockModalComponentNgFactory = i1.ɵccf("app-loan-lock", i6.LoanLockModalComponent, View_LoanLockModalComponent_Host_0, {}, {}, []);
export { LoanLockModalComponentNgFactory as LoanLockModalComponentNgFactory };
