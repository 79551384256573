var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { ModuleWithProviders } from '@angular/core';
// Global modals
import { ConfirmationModalComponent } from './modals/confirmation/confirmation-modal.component';
import { ConfirmationNewLoanModalComponent } from './modals/confirmation-new-loan/confirmation-new-loan-modal.component';
import { LogoutModalComponent } from './modals/logout/logout-modal.component';
import { FeedbackComponent } from './modals/feedback/feedback.component';
import { HintModalComponent } from './modals/hint/hint.component';
import { EconsentModalComponent } from './modals/econsent/econsent-modal.component';
import { LoanLockModalComponent } from './modals/loan-lock/loan-lock.component';
import { UrlaChangeModalComponent } from './modals/urla-change/urla-change-modal.component';
// Layout
import { FooterComponent } from './masterpage/footer/footer.component';
import { HeaderComponent } from './masterpage/header/header.component';
import { HeaderSimpleComponent } from './masterpage/header-simple/header-simple.component';
import { CompanyLogoComponent } from './masterpage/company-logo/company-logo.component';
import { LayoutMainComponent } from './masterpage/main/layout-main.component';
import { LayoutSingleComponent } from './masterpage/single/layout-single.component';
import { LayoutSimpleComponent } from './masterpage/layout-simple/layout-simple.component';
import { NavComponent } from './masterpage/nav/nav.component';
import { NavSearchComponent } from './masterpage/nav/search/nav-search.component';
import { SidebarRightComponent } from './masterpage/sidebar-right/sidebar-right.component';
import { BannerComponent } from './masterpage/banner/banner.component';
// Components
import { ApiStateComponent } from './api-state/api-state.component';
import { ErrorComponent } from './error/error.component';
import { LoadingGraphicComponent } from './counter/loading-graphic.component';
import { PasswordRequirementsComponent } from './password-requirements/password-requirements.component';
import { EconsentFormComponent } from './econsent-form/econsent-form.component';
import { ApplicationNavComponent } from './application-nav/application-nav.component';
import { DashboardNavComponent } from './dashboard-nav/dashboard-nav.component';
import { NewDesignModalComponent } from './modals/new-design/new-design-modal.component';
import { FormFreeComponent } from './form-free/form-free.component';
import { PageNavigationComponent } from './page-navigation/page-navigation.component';
import { DateControlComponent } from './date-control/date-control.component';
import { ConfirmationNextModelComponent } from './modals/confirmation-next/confirmation-next.component';
// Modals include
var APP_MODALS = [
    ConfirmationModalComponent,
    ConfirmationNewLoanModalComponent,
    LogoutModalComponent,
    FeedbackComponent,
    HintModalComponent,
    EconsentModalComponent,
    NewDesignModalComponent,
    LoanLockModalComponent,
    UrlaChangeModalComponent,
    ConfirmationNextModelComponent
];
// Components to include
export var APP_COMPONENTS = __spread(APP_MODALS, [
    FooterComponent,
    HeaderComponent,
    HeaderSimpleComponent,
    CompanyLogoComponent,
    LayoutMainComponent,
    LayoutSingleComponent,
    LayoutSimpleComponent,
    NavComponent,
    NavSearchComponent,
    SidebarRightComponent,
    ApiStateComponent,
    ErrorComponent,
    LoadingGraphicComponent,
    PasswordRequirementsComponent,
    EconsentFormComponent,
    ApplicationNavComponent,
    DashboardNavComponent,
    BannerComponent,
    FormFreeComponent,
    DateControlComponent,
    PageNavigationComponent,
]);
var ComponentsModule = /** @class */ (function () {
    function ComponentsModule() {
    }
    ComponentsModule.forRoot = function () {
        return {
            ngModule: ComponentsModule,
            providers: [],
        };
    };
    return ComponentsModule;
}());
export { ComponentsModule };
