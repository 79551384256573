import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { AppSettings, AuthService, AnalyticsService } from '$shared';
import { ApiService } from '$api';
import { switchMap, take } from 'rxjs/operators';
import { AuthorizationFormValidators, RepeatPasswordErrorStateMatcher } from '../../shared/validators/authorization-form.validators';
import { UIStoreService } from '$ui';
import { combineLatest } from 'rxjs';
import { TermsAndPrivacyService } from '../../shared/services/terms-and-privacy.service';
import { Title } from '@angular/platform-browser';
import {
  ISecureLinkAuthenticationViewModel,
  ILookupItem,
  IAuthenticationRequestViewModel,
  AuthenticationRequestTypeEnum,
  AuthenticationStatusEnum
} from 'src/app/shared/models';


interface FormData {
  loanId: string;
  password: string;
  securityAnswer: string;
  securityQuestionId: number;
  userName: string;
}

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.scss'],
})
export class ActivationComponent implements OnInit, OnDestroy, AfterViewInit {
  public config$ = this.ui.select.config$;
  public formMain: FormGroup;
  public waiting: boolean;
  public error: string;
  public isFormContentLoading = false;
  showNewPassword = false;
  showConfirmPassword = false;
  /** Show confirm password errors */
  passwordErrorMatcher = new RepeatPasswordErrorStateMatcher();
  /** Toggle password requirements */
  showPasswordRequirements = false;
  /** Show security question in UI */
  public securityQuestion: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private api: ApiService,
    private ref: ChangeDetectorRef,
    private ui: UIStoreService,
    private termsAndPrivacyService: TermsAndPrivacyService,
    private title: Title,
    private settings: AppSettings,
    private analytics: AnalyticsService
  ) { }

  public ngOnInit() {

    this.formMain = this.fb.group({
      userName: [null, [Validators.required, AuthorizationFormValidators.validEmail]],
      securityAnswer: [null, [Validators.required]],
      password: [null, [
        Validators.required,
        AuthorizationFormValidators.oneLowercase,
        AuthorizationFormValidators.oneUppercase,
        AuthorizationFormValidators.oneDigit,
        AuthorizationFormValidators.specialCharacters,
        AuthorizationFormValidators.eightCharacters,
      ]],
      passwordConfirm: [null, [Validators.required]],
      loanId: [null],
      securityQuestionId: [null],
    }, { validator: AuthorizationFormValidators.samePasswords });

    this.checkUserActivation();

    this.populateForm();
  }

  ngAfterViewInit(): void {
    // Add company name to the title of this page
    const newTitle = this.settings.clientName
      ? `${this.title.getTitle()} - ${this.settings.clientName}`
      : this.title.getTitle();
    this.title.setTitle(newTitle);
  }

  /**
   * Handle terms of use link click
   */
  onTermsClick(): void {
    return this.termsAndPrivacyService.viewTermsOfUse();
  }

  /**
   * Handle privacy policy link click
   */
  onPrivacyClick(): void {
    return this.termsAndPrivacyService.viewPrivacyPolicy();
  }

  /**
   * Check for a token in the URL and redirect to the activation screen if the user is already activated
   */
  checkUserActivation(): void {
    const activationToken = this.route.snapshot.queryParams['token'];
    if (!activationToken) return;
    this.authService.checkUserActivationByToken(activationToken)
      .pipe(take(1))
      .subscribe(activationResponse => {
        // If account is already active
        if (activationResponse && activationResponse.isUserAccountActive === true) {
          this.router.navigate(['/login'], { queryParamsHandling: 'merge' });
        }
      });
  }

  /**
   * Get borrower information to pre-populate the form
   */
  populateForm(): void {
    const token = this.route.snapshot.queryParams['token'];
    // Do not fill out the form if not query params were provided
    if (!token) {
      return;
    }
    this.setFormContentLoading();
    const securityQuestionFields$ = this.api.dataFields.get(211);
    const accountInfo$ = this.api.userAccount.getAccountInfo(token);
    combineLatest(securityQuestionFields$, accountInfo$).subscribe(([securityQuestions, accountInfoApiResponse]) => {
      this.setFormContentLoading(false);
      const accountInfo: ISecureLinkAuthenticationViewModel = accountInfoApiResponse.response;
      if (!accountInfo) {
        this.error = `Could not find your account. Please contact your LO for assistance.`;
        this.ref.markForCheck();
        return;
      }
      this.formMain.patchValue({
        userName: accountInfo.signinUserEmailAddress,
        loanId: accountInfo.loanId,
        securityQuestionId: accountInfo.signinSecurityQuestionId,
      });
      this.setSecurityQuestion(securityQuestions, accountInfo.signinSecurityQuestionId);
    }, () => {
      this.setFormContentLoading(false);
      this.error = `A problem while loading your account information. Please try again.`;
    });
  }

  /**
   * Set the security question based on the value chosen by the LO in Loan Center
   * @param securityQuestions List of possible security questions
   * @param questionId ID for desired security question
   */
  setSecurityQuestion(securityQuestions: ILookupItem[], questionId: number): void {
    const filteredQuestions = securityQuestions.filter(question => question.enumValue === questionId);
    if (filteredQuestions && filteredQuestions.length) {
      this.securityQuestion = filteredQuestions[0].text;
    } else {
      this.securityQuestion = 'Security question answer:';
    }
    this.ref.markForCheck();
  }

  setFormContentLoading(loading = true): void {
    this.isFormContentLoading = loading;
    this.ref.markForCheck();
  }

  /**
   * Submit the form
   */
  public onActivation(form: FormGroup): void {

    // Do nothing if form is invalid
    if (form.invalid) return;

    this.waiting = true;
    this.error = null;

    const formData: FormData = form.value;

    const request = {
      authenticationRequestViewModel: <IAuthenticationRequestViewModel>{
        authentication: {
          loanId: formData.loanId,
          password: formData.password,
          securityAnswer: formData.securityAnswer,
          securityQuestionId: formData.securityQuestionId,
          userAccountName: formData.userName,
        },
        authenticationRequestType: AuthenticationRequestTypeEnum.Activate
      }
    };

    this.api.userAccount.updateAccount(request).pipe(
      switchMap(() => {
        // Prepare data to log in
        const logInFormData = {
          userName: formData.userName,
          password: formData.password,
          loanId: formData.loanId,
        };
        // Switch to login API
        return this.authService.logIn(logInFormData);
      }))
      .subscribe((bodyResponse) => {
        this.waiting = false;
        const mfaToken = bodyResponse.mfaToken;
        const mfaRequired = !!mfaToken;
        if (mfaRequired) {
          this.router.navigate(['/mfa'], {
            queryParams: {
              userName: form.value.userName,
              mfaToken: mfaToken,
            },
            queryParamsHandling: 'merge'
          });
        } else if (bodyResponse && bodyResponse.succeeded) {
          // In order to view the series of mixpanel events triggered in Dev Console.
          this.analytics.mixpanelSetDebugTrue(true);
          // If successfully logged in
          this.analytics.mixpanelAlias(this.settings.userId);
          this.analytics.trackEvent('Activate Account BC');
          this.router.navigate(['/'], { queryParamsHandling: 'merge' });
        } else if (bodyResponse.authenticationStatus === AuthenticationStatusEnum.AccountLocked) {
          this.error = `Your account has been locked as a result of too many unsuccessful login attempts. Please contact your loan officer to unlock your account.`;
        } else {
          this.error = `Incorrect email or security answer.`;
        }
        this.ref.markForCheck();
      },
        error => {
          // Show error message for API errors
          this.error = 'Error logging in.';
          if ((error.statusText === 'Unauthorized')) {
            this.error = 'Invalid username or password, please try again.';
          }
          this.waiting = false;
          this.ref.markForCheck();
        },
      );
  } // end onActivation

  // Required for AutoUnsubscribe
  ngOnDestroy() { }
}
