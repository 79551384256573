import { AppSettings } from '../app.settings';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { AuthService } from './auth.service';
import { interval } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../app.settings";
import * as i2 from "@angular/common/http";
import * as i3 from "./auth.service";
var REFRESH_TIME = 60000;
var HeartbeatService = /** @class */ (function () {
    function HeartbeatService(settings, http, authService) {
        this.settings = settings;
        this.http = http;
        this.authService = authService;
    }
    HeartbeatService.prototype.releaseLock = function () {
        if (this.settings.userId) {
            var releaseLockUrl = this.settings.apiUrl + "/api/loan/SingleUserReleaseLock?loanId=" + this.settings.loanId + "&UserAccountId=" + this.settings.userId;
            return this.http.post(releaseLockUrl, null);
        }
        else {
            return of({ success: true });
        }
    };
    HeartbeatService.prototype.refreshLock = function () {
        if (this.settings.userId) {
            var refreshLockUrl = this.settings.apiUrl + "/api/loan/SingleUserRefreshLock?loanId=" + this.settings.loanId + "&UserAccountId=" + this.settings.userId;
            return this.http.post(refreshLockUrl, null);
        }
        else {
            return of({ success: true });
        }
    };
    HeartbeatService.prototype.createLock = function () {
        if (this.settings.userId) {
            var createLockUrl = this.settings.apiUrl + "/api/loan/SingleUserCreateLock?loanId=" + this.settings.loanId + "&UserAccountId=" + this.settings.userId;
            return this.http.post(createLockUrl, null);
        }
        else {
            return of({ success: true });
        }
    };
    HeartbeatService.prototype.confirmReleaseOnLogOut = function () {
        var _this = this;
        if (this.releaseLockUnsubscribeFunc) {
            return;
        }
        this.releaseLockUnsubscribeFunc = this.authService.attachPreLogOutAction(function () {
            _this.releaseLockUnsubscribeFunc = null;
            return _this.releaseLock();
        });
    };
    HeartbeatService.prototype.handleLoanRefresh = function () {
        var _this = this;
        return interval(REFRESH_TIME).subscribe(function () {
            return _this.refreshLock().subscribe();
        });
    };
    HeartbeatService.prototype.handleLoanCreateLock = function () {
        return this.createLock();
    };
    HeartbeatService.ngInjectableDef = i0.defineInjectable({ factory: function HeartbeatService_Factory() { return new HeartbeatService(i0.inject(i1.AppSettings), i0.inject(i2.HttpClient), i0.inject(i3.AuthService)); }, token: HeartbeatService, providedIn: "root" });
    return HeartbeatService;
}());
export { HeartbeatService };
