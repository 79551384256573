import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';

import { AppSettings } from '../app.settings';
import { AuthService } from '../services/auth.service';
//import { ModalsService } from '$modals';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private settings: AppSettings,
    private auth: AuthService,
    //private modals: ModalsService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    if (
      // A token and an api url are present
      this.settings.token && this.settings.apiUrl
    ) {

      /** TEMPORARY CODE FOR SHOWING NEW DESIGN MODAL */
      //if (!this.settings.hasSeenNewDesignModal) {
      //  this.modals.open('NewDesignModalComponent', false, 360, null);
      //  this.settings.hasSeenNewDesignModal = true;
      //} /** END OF TEMPORARY CODE FOR SHOWING NEW DESIGN MODAL */

      return true; // logged in and has apiUrl so set true
    }
    this.auth.redirectToAuthenticate(false, route.queryParams);
    return false;
  }
}
