import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { AuthService, AppSettings, AnalyticsService } from '$shared';
import {
  AuthorizationFormValidators,
  RepeatEmailErrorStateMatcher,
} from '../../shared/validators/authorization-form.validators';
import { TermsAndPrivacyService } from '../../shared/services/terms-and-privacy.service';
import { UIStoreService } from '$ui';
import { ApiService } from '$api';
import { Title } from '@angular/platform-browser';
import { take } from 'rxjs/operators';

@AutoUnsubscribe()
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, OnDestroy, AfterViewInit {
  public config$ = this.ui.select.config$;
  public formMain: FormGroup;
  /** Show loading state in the UI */
  public waiting: boolean;
  /** Show loading state in the UI */
  public activationEmailLoading: boolean;
  /** Make password visible in the UI */
  public showPassword = false;
  /** Show the requirements for the password */
  public showPasswordRequirements = false;
  public returnUrl: string;
  public error: string;
  public emailErrorMatcher = new RepeatEmailErrorStateMatcher();
  public isLsidAvailable = false;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private ui: UIStoreService,
    private termsAndPrivacyService: TermsAndPrivacyService,
    private settings: AppSettings,
    private api: ApiService,
    private title: Title,
    private analytics: AnalyticsService,
  ) {}

  public ngOnInit() {

    // Check if user is activated
    this.checkUserActivation();

    // Check to see if LSID has been set
    this.isLsidAvailable = this.settings.lsid != null;

    this.formMain = this.fb.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      email: [
        null,
        [Validators.required, AuthorizationFormValidators.validEmail],
        [AuthorizationFormValidators.emailNotTaken(this.api)],
      ],
      emailConfirm: [null, [Validators.required]],
      password: [
        null,
        [
          Validators.required,
          AuthorizationFormValidators.oneLowercase,
          AuthorizationFormValidators.oneUppercase,
          AuthorizationFormValidators.oneDigit,
          AuthorizationFormValidators.specialCharacters,
          AuthorizationFormValidators.eightCharacters,
        ],
      ],
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = '/';
  }

  ngAfterViewInit(): void {
    // Add company name to the title of this page
    const newTitle = this.settings.clientName
      ? `${this.title.getTitle()} - ${this.settings.clientName}`
      : this.title.getTitle();
    this.title.setTitle(newTitle);
  }

  checkSameEmails(): void {
    this.formMain.setValidators(AuthorizationFormValidators.sameEmails);
    this.formMain.updateValueAndValidity();
  }

  /**
   * Handle terms of use link click
   */
  onTermsClick(): void {
    return this.termsAndPrivacyService.viewTermsOfUse();
  }

  /**
   * Handle privacy policy link click
   */
  onPrivacyClick(): void {
    return this.termsAndPrivacyService.viewPrivacyPolicy();
  }

  /**
   * Check for a token in the URL and redirect to the activation screen if the user is already activated
   */
  checkUserActivation(): void {
    const activationToken = this.route.snapshot.queryParams['token'];
    if (!activationToken) return;
    this.authService.checkUserActivationByToken(activationToken)
      .pipe(take(1))
      .subscribe((activationResponse) => {
        if (!activationResponse) return;
        if (activationResponse.isUserAccountActive) {
          // If account is active
          this.router.navigate(['/login'], { queryParamsHandling: 'preserve' });
        } else if (activationResponse.isValidUserAccount) {
          // If account exists, but is not yet active
          this.router.navigate(['/activation'], { queryParamsHandling: 'preserve' });
        }
      });
  }

  /**
   * Submit the form
   */
  public onRegister(formMain: FormGroup): void {
    // Do nothing if form is invalid
    if (formMain.invalid) return;
    // Set loading state
    this.waiting = true;
    // Reset any error messages showing in the UI
    this.error = null;
    this.authService.registerNewUser(formMain.value).subscribe(
      (response) => {
        const mfaToken = response.mfaToken;
        const mfaRequired = !!mfaToken;
        if (mfaRequired) {
          this.router.navigate(['/mfa'], {
            queryParams: {
              userName: formMain.value.email,
              mfaToken: mfaToken
            },
            queryParamsHandling: 'merge'
          });
        } else {
          // In order to view the series of mixpanel events triggered in Dev Console.
          this.analytics.mixpanelSetDebugTrue(true);
          // Register user as uniqueID to mixpanel
          this.analytics.mixpanelAlias(this.settings.userId);
          this.analytics.mixpanelPeople({ 'User': 'Borrower'});
          this.analytics.trackEvent('Create An Account BC', {});
          this.analytics.trackEvent('Register BC', {});
          this.router.navigate([this.returnUrl], {queryParamsHandling: 'merge'});
        }
        this.waiting = false;
      },
      (error: string) => {
        this.error = error;
        this.waiting = false;
      },
    );
  } // end onSubmit

  /**
   * Submit the form
   */
  public sendActivationEmail(formMain: FormGroup): void {
    // Do nothing if form is invalid
    if (!formMain.get('email').value) return;
    // // Set loading state
    this.activationEmailLoading = true;
    // // Reset any error messages showing in the UI
    this.error = null;
    this.api.userAccount.sendActivationEmail(formMain.get('email').value).subscribe(
      () => {
        this.activationEmailLoading = false;
      },
      (error: string) => {
        this.error = error;
        this.activationEmailLoading = false;
      },
    );
  } // end onSubmit

  // Required for AutoUnsubscribe
  ngOnDestroy() {}
}
