import { environment } from '$env';
import * as i0 from "@angular/core";
var DeveloperGuard = /** @class */ (function () {
    function DeveloperGuard() {
    }
    DeveloperGuard.prototype.canActivate = function () {
        // prevent access if production enabled
        if (environment.production) {
            return false;
        }
        return true;
    };
    DeveloperGuard.ngInjectableDef = i0.defineInjectable({ factory: function DeveloperGuard_Factory() { return new DeveloperGuard(); }, token: DeveloperGuard, providedIn: "root" });
    return DeveloperGuard;
}());
export { DeveloperGuard };
