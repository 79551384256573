var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { DocumentsUtils } from '../../routes/dashboard/shared/utils/documents-utils';
import { BorrowerContextTypeEnum, ESignerRoleEnum, MilestoneStatusTypeEnum, MortgageTypeEnum, LoanChannelTypeEnum, LoanPurposeTypeEnum, EmploymentTypeEnum, BankStatementsIncomeTypeEnum, IncomeTypeEnum, URLAFormTypeEnum, ConsentStatusEnum } from 'src/app/shared/models';
import { propertyIsDefined, ValueTypeRule, propertyHasValue } from './data-validation-util';
export var MilestoneStateEnum;
(function (MilestoneStateEnum) {
    MilestoneStateEnum[MilestoneStateEnum["Application"] = 1] = "Application";
    MilestoneStateEnum[MilestoneStateEnum["Documentation"] = 2] = "Documentation";
    MilestoneStateEnum[MilestoneStateEnum["Closing"] = 3] = "Closing";
    MilestoneStateEnum[MilestoneStateEnum["Inactive"] = 4] = "Inactive";
})(MilestoneStateEnum || (MilestoneStateEnum = {}));
export var ProcessingStateEnum;
(function (ProcessingStateEnum) {
    ProcessingStateEnum[ProcessingStateEnum["Preprocessing"] = 1] = "Preprocessing";
    ProcessingStateEnum[ProcessingStateEnum["Postprocessing"] = 2] = "Postprocessing";
})(ProcessingStateEnum || (ProcessingStateEnum = {}));
export var CSAppraisalEnabledEnum;
(function (CSAppraisalEnabledEnum) {
    CSAppraisalEnabledEnum[CSAppraisalEnabledEnum["Unknown"] = 0] = "Unknown";
    CSAppraisalEnabledEnum[CSAppraisalEnabledEnum["Conventional"] = 1] = "Conventional";
    CSAppraisalEnabledEnum[CSAppraisalEnabledEnum["FHA"] = 2] = "FHA";
    CSAppraisalEnabledEnum[CSAppraisalEnabledEnum["ConventionalJumbo"] = 4] = "ConventionalJumbo";
    CSAppraisalEnabledEnum[CSAppraisalEnabledEnum["ConventionalSuperJumbo"] = 8] = "ConventionalSuperJumbo";
    CSAppraisalEnabledEnum[CSAppraisalEnabledEnum["VA"] = 16] = "VA";
    CSAppraisalEnabledEnum[CSAppraisalEnabledEnum["USDA"] = 32] = "USDA";
})(CSAppraisalEnabledEnum || (CSAppraisalEnabledEnum = {}));
export function isSpouseOnTheLoan(loan) {
    return !!loan
        && !!loan.transactionInfo
        && !!loan.transactionInfo.loanApplications
        && !!loan.transactionInfo.loanApplications.length
        && loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan;
}
export function hasCoBorrower(loan) {
    return isSpouseOnTheLoan(loan);
}
export function getBorrowers(loan) {
    return loan
        && loan.transactionInfo
        && loan.transactionInfo.borrowers
        || [];
}
export function getBorrower(loan) {
    return getBorrowers(loan)[0];
}
export function getCoBorrower(loan) {
    return hasCoBorrower(loan) ? getBorrowers(loan)[1] : null;
}
export function isJointAccount(loan) {
    var borrower = getBorrower(loan);
    var coBorrower = getCoBorrower(loan);
    return borrower && borrower.userAccount && borrower.userAccount.userAccountId
        && coBorrower && coBorrower.userAccount && coBorrower.userAccount.userAccountId
        && borrower.userAccount.userAccountId !== coBorrower.userAccount.userAccountId
        && (coBorrower.userAccount.isOnlineUser !== true || borrower.userAccount.username === coBorrower.userAccount.username);
    // if isOnlineUser is true AND the borr/coborr have different email => coborrower has their own account
}
export function isCoBorrowerAccountOffered(loan, loanApplicationId) {
    var borrowersFilteredByLoanAppId = LoanUtils.getBorrowersByLoanApplicationId(loan, loanApplicationId);
    var coborrower = borrowersFilteredByLoanAppId[1];
    // null - not offered
    // false/true - offered
    return coborrower && coborrower.borrowerDetail && coborrower.borrowerDetail.welcomeEmailRequired !== null;
}
export function didSpouseProvideSecurityAnswer(loan) {
    var _a = __read(loan.transactionInfo.borrowers, 2), securityAnswer = _a[1].userAccount.securityAnswer;
    return securityAnswer !== null && securityAnswer !== '';
}
export function isCoBorrowerJointAccount(loan) {
    var _a = __read(loan.transactionInfo.borrowers, 2), jointAccountId = _a[1].userAccount.jointAccountId;
    return jointAccountId !== null;
}
export function setCoBorrowerJointAccount(loan, loanApplicationId) {
    var borrowerRegistrationOffered = isCoBorrowerAccountOffered(loan, loanApplicationId);
    if (!borrowerRegistrationOffered) {
        var _a = __read(loan.transactionInfo.borrowers, 2), borrowerPrimary = _a[0], borrowerSecondary = _a[1];
        borrowerPrimary.userAccount.jointAccountId = borrowerSecondary.userAccount.userAccountId;
        borrowerSecondary.userAccount.jointAccountId = borrowerPrimary.userAccount.userAccountId;
        borrowerSecondary.borrowerDetail.welcomeEmailRequired = false;
    }
}
export function setCoBorrowerSplitAccount(loan, loanApplicationId) {
    var borrowerRegistrationOffered = isCoBorrowerAccountOffered(loan, loanApplicationId);
    if (!borrowerRegistrationOffered) {
        var _a = __read(loan.transactionInfo.borrowers, 2), borrowerPrimary = _a[0], borrowerSecondary = _a[1];
        borrowerPrimary.userAccount.jointAccountId = null;
        borrowerSecondary.userAccount.jointAccountId = null;
    }
}
var LoanUtils = /** @class */ (function () {
    function LoanUtils() {
    }
    LoanUtils.isSpouseOnTheLoan = function (loan) {
        return isSpouseOnTheLoan(loan);
    };
    LoanUtils.hasCoBorrower = function (loan) {
        return hasCoBorrower(loan);
    };
    LoanUtils.getBorrowers = function (loan) {
        return getBorrowers(loan);
    };
    LoanUtils.getBorrower = function (loan) {
        return getBorrower(loan);
    };
    LoanUtils.getCoBorrower = function (loan) {
        return getCoBorrower(loan);
    };
    LoanUtils.isJointAccount = function (loan) {
        return isJointAccount(loan);
    };
    LoanUtils.getPreferredPhoneNumber = function (borrower) {
        if (!(borrower && borrower.phones && borrower.phones.length))
            return null;
        var preferredNumbers = borrower.phones.filter(function (phoneNumber) { return phoneNumber.isPreferred; });
        return preferredNumbers && preferredNumbers.length ? preferredNumbers[0] : null;
    };
    LoanUtils.getBorrowerIdByUserAccountId = function (loan, userAccountId) {
        var userAccountIdMap = LoanUtils.getBorrowers(loan)
            .filter(function (borrower) {
            return borrower.userAccount && !!borrower.userAccount.userAccountId;
        })
            .reduce(function (currentValue, borrower) {
            currentValue[borrower.userAccount.userAccountId] = borrower.borrowerId;
            return currentValue;
        }, {});
        return userAccountIdMap[+userAccountId] || null;
    };
    LoanUtils.isPinAuthRequired = function (loan, document, loggedInUserId) {
        return LoanUtils.isJointAccount(loan) && LoanUtils.eSignRequired(loan, document, loggedInUserId);
    };
    LoanUtils.eSignRequired = function (loan, document, loggedInUserId) {
        return DocumentsUtils.areThereDocumentsToSign(document, LoanUtils.getLoggedInUserEnum(loan, loggedInUserId));
    };
    LoanUtils.getLoggedInUserEnum = function (loan, loggedInUserId) {
        if (LoanUtils.isJointAccount(loan)) {
            return BorrowerContextTypeEnum.Both;
        }
        var coBorrower = LoanUtils.getCoBorrower(loan);
        return loan
            && coBorrower
            && coBorrower.userAccount
            && coBorrower.userAccount.userAccountId === loggedInUserId
            ? BorrowerContextTypeEnum.CoBorrower
            : BorrowerContextTypeEnum.Borrower;
    };
    /**
     * Based on the currently logged in user, get a list of borrowers that should
     * be considered when eConsent status values are checked
     * @param loan Current loan object
     * @param loggedInUserId User ID for the currently logged in borrower
     */
    LoanUtils.getBorrowersRequiringEconsent = function (loan, loggedInUserId) {
        var borrowers = [];
        var borrower = LoanUtils.getBorrower(loan);
        var coBorrower = LoanUtils.getCoBorrower(loan);
        var loggedInUser = LoanUtils.getLoggedInUserEnum(loan, loggedInUserId);
        switch (loggedInUser) {
            case BorrowerContextTypeEnum.Both:
                borrowers.push(borrower);
                borrowers.push(coBorrower);
                break;
            case BorrowerContextTypeEnum.Borrower:
                borrowers.push(borrower);
                break;
            case BorrowerContextTypeEnum.CoBorrower:
                borrowers.push(coBorrower);
                break;
            default:
                // We should hopefully never run into this issue
                throw new Error("No borrowers were found on the loan.");
        }
        return borrowers;
    };
    /**
     * When cPOS.eConsent.JointApplicants.AskAllBorrowerseConsent config is on,
     * We use this function to determine the number of borrowers on a 1003 that have
     * econsented
     * @param loan
     */
    LoanUtils.getBorrowersEconsented = function (loan) {
        var borrowers = [];
        var borrower = LoanUtils.getBorrower(loan);
        var coBorrower = LoanUtils.getCoBorrower(loan);
        if (borrower && (borrower.eConsent.consentStatus == ConsentStatusEnum.Accept)) {
            borrowers.push(borrower);
        }
        if (coBorrower && (coBorrower.eConsent.consentStatus == ConsentStatusEnum.Accept)) {
            borrowers.push(coBorrower);
        }
        return borrowers;
    };
    /**
     * Copied from existing consumer app
     * @param loan
     * @param loggedInUserId
     * @param document
     */
    LoanUtils.getNextBorrowerESignerRoleForDocument = function (loan, loggedInUserId, document) {
        var loggedInUser = LoanUtils.getLoggedInUserEnum(loan, loggedInUserId);
        // Borrower is logged in but has not accepted
        if (loggedInUser === BorrowerContextTypeEnum.Borrower && !document.borrowerAcceptId) {
            return ESignerRoleEnum.Borrower;
        }
        // Coborrower is logged in but has not accepted
        if (loggedInUser === BorrowerContextTypeEnum.CoBorrower && !document.coBorrowerAcceptId) {
            return ESignerRoleEnum.CoBorrower;
        }
        // Both are logged in
        if (loggedInUser === BorrowerContextTypeEnum.Both) {
            if (!document.borrowerAcceptId) {
                // Borrower has not accepted
                return ESignerRoleEnum.Borrower;
            }
            else if (!document.coBorrowerAcceptId) {
                // Borrower has accepted, but coborrower has not
                return ESignerRoleEnum.CoBorrower;
            }
        }
        // Catch all
        return null;
    };
    LoanUtils.getMilestoneState = function (milestone, appState) {
        if (appState === void 0) { appState = null; }
        if (!milestone)
            return MilestoneStateEnum.Application;
        switch (milestone) {
            case MilestoneStatusTypeEnum.Prospect:
            case MilestoneStatusTypeEnum.PreApproved:
                if (appState && appState.form1003 && !!appState.form1003.completedDate) {
                    return MilestoneStateEnum.Documentation;
                }
                else {
                    return MilestoneStateEnum.Application;
                }
            case MilestoneStatusTypeEnum.Incomplete:
            case MilestoneStatusTypeEnum.AppCompleted:
            case MilestoneStatusTypeEnum.Processing:
            case MilestoneStatusTypeEnum.Underwriting:
                return MilestoneStateEnum.Documentation;
            case MilestoneStatusTypeEnum.Approved:
            case MilestoneStatusTypeEnum.DocsOut:
            case MilestoneStatusTypeEnum.Funded:
            case MilestoneStatusTypeEnum.Completed:
                return MilestoneStateEnum.Closing;
            case MilestoneStatusTypeEnum.Adverse:
            case MilestoneStatusTypeEnum.Cancelled:
                return MilestoneStateEnum.Inactive;
            // case MilestoneStatusTypeEnum.Closed:
            // case MilestoneStatusTypeEnum.Registered:
            // case MilestoneStatusTypeEnum.Rejected:
            // case MilestoneStatusTypeEnum.Submitted:
            // case MilestoneStatusTypeEnum.Unsubmitted:
            default:
                console.warn("Could not find a match to map MilestoneStatusTypeEnum:", milestone);
                return MilestoneStateEnum.Application;
        }
    };
    LoanUtils.getProcessingState = function (milestone) {
        if (!milestone)
            return ProcessingStateEnum.Preprocessing;
        switch (milestone) {
            case MilestoneStatusTypeEnum.Prospect:
            case MilestoneStatusTypeEnum.PreApproved:
            case MilestoneStatusTypeEnum.Incomplete:
            case MilestoneStatusTypeEnum.AppCompleted:
                return ProcessingStateEnum.Preprocessing;
            default:
                return ProcessingStateEnum.Postprocessing;
        }
    };
    LoanUtils.getCurrentAddress = function (loan, borrower) {
        if (!(borrower
            && borrower.currentAddressId
            && loan
            && loan.transactionInfo
            && loan.transactionInfo.properties
            && loan.transactionInfo.properties.length))
            return null;
        var allCurrentAddresses = loan.transactionInfo.properties.filter(function (property) { return property.propertyId === borrower.currentAddressId; });
        if (allCurrentAddresses && allCurrentAddresses.length) {
            return allCurrentAddresses[0];
        }
        return null;
    };
    LoanUtils.getSubjectProperty = function (loan) {
        if (!(loan
            && loan.transactionInfo
            && loan.transactionInfo.properties
            && loan.transactionInfo.properties.length)) {
            return null;
        }
        var allSubjectProperties = loan.transactionInfo.properties.filter(function (property) { return property.isSubjectProperty; });
        if (allSubjectProperties && allSubjectProperties.length) {
            return allSubjectProperties[0];
        }
        return null;
    };
    LoanUtils.getPreApproval = function (subjectProperty) {
        return subjectProperty && subjectProperty.needPreApproval != null ? subjectProperty.needPreApproval : null;
    };
    // non-subject property - address on the Credit tab is without county
    LoanUtils.isSubjectPropertyValid = function (loan, validateCounty) {
        if (validateCounty === void 0) { validateCounty = true; }
        var subjectProperty = LoanUtils.getSubjectProperty(loan);
        if (!subjectProperty)
            return false;
        var isValid = true;
        if (validateCounty) {
            isValid = !!subjectProperty.countyName;
        }
        return isValid
            && !!subjectProperty.cityName
            && !!subjectProperty.stateName
            && !!subjectProperty.streetName
            && !!subjectProperty.zipCode;
    };
    LoanUtils.getAppraisalEnabledMapMortgageType = function (loan) {
        // Check for property
        if (!(loan
            && loan.financialInfo
            && loan.financialInfo.mortgageType != null))
            return null;
        switch (loan.financialInfo.mortgageType) {
            case MortgageTypeEnum.Conventional: {
                return CSAppraisalEnabledEnum.Conventional;
            }
            case MortgageTypeEnum.FHA: {
                return CSAppraisalEnabledEnum.FHA;
            }
            case MortgageTypeEnum.ConventionalJumbo: {
                return CSAppraisalEnabledEnum.ConventionalJumbo;
            }
            case MortgageTypeEnum.ConventionalSuperJumbo: {
                return CSAppraisalEnabledEnum.ConventionalSuperJumbo;
            }
            case MortgageTypeEnum.VA: {
                return CSAppraisalEnabledEnum.VA;
            }
            case MortgageTypeEnum.USDA: {
                return CSAppraisalEnabledEnum.USDA;
            }
            default:
                return CSAppraisalEnabledEnum.Unknown;
        }
    };
    LoanUtils.isWholesale = function (loan) {
        return loan.loanChannelType === LoanChannelTypeEnum.Wholesale;
    };
    // If we've collected five of the six pieces, and is GetPreApproved, validation will fail
    LoanUtils.areSixPiecesAcquiredForAllLoanApplications = function (loan, allowMissingSubjectProperty) {
        if (allowMissingSubjectProperty === void 0) { allowMissingSubjectProperty = false; }
        var loanApplications = LoanUtils.getLoanApplications(loan);
        if (!loanApplications || loanApplications.length === 0) {
            return false;
        }
        else if (!(LoanUtils.isPropertyPieceAcquired(loan, allowMissingSubjectProperty)
            && LoanUtils.isPersonalPieceAcquired(loan)
            && LoanUtils.isIncomePieceAcquired(loan))) {
            // If any of the 6 pieces is not acquired, break.
            return false;
        }
        return true;
    };
    LoanUtils.getLoanApplications = function (loan) {
        return loan
            && loan.transactionInfo
            && loan.transactionInfo.loanApplications
            || [];
    };
    /**
     * Validates if 1st and 2nd piece of 6 pieces is acquired.
     */
    LoanUtils.isPersonalPieceAcquired = function (loan) {
        // Check for name is SSN
        var validatePersonalPieceForBorrower = function (borrower) {
            return !!borrower.firstName && borrower.lastName && !!borrower.ssn;
        };
        var retVal = validatePersonalPieceForBorrower(LoanUtils.getBorrower(loan));
        var coBorrower = LoanUtils.getCoBorrower(loan);
        // Check co-borrower if found
        if (coBorrower && LoanUtils.isSpouseOnTheLoan(loan)) {
            retVal = retVal && validatePersonalPieceForBorrower(coBorrower);
        }
        return retVal;
    };
    /**
     * Validates if 3rd, 4th and 5th piece of 6 pieces is acquired.
     * @param loan
     * @param allowMissingSubjectProperty
     */
    LoanUtils.isPropertyPieceAcquired = function (loan, allowMissingSubjectProperty) {
        if (allowMissingSubjectProperty === void 0) { allowMissingSubjectProperty = false; }
        var subjectProperty = LoanUtils.getSubjectProperty(loan);
        // 3rd piece - subject property street.
        var is3rdPieceValid = (subjectProperty && LoanUtils.isSubjectPropertyValid(loan)) || allowMissingSubjectProperty;
        // 4th piece - loan amount.
        var is4thPieceValid = !!loan.loanAmount;
        // 5th piece - estimated value of subject property for refinance, or purchase price for purchase.
        var is5thPieceValid = ((loan.loanPurposeType === LoanPurposeTypeEnum.Refinance && subjectProperty && !!subjectProperty.currentEstimatedValue)
            || (loan.loanPurposeType === LoanPurposeTypeEnum.Purchase && subjectProperty && !!subjectProperty.purchasePrice));
        return is3rdPieceValid && is4thPieceValid && is5thPieceValid;
    };
    /**
     * Validates if 6th piece of 6 pieces is acquired.
     * @param loan
     */
    LoanUtils.isIncomePieceAcquired = function (loan) {
        var totalCombinedIncomeAmountBorrowerAndCoborrower = 0;
        var borrower = LoanUtils.getBorrower(loan);
        var coBorrower = LoanUtils.getCoBorrower(loan);
        // Validate for borrower
        var retVal = LoanUtils.validateIncomePieceForBorrower(loan, LoanUtils.getBorrower(loan));
        var borrowerCurrentEmployment = LoanUtils.getCurrentEmploymentInfo(loan, borrower);
        var borrowerOrCoborrowerIsUnemployed = borrowerCurrentEmployment
            && borrowerCurrentEmployment.employmentTypeId === EmploymentTypeEnum.OtherOrUnemployed;
        // Validate if a coborrower also exists on the loan
        if (coBorrower) {
            retVal = retVal && LoanUtils.validateIncomePieceForBorrower(loan, coBorrower);
            var coBorrowerCurrentEmployment = LoanUtils.getCurrentEmploymentInfo(loan, coBorrower);
            var coBorrowerIsUnemployed = coBorrowerCurrentEmployment
                && coBorrowerCurrentEmployment.employmentTypeId === EmploymentTypeEnum.OtherOrUnemployed;
            borrowerOrCoborrowerIsUnemployed = borrowerOrCoborrowerIsUnemployed || coBorrowerIsUnemployed;
        }
        if (loan.incomeVerificationType === BankStatementsIncomeTypeEnum.FullDocumentation && borrowerOrCoborrowerIsUnemployed) {
            totalCombinedIncomeAmountBorrowerAndCoborrower = LoanUtils.getBorrowerTotalIncomeAmount(loan, borrower);
            totalCombinedIncomeAmountBorrowerAndCoborrower = !!coBorrower
                ? totalCombinedIncomeAmountBorrowerAndCoborrower + LoanUtils.getBorrowerTotalIncomeAmount(loan, coBorrower)
                : totalCombinedIncomeAmountBorrowerAndCoborrower;
            retVal = retVal && (totalCombinedIncomeAmountBorrowerAndCoborrower > 0);
        }
        return retVal;
    };
    LoanUtils.validateIncomePieceForBorrower = function (loan, borrower) {
        var borrowerCurrentEmployment = LoanUtils.getCurrentEmploymentInfo(loan, borrower);
        var hasOtherIncomes = LoanUtils.getOtherIncomes(loan, borrower).filter(function (i) {
            return (!i.isRemoved && !!i.amount);
        }).length > 0;
        if (!borrowerCurrentEmployment)
            return false;
        var incomeInformation = LoanUtils.getIncomeInformation(loan, borrowerCurrentEmployment);
        var hasIncomeInformation = incomeInformation.length > 0;
        return hasIncomeInformation
            &&
                (borrowerCurrentEmployment.employmentTypeId === EmploymentTypeEnum.ActiveMilitaryDuty
                    && LoanUtils.incomeInfoByTypeId(incomeInformation, IncomeTypeEnum.MilitaryBasePay)
                    && LoanUtils.incomeInfoByTypeId(incomeInformation, IncomeTypeEnum.MilitaryBasePay).amount > 0)
            || (borrowerCurrentEmployment.employmentTypeId === EmploymentTypeEnum.SelfEmployed
                && (LoanUtils.incomeInfoByTypeId(incomeInformation, IncomeTypeEnum.SelfEmployedIncome)
                    && LoanUtils.incomeInfoByTypeId(incomeInformation, IncomeTypeEnum.SelfEmployedIncome).amount !== 0
                    || LoanUtils.incomeInfoByTypeId(incomeInformation, IncomeTypeEnum.Commissions)
                        && LoanUtils.incomeInfoByTypeId(incomeInformation, IncomeTypeEnum.Commissions).amount > 0))
            || (borrowerCurrentEmployment.employmentTypeId === EmploymentTypeEnum.SalariedEmployee
                && ((LoanUtils.incomeInfoByTypeId(incomeInformation, IncomeTypeEnum.BaseEmployment)
                    && LoanUtils.incomeInfoByTypeId(incomeInformation, IncomeTypeEnum.BaseEmployment).amount > 0)
                    || (LoanUtils.incomeInfoByTypeId(incomeInformation, IncomeTypeEnum.Commissions)
                        && LoanUtils.incomeInfoByTypeId(incomeInformation, IncomeTypeEnum.Commissions).amount > 0)))
            || ((borrowerCurrentEmployment.employmentTypeId === EmploymentTypeEnum.Retired) && hasOtherIncomes)
            || (borrowerCurrentEmployment.employmentTypeId === EmploymentTypeEnum.OtherOrUnemployed);
    };
    LoanUtils.getCurrentEmploymentInfo = function (loan, borrower) {
        var filtered = LoanUtils.getBorrowerEmploymentInfo(loan, borrower).filter(function (o) { return !o.isAdditional; });
        return filtered && filtered.length ? filtered[0] : null;
    };
    LoanUtils.getBorrowerEmploymentInfo = function (loan, borrower) {
        if (!(loan
            && loan.transactionInfo
            && loan.transactionInfo.employments
            && loan.transactionInfo.employments.length))
            return [];
        return loan.transactionInfo.employments.filter(function (o) { return !o.isRemoved && o.borrowerId === borrower.borrowerId; });
    };
    LoanUtils.getBorrowerTotalIncomeAmount = function (loan, borrower) {
        var employmentInfo = LoanUtils.getCurrentEmploymentInfo(loan, borrower);
        var totalIncomeAmount = employmentInfo.totalMonthlyAmount * 12;
        var otherIncomes = this.getOtherIncomes(loan, borrower);
        otherIncomes.forEach(function (o) { return totalIncomeAmount += (o.calculatedMonthlyAmount * 12); });
        return totalIncomeAmount;
    };
    LoanUtils.getIncomes = function (loan) {
        return loan
            && loan.transactionInfo
            && loan.transactionInfo.incomes
            || [];
    };
    LoanUtils.getOtherIncomes = function (loan, borrower) {
        var incomes = LoanUtils.getIncomes(loan);
        return incomes.filter(function (income) {
            return income.borrowerId === borrower.borrowerId
                && !income.isRemoved
                && (!income.employmentInfoId || income.employmentInfoId === '00000000-0000-0000-0000-000000000000');
        });
    };
    LoanUtils.getIncomeInformation = function (loan, employment) {
        var incomes = LoanUtils.getIncomes(loan);
        return incomes.filter(function (o) { return o.employmentInfoId === employment.employmentInfoId; });
    };
    LoanUtils.incomeInfoByTypeId = function (incomes, typeId) {
        var incomeFiltered = incomes.filter(function (i) { return i.incomeTypeId === typeId; });
        return incomeFiltered.length > 0 ? incomeFiltered[0] : null;
    };
    LoanUtils.hasCreditRan = function (loan) {
        var borrower = LoanUtils.getBorrower(loan);
        if (!borrower)
            return false;
        return borrower.ficoScore
            && (!!borrower.ficoScore.decisionScore
                || !!borrower.ficoScore.equifax
                || !!borrower.ficoScore.experian
                || !!borrower.ficoScore.transunion);
    };
    LoanUtils.getBorrowersByLoanApplicationId = function (loan, loanApplicationId) {
        var allBorrowers = LoanUtils.getBorrowers(loan);
        return allBorrowers.filter(function (borrower) {
            return borrower.loanApplicationId === loanApplicationId;
        });
    };
    LoanUtils.isBaseLoan = function (loan) {
        return loan && loan.isBaseLoan;
    };
    LoanUtils.isLoanProductSelected = function (loan) {
        return loan && loan.product && loan.product.ruCode != null && loan.product.ruCode !== '';
    };
    LoanUtils.isURLA2020 = function (loanURLAFormType) {
        return loanURLAFormType !== URLAFormTypeEnum.URLA2009;
    };
    LoanUtils.isLoanPurposeTypePurchase = function (loan) {
        return propertyIsDefined(loan, 'loanPurposeType') && loan.loanPurposeType === LoanPurposeTypeEnum.Purchase;
    };
    LoanUtils.isLoanRefiOrPurchase = function (loan) {
        return propertyHasValue(loan, 'loanPurposeType', ValueTypeRule.GreaterThanZero) && loan.loanPurposeType === LoanPurposeTypeEnum.Purchase ?
            LoanPurposeTypeEnum.Purchase : LoanPurposeTypeEnum.Refinance;
    };
    return LoanUtils;
}());
export { LoanUtils };
