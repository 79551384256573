var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { OnInit, OnChanges, EventEmitter } from '@angular/core';
import { UIStoreService } from '$ui';
import { combineLatest } from 'rxjs';
import { map, filter, distinctUntilChanged, startWith } from 'rxjs/operators';
export var FieldTypes;
(function (FieldTypes) {
    FieldTypes["backgroundcolor"] = "backgroundcolor";
    FieldTypes["backgroundimage"] = "backgroundimage";
    FieldTypes["textcolor"] = "textcolor";
    FieldTypes["textline1"] = "textline1";
    FieldTypes["textline2"] = "textline2";
    FieldTypes["overlap"] = "overlap";
})(FieldTypes || (FieldTypes = {}));
var BannerComponent = /** @class */ (function () {
    function BannerComponent(ui) {
        var _this = this;
        this.ui = ui;
        this.changed = new EventEmitter();
        //Bug 382496 Added startWith so that banner has initial value
        //seems to be a timing issue, as it behaves differently depending on the environment
        this.banner$ = combineLatest(this.ui.select.config$, this.changed).pipe(map(function (_a) {
            var _b = __read(_a, 1), config = _b[0];
            return config;
        }), filter(function (config) { return !!config; }), distinctUntilChanged(), map(function (config) { return _this.bannerConfigCreate(config, _this.location); }), startWith({}));
    }
    BannerComponent.prototype.ngOnInit = function () {
    };
    BannerComponent.prototype.ngOnChanges = function () {
        this.changed.emit();
    };
    /**
     * Extract the banner properties from the config and create a banner object
     * Supports global level banner properties with page specific overrides
     * @param config
     * @param location
     */
    BannerComponent.prototype.bannerConfigCreate = function (config, location) {
        var configValue = function (key) { return config[key] ? config[key].value : null; };
        var banner = {
            backgroundcolor: configValue('clover.global.banner.background.color'),
            backgroundimage: configValue('clover.global.banner.background.image'),
            textcolor: configValue('clover.global.banner.text.color'),
            textline1: configValue('clover.global.banner.text.line1'),
            textline2: configValue('clover.global.banner.text.line2'),
            overlap: configValue('clover.global.banner.overlap'),
        };
        // If a location is passed, override properties in the banner object IF PRESENT
        if (location) {
            // Rename some locations to match config values
            if (location === 'loan-purpose')
                location = 'purpose';
            if (location === 'review')
                location = 'submit';
            // Loop through the config properties
            Object.keys(config).forEach(function (key) {
                // Split to be able to get current location
                var locCurrent = key.split('.');
                // Location property is always in index 1, check to see if there is a match
                if (location === locCurrent[1] && locCurrent[2] === 'banner') {
                    // Extract the property to override
                    var propOverride = locCurrent[locCurrent.length - 2] + locCurrent[locCurrent.length - 1];
                    // If present, add OR override
                    banner[propOverride] = config[key].value;
                }
            });
        }
        // Banner must have at least one value in order to show
        var hasValues = Object.values(banner).reduce(function (acc, value) { return acc || !!value; }, false);
        // If at least one banner property is set, return banner object, otherwise return false
        // console.log(hasValues, banner);
        return hasValues ? banner : null;
    };
    return BannerComponent;
}());
export { BannerComponent };
