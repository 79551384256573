import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, of } from 'rxjs';
import { AppSettings } from '../app.settings';
import { ApiService } from '$api';
import { map, tap, withLatestFrom, take } from 'rxjs/operators';
import { LoanUtils } from '../utils/loan-utils';
import { AuthService } from './auth.service';
import {
  IBorrowerViewModel,
  ILoanViewModel,
  ConsentStatusEnum,
  ICPOSSrvApiResponse,
  DocDeliveryTypeEnum
 } from 'src/app/shared/models';

export interface EconsentResult {
  showStep?: number;
  borrowersToDecline?: IBorrowerViewModel[];
  noChanges?: boolean;
  wasEconsentSuccessful?: boolean;
  isCoborrowerTurn?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class EconsentService {
  borrowersAccepted?: IBorrowerViewModel[];
  numberOfConsentingBorrowersFromLastEconsentUpdate$: BehaviorSubject<number> = new BehaviorSubject(0);

  /** Observable for keeping track of whether or not all borrowers have consented*/
  private _haveAllRequiredborrowersConsented$: ReplaySubject<boolean>;
  /** Observable for checking if at least one borrower has eConsented */
  atLeastOneBorrowerEconsentedOnLoan$ = this.api.getApiStoreData(this.api.select.megaLoan$)
    .pipe(
      map((loan) => this.atLeastOneBorrowerEconsented(loan)),
    );

  atLeastOneBorrowerEconsented$ = this.atLeastOneBorrowerEconsentedOnLoan$
    .pipe(
      withLatestFrom(this.numberOfConsentingBorrowersFromLastEconsentUpdate$),
      map(([atLeastOneBorrowerEconsentedOnLoan, numberOfConsentingBorrowersFromLastEconsentUpdate$]) => {
      return atLeastOneBorrowerEconsentedOnLoan || numberOfConsentingBorrowersFromLastEconsentUpdate$ > 0;
   }));

  constructor(
    private http: HttpClient,
    private settings: AppSettings,
    private api: ApiService,
    private auth: AuthService,
  ) {
    //Initialize haveAllRequiredborrowersConsented$ when service is loaded 
    this.api.getApiStoreData(this.api.select.megaLoan$).pipe(
      map((loan) => this.haveAllBorrowersEconsented(loan)),
      take(1))
      .subscribe((haveAllBorrowersEconsented: boolean) => {
        this.haveAllRequiredborrowersConsented$.next(haveAllBorrowersEconsented);
    });
  }

  /**
   * 
   */
  get haveAllRequiredborrowersConsented$(): ReplaySubject<boolean> {
    // This ensures that a new replay subject is used between different user sessions,
    // which prevents values from previous user session from being used in a subsequent session.
    const bufferReplaySize = 1;
    if (!this._haveAllRequiredborrowersConsented$) {
      this._haveAllRequiredborrowersConsented$ = new ReplaySubject<boolean>(bufferReplaySize);
      this.auth.attachPreLogOutAction(() => {
        // Close and remove the current replay subject
        this._haveAllRequiredborrowersConsented$.complete();
        this._haveAllRequiredborrowersConsented$ = undefined;
        //attachPreLogOutAction requires a non-empty observable
        return of(1);
      });

      this.api.getApiStoreData(this.api.select.megaLoan$).pipe(
        map((loan) => this.haveAllBorrowersEconsented(loan)),
        take(1))
        .subscribe((haveAllBorrowersEconsented: boolean) => {
          this._haveAllRequiredborrowersConsented$.next(haveAllBorrowersEconsented);
       });

    }
    return this._haveAllRequiredborrowersConsented$;
  }

  /**
   * Determination based on loan
   * @param loan
   */
  atLeastOneBorrowerEconsented(loan: ILoanViewModel): boolean {
    const borrowers = LoanUtils.getBorrowersRequiringEconsent(loan, +this.settings.userId);
    return borrowers.reduce((acc, borrower) => {
      return this.hasBorrowerEconsented(borrower) || acc;
    }, false);
  }

  /**
   * Check if all required borrowers for a 1003 have eConsented on load
   * We only check this once on service load and when loan refreshes
   * @param loan
   */
  haveAllBorrowersEconsented(loan: ILoanViewModel): boolean {
    const borrowersEconsented = LoanUtils.getBorrowersEconsented(loan);
    this.borrowersAccepted = borrowersEconsented;
    const totalBorrowerCountRequired = LoanUtils.getCoBorrower(loan) != null ? LoanUtils.getBorrowers(loan).length : 1;
    return borrowersEconsented.length >= totalBorrowerCountRequired;
  }
  /**
   * Take an array of borrowers and save the eConsent status for each
   * @param borrowers Array of IBorrowers
   * @param acceptConsent Is eConsent being accepted
   */
  saveEconsentStatus(borrowers: IBorrowerViewModel[], showCommunicationConsentText: boolean): Observable<any> {
    const callTimestamp = new Date().getTime().toString();
    const url = `${this.settings.apiUrl}/api/ConsumerSiteService/BorrowerEConsent`;
    const params = {
      callTimestamp,
      userId: 'User',
    };


    var consentingBorrowers = borrowers.filter(borrower => {
      return borrower.eConsent.consentStatus === ConsentStatusEnum.Accept;
    });
    const numberOfConsentingBorrowers = consentingBorrowers.length;

    // Create a API request for each borrower that needs to be saved
    const httpRequests = borrowers.map(borrower => {
      return this.http.post<ICPOSSrvApiResponse<null>>(url, {
        borrowerViewModel: borrower,
        documentDeliveryType: (numberOfConsentingBorrowers > 0 ? DocDeliveryTypeEnum.Electronic : DocDeliveryTypeEnum.Mail),
        loanId: this.settings.loanId,
        communicationConsentTextAvailable: showCommunicationConsentText
      }, {params}).pipe(map((response) => {
        if (response && response.errorMsg) {
          throw new Error(response.errorMsg);
        } else {
          return response;
        }
      }));
    });

    return combineLatest(httpRequests)
      .pipe(tap(() => {
        // As a side affect, update the number of consenting borrowers
        if (this.settings.config['cPOS.eConsent.JointApplicants.AskAllBorrowerseConsent'].value == true) {
          this.api.getApiStoreData(this.api.select.megaLoan$).pipe(
            map((loan) => LoanUtils.getCoBorrower(loan) != null ? LoanUtils.getBorrowers(loan).length : 1), 
            take(1)
          ).subscribe((borrowersRequired: number) => {
            this.borrowersAccepted = this.borrowersAccepted.concat(consentingBorrowers);
            if (this.borrowersAccepted.length >= borrowersRequired) {
              this.haveAllRequiredborrowersConsented$.next(true);
            }
          });
        } else {
          this.numberOfConsentingBorrowersFromLastEconsentUpdate$.next(numberOfConsentingBorrowers);
        }
      }));
  }

  /**
   * Decline consent for all provided borrowers
   * @param borrowers
   */
  declineEconsentForBorrowers(borrowers: IBorrowerViewModel[]): Observable<any> {
    // Update the borrowers before sending to back-end
    let borrowersToDecline = this.updateEconsentDate(borrowers);
    borrowersToDecline = this.setEconsentStatus(borrowersToDecline, ConsentStatusEnum.Decline);
    // Return update call
    return this.saveEconsentStatus(borrowersToDecline, false);
  }

  /**
   * Update the signed dates
   * @param borrowers Array of borrowers to update
   */
  updateEconsentDate(borrowers: IBorrowerViewModel[]): IBorrowerViewModel[] {
    return borrowers.map(borrower => {
      // Update the signed dates
      borrower.eConsent.statusAt = new Date().toISOString();
      return borrower;
    });
  }

  /**
   * Update the status
   * @param borrowers Array of borrowers to update
   * @param status New status to assign to all borrowers
   */
  setEconsentStatus(borrowers: IBorrowerViewModel[], status: ConsentStatusEnum): IBorrowerViewModel[] {
    return borrowers.map(borrower => {
      // Update the signed dates
      borrower.eConsent.consentStatus = status;
      return borrower;
    });
  }

  /**
   * Check if a single borrower has eConsented
   * @param borrower
   */
  hasBorrowerEconsented(borrower: IBorrowerViewModel): boolean {
    return !!borrower
      && !!borrower.eConsent
      && borrower.eConsent.consentStatus === ConsentStatusEnum.Accept;
  }

}
