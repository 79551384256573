<div role="alert" class="alert alert-danger icon" *ngIf="error" automationid="Section_Error">
  <button type="button" class="close float-right" aria-label="Close" *ngIf="canClose" (click)="error = null">
    <span aria-hidden="true">&times;</span>
  </button>

  <!-- Transclude the error message, default to span if none specified-->
  <div #ref class="d-inline"><ng-content></ng-content></div>
  <div class="d-inline" *ngIf="!ref.innerHTML.trim()">An error has occurred.</div>

  <div class="mt-2">
    <!-- Show error on load -->
    <ng-container *ngIf="showError">
      <hr class="my-2 mx-0" />
      <pre class="mb-0">{{ error }}</pre>
    </ng-container>
    <!-- Show accordion not error on load-->
    <ng-container *ngIf="!showError">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> See error details </mat-panel-title>
          </mat-expansion-panel-header>
          <pre class="mb-0">{{ error }}</pre>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>
  </div>

</div>
