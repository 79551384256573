import { FormControl } from "@angular/forms";
import { CPOSdateTypeEnum } from 'src/app/shared/models';

/**
 * Is the supplied year a leap year?
 * @param year A four digit string or number of the year, IE YYYY
 */
export const isLeapYear = (year: number | string) => {
  if (typeof year === 'string') {
    year = parseInt(year);
  }
  return (year % 100 === 0) ? (year % 400 === 0) : (year % 4 === 0);
};

/**
 * Check that the supplied date falls with in the correct range for dates
 * @param dob Requires format: YYYY-MM-DD
 * @param maxAge Max age allowed for dob
 */
export const isValidDobPurchaseDate = (date: string, maxAge = 125, minYear: number = null) => {
  if (!date || typeof date !== 'string') {
    return date;
  }

  // Split date string to extract elements
  const dateSplit = date.split('-').map(x => parseInt(x));
  // Easier to use syntax
  const dateObj = {
    year: dateSplit[0],
    month: dateSplit[1],
    day: dateSplit[2],
  };

  // Get today
  const todayDate = new Date();
  // Convert today to more usable syntax
  const todayObj = {
    year: todayDate.getFullYear(),
    month: todayDate.getMonth() + 1,
    day: todayDate.getDate(),
  };

  // Ordered array of months with the # of days in each month. 0 = Jan etc
  const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  var maxDaysInMonth = daysInMonth[dateObj.month - 1];
  if (dateObj.month === 2 && isLeapYear(dateObj.year)) {
    maxDaysInMonth = 29;
  }

  if (
    (dateObj.year < (!minYear ? (todayObj.year - maxAge) : minYear)) || // DOB is less than max allowed age
    (dateObj.year > todayObj.year) || // DOB is greater than today
    (dateObj.year < 1900) || // DoB can not be less than 1900
    (dateObj.month <= 0) || // Month is not less than 0
    (dateObj.month > 12) || // Month is not greater than 12
    (dateObj.day <= 0) || // Day is not less than 0
    (dateObj.day > maxDaysInMonth)
  ) {
    return false;
  }

  return true;
};

export const isAgeLessThan18 = (date: string) => {
  const diff = new Date(new Date().getTime() - new Date(date).getTime());

  if (diff.getUTCFullYear() - 1970 < 18) {
    return false;
  }

  return true;
}

// Start Date CAN NOT be any date after today.
export const isStartDateValid = (date: string): boolean => {
  if (!date || typeof date !== 'string') {
    return false;
  }

  const dateSplit = date.split('-').map(x => parseInt(x));

  const startDate = {
    year: dateSplit[0],
    month: dateSplit[1],
    day: dateSplit[2],
  };

  const todayDate = new Date();
  const today = {
    year: todayDate.getFullYear(),
    month: todayDate.getMonth() + 1,
    day: todayDate.getDate(),
  };

  if (
    startDate.year > today.year ||
    (startDate.year === today.year && startDate.month > today.month) ||
    (startDate.year === today.year && startDate.month === today.month && startDate.day > today.day)) {

    return false;
  }

  return true;
}

// End Date CAN NOT be any date before the entered Start Date.
export const isEndDateValid = (dateStart: string, dateEnd: string): boolean | string => {
  if (!dateEnd) {
    return true;
  }

  if (typeof dateEnd !== 'string' || typeof dateStart !== 'string') {
    return null;
  }

  const dateStartSplit = dateStart.split('-').map(x => parseInt(x));
  const dateEndSplit = dateEnd.split('-').map(x => parseInt(x));

  const startDate = {
    year: dateStartSplit[0],
    month: dateStartSplit[1],
    day: dateStartSplit[2],
  };

  const endDate = {
    year: dateEndSplit[0],
    month: dateEndSplit[1],
    day: dateEndSplit[2],
  }

  if (
    (startDate.year > endDate.year) ||
    (startDate.year === endDate.year && startDate.month > endDate.month) ||
    (startDate.year === endDate.year && startDate.month === endDate.month && startDate.day >= endDate.day) ||
    !isStartDateValid(dateEnd)) {

    return false;
  }

  return true;
}

export const isProjectedEndOfServiceValid = (date: string) => {
  if (date && date !== '') {
    const dateArray = date.split("-"); // ["YYYY", "MM", "DD"]
    const year = +dateArray[0];
    const month = +dateArray[1];
    const day = +dateArray[2];

    const todayDate = new Date();
    const today = {
      year: todayDate.getFullYear(),
      month: todayDate.getMonth() + 1,
      day: todayDate.getDate(),
    };

    if (year < today.year ||
      (year === today.year && month < today.month) ||
      (year === today.year && month === today.month && day <= today.day)) {
      return false;
    }
  }

  return true;
}

export const isMonthValid = (month: number): boolean => {
  if (month > 12 || month === 0) {
    return false;
  }

  return true;
}

export const isDayValid = (month: number, day: number, year: number): boolean => {
  const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  var maxDaysInMonth = daysInMonth[month - 1];
  if (month === 2 && isLeapYear(year)) {
    maxDaysInMonth = 29;
  }
  if (day > maxDaysInMonth || day === 0) {
    return false;
  }
  if (isYearValid(year) && month === 2 && !isLeapYear(year) && day === 29) {
    return false;
  }
  return true;
}

export const isYearValid = (year: number): boolean => {
  if (year > 2099 || year < 1900) {
    return false;
  }

  return true;
}

export const startDateEndDateValidation = (page: CvFormBuilder.PageControl, _form: any, hasStartDate = true): boolean => {
  let startDateFormControl: FormControl;
  let endDateFormControl: FormControl;
  let projectedEndOfServiceFormControl: FormControl;

  page.content.forEach((element: any) => {
    if (element.isVisible && element.type === 'feature' && element.featureId === 'date-control' && element.fields && element.data) {
      const controlId = ((<CvFormBuilder.Feature>element).fields[0]).field;
      switch (element.data.dateType) {
        case CPOSdateTypeEnum.start:
          startDateFormControl = page.controlsById[controlId];
          break;
        case CPOSdateTypeEnum.end:
          endDateFormControl = page.controlsById[controlId];
          break;
        case CPOSdateTypeEnum.projectedEndOfService:
          projectedEndOfServiceFormControl = page.controlsById[controlId];
          break;
      }
    }
  });

  if (hasStartDate) {
    if (!startDateFormControl || !startDateFormControl.value || startDateFormControl.value.length < 10) {
      return false;
    } else {
      const startDate: string = startDateFormControl.value;
      const startDateArray = startDate.split('-').map((value: string) => parseInt(value));
      const year = startDateArray[0];
      const month = startDateArray[1];
      const day = startDateArray[2];

      if (
        !(month && day && year && isMonthValid(month) && isDayValid(month, day, year) && isYearValid(year)) ||
        !isStartDateValid(startDateFormControl.value)
      ) {
        return false;
      }
    }
  }

  if (endDateFormControl && endDateFormControl.value) {
    if (endDateFormControl.value.length < 10 || endDateFormControl.value.length > 10) {
      return false;
    } else {
      const endDate: string = endDateFormControl.value;
      const endDateArray = endDate.split('-').map((value: string) => parseInt(value));
      const year = endDateArray[0];
      const month = endDateArray[1];
      const day = endDateArray[2];

      if (
        !(month && day && year && isMonthValid(month) && isDayValid(month, day, year) && isYearValid(year)) ||
        !isEndDateValid(startDateFormControl.value, endDateFormControl.value)
      ) {
        return false;
      }
    }
  }

  if (projectedEndOfServiceFormControl && projectedEndOfServiceFormControl.value) {
    const projectedEndOfServiceDate: string = projectedEndOfServiceFormControl.value;
    if (projectedEndOfServiceDate.length < 10 || projectedEndOfServiceDate.length > 10) {
      return false;
    } else {
      const projectedEndOfServiceDateArray = projectedEndOfServiceDate.split('-').map((value: string) => parseInt(value));
      const year = projectedEndOfServiceDateArray[0];
      const month = projectedEndOfServiceDateArray[1];
      const day = projectedEndOfServiceDateArray[2];

      if (
        !(month && day && year && isMonthValid(month) && isDayValid(month, day, year) && isYearValid(year)) ||
        !isProjectedEndOfServiceValid(projectedEndOfServiceDate)
      ) {
        return false;
      }
    }
  }

  return true;
}

export const isPreferredDateValid = (date: string) => {
  if (!date || typeof date !== 'string') {
    return false;
  }

  const dateSplit = date.split('-').map(x => parseInt(x));

  const preferredDate = {
    year: dateSplit[0],
    month: dateSplit[1],
    day: dateSplit[2],
  };

  const todayDate = new Date();
  const today = {
    year: todayDate.getFullYear(),
    month: todayDate.getMonth() + 1,
    day: todayDate.getDate(),
  };

  if (
    preferredDate.year < today.year ||
    (preferredDate.year === today.year && preferredDate.month < today.month) ||
    (preferredDate.year === today.year && preferredDate.month === today.month && preferredDate.day <= today.day)) {

    return false;
  }

  return true;
}
