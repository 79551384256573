import { Component } from '@angular/core';

@Component({
  selector: 'app-layout-simple',
  styleUrls: ['./layout-simple.component.scss'],
  templateUrl: './layout-simple.component.html',
})
export class LayoutSimpleComponent  {

}
