import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class Form1003Guard implements CanActivate {
  constructor(
  ) { }

  // Used only for the 1003. Can be used in the future for any 1003-specific logic
  canActivate() {

    return true;
  }
}
