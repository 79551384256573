var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, combineLatest, ReplaySubject, of } from 'rxjs';
import { AppSettings } from '../app.settings';
import { ApiService } from '$api';
import { map, tap, withLatestFrom, take } from 'rxjs/operators';
import { LoanUtils } from '../utils/loan-utils';
import { AuthService } from './auth.service';
import { ConsentStatusEnum, DocDeliveryTypeEnum } from 'src/app/shared/models';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../app.settings";
import * as i3 from "../stores/api/api.store.service";
import * as i4 from "./auth.service";
var EconsentService = /** @class */ (function () {
    function EconsentService(http, settings, api, auth) {
        var _this = this;
        this.http = http;
        this.settings = settings;
        this.api = api;
        this.auth = auth;
        this.numberOfConsentingBorrowersFromLastEconsentUpdate$ = new BehaviorSubject(0);
        /** Observable for checking if at least one borrower has eConsented */
        this.atLeastOneBorrowerEconsentedOnLoan$ = this.api.getApiStoreData(this.api.select.megaLoan$)
            .pipe(map(function (loan) { return _this.atLeastOneBorrowerEconsented(loan); }));
        this.atLeastOneBorrowerEconsented$ = this.atLeastOneBorrowerEconsentedOnLoan$
            .pipe(withLatestFrom(this.numberOfConsentingBorrowersFromLastEconsentUpdate$), map(function (_a) {
            var _b = __read(_a, 2), atLeastOneBorrowerEconsentedOnLoan = _b[0], numberOfConsentingBorrowersFromLastEconsentUpdate$ = _b[1];
            return atLeastOneBorrowerEconsentedOnLoan || numberOfConsentingBorrowersFromLastEconsentUpdate$ > 0;
        }));
        //Initialize haveAllRequiredborrowersConsented$ when service is loaded 
        this.api.getApiStoreData(this.api.select.megaLoan$).pipe(map(function (loan) { return _this.haveAllBorrowersEconsented(loan); }), take(1))
            .subscribe(function (haveAllBorrowersEconsented) {
            _this.haveAllRequiredborrowersConsented$.next(haveAllBorrowersEconsented);
        });
    }
    Object.defineProperty(EconsentService.prototype, "haveAllRequiredborrowersConsented$", {
        /**
         *
         */
        get: function () {
            var _this = this;
            // This ensures that a new replay subject is used between different user sessions,
            // which prevents values from previous user session from being used in a subsequent session.
            var bufferReplaySize = 1;
            if (!this._haveAllRequiredborrowersConsented$) {
                this._haveAllRequiredborrowersConsented$ = new ReplaySubject(bufferReplaySize);
                this.auth.attachPreLogOutAction(function () {
                    // Close and remove the current replay subject
                    _this._haveAllRequiredborrowersConsented$.complete();
                    _this._haveAllRequiredborrowersConsented$ = undefined;
                    //attachPreLogOutAction requires a non-empty observable
                    return of(1);
                });
                this.api.getApiStoreData(this.api.select.megaLoan$).pipe(map(function (loan) { return _this.haveAllBorrowersEconsented(loan); }), take(1))
                    .subscribe(function (haveAllBorrowersEconsented) {
                    _this._haveAllRequiredborrowersConsented$.next(haveAllBorrowersEconsented);
                });
            }
            return this._haveAllRequiredborrowersConsented$;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Determination based on loan
     * @param loan
     */
    EconsentService.prototype.atLeastOneBorrowerEconsented = function (loan) {
        var _this = this;
        var borrowers = LoanUtils.getBorrowersRequiringEconsent(loan, +this.settings.userId);
        return borrowers.reduce(function (acc, borrower) {
            return _this.hasBorrowerEconsented(borrower) || acc;
        }, false);
    };
    /**
     * Check if all required borrowers for a 1003 have eConsented on load
     * We only check this once on service load and when loan refreshes
     * @param loan
     */
    EconsentService.prototype.haveAllBorrowersEconsented = function (loan) {
        var borrowersEconsented = LoanUtils.getBorrowersEconsented(loan);
        this.borrowersAccepted = borrowersEconsented;
        var totalBorrowerCountRequired = LoanUtils.getCoBorrower(loan) != null ? LoanUtils.getBorrowers(loan).length : 1;
        return borrowersEconsented.length >= totalBorrowerCountRequired;
    };
    /**
     * Take an array of borrowers and save the eConsent status for each
     * @param borrowers Array of IBorrowers
     * @param acceptConsent Is eConsent being accepted
     */
    EconsentService.prototype.saveEconsentStatus = function (borrowers, showCommunicationConsentText) {
        var _this = this;
        var callTimestamp = new Date().getTime().toString();
        var url = this.settings.apiUrl + "/api/ConsumerSiteService/BorrowerEConsent";
        var params = {
            callTimestamp: callTimestamp,
            userId: 'User',
        };
        var consentingBorrowers = borrowers.filter(function (borrower) {
            return borrower.eConsent.consentStatus === ConsentStatusEnum.Accept;
        });
        var numberOfConsentingBorrowers = consentingBorrowers.length;
        // Create a API request for each borrower that needs to be saved
        var httpRequests = borrowers.map(function (borrower) {
            return _this.http.post(url, {
                borrowerViewModel: borrower,
                documentDeliveryType: (numberOfConsentingBorrowers > 0 ? DocDeliveryTypeEnum.Electronic : DocDeliveryTypeEnum.Mail),
                loanId: _this.settings.loanId,
                communicationConsentTextAvailable: showCommunicationConsentText
            }, { params: params }).pipe(map(function (response) {
                if (response && response.errorMsg) {
                    throw new Error(response.errorMsg);
                }
                else {
                    return response;
                }
            }));
        });
        return combineLatest(httpRequests)
            .pipe(tap(function () {
            // As a side affect, update the number of consenting borrowers
            if (_this.settings.config['cPOS.eConsent.JointApplicants.AskAllBorrowerseConsent'].value == true) {
                _this.api.getApiStoreData(_this.api.select.megaLoan$).pipe(map(function (loan) { return LoanUtils.getCoBorrower(loan) != null ? LoanUtils.getBorrowers(loan).length : 1; }), take(1)).subscribe(function (borrowersRequired) {
                    _this.borrowersAccepted = _this.borrowersAccepted.concat(consentingBorrowers);
                    if (_this.borrowersAccepted.length >= borrowersRequired) {
                        _this.haveAllRequiredborrowersConsented$.next(true);
                    }
                });
            }
            else {
                _this.numberOfConsentingBorrowersFromLastEconsentUpdate$.next(numberOfConsentingBorrowers);
            }
        }));
    };
    /**
     * Decline consent for all provided borrowers
     * @param borrowers
     */
    EconsentService.prototype.declineEconsentForBorrowers = function (borrowers) {
        // Update the borrowers before sending to back-end
        var borrowersToDecline = this.updateEconsentDate(borrowers);
        borrowersToDecline = this.setEconsentStatus(borrowersToDecline, ConsentStatusEnum.Decline);
        // Return update call
        return this.saveEconsentStatus(borrowersToDecline, false);
    };
    /**
     * Update the signed dates
     * @param borrowers Array of borrowers to update
     */
    EconsentService.prototype.updateEconsentDate = function (borrowers) {
        return borrowers.map(function (borrower) {
            // Update the signed dates
            borrower.eConsent.statusAt = new Date().toISOString();
            return borrower;
        });
    };
    /**
     * Update the status
     * @param borrowers Array of borrowers to update
     * @param status New status to assign to all borrowers
     */
    EconsentService.prototype.setEconsentStatus = function (borrowers, status) {
        return borrowers.map(function (borrower) {
            // Update the signed dates
            borrower.eConsent.consentStatus = status;
            return borrower;
        });
    };
    /**
     * Check if a single borrower has eConsented
     * @param borrower
     */
    EconsentService.prototype.hasBorrowerEconsented = function (borrower) {
        return !!borrower
            && !!borrower.eConsent
            && borrower.eConsent.consentStatus === ConsentStatusEnum.Accept;
    };
    EconsentService.ngInjectableDef = i0.defineInjectable({ factory: function EconsentService_Factory() { return new EconsentService(i0.inject(i1.HttpClient), i0.inject(i2.AppSettings), i0.inject(i3.ApiService), i0.inject(i4.AuthService)); }, token: EconsentService, providedIn: "root" });
    return EconsentService;
}());
export { EconsentService };
