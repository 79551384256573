import { UIStoreService } from '$ui';
import { take } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../stores/ui/ui.store.service";
var HeadContentService = /** @class */ (function () {
    function HeadContentService(ui) {
        this.ui = ui;
        // Store DOM element IDs
        this.insertedDOMIds = [];
    }
    /**
     * Manage DOM elements inserted into the <head> tag
     */
    HeadContentService.prototype.start = function () {
        var _this = this;
        // When styling changes come through the UI store
        this.ui.select.config$.pipe(take(2)).subscribe(function (configs) {
            _this.clearPreviousDOMElements();
            _this.createNewDOMElements(configs);
            _this.updateExistingDOMElements(configs);
        });
    };
    /**
     * Remove any previously inserted DOM elements from <head>
     */
    HeadContentService.prototype.clearPreviousDOMElements = function () {
        if (!this.insertedDOMIds.length)
            return;
        // Reference to <head> tag
        var head = document.head || document.getElementsByTagName('head')[0];
        // Remove each element
        this.insertedDOMIds.forEach(function (id) {
            var elem = document.getElementById(id);
            head.removeChild(elem);
        });
        // Clear array
        this.insertedDOMIds = [];
    };
    /**
     * Create each DOM element and insert into <head>
     * @param configs
     */
    HeadContentService.prototype.createNewDOMElements = function (configs) {
        var webfontCSSURL = configs['clover.global.font.url']
            && configs['clover.global.font.url'].value
            || null;
        var newDOMStrings = [];
        // Add web font CSS URL
        if (webfontCSSURL) {
            newDOMStrings.push("<link href=\"" + webfontCSSURL + "\" rel=\"stylesheet\">");
        }
        // Convert DOM element strings to DOM elements
        var elements = this.createDOMElementsFromStrings(newDOMStrings);
        // Insert DOM elements into <head>
        this.insertDOMElements(elements);
    };
    /**
     * Create each DOM element and insert into <head>
     * @param configs
     */
    HeadContentService.prototype.updateExistingDOMElements = function (configs) {
        // Store fav icon path
        var favIconPath = configs['clover.favicon.folder']
            && configs['clover.favicon.folder'].value
            || null;
        if (!favIconPath)
            return;
        var favIcon16 = document.getElementById("favicon-icon-16");
        var favIcon32 = document.getElementById("favicon-icon-32");
        var favIconApple180 = document.getElementById("favicon-apple-180");
        var faviconManifest = document.getElementById("favicon-manifest");
        var faviconSafariPinned = document.getElementById("favicon-safari-pinned");
        if (favIcon16)
            favIcon16.setAttribute("href", favIconPath + "favicon-16x16.png");
        if (favIcon32)
            favIcon32.setAttribute("href", favIconPath + "favicon-32x32.png");
        if (favIconApple180)
            favIconApple180.setAttribute("href", favIconPath + "apple-touch-icon.png");
        if (faviconManifest)
            faviconManifest.setAttribute("href", favIconPath + "site.webmanifest");
        if (faviconSafariPinned)
            faviconSafariPinned.setAttribute("href", favIconPath + "safari-pinned-tab.svg");
    };
    /**
     * Used to create DOM elements from strings
     * @param domStrings Strings to convert to DOM elements
     */
    HeadContentService.prototype.createDOMElementsFromStrings = function (domStrings) {
        if (domStrings === void 0) { domStrings = []; }
        var fragments = document.createRange().createContextualFragment(domStrings.join(''));
        // IE does not support .children
        var children = fragments.children
            ? fragments.children
            : fragments.childNodes;
        return Array.from(children);
    };
    /**
     * Take a DOM element and insert it into the head
     * @param domElements DOM elements to insert
     */
    HeadContentService.prototype.insertDOMElements = function (domElements) {
        var _this = this;
        // Reference to <head> tag
        var head = document.head || document.getElementsByTagName('head')[0];
        // Insert DOM element for each item in the array
        domElements.forEach(function (newElement) {
            // Add unique ID
            var randomGuid = _this.createGuid();
            newElement.id = randomGuid;
            // Store unique ID for each element
            _this.insertedDOMIds.push(randomGuid);
            head.appendChild(newElement);
        });
    };
    HeadContentService.prototype.createGuid = function () {
        var dt = new Date().getTime();
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            // tslint:disable-next-line:no-bitwise
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            // tslint:disable-next-line:no-bitwise
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    };
    HeadContentService.ngInjectableDef = i0.defineInjectable({ factory: function HeadContentService_Factory() { return new HeadContentService(i0.inject(i1.UIStoreService)); }, token: HeadContentService, providedIn: "root" });
    return HeadContentService;
}());
export { HeadContentService };
