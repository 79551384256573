import { NgZone } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject } from 'rxjs';
import { ModalsService } from '$modals';
import { environment } from '$env';
import { AppSettings } from '../app.settings';
import * as i0 from "@angular/core";
import * as i1 from "@angular/service-worker";
import * as i2 from "../../components/modals/modals.service";
import * as i3 from "../app.settings";
var ServiceWorkerService = /** @class */ (function () {
    function ServiceWorkerService(sw, modals, zone, settings) {
        this.sw = sw;
        this.modals = modals;
        this.zone = zone;
        this.settings = settings;
        /** Notify app when a new version is available */
        this.updateAvailable$ = new BehaviorSubject(false);
        /** Check for update this many minutes */
        this.checkInterval = 1; //
        /** Has the modal been popped already? */
        this.modalPopped = false;
    }
    /**
     * Enable service worker functionality which includes polling for updates
     */
    ServiceWorkerService.prototype.enable = function () {
        var _this = this;
        if (this.sw.isEnabled && this.settings.isBrowser) {
            // On initial load, check if service worker is available first
            this.sub = this.sw.available.subscribe(function () {
                _this.updateAvailable$.next(true);
                window.clearInterval(_this.counter);
                if (!_this.modalPopped) {
                    _this.openModal();
                    _this.modalPopped = true;
                }
            });
            // Check for update immediately on app load
            this.sw.checkForUpdate();
            this.pollForUpdates();
        }
    };
    /**
     * Disable service worker and stop polling
     */
    ServiceWorkerService.prototype.disable = function () {
        if (this.settings.isBrowser) {
            window.clearInterval(this.counter);
            this.sub.unsubscribe();
        }
    };
    /**
     * Start polling for SW updates
     */
    ServiceWorkerService.prototype.pollForUpdates = function () {
        var _this = this;
        // Service worker/zone.js has issue with setInterval https://github.com/angular/angular/issues/20970
        this.zone.runOutsideAngular(function () {
            if (_this.settings.isBrowser) {
                _this.counter = window.setInterval(function () {
                    _this.zone.run(function () { return _this.sw.checkForUpdate(); });
                }, _this.checkInterval * 1000 * 60);
            }
        });
    };
    /**
     * Open the modal asking the user to update
     */
    ServiceWorkerService.prototype.openModal = function () {
        this.modals
            .open('ConfirmationModalComponent', false, 'lg', "A new version of " + environment.properties.appName + " is available, would you like to update to the latest version?")
            .afterClosed()
            .subscribe(function (reason) {
            if (reason) {
                window.location.reload();
                // this.sw.activateUpdate();
                // this.updateAvailable$.next(false);
                // this.pollForUpdates();
                // this.modalPopped = false;
            }
        }, function () { return console.warn('User is on an outdated version of the application'); });
    };
    ServiceWorkerService.ngInjectableDef = i0.defineInjectable({ factory: function ServiceWorkerService_Factory() { return new ServiceWorkerService(i0.inject(i1.SwUpdate), i0.inject(i2.ModalsService), i0.inject(i0.NgZone), i0.inject(i3.AppSettings)); }, token: ServiceWorkerService, providedIn: "root" });
    return ServiceWorkerService;
}());
export { ServiceWorkerService };
