import { PostMessageService } from './post-message.service';
import { UIStoreService } from '$ui';
import { environment } from '$env';
import { AppSettings } from '../app.settings';
import * as i0 from "@angular/core";
import * as i1 from "./post-message.service";
import * as i2 from "../stores/ui/ui.store.service";
import * as i3 from "../app.settings";
export var MessageActions;
(function (MessageActions) {
    MessageActions["RESYNC_UI"] = "RESYNC_UI";
    MessageActions["END_MULTISCREEN"] = "END_MULTISCREEN";
})(MessageActions || (MessageActions = {}));
/**
 * Manages communication between multiple app instances or apps that live on separate domains
 */
var AppCommsService = /** @class */ (function () {
    function AppCommsService(messaging, ui, settings) {
        this.messaging = messaging;
        this.ui = ui;
        this.settings = settings;
        /** Hold subs for unsub */
        this.subs = [];
    }
    /**
     * Start listening for app communication
     */
    AppCommsService.prototype.commsEnable = function () {
        var _this = this;
        this.subs = [
            // Watch UI Store changes and fire the resync UI method to update store state in all instances
            this.ui.select.saveState$.subscribe(function () { return _this.resyncUI(); }),
            // Listen for any interapp communication set by the listenTo env settings
            this.messaging.listenForMessages(environment.domains.listenTo).subscribe(function (message) {
                switch (message.event) {
                    // Resync any UI Changes
                    case MessageActions.RESYNC_UI:
                        // If a UI store state payload was passed, load that into the store
                        if (message.payload) {
                            _this.ui.storeStateRestore(message.payload);
                        }
                        else {
                            // Otherwise update UI state from localstorage
                            var str = _this.settings.uiState();
                            if (environment.settings.obfuscate) {
                                // If de-obfuscating errors out, remove ui store state and fail gracefully
                                str = _this.ui.obfuscateRemove(str);
                            }
                            // const state = JSON.parse(str);
                            var ui = JSON.parse(str);
                            _this.ui.storeStateRestore(ui);
                        }
                        break;
                    // Notify parent window that this window has closed
                    case MessageActions.END_MULTISCREEN:
                        _this.ui.toggle('multiScreen', false);
                        break;
                }
            }),
        ];
        // Manage the state of multiscreen functionality
        this.multiScreenState();
        if (this.settings.isBrowser) {
            // When this window is closed, tell parent to end multiscreen
            window.onbeforeunload = function () {
                if (window.opener) {
                    _this.messaging.postMessageToWindow(window.opener, { event: MessageActions.END_MULTISCREEN, payload: null });
                }
            };
        }
    };
    /**
     * Disable app communication
     */
    AppCommsService.prototype.commsDisable = function () {
        if (this.subs.length) {
            this.subs.forEach(function (sub) { return sub.unsubscribe(); });
        }
    };
    /**
     * Resync the UI state between multiple instances of the web app
     */
    AppCommsService.prototype.resyncUI = function () {
        if (this.settings.isBrowser) {
            if (this.ui.screen) {
                this.messaging.postMessageToWindow(this.ui.screen, { event: MessageActions.RESYNC_UI, payload: null });
            }
            else if (window.opener) {
                this.messaging.postMessageToWindow(window.opener, { event: MessageActions.RESYNC_UI, payload: null });
            }
        }
    };
    /**
     * Manage the state of multiscreen functionality
     */
    AppCommsService.prototype.multiScreenState = function () {
        var _this = this;
        if (this.settings.isBrowser) {
            // Get current path
            var slug_1 = window.location.origin + window.location.pathname;
            this.subs.push(this.ui.select.toggle$('multiScreen').subscribe(function (multiScreen) {
                // If multiscreen is present and a window is not yet open and has not been closed
                if (multiScreen && !_this.ui.screen && !window.opener) {
                    setTimeout(function () {
                        _this.ui.screen = window.open(slug_1 + '#/', 'App Instance');
                    });
                }
                else if (_this.ui.screen && _this.ui.screen.closed) {
                    // If window has been closed
                    _this.ui.screen = null;
                }
                else if (multiScreen && _this.ui.screen) {
                    // If multi screen has been set and a window is already opened, update url in current window
                    _this.ui.screen = window.open(slug_1 + '#/', 'App Instance');
                }
                else if (_this.ui.screen && multiScreen === false) {
                    // If screen is open and multiscreen is false, close window
                    _this.ui.screen.close();
                    _this.ui.screen = null;
                }
            }));
        }
    };
    AppCommsService.ngInjectableDef = i0.defineInjectable({ factory: function AppCommsService_Factory() { return new AppCommsService(i0.inject(i1.PostMessageService), i0.inject(i2.UIStoreService), i0.inject(i3.AppSettings)); }, token: AppCommsService, providedIn: "root" });
    return AppCommsService;
}());
export { AppCommsService };
