import { LoanUtils } from 'src/app/shared/utils/loan-utils';
import { ApiService } from '$api';
import { map } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material';
var ConfirmationNewLoanModalComponent = /** @class */ (function () {
    function ConfirmationNewLoanModalComponent(api, dialogRef) {
        var _this = this;
        this.api = api;
        this.dialogRef = dialogRef;
        this.isLoanWholesale$ = this.api.getApiStoreData(this.api.select.megaLoan$)
            .pipe(map(function (loan) { return _this.isWholesaleLoan(loan); }));
        this.submit = function () {
            _this.dialogRef.close(true);
        };
    }
    ConfirmationNewLoanModalComponent.prototype.isWholesaleLoan = function (loan) {
        var isWholesale = LoanUtils.isWholesale(loan);
        console.log("Is wholesale loan: " + isWholesale);
        return isWholesale;
    };
    Object.defineProperty(ConfirmationNewLoanModalComponent.prototype, "lenderName", {
        get: function () {
            return this.isWholesaleLoan ? 'Broker' : 'Loan Officer';
        },
        enumerable: true,
        configurable: true
    });
    return ConfirmationNewLoanModalComponent;
}());
export { ConfirmationNewLoanModalComponent };
