import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from '../app.settings';
import { UIStoreService } from '$ui';
import { environment } from '$env';
import { ICPOSSrvApiResponse, ILeadSourceInfoViewModel, ICPOSAppConfig, ICPOSEnvConfig } from 'src/app/shared/models';


/**
 * Manages receiving and setting initial environment settings
 */
@Injectable()
export class AppConfigService {
  constructor(private settings: AppSettings, private http: HttpClient, private ui: UIStoreService) { }

  /**
   * Load application settings/environment config, etc needed by the app before initializing
   * Note that LSID is required before configuration so that on the first load there is a double blocking async call
   * Responses are cached to app.settings to speed up subsequent load times
   */
  public loadAppSettings() {
    if (!this.settings.apiUrl) {
      return this.loadEnvConfig()
        .then(() => this.loadConfig());
    }

    if (!this.settings.lsid) {
      return this.loadLsid()
        .then(() => this.loadConfig());
    }

    if (!this.settings.config) {
      return this.loadConfig();
    }

    // Reload all configuration async in the background but resolve promise immediately to be non-blocking
    // This will handle edge cases where config has been cached for a user but has changed between sessions
    // This way theres no blocking load time but configuration is always refreshed in the background
    this.loadEnvConfig()
      .then(() => this.loadConfig());

    // Always check to see which LSID should be used
    return this.loadLsid();
  }

  /**
   * Load environment config settings from local json file
   */
  private loadEnvConfig() {
    return this.http
      .get<ICPOSEnvConfig>(environment.endpoints.envConfig)
      .toPromise()
      .then(data => {
        this.settings.apiUrl = data.apiUrl;
        this.settings.clientId = data.clientId;
        this.settings.mixpanelKey = data.mixpanelKey;
        // this.settings.temporaryUserId = '0';
        return this.loadLsid();
      })
      .catch((err: any) => {
        console.error('Error getting environment settings', err);
        this.settings.sessionStorageClear();
        return Promise.resolve();
      });
  }

  /**
   * Load the lead source ID which is needed to identify the client.
   * LSID it used by loanConfig to pull the correct configuration data for each client
   * @param url
   */
  private loadLsid() {
    this.settings.landingUrl = window.location.href || null;
    return this.http
      .get<ICPOSSrvApiResponse<ILeadSourceInfoViewModel>>(
        this.settings.apiUrl + environment.endpoints.client + `?url=${encodeURIComponent(window.location.href)}`,
      )
      .toPromise()
      .then(data => {
        // If no LSID is currently stored, or if the backend indicates the we should be prefer the LSID it's providing
        this.settings.isDefaultCorporateLeadSource = data.response.isDefaultCorporateLeadSource;
        if (!this.settings.lsid || (data && data.response && data.response.usingSuppliedLeadSource)) {
          this.settings.lsid = data.response.leadSourceId;
          this.settings.lsidUserId = data.response.userAccountId.toString();
        }
        return new Promise(resolve => resolve());
      })
      .catch((err: any) => {
        console.error('Error getting environment settings', err);
        this.settings.sessionStorageClear();
        return Promise.resolve();
      });
  }

  /**
   * Load client specific configuration data
   * @param url
   */
  public loadConfig(lsid?: string): Promise<any> {
    const lsidParam = lsid ? lsid : this.settings.lsid;
    return this.http
      .get<ICPOSAppConfig[]>(
        this.settings.apiUrl + environment.endpoints.config + '?leadSourceId=' + lsidParam,
      )
      .toPromise()
      .then(data => {
        const configs = this.mapConfig(data);
        this.ui.configChange(configs);
        return data;
      })
      .catch((err: any) => {
        console.error('Error getting environment settings', err);
        this.settings.sessionStorageClear();
        return Promise.resolve();
      });
  }

  /**
   * Convert the configuration settings into a dictionary and prune unnecessary entries
   * @param configs
   */
  private mapConfig(configs: ICPOSAppConfig[]) {
    const dict: Record<string, ICPOSAppConfig> = {};
    configs.forEach(config => {
      // Only add entries that have values
      if (config.value && config.value !== '' && config.value !== '-' && config.key !== 'clover.config.default') {
        let value = config.value;
        // Normalize booleans
        if (value === 'true' || value === 'True') {
          value = true;
        } else if (value === 'false' || value === 'False') {
          value = false;
        }
        dict[config.key] = {
          value: value,
          defaultValue: config.defaultValue || null,
        };

      }
    });
    return dict;
  }
}
