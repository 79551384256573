var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
import { Renderer2 } from '@angular/core';
import * as i0 from "@angular/core";
var TcpaVerbiageService = /** @class */ (function () {
    function TcpaVerbiageService() {
    }
    TcpaVerbiageService.prototype.applyVerbiage = function (renderer, target, subscriptions, options) {
        var _this = this;
        if (options.tcpaDisclaimerMarkup) {
            this.applyDisclaimerMarkup(renderer, target, options.tcpaDisclaimerMarkup);
        }
        Array.prototype.forEach.call(target.querySelectorAll('*[data-tag]'), function (element) {
            var e_1, _a;
            try {
                for (var _b = __values(element.getAttribute('data-tag').split(/\s+/)), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var tag = _c.value;
                    _this.applyTag(renderer, element, tag, subscriptions, options);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        });
    };
    TcpaVerbiageService.prototype.applyTag = function (renderer, element, tag, subscriptions, _a) {
        var onTermsClick = _a.onTermsClick, onPrivacyClick = _a.onPrivacyClick, onThirdPartyClick = _a.onThirdPartyClick, companyName = _a.companyName;
        switch (tag) {
            case 'link-terms':
                return this.setTagLink(renderer, element, subscriptions, function (_) { return onTermsClick && onTermsClick(); });
            case 'link-privacy':
                return this.setTagLink(renderer, element, subscriptions, function (_) { return onPrivacyClick && onPrivacyClick(); });
            case 'link-thirdparty':
                return this.setTagLink(renderer, element, subscriptions, function (_) { return onThirdPartyClick && onThirdPartyClick(); });
            case 'text-companyName':
                return this.setTagText(renderer, element, companyName);
        }
    };
    TcpaVerbiageService.prototype.unescapeHtml = function (renderer, str) {
        var div = renderer.createElement('div');
        div.innerHTML = str;
        return div.textContent;
    };
    TcpaVerbiageService.prototype.applyDisclaimerMarkup = function (renderer, element, markup) {
        // we will assume this is valid markup
        element.innerHTML = this.unescapeHtml(renderer, markup);
    };
    TcpaVerbiageService.prototype.setTagLink = function (renderer, element, subscriptions, clickHandler) {
        subscriptions.push({ unsubscribe: renderer.listen(element, 'click', clickHandler) });
        renderer.setProperty(element, 'tabindex', 0);
    };
    TcpaVerbiageService.prototype.setTagText = function (_renderer, element, text) {
        element.textContent = text;
    };
    TcpaVerbiageService.ngInjectableDef = i0.defineInjectable({ factory: function TcpaVerbiageService_Factory() { return new TcpaVerbiageService(); }, token: TcpaVerbiageService, providedIn: "root" });
    return TcpaVerbiageService;
}());
export { TcpaVerbiageService };
