import { RuleExprOp } from 'src/app/features/cvFormBuilder/shared/cvFormBuilder.enums';
import { HomeBuyingTypeEnum, LoanPurposeTypeEnum } from 'src/app/shared/models';

export const loanPurposeRouting: CvFormBuilder.Routes = {
  // loan purpose doesn't have a "start" page
  guidRouteA: {
    pageGuid: 'guidPageB', // purchase-or-refinance
    ruleGroups: [
      {
        routeNext: 'guidRouteC',
        rules: [
          {
            field: 'loan.loanPurposeType',
            operator: RuleExprOp.EQ,
            value: LoanPurposeTypeEnum.Purchase,
          },
        ],
      },
      {
        routeNext: 'guidRouteD',
        rules: [
          {
            field: 'loan.loanPurposeType',
            operator: RuleExprOp.EQ,
            value: LoanPurposeTypeEnum.Refinance,
          },
        ],
      },
    ],
  },

  //#region purchase
  guidRouteC: {
    pageGuid: 'guidPageC', // purchase-process
    ruleGroups: [
      {
        routeNext: 'guidRouteC2',
        rules: [
          {
            field: 'loan.homeBuyingType',
            operator: RuleExprOp.EQ,
            value: HomeBuyingTypeEnum.OfferAccepted,
          },
        ],
      },
      {
        routeNext: 'guidRouteC3',
        rules: [
          {
            field: 'loan.homeBuyingType',
            operator: RuleExprOp.EQ,
            value: HomeBuyingTypeEnum.GetPreApproved,
          },
        ],
      },
    ],
  },

  guidRouteC2: {
    pageGuid: 'guidPageC2', // purchase-agreement
    routeNext: 'guidRouteC3',
  },

  guidRouteC3: {
    pageGuid: 'guidPageC3', // have-agent
    sectionComplete: true,
  },
  //#endregion

  //#region refinance
  guidRouteD: {
    pageGuid: 'guidPageD', // refinance-goals
    sectionComplete: true,
  },
  //#endregion

  guidRouteZ: {
    pageGuid: 'guidPageZ', // summary
    sectionComplete: true,
  },
};
