/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./layout-main.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../header/header.component.ngfactory";
import * as i3 from "../header/header.component";
import * as i4 from "@angular/router";
import * as i5 from "../nav/nav.component.ngfactory";
import * as i6 from "../nav/nav.component";
import * as i7 from "../../../shared/services/auth.service";
import * as i8 from "../../../shared/app.settings";
import * as i9 from "../../modals/modals.service";
import * as i10 from "../../../shared/stores/ui/ui.store.service";
import * as i11 from "../../../shared/stores/api/api.store.service";
import * as i12 from "../../../shared/services/analytics.service";
import * as i13 from "../../../shared/services/application-navigation.service";
import * as i14 from "@angular/common";
import * as i15 from "../footer/footer.component.ngfactory";
import * as i16 from "../footer/footer.component";
import * as i17 from "./layout-main.component";
var styles_LayoutMainComponent = [i0.styles];
var RenderType_LayoutMainComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LayoutMainComponent, data: {} });
export { RenderType_LayoutMainComponent as RenderType_LayoutMainComponent };
export function View_LayoutMainComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.HeaderComponent, [i4.Router], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "app-nav", [["class", "nav-component"]], null, null, null, i5.View_NavComponent_0, i5.RenderType_NavComponent)), i1.ɵdid(3, 114688, null, 0, i6.NavComponent, [i7.AuthService, i8.AppSettings, i9.ModalsService, i4.Router, i10.UIStoreService, i11.ApiService, i12.AnalyticsService, i13.ApplicationNavigationService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 5, "main", [], null, null, null, null, null)), i1.ɵdid(5, 278528, null, 0, i14.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpid(131072, i14.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpod(7, { "banner-overlap": 0 }), (_l()(), i1.ɵeld(8, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(9, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-footer", [], null, null, null, i15.View_FooterComponent_0, i15.RenderType_FooterComponent)), i1.ɵdid(11, 114688, null, 0, i16.FooterComponent, [i10.UIStoreService], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); _ck(_v, 3, 0); var currVal_0 = _ck(_v, 7, 0, i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.shouldOverlap$))); _ck(_v, 5, 0, currVal_0); _ck(_v, 9, 0); _ck(_v, 11, 0); }, null); }
export function View_LayoutMainComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-layout-main", [], null, null, null, View_LayoutMainComponent_0, RenderType_LayoutMainComponent)), i1.ɵdid(1, 114688, null, 0, i17.LayoutMainComponent, [i10.UIStoreService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LayoutMainComponentNgFactory = i1.ɵccf("app-layout-main", i17.LayoutMainComponent, View_LayoutMainComponent_Host_0, {}, {}, []);
export { LayoutMainComponentNgFactory as LayoutMainComponentNgFactory };
