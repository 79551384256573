var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { OnInit, OnDestroy } from '@angular/core';
import { sections2009 } from 'src/assets/config/2009/01741/src/sections';
import { sections2020 } from 'src/assets/config/2020/01741/src/sections';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
var SectionsConfigComponent = /** @class */ (function () {
    function SectionsConfigComponent(route) {
        this.route = route;
        this.sqlEscaped = false;
        this.version = '2020';
        this.configJson = null;
    }
    SectionsConfigComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(this.route.params, this.route.queryParams)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var _b = __read(_a, 2), version = _b[0].version, sqlEscaped = _b[1].sqlEscaped;
            _this.sqlEscaped = sqlEscaped !== undefined;
            _this.version = version === '2009' ? '2009' : '2020';
            _this.configJson = _this.stringify(version === '2009' ? sections2009 : sections2020);
        });
    };
    SectionsConfigComponent.prototype.stringify = function (config) {
        var configJson = JSON.stringify(config, null, 2);
        configJson = this.cleanup(configJson);
        if (this.sqlEscaped) {
            configJson = configJson.replace(/[']/g, "''"); // tslint:disable-line: quotemark
        }
        return configJson;
    };
    SectionsConfigComponent.prototype.cleanup = function (json) {
        return json
            .replace(/[\u2018\u2019]/g, "'") // tslint:disable-line: quotemark
            .replace(/[\u201C\u201D]/g, '"');
    };
    SectionsConfigComponent.prototype.ngOnDestroy = function () {
    };
    return SectionsConfigComponent;
}());
export { SectionsConfigComponent };
