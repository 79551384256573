/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header-simple.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../company-logo/company-logo.component.ngfactory";
import * as i3 from "../company-logo/company-logo.component";
import * as i4 from "../../../shared/app.settings";
import * as i5 from "../../../shared/stores/ui/ui.store.service";
import * as i6 from "./header-simple.component";
var styles_HeaderSimpleComponent = [i0.styles];
var RenderType_HeaderSimpleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderSimpleComponent, data: {} });
export { RenderType_HeaderSimpleComponent as RenderType_HeaderSimpleComponent };
export function View_HeaderSimpleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "header", [["class", "py-md-3 px-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "col-2 col-md-4"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "col-8 col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "main-header-logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-company-logo", [], null, null, null, i2.View_CompanyLogoComponent_0, i2.RenderType_CompanyLogoComponent)), i1.ɵdid(8, 245760, null, 0, i3.CompanyLogoComponent, [i4.AppSettings, i5.UIStoreService], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "col-2 col-md-4"]], null, null, null, null, null)), i1.ɵncd(null, 1)], function (_ck, _v) { _ck(_v, 8, 0); }, null); }
export function View_HeaderSimpleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header-simple", [], null, null, null, View_HeaderSimpleComponent_0, RenderType_HeaderSimpleComponent)), i1.ɵdid(1, 49152, null, 0, i6.HeaderSimpleComponent, [], null, null)], null, null); }
var HeaderSimpleComponentNgFactory = i1.ɵccf("app-header-simple", i6.HeaderSimpleComponent, View_HeaderSimpleComponent_Host_0, {}, {}, ["[left-column]", "[right-column]"]);
export { HeaderSimpleComponentNgFactory as HeaderSimpleComponentNgFactory };
