/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirmation-next.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../shared/directives/focus-contain.directive";
import * as i4 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "./confirmation-next.component";
var styles_ConfirmationNextModelComponent = [i0.styles];
var RenderType_ConfirmationNextModelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmationNextModelComponent, data: {} });
export { RenderType_ConfirmationNextModelComponent as RenderType_ConfirmationNextModelComponent };
export function View_ConfirmationNextModelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["automationid", "Section_Header"], ["class", "page-title text-center padding-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "inline"], ["id", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "inline"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "button", [["aria-label", "Close"], ["automationid", "BtnClose"], ["class", "modal-header close"], ["mat-dialog-close", ""], ["tabindex", "3"], ["type", "button"]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).dialogRef.close(i1.ɵnov(_v, 5).dialogResult) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onExit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { ariaLabel: [0, "ariaLabel"], dialogResult: [1, "dialogResult"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(8, 0, null, null, 11, "div", [["automationid", "Section_ModalBody"], ["class", "modal-body"], ["focusContain", ""], ["id", "modal-body"]], null, [[null, "keydown"]], function (_v, en, $event) { var ad = true; if (("keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i3.FocusContainDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 7, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" We noticed you have "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["changed"])), (_l()(), i1.ɵted(-1, null, [" the "])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["subject property location."])), (_l()(), i1.ɵted(-1, null, [" In order to save this change, you will need to return to the screen and select the next button. "])), (_l()(), i1.ɵeld(18, 0, null, null, 1, "p", [["class", "modal-body-innerPara"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Would you like to return to the screen so that you can save the change, or continue and discard the change? "])), (_l()(), i1.ɵeld(20, 0, null, null, 8, "div", [["automationid", "Section_Footer"], ["class", "modal-footer justify-content-between justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 3, "button", [["automationid", "BtnDiscard"], ["class", "col-6"], ["color", "primary-outline"], ["mat-flat-button", ""], ["tabindex", "2"], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23).dialogRef.close(i1.ɵnov(_v, 23).dialogResult) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onCancel() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(22, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(23, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(24, 0, [" ", " "])), (_l()(), i1.ɵeld(25, 0, null, null, 3, "button", [["automationid", "BtnReturn"], ["class", "col-6"], ["color", "accent"], ["mat-flat-button", ""], ["style", "margin-left: 5px;"], ["tabindex", "1"], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 27).dialogRef.close(i1.ɵnov(_v, 27).dialogResult) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onConfirm() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(26, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(27, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(28, 0, [" ", " "]))], function (_ck, _v) { var currVal_2 = "Close"; var currVal_3 = ""; _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_7 = "primary-outline"; _ck(_v, 22, 0, currVal_7); var currVal_8 = false; _ck(_v, 23, 0, currVal_8); var currVal_13 = "accent"; _ck(_v, 26, 0, currVal_13); var currVal_14 = false; _ck(_v, 27, 0, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 5).ariaLabel || null); _ck(_v, 4, 0, currVal_1); var currVal_4 = (i1.ɵnov(_v, 22).disabled || null); var currVal_5 = (i1.ɵnov(_v, 22)._animationMode === "NoopAnimations"); var currVal_6 = (i1.ɵnov(_v, 23).ariaLabel || null); _ck(_v, 21, 0, currVal_4, currVal_5, currVal_6); var currVal_9 = _co.data.buttonDiscard; _ck(_v, 24, 0, currVal_9); var currVal_10 = (i1.ɵnov(_v, 26).disabled || null); var currVal_11 = (i1.ɵnov(_v, 26)._animationMode === "NoopAnimations"); var currVal_12 = (i1.ɵnov(_v, 27).ariaLabel || null); _ck(_v, 25, 0, currVal_10, currVal_11, currVal_12); var currVal_15 = _co.data.buttonConfirm; _ck(_v, 28, 0, currVal_15); }); }
export function View_ConfirmationNextModelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "confirmation-next-modal", [], null, null, null, View_ConfirmationNextModelComponent_0, RenderType_ConfirmationNextModelComponent)), i1.ɵdid(1, 49152, null, 0, i9.ConfirmationNextModelComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], null, null); }
var ConfirmationNextModelComponentNgFactory = i1.ɵccf("confirmation-next-modal", i9.ConfirmationNextModelComponent, View_ConfirmationNextModelComponent_Host_0, {}, {}, []);
export { ConfirmationNextModelComponentNgFactory as ConfirmationNextModelComponentNgFactory };
