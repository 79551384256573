import { OnInit, OnDestroy, OnChanges, ElementRef, } from '@angular/core';
import { FormControl } from '@angular/forms';
/**
 * Autocomplete extension for angular materials. Automatically handles the filtering and emits selected values
 * EXAMPLE: For a string array, returns string value
    <app-autocomplete-address
                  [placeholder]="element?.data?.placeholder || 'Street Address'"
                  [apiKey]="googleApiKey"
                  [addressControl]="formControlGet(element.data.addressPath)"
                  [cityControl]="formControlGet(element.data.cityPath)"
                  [stateControl]="formControlGet(element.data.statePath)"
                  [zipControl]="formControlGet(element.data.zipPath)"
                ></app-autocomplete-address>
 */
var AutocompleteAddressComponent = /** @class */ (function () {
    function AutocompleteAddressComponent() {
        /** Default placeholder text */
        this.placeholder = '';
        /** Show success */
        this.showSuccess = false;
    }
    AutocompleteAddressComponent.prototype.ngOnInit = function () {
        // Check for api key
        if (!this.apiKey) {
            console.error('Please add a google maps api key');
        }
        this.autoCompleteControl = this.addressControl;
        if (!window.google) {
            this.loadApi();
        }
        else {
            this.setGoogleReferences();
        }
    };
    AutocompleteAddressComponent.prototype.ngOnChanges = function () { };
    /**
     * Load google places script
     */
    AutocompleteAddressComponent.prototype.loadApi = function () {
        var _this = this;
        var script = document.createElement('script');
        script.async = true;
        script.defer = true;
        script.src = "https://maps.googleapis.com/maps/api/js?libraries=places&key=" + this.apiKey;
        script.onload = function () { return _this.setGoogleReferences(); };
        document.head.appendChild(script);
    };
    /**
     * After google places script loads, set listeners and autocomplete references
     */
    AutocompleteAddressComponent.prototype.setGoogleReferences = function () {
        var _this = this;
        // 4517 Campus Drive, Arcadia, CA, USA
        // Set up autocomplete control
        this.autoComplete = new google.maps.places.Autocomplete(this.address.nativeElement, {
            types: ['address'],
            fields: ['address_components'],
            componentRestrictions: { country: 'us' }
        });
        // Add event listener for when a user clicks on the address
        this.autoComplete.addListener('place_changed', function () { return _this.updateFields(); });
    };
    AutocompleteAddressComponent.prototype.updateFields = function () {
        var _a = this.getLocationValues(this.autoComplete.getPlace()), addressLine = _a.addressLine, city = _a.city, state = _a.state, zipCode = _a.zipCode, hasLastLine = _a.hasLastLine;
        if (addressLine) {
            if (this.addressControl && addressLine) {
                this.addressControl.setValue(addressLine, { emitEvent: true });
            }
        }
        if (hasLastLine) {
            if (this.stateControl && state) {
                var stateValue = this.stateCallback ? this.stateCallback(state) : state;
                this.stateControl.setValue(stateValue, { emitEvent: true });
            }
            if (this.cityControl && city) {
                this.cityControl.setValue(city, { emitEvent: true });
            }
            if (this.zipControl && zipCode) {
                this.zipControl.setValue(zipCode, { emitEvent: true });
            }
            this.zipLookupControl.setValue(null, { emitEvent: true });
        }
    };
    /**
     * Extract values from the autocomplete response, convert to more easily consumable method
     * The places api doesn't always return all values
     * @param location
     */
    AutocompleteAddressComponent.prototype.getLocationValues = function (location) {
        if (!location || !location.address_components) {
            return {};
        }
        var _a = location.address_components.reduce(function (acc, _a) {
            var short_name = _a.short_name, types = _a.types;
            if (types.includes('street_number')) {
                acc.streetNumber = short_name;
            }
            else if (types.includes('route')) {
                acc.streetName = short_name;
            }
            else if (types.includes('locality')) {
                acc.city = short_name;
            }
            else if (types.includes('administrative_area_level_1')) {
                acc.state = short_name;
            }
            else if (types.includes('postal_code')) {
                acc.zipCode = short_name;
            }
            return acc;
        }, {}), streetNumber = _a.streetNumber, streetName = _a.streetName, city = _a.city, state = _a.state, zipCode = _a.zipCode;
        return {
            streetNumber: streetNumber,
            streetName: streetName,
            addressLine: streetNumber && streetName ? streetNumber + " " + streetName : null,
            city: city,
            state: state,
            zipCode: zipCode,
            hasLastLine: !!(city && state && zipCode)
        };
    };
    AutocompleteAddressComponent.prototype.ngOnDestroy = function () {
        if (this.autoComplete) {
            this.autoComplete.unbindAll();
        }
    };
    return AutocompleteAddressComponent;
}());
export { AutocompleteAddressComponent };
