import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { sections2009 } from 'src/assets/config/2009/01741/src/sections';
import { sections2020 } from 'src/assets/config/2020/01741/src/sections';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-sections-config',
  templateUrl: './sections-config.component.html',
  styleUrls: ['./sections-config.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionsConfigComponent implements OnInit, OnDestroy {

  sqlEscaped = false;
  version = '2020';
  configJson = null as string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    combineLatest(this.route.params, this.route.queryParams)
      .pipe(untilDestroyed(this))
      .subscribe(([{version}, {sqlEscaped}]) => {
        this.sqlEscaped = sqlEscaped !== undefined;
        this.version = version === '2009' ? '2009' : '2020';
        this.configJson = this.stringify(version === '2009' ? sections2009 : sections2020);
      });
  }

  private stringify(config: any) {
    let configJson = JSON.stringify(config, null, 2);
    configJson = this.cleanup(configJson);
    if (this.sqlEscaped) {
      configJson = configJson.replace(/[']/g, "''"); // tslint:disable-line: quotemark
    }
    return configJson;
  }
  private cleanup(json: string) {
    return json
      .replace(/[\u2018\u2019]/g, "'") // tslint:disable-line: quotemark
      .replace(/[\u201C\u201D]/g, '"')
      ;
  }

  ngOnDestroy() {
  }

}
