import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var DashboardGuard = /** @class */ (function () {
    function DashboardGuard(router) {
        this.router = router;
    }
    DashboardGuard.prototype.canActivate = function (route) {
        // Handle redirects while preserving query parameters
        this.router.navigate(['/'], { queryParams: route.queryParams });
        return true;
    };
    DashboardGuard.ngInjectableDef = i0.defineInjectable({ factory: function DashboardGuard_Factory() { return new DashboardGuard(i0.inject(i1.Router)); }, token: DashboardGuard, providedIn: "root" });
    return DashboardGuard;
}());
export { DashboardGuard };
