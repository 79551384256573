var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSelector, Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
var ɵ0 = function (state) { return state.api.users; }, ɵ1 = function (users) {
    if (users && users.data) {
        // Modify data before returning to selector
    }
    return users;
};
// import { ApiMap } from 'src/app/shared/stores/api';
// import { Observable, combineLatest } from 'rxjs';
// import { map } from 'rxjs/operators';
// const keyBy = require('lodash/keyBy');
// Mapped/source selectors for reuse or transforming data
var selectors = {
    users: createSelector(ɵ0, ɵ1),
};
var ApiSelectorsService = /** @class */ (function () {
    function ApiSelectorsService(store) {
        this.store = store;
        this.appState$ = this.store.select(function (store) { return store.api.appState; }).pipe(map(function (state) {
            // Ensure default state is always available
            // Since appState is generated client side, it will not be available on first load
            if (!state || !state.data) {
                state = {
                    data: {}
                };
            }
            if (state && state.data) {
                state.data = {
                    loaded: state.data.loaded,
                    form1003: __assign({ isActive: null, isStarted: null, completedDate: null, state: [] }, state.data.form1003),
                    dashboard: __assign({ isActive: null, isStarted: null, completedDate: null }, state.data.dashboard),
                    complete: state.data.complete || null
                };
                return state;
            }
        })); // Memoized selector
        this.users$ = this.store.select(selectors.users); // Memoized selector
        this.megaLoan$ = this.store.select(function (store) { return store.api.megaLoan; });
        this.bnl$ = this.store.select(function (store) { return store.api.bnl; });
        this.submitting$ = new BehaviorSubject(false);
        this.broker$ = this.store.select(function (store) { return store.api.broker; });
        this.documents$ = this.store.select(function (store) { return store.api.documents; });
        this.activeLoanSnapshots$ = this.store.select(function (store) { return store.api.activeLoanSnapshots; });
        this.loanContacts$ = this.store.select(function (store) { return store.api.loanContacts; });
        this.currentCompanyProfile$ = this.store.select(function (store) { return store.api.currentCompanyProfile; });
        this.config1003$ = this.store.select(function (store) { return store.api.config1003; });
        this.client1003Config$ = new BehaviorSubject(null);
    }
    ApiSelectorsService.ngInjectableDef = i0.defineInjectable({ factory: function ApiSelectorsService_Factory() { return new ApiSelectorsService(i0.inject(i1.Store)); }, token: ApiSelectorsService, providedIn: "root" });
    return ApiSelectorsService;
}());
export { ApiSelectorsService };
export { ɵ0, ɵ1 };
