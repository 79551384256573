import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnDestroy,
  OnChanges,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { FormControl } from '@angular/forms';

interface LocationValues {
  streetNumber?: string;
  streetName?: string;
  addressLine?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  hasLastLine: boolean;
}

/**
 * Autocomplete extension for angular materials. Automatically handles the filtering and emits selected values
 * EXAMPLE: For a string array, returns string value
    <app-autocomplete-address
                  [placeholder]="element?.data?.placeholder || 'Street Address'"
                  [apiKey]="googleApiKey"
                  [addressControl]="formControlGet(element.data.addressPath)"
                  [cityControl]="formControlGet(element.data.cityPath)"
                  [stateControl]="formControlGet(element.data.statePath)"
                  [zipControl]="formControlGet(element.data.zipPath)"
                ></app-autocomplete-address>
 */

@Component({
  selector: 'app-autocomplete-address',
  templateUrl: './autocomplete-address.component.html',
  styleUrls: ['./autocomplete-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteAddressComponent implements OnInit, OnChanges, OnDestroy {
  /** Form control reference for the STREET ADDRESS */
  @Input() addressControl: FormControl;
  /** Form control reference for the CITY */
  @Input() cityControl: FormControl;
  /** Form control reference for the STATE */
  @Input() stateControl: FormControl;
  /** Form control reference for the ZIP */
  @Input() zipControl: FormControl;

  @Input() zipLookupControl: FormControl;
  /** Default placeholder text */
  @Input() placeholder = '';
  /** If is disabled */
  @Input() disabled: boolean;
  /** Show success */
  @Input() showSuccess = false;

  /** Map state result to a different value */
  @Input() stateCallback: (arg: string) => any;

  /** Google places api key */
  @Input() apiKey: string;

  /** Internal form control used for input */
  public autoCompleteControl: FormControl;

  @ViewChild('address') address: ElementRef;

  private autoComplete: google.maps.places.Autocomplete;

  constructor() {}

  ngOnInit() {
    // Check for api key
    if (!this.apiKey) {
      console.error('Please add a google maps api key');
    }

    this.autoCompleteControl = this.addressControl;

    if (!(<any>window).google) {
      this.loadApi();
    } else {
      this.setGoogleReferences();
    }
  }

  ngOnChanges() {}

  /**
   * Load google places script
   */
  private loadApi() {
    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${this.apiKey}`;
    script.onload = () => this.setGoogleReferences();
    document.head.appendChild(script);
  }

  /**
   * After google places script loads, set listeners and autocomplete references
   */
  private setGoogleReferences() {
    // 4517 Campus Drive, Arcadia, CA, USA
    // Set up autocomplete control
    this.autoComplete = new google.maps.places.Autocomplete(this.address.nativeElement, {
      types: ['address'],
      fields: ['address_components'],
      componentRestrictions: {country: 'us'}
    });
    // Add event listener for when a user clicks on the address
    this.autoComplete.addListener('place_changed', () => this.updateFields());
  }

  private updateFields() {
    const {addressLine, city, state, zipCode, hasLastLine} = this.getLocationValues(this.autoComplete.getPlace());
    if (addressLine) {
      if (this.addressControl && addressLine) {
        this.addressControl.setValue(addressLine, { emitEvent: true });
      }
    }

    if (hasLastLine) {
      if (this.stateControl && state) {
        const stateValue = this.stateCallback ? this.stateCallback(state) : state;
        this.stateControl.setValue(stateValue, { emitEvent: true });
      }
      if (this.cityControl && city) {
        this.cityControl.setValue(city, { emitEvent: true });
      }
      if (this.zipControl && zipCode) {
        this.zipControl.setValue(zipCode, { emitEvent: true });
      }
      this.zipLookupControl.setValue(null, { emitEvent: true });
    }
  }

  /**
   * Extract values from the autocomplete response, convert to more easily consumable method
   * The places api doesn't always return all values
   * @param location
   */
  private getLocationValues(location: google.maps.places.PlaceResult): LocationValues {
    if (!location || !location.address_components) {
      return <LocationValues>{};
    }

    const {streetNumber, streetName, city, state, zipCode} = location.address_components.reduce(
      (acc, {short_name, types}) => {
        if (types.includes('street_number')) {
          acc.streetNumber = short_name;
        } else if (types.includes('route')) {
          acc.streetName = short_name;
        } else if (types.includes('locality')) {
          acc.city = short_name;
        } else if (types.includes('administrative_area_level_1')) {
          acc.state = short_name;
        } else if (types.includes('postal_code')) {
          acc.zipCode = short_name;
        }
        return acc;
      }, <LocationValues>{}
    );

    return {
      streetNumber,
      streetName,
      addressLine: streetNumber && streetName ? `${streetNumber} ${streetName}` : null,
      city,
      state,
      zipCode,
      hasLastLine: !!(city && state && zipCode)
    };
  }

  ngOnDestroy() {
    if (this.autoComplete) {
      this.autoComplete.unbindAll();
    }
  }
}
