import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { AppSettings, AuthService, AnalyticsService } from '$shared';
import { Subscription } from 'rxjs';
import { UIStoreService } from '$ui';
import { Title } from '@angular/platform-browser';
import { ApiService } from '$api';
import { AuthenticationStatusEnum } from 'src/app/shared/models';


@Component({
  selector: 'app-mfa',
  templateUrl: './mfa.component.html',
  styleUrls: ['./mfa.component.scss'],
})
export class MfaComponent implements OnInit, AfterViewInit {
  public config$ = this.ui.select.config$;
  public formMain: FormGroup;
  public waiting: boolean;
  public returnUrl: string;
  public subs: Subscription[] = [];
  public userName: string;
  public mfaToken: string;
  /** Show error messages in UI */
  public error: string;
  /** Show info messages in UI */
  public info: string;
  /** Boolean to force buttons to show as disabled */
  isAccountLocked = false;
  /** Broker info */
  public brokerName: string;
  public brokerPhone: string;

  public isLsidAvailable = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private ui: UIStoreService,
    private title: Title,
    private settings: AppSettings,
    private authService: AuthService,
    private analytics: AnalyticsService,
    private api: ApiService,
  ) {}

  public ngOnInit() {

    // Check to see if LSID has been set
    this.isLsidAvailable = this.settings.lsid != null;

    /** Store broker data needed for custom error message */
    this.api.broker.get().subscribe(broker => {
      if (!broker) return;
      this.brokerName = `${broker.firstName} ${broker.lastName}`;
      if (broker.phones && broker.phones.length) {
        this.brokerPhone = broker.phones[0].number;
      }
    });

      this.formMain = this.fb.group({
      mfaCode: [null, [Validators.required]],
    });

    // Get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    // Do not use return url on application section
    if (this.returnUrl.indexOf('application') !== -1) {
      this.returnUrl = '/';
    }
    this.userName = this.route.snapshot.queryParams['userName'];
    this.mfaToken = this.route.snapshot.queryParams['mfaToken'];
    // Make sure the correct data has been received
    if (!this.userName || !this.mfaToken) {
      this.error = `An error occurred. Please return to the previous page and try again.`;
    }
  }

  ngAfterViewInit(): void {
    // Add company name to the title of this page
    const newTitle = this.settings.clientName
      ? `${this.title.getTitle()} - ${this.settings.clientName}`
      : this.title.getTitle();
    this.title.setTitle(newTitle);
  }

  /**
   * Submit the form
   */
  public onSubmit(form: FormGroup): void {

    // Do nothing if form is invalid
    if (form.invalid) return;

    this.resetAlerts();
    this.waiting = true;

    // // Authenticate
    this.authService.logInMfa(this.userName, form.value.mfaCode, this.mfaToken).subscribe(
      (bodyResponse) => {
        this.waiting = false;
        if (bodyResponse && bodyResponse.succeeded) {
          this.analytics.mixpanelAlias(this.settings.userId);
          this.analytics.mixpanelPeople({ 'User': 'Borrower'});
          this.analytics.trackEvent('MFA Verification BC', {
            'MFA Verification result': 'Passed',
            'Clicked Send new verification': false,
            'Incorrect Verification error fired?': false,
            'Locked account error': false,
          });
          // Navigate to the proper route
          this.router.navigate([this.returnUrl], { queryParamsHandling: 'merge' });
        } else if (bodyResponse && bodyResponse.authenticationStatus === AuthenticationStatusEnum.AccountLocked) {
          // Account lock was detected
          this.isAccountLocked = true;
          this.error = this.brokerName && this.brokerPhone
            ? `Incorrect Verification Code. This account is locked. Please contact ${this.brokerName} at ${this.brokerPhone}`
            : `Incorrect Verification Code. This account is locked. Please contact your loan officer to unlock your account.`;
          this.analytics.trackEvent('MFA Verification BC', {
            'MFA Verification result': 'Failed',
            'Clicked Send new verification': false,
            'Incorrect Verification error fired?': true,
            'Locked account error': true,
          });
        } else {
          // Generic error message
          this.error = `Incorrect Verification Code. Please click the send new verification code below. After 5 failed attempts, this account will be locked.`;
          this.formMain.get('mfaCode').setErrors({incorrect: true});
          this.analytics.trackEvent('MFA Verification BC', {
            'MFA Verification result': 'Failed',
            'Clicked Send new verification': false,
            'Incorrect Verification error fired?': true,
            'Locked account error': false,
          });
        }
      },
      error => {
        if ((error.statusText === 'Unauthorized')) {
          this.error = 'Invalid username or password, please try again.';
        } else {
          this.error = 'Error logging in.';
        }
        this.error = error;
        this.waiting = false;
      },
    );
  } // end onSubmit

  sendNewCode(): void {
    this.resetAlerts();
    this.authService.requestNewMfa(this.userName, this.mfaToken).subscribe(
      (bodyResponse) => {
        this.waiting = false;
        if (bodyResponse && bodyResponse.succeeded) {
          // Success
          this.info = `A new code has been sent.`;
          this.mfaToken = bodyResponse.mfaToken;
          this.analytics.trackEvent('MFA Verification BC', {
            'MFA Verification result': 'Passed',
            'Clicked Send new verification': true,
            'Incorrect Verification error fired?': false,
            'Locked account error': false,
          });
        } else if (bodyResponse && bodyResponse.authenticationStatus === AuthenticationStatusEnum.AccountLocked) {
          // Account lock was detected
          this.isAccountLocked = true;
          this.error = this.brokerName && this.brokerPhone
            ? `This account is locked. Please contact ${this.brokerName} at ${this.brokerPhone}`
            : `This account is locked. Please contact your loan officer to unlock your account.`;
        } else {
          // Generic error message
          this.error = `An error occurred. Please return to the previous page and try again.`;
        }
      },
      error => {
        if ((error.statusText === 'Unauthorized')) {
          this.error = 'Invalid mfa code.';
        } else {
          this.error = 'Error logging in.';
        }

        this.error = error;
        this.waiting = false;
      },
    );
  }

  public resetAlerts() {
    this.error = null;
    this.info = null;
  }
}
