/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./api-state.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../error/error.component.ngfactory";
import * as i4 from "../error/error.component";
import * as i5 from "./api-state.component";
var styles_ApiStateComponent = [i0.styles];
var RenderType_ApiStateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ApiStateComponent, data: {} });
export { RenderType_ApiStateComponent as RenderType_ApiStateComponent };
function View_ApiStateComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-spinner fa-spin"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Loading... "]))], null, null); }
function View_ApiStateComponent_4(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_ApiStateComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApiStateComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["customLoadingContent", 2]], null, 0, null, View_ApiStateComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.showCustomLoadingContent; var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ApiStateComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "app-error", [], null, null, null, i3.View_ErrorComponent_0, i3.RenderType_ErrorComponent)), i1.ɵdid(2, 114688, null, 0, i4.ErrorComponent, [], { error: [0, "error"], canClose: [1, "canClose"] }, null), i1.ɵpid(0, i2.JsonPipe, []), (_l()(), i1.ɵted(4, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform((_co.state.error || _co.state.errorModifying))); var currVal_1 = false; _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (((_co.state == null) ? null : ((_co.state.error == null) ? null : _co.state.error.message)) || ((_co.state == null) ? null : ((_co.state.errorModifying == null) ? null : _co.state.errorModifying.message))); _ck(_v, 4, 0, currVal_2); }); }
function View_ApiStateComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), i1.ɵncd(null, 1), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_ApiStateComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApiStateComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApiStateComponent_5)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApiStateComponent_6)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.state.loading && !_co.disableLoading) || (_co.disableLoading && !((_co.state == null) ? null : _co.state.data))); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.errorShow && (_co.state.error || _co.state.errorModifying)); _ck(_v, 4, 0, currVal_1); var currVal_2 = ((!((_co.state == null) ? null : _co.state.loading) && !((_co.state == null) ? null : _co.state.error)) || (_co.disableLoading && ((_co.state == null) ? null : _co.state.data))); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_ApiStateComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApiStateComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ApiStateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-api-state", [], null, null, null, View_ApiStateComponent_0, RenderType_ApiStateComponent)), i1.ɵdid(1, 638976, null, 0, i5.ApiStateComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ApiStateComponentNgFactory = i1.ɵccf("app-api-state", i5.ApiStateComponent, View_ApiStateComponent_Host_0, { state: "state", disableLoading: "disableLoading", errorShow: "errorShow", showCustomLoadingContent: "showCustomLoadingContent" }, {}, ["[loadingContent]", "*"]);
export { ApiStateComponentNgFactory as ApiStateComponentNgFactory };
