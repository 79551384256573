/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i2 from "@angular/material/card";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "./no-content.component";
var styles_NoContentComponent = [];
var RenderType_NoContentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NoContentComponent, data: {} });
export { RenderType_NoContentComponent as RenderType_NoContentComponent };
export function View_NoContentComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "container mt-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "mat-card", [["class", "mat-card"]], null, null, null, i1.View_MatCard_0, i1.RenderType_MatCard)), i0.ɵdid(2, 49152, null, 0, i2.MatCard, [], null, null), (_l()(), i0.ɵeld(3, 0, null, 0, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["404: page missing"])), (_l()(), i0.ɵeld(5, 0, null, 0, 3, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "a", [["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["Click here to go to the homepage."]))], function (_ck, _v) { var currVal_2 = "/"; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 7).target; var currVal_1 = i0.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_0, currVal_1); }); }
export function View_NoContentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-no-content", [], null, null, null, View_NoContentComponent_0, RenderType_NoContentComponent)), i0.ɵdid(1, 49152, null, 0, i5.NoContentComponent, [], null, null)], null, null); }
var NoContentComponentNgFactory = i0.ɵccf("app-no-content", i5.NoContentComponent, View_NoContentComponent_Host_0, {}, {}, []);
export { NoContentComponentNgFactory as NoContentComponentNgFactory };
