import { OnInit, EventEmitter } from '@angular/core';
var PageNavigationComponent = /** @class */ (function () {
    function PageNavigationComponent() {
        this.pageBack = new EventEmitter();
        this.pageNext = new EventEmitter();
    }
    PageNavigationComponent.prototype.ngOnInit = function () {
    };
    Object.defineProperty(PageNavigationComponent.prototype, "buttonCount", {
        get: function () {
            var withBackButton = +(this.showButtonBack !== false);
            var withNextButton = +(this.showButtonNext !== false);
            return withBackButton + withNextButton;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageNavigationComponent.prototype, "buttonClass", {
        get: function () {
            switch (this.buttonCount) {
                case 1: return 'w-100';
                case 2:
                default: return 'w-50';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageNavigationComponent.prototype, "showSpacer", {
        get: function () {
            return this.isEmbedded === true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageNavigationComponent.prototype, "navButtonRowClass", {
        get: function () {
            return this.isEmbedded === true ? 'embedded' : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageNavigationComponent.prototype, "showNavigationButtons", {
        get: function () {
            return this.showButtonBack !== false || (!this.isLastPage && this.showButtonNext !== false);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageNavigationComponent.prototype, "showBackNavigationButton", {
        get: function () {
            return this.showButtonBack !== false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageNavigationComponent.prototype, "showNextNavigationButton", {
        get: function () {
            return !this.isLastPage && this.showButtonNext !== false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageNavigationComponent.prototype, "showSubmitNavigationButton", {
        get: function () {
            return this.isLastPage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageNavigationComponent.prototype, "disableBackButton", {
        get: function () {
            return this.isFirstPage;
        },
        enumerable: true,
        configurable: true
    });
    PageNavigationComponent.prototype.onPageBack = function (e) {
        this.pageBack.next(e);
    };
    PageNavigationComponent.prototype.onPageNext = function (e) {
        this.pageNext.next(e);
    };
    return PageNavigationComponent;
}());
export { PageNavigationComponent };
