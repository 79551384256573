/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./company-logo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/pipes/safe-html.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "./company-logo.component";
import * as i5 from "../../../shared/app.settings";
import * as i6 from "../../../shared/stores/ui/ui.store.service";
var styles_CompanyLogoComponent = [i0.styles];
var RenderType_CompanyLogoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CompanyLogoComponent, data: {} });
export { RenderType_CompanyLogoComponent as RenderType_CompanyLogoComponent };
export function View_CompanyLogoComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafeHtmlPipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, [["headerLogo", 1]], null, 1, "img", [["alt", "Company Logo: Go to Home Page"]], [[8, "src", 4]], [[null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.imageLoadErrorHandler(i1.ɵnov(_v, 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v, 0), _co.mainLogoSrc, "resourceUrl")); _ck(_v, 1, 0, currVal_0); }); }
export function View_CompanyLogoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-company-logo", [], null, null, null, View_CompanyLogoComponent_0, RenderType_CompanyLogoComponent)), i1.ɵdid(1, 245760, null, 0, i4.CompanyLogoComponent, [i5.AppSettings, i6.UIStoreService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CompanyLogoComponentNgFactory = i1.ɵccf("app-company-logo", i4.CompanyLogoComponent, View_CompanyLogoComponent_Host_0, { configKey: "configKey" }, {}, []);
export { CompanyLogoComponentNgFactory as CompanyLogoComponentNgFactory };
