var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { Store } from '@ngrx/store';
import { environment } from '$env';
import { StringUtils } from '$utils';
import { AppSettings } from '../../app.settings';
import { UIStoreActions } from './ui.actions';
import { UiSelectorsService } from './ui.selectors.service';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "./ui.selectors.service";
import * as i3 from "../../app.settings";
var UIStoreService = /** @class */ (function () {
    function UIStoreService(store, 
    /** UI Store Selectors */
    select, settings) {
        var _this = this;
        this.store = store;
        this.select = select;
        this.settings = settings;
        /** Obfuscate reference */
        this.pad = 100;
        // Rehydrate UI state from localstorage on instantiation
        if (this.settings.ui) {
            // Get UI state from localstorage
            var str = this.settings.ui;
            // Remove obfusucation if is set
            if (environment.settings.obfuscate) {
                // If de-obfuscating errors out, remove ui store state and fail gracefully
                str = this.obfuscateRemove(str);
            }
            // Convert to JSON
            var uiState = JSON.parse(str);
            this.storeStateRestore(uiState);
        }
        // On UI store changes, persist to localstorage
        this.select.saveState$.subscribe(function (uiState) { return _this.storeStateSave(uiState); });
        // Output store changes to console
        // this.store.subscribe(storeApi => console.log(JSON.parse(JSON.stringify(storeApi.ui))));
    }
    /**
     * Change and persist the visible tab of a tabset
     * Make sure this service is public: constructor(public ui: UIStoreService) and that the first argument matches
     * USAGE
     <mat-tab-group [selectedIndex]="ui.select.tabActive$('home') | async" (selectedTabChange)="ui.tabChange('home', $event)">
     * @param tabInstanceId - A name or unique identifier for this tab instance
     * @param tabEvent - The tabChange event supplied by ng-boostrap
     */
    UIStoreService.prototype.tabChange = function (tabInstanceId, tabEvent) {
        this.store.dispatch(UIStoreActions.TAB_CHANGE({ tabInstanceId: tabInstanceId, tabId: tabEvent.index }));
    };
    /**
     * Change a toggle which is just a boolean in a dictionary
     * @param prop - Property to set, corresponds to toggle$ in the ui.select service
     * @param value - T/F
     */
    UIStoreService.prototype.toggle = function (prop, value) {
        this.store.dispatch(UIStoreActions.TOGGLES({ prop: prop, value: value }));
    };
    /**
     *  Reload the latest UI state from localstorage
     */
    UIStoreService.prototype.storeStateRestore = function (uiState) {
        this.store.dispatch(UIStoreActions.REHYDRATE(uiState));
    };
    /**
     * Change formbuilder state
     * @param state
     */
    UIStoreService.prototype.formBuilderChange = function (state) {
        this.store.dispatch(UIStoreActions.FORM_BUILDER_CHANGE(state));
    };
    /**
     * Change formbuilder state
     * @param state
     */
    UIStoreService.prototype.configChange = function (config) {
        this.store.dispatch(UIStoreActions.CONFIG(config));
    };
    /**
     * Save the UI store state to localstorage for persistance
     * @param state
     */
    UIStoreService.prototype.storeStateSave = function (state) {
        if (state) {
            var filteredState = this.filterState(state);
            var str = JSON.stringify(filteredState);
            // Add obfusciation if set
            if (environment.settings.obfuscate) {
                str = this.obfuscateAdd(str);
            }
            // Set to localstorage
            this.settings.ui = str;
        }
    };
    UIStoreService.prototype.filterState = function (state) {
        var _this = this;
        // Delete any keys that should not be persisted
        var excludeKeys = environment.state.uiStoreBlacklist || [];
        return Object.assign.apply(Object, __spread([{}], Object.entries(state || {})
            .filter(function (_a) {
            var _b = __read(_a, 1), key = _b[0];
            return !excludeKeys.includes(key);
        })
            .map(function (_a) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            return [key, key === 'config'
                    ? _this.filterConfig(value)
                    : value
            ];
        })
            .map(function (_a) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            var _c;
            return (_c = {}, _c[key] = value, _c);
        })));
    };
    UIStoreService.prototype.filterConfig = function (config) {
        var excludeKeys = [
            'clover.config.default',
            'clover.config.URLA2009',
            'clover.config.URLA2020',
        ];
        return Object.assign.apply(Object, __spread([{}], Object.entries(config || {})
            .filter(function (_a) {
            var _b = __read(_a, 1), key = _b[0];
            return !excludeKeys.includes(key);
        })
            .map(function (_a) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            var _c;
            return (_c = {}, _c[key] = value, _c);
        })));
    };
    /**
     * Add state obfuscation
     * @param str
     */
    UIStoreService.prototype.obfuscateAdd = function (str) {
        str = StringUtils.pad(str, this.pad, this.pad);
        str = StringUtils.obfuscateAdd(str);
        str = StringUtils.charShift(str, 10);
        return str;
    };
    /**
     * Remove state obfuscation
     * @param str
     */
    UIStoreService.prototype.obfuscateRemove = function (str) {
        try {
            str = StringUtils.charShift(str, -10);
            str = StringUtils.obfuscateRemove(str);
            str = StringUtils.trim(str, this.pad, this.pad);
        }
        catch (err) {
            console.error(err);
            this.settings.ui = null;
        }
        return str;
    };
    UIStoreService.ngInjectableDef = i0.defineInjectable({ factory: function UIStoreService_Factory() { return new UIStoreService(i0.inject(i1.Store), i0.inject(i2.UiSelectorsService), i0.inject(i3.AppSettings)); }, token: UIStoreService, providedIn: "root" });
    return UIStoreService;
}());
export { UIStoreService };
