var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/**
Adding a new endpoints:
1. Create a new property in the ApiProps enum in api.actions.ts
2. Create a new entry in this api.map. Add endpoint, store property and entity
3. Add the end data location in the main store state in api.store.service.ts
*/
import { ApiProps } from './api.props';
import { createEntity } from './api.actions';
function getResponseProp(data) {
    return (data && data.response) || null;
}
export var ApiMap = {
    // Users Example
    users: {
        endpoint: '//jsonplaceholder.typicode.com/users',
        storeProperty: ApiProps.users,
        entity: createEntity('id'),
    },
    megaLoan: {
        // /api/v2/Loans
        endpoint: "/api/MegaLoanService/EagerLoad",
        storeProperty: ApiProps.megaLoan,
        entity: createEntity('loanId'),
        map: getResponseProp,
    },
    baseLoan: {
        endpoint: "/api/LoanEx/baseloan",
        storeProperty: ApiProps.megaLoan,
        entity: createEntity('loanId'),
        map: function (data) {
            var loan = getResponseProp(data);
            return loan !== null ? __assign({}, loan, { isBaseLoan: true }) : null;
        }
    },
    bnl: {
        endpoint: "/api/BorrowerNeedsService/GetRequestedDocuments",
        storeProperty: ApiProps.bnl,
        entity: createEntity('guid'),
        map: function (data) {
            var bnl = getResponseProp(data);
            // TODO: determine if each borrower need actually has it's own unique ID
            // Move first borrowerNeedIds to GUID property for store purposes
            return bnl.map(function (item) {
                return Object.assign({}, item, { guid: (item && item.borrowerNeedIds && item.borrowerNeedIds[0] || null) });
            });
        },
    },
    broker: {
        storeProperty: ApiProps.broker,
        entity: createEntity('userAccountId'),
        map: getResponseProp,
    },
    client1003Config: {
        // endpoint: 'api/assets/config',
        endpoint: 'assets/config',
        storeProperty: ApiProps.client1003Config,
        entity: createEntity('sectionId'),
    },
    documents: {
        endpoint: "/api/DocumentsService/GetBorrowerDocuments",
        storeProperty: ApiProps.documents,
        entity: createEntity('documentId'),
        map: getResponseProp,
    },
    activeLoanSnapshots: {
        endpoint: "/api/LoanMethodsService/GetActiveLoanSnapshots",
        storeProperty: ApiProps.activeLoanSnapshots,
        entity: createEntity('loanApplicationId'),
        map: getResponseProp,
    },
    loanContacts: {
        endpoint: "/api/LoanParticipantsService/GetLoanContacts",
        storeProperty: ApiProps.loanContacts,
        entity: createEntity('userAccountId'),
        map: getResponseProp,
    },
    currentCompanyProfile: {
        endpoint: "/api/CompanyProfileService/GetCurrentCompanyProfileInfo",
        storeProperty: ApiProps.currentCompanyProfile,
        entity: createEntity('companyId'),
        map: getResponseProp,
    },
    appState: {
        endpoint: "/api/v2/borrowers",
        storeProperty: ApiProps.appState,
        entity: createEntity(null),
        map: function (data) { return data && data.sessionData ? JSON.parse(data.sessionData) : null; }
    },
    config1003: {
        endpoint: "/api/Configuration/GetCloverConfiguration",
        storeProperty: ApiProps.config1003,
        entity: createEntity('sectionId'),
    },
};
