var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { OnInit } from '@angular/core';
import { AppSettings, AuthService } from '$shared';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { ModalsService } from '$modals';
import { UIStoreService } from '$ui';
import { ApiService } from '$api';
import { combineLatest } from 'rxjs';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { BusinessContactPhoneTypeEnum, CPOSNavigationPathEnum, CPOSPageIdEmum } from 'src/app/shared/models';
import { ApplicationNavigationService } from 'src/app/shared/services/application-navigation.service';
var WhichNavEnum;
(function (WhichNavEnum) {
    WhichNavEnum[WhichNavEnum["Neither"] = 1] = "Neither";
    WhichNavEnum[WhichNavEnum["Application"] = 2] = "Application";
    WhichNavEnum[WhichNavEnum["Dashboard"] = 3] = "Dashboard";
})(WhichNavEnum || (WhichNavEnum = {}));
var NavComponent = /** @class */ (function () {
    function NavComponent(auth, settings, modals, 
    // private vm: VersionManagementService,
    router, ui, api, analytics, navService) {
        var _this = this;
        this.auth = auth;
        this.settings = settings;
        this.modals = modals;
        this.router = router;
        this.ui = ui;
        this.api = api;
        this.analytics = analytics;
        this.navService = navService;
        /** Company name */
        this.companyName = this.settings.clientName;
        /** Is the main menu open on mobile */
        this.isMainNavOpen = false;
        /** Is the communications menu open on mobile */
        this.isCommNavOpen = false;
        /** Is the section menu open on mobile */
        this.isSectionNavOpen = false;
        /**   Does the app have an update */
        // public hasUpdate$ = this.vm.hasUpdate$;
        /** App version */
        this.version = this.settings.version;
        /** Used to determine which dropdown nav to show */
        this.whichNavToShow = WhichNavEnum.Neither;
        /** Used to compare in the UI */
        this.whichNavEnum = WhichNavEnum;
        /** Work phone number from the Lead Source, if there is one */
        this.leadSourceWorkPhone$ = this.api.getApiStoreData(this.api.select.broker$).pipe(map(function (broker) {
            // Error handling
            if (!(broker && broker.phones && broker.phones.length))
                return null;
            var allWorkPhones = broker.phones.filter((function (phoneNumber) {
                return phoneNumber.type === BusinessContactPhoneTypeEnum.Work
                    && phoneNumber.number !== 'about:blank';
            }));
            return allWorkPhones.length
                ? allWorkPhones[0].number
                : null;
        }));
        /** Get the loan officer phone number */
        this.wordpressPhoneNumber$ = this.ui.select.config$.pipe(map(function (config) {
            if (config
                && config['config.leadsource.default.phone']
                && config['config.leadsource.default.phone'].value
                && config['config.leadsource.default.phone'].value !== 'about:blank') {
                return config['config.leadsource.default.phone'].value;
            }
            return null;
        }));
        this.loPhoneNumber$ = combineLatest(this.leadSourceWorkPhone$, this.wordpressPhoneNumber$)
            .pipe(map(function (_a) {
            var _b = __read(_a, 2), leadSourcePhone = _b[0], wordpressPhone = _b[1];
            return leadSourcePhone || wordpressPhone || null;
        }));
        this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; })).subscribe(function () {
            _this.isMainNavOpen = false;
            _this.isCommNavOpen = false;
            _this.isSectionNavOpen = false;
            // Determine which nav should appear in the mobile dropdown
            var currentUrl = _this.router.url;
            if (currentUrl.startsWith('/application')) {
                _this.whichNavToShow = WhichNavEnum.Application;
            }
            else if (currentUrl.startsWith('/tasks')) {
                _this.whichNavToShow = WhichNavEnum.Dashboard;
            }
            else {
                _this.whichNavToShow = WhichNavEnum.Neither;
            }
        });
    }
    NavComponent.prototype.ngOnInit = function () {
    };
    NavComponent.prototype.track = function () {
        this.analytics.trackEvent('Hamburger');
    };
    NavComponent.prototype.logOut = function () {
        var currentUrl = this.router.url;
        if (currentUrl.includes('esign')) {
            this.analytics.trackEvent('ESign Back');
        }
        this.auth.logOut(true);
    };
    NavComponent.prototype.onMyLoansClicked = function () {
        this.changeDetectedPopup("/" + CPOSNavigationPathEnum['my-loans']);
    };
    NavComponent.prototype.onMyAccountClicked = function () {
        this.changeDetectedPopup("/" + CPOSNavigationPathEnum['my-account']);
    };
    NavComponent.prototype.changeDetectedPopup = function (navigateUrl) {
        if (this.navService.previousPage
            && (this.navService.previousPage === CPOSPageIdEmum['property-located'] || this.navService.previousPage === CPOSPageIdEmum['looking-to-buy'])) {
            this.navService.navigatingTo = navigateUrl;
            this.isMainNavOpen = false;
            this.navService.setCanNavigateTo(false);
        }
        else {
            this.router.navigateByUrl(navigateUrl);
        }
    };
    return NavComponent;
}());
export { NavComponent };
