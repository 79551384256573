/* tslint:disable:no-bitwise */
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
/**
 * *
 * 'evaluate' creates a Conditional that provides a fluid syntax with ifTrue or ifFalse methods
 */
export function evaluate(bool) {
    return new Conditional(bool);
}
// use for ifTrue or ifFalse as required
export var trueBoolFunc = function () { return true; };
export var falseBoolFunc = function () { return false; };
// DO NOT EXPORT, Conditional is only created via calls to evaluate and itself
var Conditional = /** @class */ (function () {
    function Conditional(state) {
        var _this = this;
        this.state = state;
        this.ifTrue = function (func) {
            return _this.state ? new Conditional(func()) : new Conditional(false);
        };
        this.ifFalse = function (func) {
            return !_this.state ? new Conditional(func()) : new Conditional(true);
        };
    }
    return Conditional;
}());
/**
* *
* 'any' returns true if any one of the input parameters are true
   ex
*/
export function any() {
    var bools = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        bools[_i] = arguments[_i];
    }
    var e_1, _a;
    try {
        for (var bools_1 = __values(bools), bools_1_1 = bools_1.next(); !bools_1_1.done; bools_1_1 = bools_1.next()) {
            var val = bools_1_1.value;
            if (val) {
                return true;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (bools_1_1 && !bools_1_1.done && (_a = bools_1.return)) _a.call(bools_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return false;
}
/**
* *
* 'all' returns true if all of the input parameters are true
*/
export function all() {
    var bools = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        bools[_i] = arguments[_i];
    }
    var e_2, _a;
    try {
        for (var bools_2 = __values(bools), bools_2_1 = bools_2.next(); !bools_2_1.done; bools_2_1 = bools_2.next()) {
            var val = bools_2_1.value;
            if (!val) {
                return false;
            }
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (bools_2_1 && !bools_2_1.done && (_a = bools_2.return)) _a.call(bools_2);
        }
        finally { if (e_2) throw e_2.error; }
    }
    return true;
}
/**
* *
* 'isNull' is modeled after SQL's IsNull the first argument is returned if NOT null,
   otherwise second argument is returned
*/
export function isNull(value, defaultValue) {
    return value ? value : defaultValue;
}
// exported rule types, more can be added as required - KEEP the rules GENERIC!!!
export var ValueTypeRule;
(function (ValueTypeRule) {
    ValueTypeRule[ValueTypeRule["None"] = 0] = "None";
    ValueTypeRule[ValueTypeRule["GreaterThanZero"] = 1] = "GreaterThanZero";
    ValueTypeRule[ValueTypeRule["GreaterOrEqualZero"] = 2] = "GreaterOrEqualZero";
    ValueTypeRule[ValueTypeRule["NotOnlyWhitepace"] = 4] = "NotOnlyWhitepace";
    ValueTypeRule[ValueTypeRule["NotFalse"] = 8] = "NotFalse";
    ValueTypeRule[ValueTypeRule["EqualToZero"] = 16] = "EqualToZero";
    ValueTypeRule[ValueTypeRule["NotZero"] = 32] = "NotZero";
    ValueTypeRule[ValueTypeRule["ArrayIsNotEmpty"] = 64] = "ArrayIsNotEmpty";
    ValueTypeRule[ValueTypeRule["OnlyWhitespace"] = 128] = "OnlyWhitespace";
    ValueTypeRule[ValueTypeRule["NotEmpty"] = 256] = "NotEmpty";
})(ValueTypeRule || (ValueTypeRule = {}));
// IsDefined is used to validate Dates, objects and arrays that have value
var IsDefined = 64;
// Undefined is to handle undefined values that made it to the runRules method
var Undefined = 128;
var ruleMap = {};
ruleMap[ValueTypeRule.GreaterOrEqualZero] = function (val) { return +val >= 0; };
ruleMap[ValueTypeRule.GreaterThanZero] = function (val) { return +val > 0; };
ruleMap[ValueTypeRule.NotOnlyWhitepace] = function (val) { return !isNullOrWhitespace(val); };
ruleMap[ValueTypeRule.NotFalse] = function (val) { return !!val; };
ruleMap[ValueTypeRule.EqualToZero] = function (val) { return val === 0; };
ruleMap[ValueTypeRule.NotZero] = function (val) { return val !== 0; };
ruleMap[IsDefined] = function (_) { return true; }; // validation check made as first step of runRules
ruleMap[Undefined] = function (_) {
    throw 'a value must be defined to run rule on it';
};
ruleMap[ValueTypeRule.ArrayIsNotEmpty] = function (val) { return isNotEmpty(val); };
ruleMap[ValueTypeRule.OnlyWhitespace] = function (val) { return hasOnlyWhitespace(val); };
ruleMap[ValueTypeRule.NotEmpty] = function (val) { return isNotEmpty(val); };
var ruleTypeMap = {};
ruleTypeMap['string'] = [ValueTypeRule.NotOnlyWhitepace];
ruleTypeMap['number'] = [ValueTypeRule.GreaterThanZero, ValueTypeRule.GreaterOrEqualZero, ValueTypeRule.EqualToZero, ValueTypeRule.NotZero];
ruleTypeMap['boolean'] = [ValueTypeRule.NotFalse];
ruleTypeMap['Date'] = [IsDefined];
ruleTypeMap['object'] = [IsDefined];
ruleTypeMap['undefined'] = [Undefined];
// default rules to use be used for data validation
export var defaultTypeRules = ValueTypeRule.GreaterThanZero | ValueTypeRule.NotOnlyWhitepace | ValueTypeRule.NotFalse;
function hasRuleDefined(allRules, rule) {
    return (allRules & rule) > 0;
}
// DO NOT EXPORT - to be only used internally
function runRules(value, valueTypeRule) {
    var e_3, _a;
    // check first if value is defined
    if (!isDefined(value)) {
        return false;
    }
    // always bit OR the default checks to the rule type
    valueTypeRule |= IsDefined | Undefined;
    // get the type
    var valueType = typeof value;
    // lookup the ValueTypeRule rules array
    var ruleTypeArray = ruleTypeMap[valueType];
    try {
        for (var ruleTypeArray_1 = __values(ruleTypeArray), ruleTypeArray_1_1 = ruleTypeArray_1.next(); !ruleTypeArray_1_1.done; ruleTypeArray_1_1 = ruleTypeArray_1.next()) {
            var ruleType = ruleTypeArray_1_1.value;
            // if a requested rule fails, return false
            if (hasRuleDefined(valueTypeRule, ruleType) && !ruleMap[ruleType](value)) {
                return false;
            }
        }
    }
    catch (e_3_1) { e_3 = { error: e_3_1 }; }
    finally {
        try {
            if (ruleTypeArray_1_1 && !ruleTypeArray_1_1.done && (_a = ruleTypeArray_1.return)) _a.call(ruleTypeArray_1);
        }
        finally { if (e_3) throw e_3.error; }
    }
    return true;
}
/**
 *
 * isNotEmpty is used to validate content for strings, arrays, and basically all data types
 */
export function isNotEmpty(value) {
    return !isEmpty(value);
}
/**
*
* isEmpty is used to determine null object, empty string and arrays
*/
export function isEmpty(value) {
    return !isDefined(value) || ((Array.isArray(value) || typeof value === 'string') && value.length === 0);
}
/**
*
* isDefinedAndHasValue can be used for strings, arrays, and basically any data type
*/
export function isDefinedAndHasValue(value, valueTypeRules) {
    if (valueTypeRules === void 0) { valueTypeRules = defaultTypeRules; }
    var hasValue = isNotEmpty(value) && runRules(value, valueTypeRules);
    return hasValue;
}
/**
 *
 * isNullOrWhitespace is used to determine whether a string is undefined or has a meaningless value
 */
var allSpacesRegEx = new RegExp('/\S/');
export function isNullOrWhitespace(val) {
    return !val || allSpacesRegEx.test(val);
}
export function hasOnlyWhitespace(val) {
    return allSpacesRegEx.test(val);
}
/**
 *
 * isDefined is used to determine whether T is actually T
 */
export function isDefined(value) {
    return value !== undefined && value !== null;
}
/**
 *
 * getPropertyValue is used to access a property values by name in a typesafe manner
 */
export function getPropertyValue(obj, key) {
    return obj[key];
}
/**
 *
 * propertyIsDefined is a type safe way to determine that both an object and its named property are defined
 */
export function propertyIsDefined(value, propertyName) {
    return value && isDefined(value[propertyName]);
}
/**
*
* allPropertiesAreDefined is a type safe way to determine that both an object and all of its named properties are defined
*/
export function allPropertiesAreDefined(value) {
    var propertyNames = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        propertyNames[_i - 1] = arguments[_i];
    }
    var e_4, _a;
    try {
        for (var propertyNames_1 = __values(propertyNames), propertyNames_1_1 = propertyNames_1.next(); !propertyNames_1_1.done; propertyNames_1_1 = propertyNames_1.next()) {
            var propertyName = propertyNames_1_1.value;
            if (!propertyIsDefined(value, propertyName)) {
                return false;
            }
        }
    }
    catch (e_4_1) { e_4 = { error: e_4_1 }; }
    finally {
        try {
            if (propertyNames_1_1 && !propertyNames_1_1.done && (_a = propertyNames_1.return)) _a.call(propertyNames_1);
        }
        finally { if (e_4) throw e_4.error; }
    }
    return true;
}
/**
*
* anyPropertyIsDefined is a type safe way to determine that both an object and at least one of its named properties are defined
*/
export function anyPropertyIsDefined(value) {
    var propertyNames = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        propertyNames[_i - 1] = arguments[_i];
    }
    var e_5, _a;
    try {
        for (var propertyNames_2 = __values(propertyNames), propertyNames_2_1 = propertyNames_2.next(); !propertyNames_2_1.done; propertyNames_2_1 = propertyNames_2.next()) {
            var propertyName = propertyNames_2_1.value;
            if (propertyIsDefined(value, propertyName)) {
                return true;
            }
        }
    }
    catch (e_5_1) { e_5 = { error: e_5_1 }; }
    finally {
        try {
            if (propertyNames_2_1 && !propertyNames_2_1.done && (_a = propertyNames_2.return)) _a.call(propertyNames_2);
        }
        finally { if (e_5) throw e_5.error; }
    }
    return false;
}
export function getNumericValue(value, defaultValue) {
    if (defaultValue === void 0) { defaultValue = 0; }
    return (isDefined(value) && +value) ? +value : defaultValue;
}
/**
*
* propertyHasValue is a type safe way to determine that the object is defined and its property is both defined and passes the input ValueTypeRule(s),
* defaultTypeRules = ValueTypeRule.GreaterThanZero | ValueTypeRule.NotOnlyWhitepace | ValueTypeRule.NotFalse;
*/
export function propertyHasValue(value, propertyName, valueTypeRule) {
    if (valueTypeRule === void 0) { valueTypeRule = defaultTypeRules; }
    return propertyIsDefined(value, propertyName) && runRules(value[propertyName], valueTypeRule);
}
/**
*
* grandChildPropertyHasValue is a type safe way to determine a child object and its property are defined
* its property is both defined and passes the input ValueTypeRule(s),
* defaultTypeRules = ValueTypeRule.GreaterThanZero | ValueTypeRule.NotOnlyWhitepace | ValueTypeRule.NotFalse;
*/
export function childObjectPropertyHasValue(value, childObjectName, grandChildPropertyName, valueTypeRule) {
    if (valueTypeRule === void 0) { valueTypeRule = defaultTypeRules; }
    return propertyHasValue(value, childObjectName, valueTypeRule) && propertyHasValue(value[childObjectName], grandChildPropertyName, valueTypeRule);
}
/**
 * safeComparePropertyWithMatchingValue: is a type safe way to determine object's property is safe to access and can do comparison with matching value
 * @param value
 * @param propertyName
 * @param valueToMatch
 */
export function safeComparePropertyWithMatchingValue(value, propertyName, valueToMatch) {
    return propertyIsDefined(value, propertyName) && value[propertyName] === valueToMatch;
}
/**
*
* allPropertiesHaveValue is a type safe way to determine that the object is defined and all its properties are both defined and pass the input ValueTypeRule(s)
*/
export function allPropertiesHaveValue(value, valueTypeRule) {
    var propertyNames = [];
    for (var _i = 2; _i < arguments.length; _i++) {
        propertyNames[_i - 2] = arguments[_i];
    }
    var e_6, _a;
    try {
        for (var propertyNames_3 = __values(propertyNames), propertyNames_3_1 = propertyNames_3.next(); !propertyNames_3_1.done; propertyNames_3_1 = propertyNames_3.next()) {
            var propertyName = propertyNames_3_1.value;
            if (!propertyHasValue(value, propertyName, valueTypeRule)) {
                return false;
            }
        }
    }
    catch (e_6_1) { e_6 = { error: e_6_1 }; }
    finally {
        try {
            if (propertyNames_3_1 && !propertyNames_3_1.done && (_a = propertyNames_3.return)) _a.call(propertyNames_3);
        }
        finally { if (e_6) throw e_6.error; }
    }
    return true;
}
/**
*
* anyPropertyHasValue is a type safe way to determine that the object is defined if any its properties are defined and pass the input ValueTypeRule(s)
*/
export function anyPropertyHasValue(value, valueTypeRule) {
    var propertyNames = [];
    for (var _i = 2; _i < arguments.length; _i++) {
        propertyNames[_i - 2] = arguments[_i];
    }
    var e_7, _a;
    try {
        for (var propertyNames_4 = __values(propertyNames), propertyNames_4_1 = propertyNames_4.next(); !propertyNames_4_1.done; propertyNames_4_1 = propertyNames_4.next()) {
            var propertyName = propertyNames_4_1.value;
            if (propertyHasValue(value, propertyName, valueTypeRule)) {
                return true;
            }
        }
    }
    catch (e_7_1) { e_7 = { error: e_7_1 }; }
    finally {
        try {
            if (propertyNames_4_1 && !propertyNames_4_1.done && (_a = propertyNames_4.return)) _a.call(propertyNames_4);
        }
        finally { if (e_7) throw e_7.error; }
    }
    return false;
}
export function runRegexForMatches(sectionScript, regex) {
    var matches = [];
    var match;
    while (match = regex.exec(sectionScript)) {
        matches.push(match[1]);
    }
    return matches;
}
/* tslint:enable:no-bitwise */
