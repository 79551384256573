/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./components/error/error.component.ngfactory";
import * as i2 from "./components/error/error.component";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
import * as i5 from "./app.component";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./shared/services/service-worker.service";
import * as i8 from "./shared/services/app-comms.service";
import * as i9 from "./shared/services/version-management.service";
import * as i10 from "./shared/app.settings";
import * as i11 from "./shared/services/analytics.service";
import * as i12 from "./shared/services/style-generator.service";
import * as i13 from "./shared/services/head-content.service";
import * as i14 from "./shared/stores/api/api.store.service";
import * as i15 from "./shared/services/logging.service";
import * as i16 from "./shared/services/heartbeat.service";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "container p-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "app-error", [], null, null, null, i1.View_ErrorComponent_0, i1.RenderType_ErrorComponent)), i0.ɵdid(2, 114688, null, 0, i2.ErrorComponent, [], { error: [0, "error"], canClose: [1, "canClose"], showError: [2, "showError"] }, null), (_l()(), i0.ɵeld(3, 0, null, 0, 3, "p", [["class", "d-inline-block"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Unfortunately you have run in to an issue with the pre-release version of Clover. Please try reloading it by pressing "])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Control + F5."])), (_l()(), i0.ɵeld(7, 0, null, 0, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" If that doesn't fix the issue then please copy+paste the contents of this page and email it to the administrators responsible for maintaining this app. "]))], function (_ck, _v) { var currVal_0 = _v.context.ngIf; var currVal_1 = false; var currVal_2 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_AppComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(4, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.error$)); _ck(_v, 1, 0, currVal_0); _ck(_v, 4, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 114688, null, 0, i5.AppComponent, [i4.Router, i4.ActivatedRoute, i6.Title, i7.ServiceWorkerService, i8.AppCommsService, i9.VersionManagementService, i10.AppSettings, i11.AnalyticsService, i12.StyleGeneratorService, i13.HeadContentService, i14.ApiService, i15.LoggingService, i16.HeartbeatService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i5.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
