var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { isType } from 'typescript-fsa';
import { UIStoreActions } from './ui.actions';
// Define initial store state : State.main
var initialState = {
    saveState: null,
    modal: null,
    formBuilder: {
        sections: null,
        pageActiveId: null,
        sectionActiveId: null,
    },
    tabsActive: {},
    toggles: {},
    config: {}
};
export function UIReducer(state, action) {
    // console.log('UI REDUCER:', action);
    if (state === void 0) { state = initialState; }
    if (isType(action, UIStoreActions.REHYDRATE)) {
        state = __assign({}, initialState, action.payload);
    }
    if (isType(action, UIStoreActions.MODAL_OPEN)) {
        state.modal = __assign({}, action.payload);
    }
    if (isType(action, UIStoreActions.FORM_BUILDER_CHANGE)) {
        state.formBuilder = __assign({}, state.formBuilder, action.payload);
    }
    if (isType(action, UIStoreActions.MODAL_UNLOAD)) {
        state.modal = null;
    }
    if (isType(action, UIStoreActions.CONFIG)) {
        state.config = __assign({}, action.payload);
    }
    if (isType(action, UIStoreActions.TOGGLES)) {
        state.toggles[action.payload.prop] = action.payload.value;
    }
    if (isType(action, UIStoreActions.TAB_CHANGE)) {
        state.tabsActive[action.payload.tabInstanceId] = action.payload.tabId;
    }
    // If the action namespace is the UI store and NOT the rehydrate action
    if (action.type && action.type.split('/')[0] === 'ui' && !isType(action, UIStoreActions.REHYDRATE)) {
        var saveState = __assign({}, state);
        delete saveState.saveState;
        // Fail silently if a circular structure error exists
        try {
            state.saveState = JSON.parse(JSON.stringify(saveState));
        }
        catch (err) { }
    }
    // console.log('UI STATE: ', JSON.parse(JSON.stringify(state)));
    return state;
}
