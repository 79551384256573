import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '$api';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { LoanUtils, ProcessingStateEnum } from '../../../shared/utils/loan-utils';
import { AppSettings, AnalyticsService } from '$shared';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { HeartbeatService } from 'src/app/shared/services/heartbeat.service';
import {
  LoanContactType,
  ILoanContact,
  ILeadSourceInfoViewModel,
  BusinessContactPhoneTypeEnum,
  IChannelViewModel,
  IDivisionViewModel,
  IBranchViewModel
 } from 'src/app//shared/models';


interface LOAddress {
  street: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
}

@Component({
  selector: 'app-sidebar-right',
  templateUrl: './sidebar-right.component.html',
  styleUrls: ['./sidebar-right.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarRightComponent implements OnInit, OnDestroy {
  private beforeProcessing = true;
  @Input()
  public isCommNavOpen = true;
  public appState$ = this.api.appState$;
  public loanContactsState$ = this.api.select.loanContacts$;
  public pictureUrlDefault = 'assets/img/agent.png';
  public pictureUrl = this.pictureUrlDefault;
  public loAddress: LOAddress = <LOAddress>{};
  public loanContactType = LoanContactType;
  public lenderName: string;
  public companyPhone: string;
  public companyNmls: string;
  public loanContacts$: Observable<ILoanContact[]> = combineLatest(
    this.api.getApiStoreData(this.loanContactsState$).pipe(
      debounceTime(1000),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    ),
    this.api.broker.get(),
  ).pipe(
    map(
      ([loanContacts, leadSourceInfo]: [ILoanContact[], ILeadSourceInfoViewModel]): ILoanContact[] => {
        loanContacts = loanContacts.length ? loanContacts : [];
        const hasProcessor = loanContacts.some((contact: ILoanContact) => {
          return contact.loanContactType === LoanContactType.Processor;
        });

        const loanContactsFiltered = loanContacts
          .filter((contact: ILoanContact) => {
            // always hide 'other'
            if (contact.loanContactType === LoanContactType.Other) return false;
            if (this.beforeProcessing) {
              // if before processing, always hide processor
              if (contact.loanContactType === LoanContactType.Processor) return false;
            } else {
              // if in processing or later, hide loan officer assistant (but only if no processor)
              if (contact.loanContactType === LoanContactType.LoanOfficerAssistant && hasProcessor) return false;
            }
            return true;
          })
          .sort((a: ILoanContact, b: ILoanContact) => {
            if (a.loanContactType === LoanContactType.LoanOfficer) return -1;
            if (b.loanContactType === LoanContactType.LoanOfficer) return 1;

            if (a.loanContactType === LoanContactType.LoanOfficerAssistant) return -1;
            if (b.loanContactType === LoanContactType.LoanOfficerAssistant) return 1;

            if (a.loanContactType === LoanContactType.Processor) return -1;
            if (b.loanContactType === LoanContactType.Processor) return 1;
          });

        if (loanContactsFiltered.length) {
          // If any loan officers were found,
          // use the LSID to get the company information
          const loanOfficer = loanContactsFiltered.filter(
            contact => contact.loanContactType === LoanContactType.LoanOfficer,
          )[0];
          if (loanOfficer) {
            this.setLOAndCompanyInfo(loanOfficer);
          } else {
            this.setLOAndCompanyInfo();
          }
          // If there are loan contacts that matched the above criteria,
          // show these contacts in the sidebar
          return loanContactsFiltered;
        } else if (leadSourceInfo) {
          // If no loan contacts were found (perhaps this is a newly registered borrower?)
          // Pull loan contact info from LeadSourceBasicInfo API
          const leadSourcePhones = leadSourceInfo.phones && leadSourceInfo.phones.length ? leadSourceInfo.phones : [];
          const phoneHash = leadSourcePhones.reduce((accumulator, phone) => {
              accumulator[phone.type] = phone.cleanNumber;
              return accumulator;
            }, <Record<BusinessContactPhoneTypeEnum, string>>{});
          const leadSourcePhone = phoneHash[BusinessContactPhoneTypeEnum.Cell] || phoneHash[BusinessContactPhoneTypeEnum.Work] || null;
          const loanOfficer = leadSourcePhone ? <ILoanContact>{phone: leadSourcePhone} : null;
          this.setLOAndCompanyInfo(loanOfficer);

          return [
            <ILoanContact>{
              pictureId: leadSourceInfo.pictureId ? leadSourceInfo.pictureId : null,
              email: leadSourceInfo.emailAddress,
              loanContactType: LoanContactType.LoanOfficer,
              name: `${leadSourceInfo.firstName} ${leadSourceInfo.lastName}`,
              nmlsNumber: leadSourceInfo.nmlsNumber,
              phone: leadSourceInfo.phones && leadSourceInfo.phones.length ? leadSourceInfo.phones[0].cleanNumber : null,
              title: leadSourceInfo.jobTitle,
              userAccountId: leadSourceInfo.userAccountId,
            },
          ];
        } else {
          this.setLOAndCompanyInfo();
          // If there is no data to display, return an empty array
          return [];
        }
      },
    ),
  );

  constructor(
    private api: ApiService,
    private settings: AppSettings,
    private ref: ChangeDetectorRef,
    private analytics: AnalyticsService,
    private heartbeatService: HeartbeatService
  ) {}

  ngOnInit() {
    this.setPictureUrl();
    this.heartbeatService.confirmReleaseOnLogOut();
  }

  /** Needed for until destroyed */
  ngOnDestroy(): void {}

  setLOAddress(loAddress: LOAddress): void {
    this.loAddress = loAddress;
    this.ref.markForCheck();
  }

  setPictureUrl(): void {
    // Make sure the store contacts contact data
    this.appState$
      .pipe(
        untilDestroyed(this),
        // Filter valid form1003 objects
        filter(appState => appState && !!appState.form1003),
        // Check to see if form1003 has changed
        map(appState => JSON.stringify(appState.form1003)),
        distinctUntilChanged(),
        // Switch to loan
        switchMap(() => this.api.getApiStoreData(this.api.select.megaLoan$)),
        // Used to determine which contact to show in the UI
        tap(loan => {
          const milestoneState = LoanUtils.getProcessingState(loan.currentMilestone);
          this.beforeProcessing = milestoneState === ProcessingStateEnum.Preprocessing;
        }),
        // Use loan to get loanContacts from API
        switchMap(() => this.api.loanContacts.get()),
        // Use consolidated and filtered loanContacts$ to find picture ID
        switchMap(() => {
          return (
            this.loanContacts$
              // Map down to a single picture URL
              .pipe(
                take(1),
                map(contacts => {
                  const loanContact = contacts.filter(
                    contact => contact.pictureId && contact.loanContactType === LoanContactType.LoanOfficer,
                  );
                  return loanContact && loanContact.length
                    ? this.api.services.pictureGetUrl(loanContact[0].pictureId)
                    : this.pictureUrlDefault;
                }),
              )
          );
        }),
        // Filter out same picture URLs
        distinctUntilChanged(),
      )
      .subscribe(pictureUrl => {
        if (pictureUrl) {
          this.pictureUrl = pictureUrl;
          this.ref.markForCheck();
        }
      });
  }

  setLOAndCompanyInfo(loanOfficer: ILoanContact = <ILoanContact>{}, setLOAddress = true): void {
    // Use loan officer LSID if found
    const lsid = loanOfficer && loanOfficer.leadSourceId || this.settings.lsid;

    this.api.companyBasicInfo.getForUser(lsid).subscribe(apiResponse => {
      // Error handling
      if (!(apiResponse && apiResponse.response)) return;
      const company = apiResponse.response;
      const channel = company.channels
        && Array.isArray(company.channels)
        && company.channels[0]
          ? company.channels[0]
          : <IChannelViewModel>{};
      const division = channel
        && channel.divisions
        && Array.isArray(channel.divisions)
        && channel.divisions[0]
          ? channel.divisions[0]
          : <IDivisionViewModel>{};
      const branch = division
        && division.branches
        && Array.isArray(division.branches)
        && division.branches[0]
          ? division.branches[0]
          : <IBranchViewModel>{};
      const isWholesale = channel && channel.isWholesale;

      if (setLOAddress) {
        // Set LO Address
        const loStreetAddress: string = branch.streetAddress || division.streetAddress || channel.streetAddress || company.streetAddress;
        const loStreetAddress2: string = null;
        const loCity: string = branch.city || division.city || channel.city || company.city;
        const loState: string = branch.state || division.state || channel.state || company.state;
        const loZip: string = branch.zip || division.zip || channel.zip || company.zipCode;
        const loPhone: string = (loanOfficer && loanOfficer.phone) || branch.phone || division.phone || channel.phone || company.phone;
        this.setLOAddress({ street: loStreetAddress, street2: loStreetAddress2, city: loCity, state: loState, zip: loZip, phone: loPhone, });
      }

      // Set company info
      this.lenderName = (!isWholesale && branch.disclosuresLenderName) || division.disclosuresLenderName || channel.disclosuresLenderName || company.disclosuresLenderName;
      this.companyPhone = (!isWholesale && branch.phone) || division.phone || channel.phone || company.phone;
      this.companyNmls = !isWholesale ? company.nmlsNumber : division.nmlsNumber || channel.nmlsNumber || company.nmlsNumber;

      // Set Client Name
      if (branch.displayName) {
        this.settings.clientName = branch.displayName;
      }

      // Analytics
      if (branch.branchId) {
        this.settings.branchId = branch.branchId;
        this.analytics.mixpanelSuperProps({ 'Branch Id': branch.branchId });
      }
      if (division.divisionId) {
        this.analytics.mixpanelSuperProps({ 'Branch Id': division.divisionId });
      }
      if (channel.channelId) {
        this.analytics.mixpanelSuperProps({ 'Branch Id': channel.channelId });
      }
      this.analytics.mixpanelSuperProps({ 'NMLS Number': this.companyNmls });

      // Update UI
      this.ref.markForCheck();
    });
  }

  /**
   * Handle issues when an image cannot successfully load
   * @param event Image event
   */
  onImageError(event: any): void {
    event.target.src = this.pictureUrlDefault;
  }

  /**
   * Open address in new window
   * @param address
   */
  openAddress(address: LOAddress): boolean {
    if (!address) return;
    let addressString = address.street ? `${address.street}` : '';
    addressString += address.street2 ? ` ${address.street2}` : '';
    addressString += address.city ? ` ${address.city}` : '';
    addressString += address.state ? ` ${address.state}` : '';
    addressString += address.zip ? ` ${address.zip}` : '';
    window.open(`https://www.google.com/maps/place/${encodeURI(addressString)}/`, '_blank');
    return false;
  }
}
