/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hint.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/directives/focus-contain.directive";
import * as i3 from "@angular/material/dialog";
import * as i4 from "../../../shared/directives/focus.directive";
import * as i5 from "./hint.component";
var styles_HintModalComponent = [i0.styles];
var RenderType_HintModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HintModalComponent, data: {} });
export { RenderType_HintModalComponent as RenderType_HintModalComponent };
export function View_HintModalComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["aria-describedby", "modal-title"], ["aria-modal", "true"], ["automationid", "Page_Hint"], ["focusContain", ""], ["id", "modal"], ["role", "alertdialog"]], null, [[null, "keydown"]], function (_v, en, $event) { var ad = true; if (("keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.FocusContainDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["automationid", "Section_Header"], ["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "h2", [["class", "modal-title"], ["id", "modal-title"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "button", [["aria-label", "Close"], ["automationid", "BtnClose"], ["class", "close"], ["mat-dialog-close", ""], ["type", "button"]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).dialogRef.close(i1.ɵnov(_v, 5).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 606208, null, 0, i3.MatDialogClose, [[2, i3.MatDialogRef], i1.ElementRef, i3.MatDialog], { ariaLabel: [0, "ariaLabel"], dialogResult: [1, "dialogResult"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "modal-body"], ["id", "modal-body"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["automationid", "Section_Footer"], ["class", "modal-footer text-center"], ["style", "justify-content: center;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "button", [["appFocus", ""], ["aria-label", "Close"], ["class", "btn btn-outline-secondary w-50"]], null, [[null, "click"], [null, "keydown.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.enter" === en)) {
        var pd_1 = (_co.submit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 4210688, null, 0, i4.FocusDirective, [i1.ElementRef], { appFocus: [0, "appFocus"] }, null), (_l()(), i1.ɵted(-1, null, [" Close "]))], function (_ck, _v) { var currVal_2 = "Close"; var currVal_3 = ""; _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_5 = ""; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 5).ariaLabel || null); _ck(_v, 4, 0, currVal_1); var currVal_4 = _co.data.body; _ck(_v, 8, 0, currVal_4); }); }
export function View_HintModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-hint", [], null, null, null, View_HintModalComponent_0, RenderType_HintModalComponent)), i1.ɵdid(1, 114688, null, 0, i5.HintModalComponent, [i3.MatDialogRef, i3.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HintModalComponentNgFactory = i1.ɵccf("app-hint", i5.HintModalComponent, View_HintModalComponent_Host_0, {}, {}, []);
export { HintModalComponentNgFactory as HintModalComponentNgFactory };
