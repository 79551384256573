import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { map, filter, mergeMap, take, switchMap } from 'rxjs/operators';

import { environment } from '$env';
import { ServiceWorkerService, AppCommsService, AppSettings, AnalyticsService, StyleGeneratorService } from '$shared';
import { VersionManagementService } from './shared/services/version-management.service';
import { HeadContentService } from './shared/services/head-content.service';
import { ApiService } from '$api';
import { LoggingService } from 'src/app/shared/services/logging.service';
import { HeartbeatService } from './shared/services/heartbeat.service';
import { SecureLinkEnum, ICPOSLogEntry } from 'src/app/shared/models';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  /** Global/app errors */
  public error$ = this.settings.error$;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    // private authService: AuthService,
    private sw: ServiceWorkerService,
    private comms: AppCommsService,
    private version: VersionManagementService,
    private settings: AppSettings,
    private analytics: AnalyticsService,
    private style: StyleGeneratorService,
    private headContentService: HeadContentService,
    private api: ApiService,
    private log: LoggingService,
    private heartbeatService: HeartbeatService
  ) { }

  ngOnInit() {
    this.addUnsupportedBannerIfIE();

    this.routeChange();

    // Load and manage client styles and cofiguration
    this.style.start();

    // Load and manage DOM elements inside <head>
    this.headContentService.start();

    // Start logging service
    if (environment.endpoints.logging) {
      this.log.start(this.settings.apiUrl + environment.endpoints.logging);
    }

    // Start analytics tracking
    this.analytics.start({ mixpanel: true });

    // Add LSID
    this.analytics.mixpanelSuperProps({ 'Lead Source Id': this.settings.lsid });

    // Both clientName and clientID must be present for all analytics calls
    // Because these are typically only available post auth, a workaround is necessary to get this data from the company profile api
    if (!this.settings.clientName) {
      // Check if lsid has been added as a query parameters
      const lsid = this.activatedRoute.snapshot.queryParams['lsid'] || this.settings.lsid || null;
      // Load broker, this is to get the userID which is needed by the companyProfile endpoint
      this.api.broker
        .get(lsid)
        .pipe(
          // Null check broker and useraccountId
          filter(broker => (broker && broker.userAccountId ? true : false)),
          // Get company profile with userId from broker endpoint
          switchMap(broker => this.api.currentCompanyProfile.get(this.settings.lsid, String(broker.userAccountId))),
        )
        .subscribe();
    }

    // If service worker
    if (environment.settings.enableServiceWorker) {
      this.sw.enable();
    }

    // If app comms
    if (environment.settings.enableAppComms) {
      this.comms.commsEnable();
    }

    // If version endpoint specified, poll for version changes
    if (environment.endpoints.version) {
      this.version.versionCheckStart();
    }

    // Handle redirects from query parameters
    this.handleRedirects();

    // Trigger loan release
    this.handleLoanRelease()

  }

  /**
   * Actions to perform on route change
   * Page titles are in app.routes.ts
   */
  public routeChange() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data),
      )
      .subscribe(event => {
        this.title.setTitle(event['title']); // Change document title
        if (environment.endpoints.logging) {
          this.log.track<ICPOSLogEntry>({
            eventTime: new Date(),
            message: 'Clover route change => ' + this.router.url,
            metadata: {
              routePath: this.router.url,
              routeTitle: event['title']
            }
          });
        }
        // If auth endpoint is available and not on the login page
        // if (environment.settings.enableAuth && this.router.url.toLowerCase().indexOf('login') === -1) {
        //   this.authService.refreshTokenUpdate(); // On Route change, refresh authentication token
        // }
      });
  } // end routeChange

  /**
   * Handles redirecting when query parameters were provided that instruct the
   * app to go to a certain route or perform a specific function (e.g. eSign)
   */
  public handleRedirects() {
    // Wait until query param data is available
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        take(1),
      )
      .subscribe(() => {
        const queryParams = this.activatedRoute.snapshot.queryParams;
        const secureLinkEnum: SecureLinkEnum =
          queryParams['secureLinkId'] != null ? +queryParams['secureLinkId'] : null;
        if (secureLinkEnum === SecureLinkEnum.ViewMyNeedsList) {
          this.router.navigate(['/tasks']);
        } else if (queryParams['token']) {
          this.router.navigate(['/'], { preserveQueryParams: true });
        }
      });
  }


  public handleLoanRelease() {
    const loanRoutes = ["/tasks", "/application", "/", "/?session=loggedout"]
    let currentUrl = this.router.url;

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    )
      .subscribe((route: any) => {
        const newRoute = route.url;
        if (loanRoutes.some((prefix) => currentUrl === prefix || currentUrl.startsWith(prefix + '/')) &&
          !(loanRoutes.some((prefix) => newRoute === prefix || newRoute.startsWith(prefix + '/'))) &&
          this.settings.userId && this.settings.loanId) {
          this.heartbeatService.releaseLock().subscribe();
        }
        currentUrl = newRoute
      });
  }



  public addUnsupportedBannerIfIE() {
    // Regular expression to test if userAgent string contains Trident or MSIE for IE 10 and IE 11
    const unsupportedBrowserTest = /Trident\/|MSIE/.test(window.navigator.userAgent);
    const tagEl = document.querySelectorAll("#unsupportedBrowser");
    if (unsupportedBrowserTest) {
      tagEl[0].innerHTML = 'Your browser is not officially supported by this application. Your experience may be slow or buggy. Please <a href="https://www.google.com/chrome/browser/desktop/index.html" style="color:red;">install or use Chrome</a>.';
      tagEl[0].classList.add("alert");
      tagEl[0].classList.add("alert-danger");
      tagEl[0].classList.add("icon");
      tagEl[0].classList.add("mb-0");
      tagEl[0].classList.add("text-center");
    }
  }
}
