/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i2 from "@angular/material/button";
import * as i3 from "@angular/cdk/platform";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../../../shared/directives/focus-contain.directive";
import * as i7 from "@angular/common";
import * as i8 from "./urla-change-modal.component";
import * as i9 from "@angular/material/dialog";
import * as i10 from "../../../shared/stores/api/api.store.service";
import * as i11 from "@angular/router";
import * as i12 from "../../../shared/services/application-navigation.service";
var styles_UrlaChangeModalComponent = [];
var RenderType_UrlaChangeModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UrlaChangeModalComponent, data: {} });
export { RenderType_UrlaChangeModalComponent as RenderType_UrlaChangeModalComponent };
function View_UrlaChangeModalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "modal-footer"], ["style", "justify-content: center;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "button", [["aria-label", "Close"], ["class", "ml-auto"], ["color", "accent"], ["mat-flat-button", ""], ["tabindex", "1"], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit(_v.context.ngIf) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatButton_0, i1.RenderType_MatButton)), i0.ɵdid(2, 180224, null, 0, i2.MatButton, [i0.ElementRef, i3.Platform, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i0.ɵeld(3, 0, null, 0, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-check"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, 0, [" Got it! "]))], function (_ck, _v) { var currVal_2 = "accent"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 2).disabled || null); var currVal_1 = (i0.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_UrlaChangeModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["aria-describedby", "modal-title"], ["aria-modal", "true"], ["focusContain", ""], ["id", "modal"], ["role", "alertdialog"]], null, [[null, "keydown"]], function (_v, en, $event) { var ad = true; if (("keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i6.FocusContainDirective, [i0.ElementRef], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["automationid", "Section_Header"], ["class", "modal-header"], ["style", "justify-content: center;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h3", [["class", "modal-title color-primary"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["WE HAVE UPDATED YOUR APPLICATION"])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "modal-body"], ["id", "urla-modal-body"], ["style", "max-width: 55vw !important; padding: 1rem 2rem 1rem 2rem !important;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "info-text"], ["id", "primary-info-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" We have slightly updated the questions asked in your application since you last logged in. Don't worry, we have saved all the information you have previously provided, but you may want to re-confirm each page before submitting. "])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_UrlaChangeModalComponent_1)), i0.ɵdid(9, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i7.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 9, 0, i0.ɵnov(_v, 10).transform(_co.appState$)); _ck(_v, 9, 0, currVal_0); }, null); }
export function View_UrlaChangeModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-urla-change-modal", [], null, null, null, View_UrlaChangeModalComponent_0, RenderType_UrlaChangeModalComponent)), i0.ɵdid(1, 49152, null, 0, i8.UrlaChangeModalComponent, [i9.MatDialogRef, i9.MAT_DIALOG_DATA, i9.MAT_DIALOG_DATA, i10.ApiService, i11.Router, i12.ApplicationNavigationService], null, null)], null, null); }
var UrlaChangeModalComponentNgFactory = i0.ɵccf("app-urla-change-modal", i8.UrlaChangeModalComponent, View_UrlaChangeModalComponent_Host_0, {}, {}, []);
export { UrlaChangeModalComponentNgFactory as UrlaChangeModalComponentNgFactory };
