import { unescape, escape } from 'lodash';
export var joinString = function (separator) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    return args.filter(function (a) { return !!a; }).join(separator);
};
/**
 * Helper utilities for string manipulation
 */
var StringUtils = /** @class */ (function () {
    function StringUtils() {
    }
    /**
     * Generate a random string of letters and numbers
     * @param length - The length of the string
     */
    StringUtils.randomstring = function (length) {
        var s = '';
        var randomchar = function () {
            var n = Math.floor(Math.random() * 62);
            if (n < 10) {
                return n; // 1-10
            }
            if (n < 36) {
                return String.fromCharCode(n + 55); // A-Z
            }
            return String.fromCharCode(n + 61); // a-z
        };
        while (s.length < length) {
            s += randomchar();
        }
        return s;
    };
    /**
     * Obfuscate a string by encoding with base64
     * @param val A string to obfuscate
     */
    StringUtils.obfuscateAdd = function (val) {
        if (val && window) {
            return window.btoa(unescape(encodeURIComponent(val.toString())));
        }
        return null;
    };
    /**
     * Remove the obfuscation of a string by decoding it from base64
     * @param val  A string to obfuscate
     */
    StringUtils.obfuscateRemove = function (val) {
        if (val && window) {
            return decodeURIComponent(escape(window.atob(val)));
        }
        return null;
    };
    /**
     * Pad a string by adding random characters before or after the input
     * @param val Input string
     * @param before Number of characters to PREPEND to the string
     * @param after Number of characters to APPEND to the string
     */
    StringUtils.pad = function (val, before, after) {
        var str = val;
        if (before) {
            str = this.randomstring(before) + str;
        }
        if (after) {
            str = str + this.randomstring(after);
        }
        return str;
    };
    /**
     * Remove a specific number of characters from the front or back of a strong
     * @param val Input string
     * @param before Number of characters to remove from the FRONT of a string
     * @param after Number of characters to remove from the Back of a string
     */
    StringUtils.trim = function (val, before, after) {
        var str = val;
        if (before) {
            str = str.substring(before);
        }
        if (after) {
            str = str.substring(0, str.length - after);
        }
        return str;
    };
    /**
     * Shifts the characters in a string to new ones based on the offset amount
     * @param str
     * @param offsetAmount
     */
    StringUtils.charShift = function (str, offsetAmount) {
        if (offsetAmount === void 0) { offsetAmount = 1; }
        var charsList = ' ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()-=[{]}|;:",<.>/?';
        var strNew = '';
        // Loop through all the chars in the string
        for (var i = 0; i < str.length; i++) {
            // Current character
            var charCurrent = str.charAt(i);
            // Position of current character in charList
            var charNewIndex = charsList.indexOf(charCurrent);
            // Make sure the character is in the charsList
            if (charNewIndex !== -1) {
                // If the character index plus the offset exceed the string length, get it to wrap via modulus
                if (charNewIndex + offsetAmount > charsList.length) {
                    charNewIndex = (charNewIndex + offsetAmount) % charsList.length;
                }
                else if (charNewIndex + offsetAmount < 0) {
                    // If char index plus offset is a number less than zero, get it to wrap
                    charNewIndex = -((charNewIndex + offsetAmount) % charsList.length);
                }
                else {
                    // Just add the offset to the char index
                    charNewIndex += offsetAmount;
                }
                // Get the new offset character from charsList, add to string
                strNew += charsList.charAt(charNewIndex);
            }
            else {
                // If char is not in the charList, don't shift it
                strNew += charCurrent;
            }
        }
        return strNew;
    };
    return StringUtils;
}());
export { StringUtils };
