import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Manages navigating around the 1003 / application route
 */
@Injectable({
  providedIn: 'root'
})
export class ApplicationNavigationService {

  sections$: BehaviorSubject<CvFormBuilder.SectionControl[]> = new BehaviorSubject(null);
  appRouting$: BehaviorSubject<{ sectionId: string, pageId: string }> = new BehaviorSubject(null);
  private subject$ = new BehaviorSubject(null);
  canNavigateTo$ = this.subject$.asObservable();
  public routeParms: any = null;
  public previousPage: string;
  public navigatingTo: string;
  public navigationModel: any = null;

  constructor() { }

  /**
   * Update the sections Subject
   * @param sections Updated section object
   */
  setSections(sections: CvFormBuilder.SectionControl[]): void {
    this.sections$.next(sections);
  }

  /**
   * Change a section
   * @param sectionId
   */
  public setCurrentSection(path: {sectionId: string, pageId: string}) {
    this.appRouting$.next(path);
  }

  public setCanNavigateTo(value = true) {
    this.subject$.next(value);
  }
}
