/**
Adding a new endpoints:
1. Create a new property in the ApiProps enum in api.actions.ts
2. Create a new entry in this api.map. Add endpoint, store property and entity
3. Add the end data location in the main store state in api.store.service.ts
*/
import { ApiProps } from './api.props';
import { createEntity } from './api.actions';
import { AppStore } from '../store';
import {
  ILoanViewModel,
  IBaseLoanViewModel,
  IRequestedDocumentViewModel,
  ILeadSourceInfoViewModel,
  IBorrowerDocumentViewModel,
  ILoanSnapshotViewModel,
  ILoanContact,
  ICompanyProfileHierarchyViewModel
 } from 'src/app/shared/models';
import { ICPOSUser } from '../../models';

function getResponseProp(data: { response: any }): any[] {
  return (data && data.response) || null;
}

export const ApiMap: { [key: string]: AppStore.ApiMap } = {
  // Users Example
  users: {
    endpoint: '//jsonplaceholder.typicode.com/users',
    storeProperty: ApiProps.users,
    entity: createEntity<ICPOSUser>('id'),
  },
  megaLoan: {
    // /api/v2/Loans
    endpoint: `/api/MegaLoanService/EagerLoad`,
    storeProperty: ApiProps.megaLoan,
    entity: createEntity<ILoanViewModel>('loanId'),
    map: getResponseProp,
  },
  baseLoan: {
    endpoint: `/api/LoanEx/baseloan`,
    storeProperty: ApiProps.megaLoan,
    entity: createEntity<IBaseLoanViewModel>('loanId'),
    map: data => {
      const loan = getResponseProp(data);
      return loan !== null ? {...loan, isBaseLoan: true} : null;
    }
  },
  bnl: {
    endpoint: `/api/BorrowerNeedsService/GetRequestedDocuments`,
    storeProperty: ApiProps.bnl,
    entity: createEntity<IRequestedDocumentViewModel>('guid'),
    map: data => {
      const bnl = getResponseProp(data);
      // TODO: determine if each borrower need actually has it's own unique ID
      // Move first borrowerNeedIds to GUID property for store purposes
      return bnl.map((item: IRequestedDocumentViewModel) => {
        return Object.assign({}, item, { guid: (item && item.borrowerNeedIds && item.borrowerNeedIds[0] || null) });
      });
    },
  },
  broker: {
    storeProperty: ApiProps.broker,
    entity: createEntity<ILeadSourceInfoViewModel>('userAccountId'),
    map: getResponseProp,
  },
  client1003Config: {
    // endpoint: 'api/assets/config',
    endpoint: 'assets/config',
    storeProperty: ApiProps.client1003Config,
    entity: createEntity<any>('sectionId'),
  },
  documents: {
    endpoint: `/api/DocumentsService/GetBorrowerDocuments`,
    storeProperty: ApiProps.documents,
    entity: createEntity<IBorrowerDocumentViewModel>('documentId'),
    map: getResponseProp,
  },
  activeLoanSnapshots: {
    endpoint: `/api/LoanMethodsService/GetActiveLoanSnapshots`,
    storeProperty: ApiProps.activeLoanSnapshots,
    entity: createEntity<ILoanSnapshotViewModel>('loanApplicationId'),
    map: getResponseProp,
  },
  loanContacts: {
    endpoint: `/api/LoanParticipantsService/GetLoanContacts`,
    storeProperty: ApiProps.loanContacts,
    entity: createEntity<ILoanContact>('userAccountId'),
    map: getResponseProp,
  },
  currentCompanyProfile: {
    endpoint: `/api/CompanyProfileService/GetCurrentCompanyProfileInfo`,
    storeProperty: ApiProps.currentCompanyProfile,
    entity: createEntity<ICompanyProfileHierarchyViewModel>('companyId'),
    map: getResponseProp,
  },
  appState: {
    endpoint: `/api/v2/borrowers`,
    storeProperty: ApiProps.appState,
    entity: createEntity<ICompanyProfileHierarchyViewModel>(null),
    map: (data: any) =>  data && data.sessionData ? JSON.parse(data.sessionData) : null
  },
  config1003: {
    endpoint: `/api/Configuration/GetCloverConfiguration`,
    storeProperty: ApiProps.config1003,
    entity: createEntity<CvFormBuilder.SectionControl[]>('sectionId'),
  },
};
