/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./application-nav.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "../../shared/pipes/text-case.pipe";
import * as i5 from "./application-nav.component";
import * as i6 from "../../shared/app.settings";
import * as i7 from "../../shared/services/application-navigation.service";
import * as i8 from "../../shared/stores/api/api.store.service";
import * as i9 from "../../shared/stores/ui/ui.store.service";
var styles_ApplicationNavComponent = [i0.styles];
var RenderType_ApplicationNavComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ApplicationNavComponent, data: {} });
export { RenderType_ApplicationNavComponent as RenderType_ApplicationNavComponent };
function View_ApplicationNavComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "expand-indicator-arrow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-chevron-down color-primary"]], null, null, null, null, null))], null, null); }
function View_ApplicationNavComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["automationid", "Page_ApplicationNav"], ["class", "section-icon-with-progress text-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationNavComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "progress-container"]], [[4, "width", "px"], [4, "height", "px"]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, [[1, 0], ["progressCanvas", 1]], null, 0, "canvas", [["class", "progress-canvas"]], [[8, "width", 0], [8, "height", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [], [[8, "className", 0]], null, null, null, null)), i1.ɵppd(6, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showExpandArrow; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.progressRadius * 2); var currVal_2 = (_co.progressRadius * 2); _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(1, "", (_co.progressRadius * 2), ""); var currVal_4 = i1.ɵinlineInterpolate(1, "", (_co.progressRadius * 2), ""); _ck(_v, 4, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵinlineInterpolate(1, "section-icon ", i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent, 0), _co.activeSectionName, "kebab")), ""); _ck(_v, 5, 0, currVal_5); }); }
function View_ApplicationNavComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["URLA 2009"]))], null, null); }
function View_ApplicationNavComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["URLA 2020"]))], null, null); }
function View_ApplicationNavComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationNavComponent_5)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationNavComponent_6)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isURLA2020(); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isURLA2020(); _ck(_v, 4, 0, currVal_1); }, null); }
function View_ApplicationNavComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["aria-current", "step"]], [[8, "tabIndex", 0], [1, "automationid", 0]], [[null, "click"], [null, "keydown.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setActiveSection(_v.parent.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.enter" === en)) {
        var pd_1 = (_co.setActiveSection(_v.parent.parent.parent.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isSectionNavOpen ? "0" : "-1"); var currVal_1 = ("ANavBar_" + _v.parent.parent.parent.context.$implicit.title); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _v.parent.parent.parent.context.$implicit.title; _ck(_v, 2, 0, currVal_2); }); }
function View_ApplicationNavComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationNavComponent_11)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isSectionAccessible(_v.parent.parent.context.$implicit); var currVal_1 = i1.ɵnov(_v.parent, 7); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ApplicationNavComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["aria-current", "step"]], [[8, "tabIndex", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 4), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_3 = _ck(_v, 2, 0, "/", "application", _v.parent.parent.parent.context.$implicit.sectionId, "final"); _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isSectionNavOpen ? "0" : "-1"); var currVal_1 = i1.ɵnov(_v, 1).target; var currVal_2 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = _v.parent.parent.parent.context.$implicit.title; _ck(_v, 3, 0, currVal_4); }); }
function View_ApplicationNavComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationNavComponent_13)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.canSubmit; var currVal_1 = i1.ɵnov(_v.parent, 7); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ApplicationNavComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.title; _ck(_v, 1, 0, currVal_0); }); }
function View_ApplicationNavComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Step Completed"]))], null, null); }
function View_ApplicationNavComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "li", [], [[1, "aria-current", 0]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { active: 0, complete: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationNavComponent_10)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationNavComponent_12)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["disabled", 2]], null, 0, null, View_ApplicationNavComponent_14)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationNavComponent_15)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, " ", ("section-" + _v.parent.context.$implicit.sectionId), ""); var currVal_2 = _ck(_v, 2, 0, _co.sectionState[_v.parent.context.$implicit.sectionId].isActive, _co.sectionState[_v.parent.context.$implicit.sectionId].isComplete); _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = (_v.parent.context.$implicit.sectionId !== "review"); _ck(_v, 4, 0, currVal_3); var currVal_4 = (_v.parent.context.$implicit.sectionId === "review"); _ck(_v, 6, 0, currVal_4); var currVal_5 = _co.sectionState[_v.parent.context.$implicit.sectionId].isComplete; _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.sectionState[_v.parent.context.$implicit.sectionId].isActive ? "step" : ""); _ck(_v, 0, 0, currVal_0); }); }
function View_ApplicationNavComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationNavComponent_9)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.sectionState && _co.sectionState[_v.context.$implicit.sectionId]); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ApplicationNavComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["aria-describedby", "step-info"], ["automationid", "Section_ApplicationNavBar"], ["class", "application-nav"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationNavComponent_8)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ApplicationNavComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationNavComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationNavComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationNavComponent_7)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "sr-only"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵted(8, null, ["Currently on step ", " of ", " steps"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.showProgress && !!_v.context.ngIf); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.isProd; _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.showList && !!_v.context.ngIf); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵinlineInterpolate(1, "step-info-", _co.stepCurrent, ""); _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.stepCurrent; var currVal_5 = _v.context.ngIf.length; _ck(_v, 8, 0, currVal_4, currVal_5); }); }
export function View_ApplicationNavComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i4.TextCasePipe, []), i1.ɵqud(671088640, 1, { canvasRef: 0 }), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ApplicationNavComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.sections$)); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ApplicationNavComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-application-nav", [], null, null, null, View_ApplicationNavComponent_0, RenderType_ApplicationNavComponent)), i1.ɵdid(1, 245760, null, 0, i5.ApplicationNavComponent, [i6.AppSettings, i7.ApplicationNavigationService, i8.ApiService, i9.UIStoreService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ApplicationNavComponentNgFactory = i1.ɵccf("app-application-nav", i5.ApplicationNavComponent, View_ApplicationNavComponent_Host_0, { showExpandArrow: "showExpandArrow", showList: "showList", showProgress: "showProgress", progressRadius: "progressRadius", canAccessAll: "canAccessAll", isSectionNavOpen: "isSectionNavOpen" }, {}, []);
export { ApplicationNavComponentNgFactory as ApplicationNavComponentNgFactory };
