var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { local } from './environment.local';
// Common env settings that don't change between dev and prod
export var common = __assign({}, local, { properties: {
        /** Name of application */
        appName: 'Clover',
    }, settings: __assign({ 
        /** Google Maps API Key */
        googleMapsKey: 'AIzaSyDwOKPEw8427RRLT8OpBaTKLkTdazi87v8', 
        /** Is an authentication endpoint available? If so make sure to update the endpoints in this file */
        enableAuth: true, 
        /** Enable service worker functionality */
        enableServiceWorker: false, 
        /** Is this app going to communicate with other domains or instances of itself for multiscreen usage?
         * If so, whitelist domains in the domains.listenTo property */
        enableAppComms: true, 
        /** Should lazy loaded routes be preloaded on app instantiation? If false will be loaded on demand */
        preloadRoutes: false, 
        /** Should data that is written to localstorage (such as app settings and store state) be obfuscated? */
        obfuscate: true }, local.settings), domains: {
        /** If App Comms is enabled, whitelist domains to accept messages from here */
        listenTo: null,
    }, endpoints: __assign({ 
        /** Location to get environment and config settings */
        envConfig: 'env.json', 
        /** Fetch current client ud */
        client: '/api/UserService/GetLeadSourceBasicInfoByUrl', 
        /** This endpoint supplies client specific configuration such as css styling, 1003 configuration, etc */
        config: '/api/Configuration/ConsumerSiteConfiguration', 
        /** Location of API if not getting that from envConfig */
        apiUrl: 'https://dev20loancenter.cloudvirga.com', 
        /** Login endpoint */
        authLogin: '/SecurityService/GrantOauthTokenForConsumer', 
        /** Login MFA endpoint */
        authLoginMfa: '/SecurityService/GrantOauthTokenForConsumerMfa', 
        /** Request new MFA endpoint */
        authRequestNewMfa: '/SecurityService/RequestConsumerMfaToken', 
        /** Refresh token endpoint */
        authTokenRefresh: '/authentication/token', 
        /** Log all the things */
        logging: '/api/log/bulk' }, local.endpoints), state: {
        /** Which UI store properties to not write to localstorage. IE do not persist confidential/personal information */
        uiStoreBlacklist: [],
    }, licenses: {} });
