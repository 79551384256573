import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
/**
 * Manages navigating around the 1003 / application route
 */
var ApplicationNavigationService = /** @class */ (function () {
    function ApplicationNavigationService() {
        this.sections$ = new BehaviorSubject(null);
        this.appRouting$ = new BehaviorSubject(null);
        this.subject$ = new BehaviorSubject(null);
        this.canNavigateTo$ = this.subject$.asObservable();
        this.routeParms = null;
        this.navigationModel = null;
    }
    /**
     * Update the sections Subject
     * @param sections Updated section object
     */
    ApplicationNavigationService.prototype.setSections = function (sections) {
        this.sections$.next(sections);
    };
    /**
     * Change a section
     * @param sectionId
     */
    ApplicationNavigationService.prototype.setCurrentSection = function (path) {
        this.appRouting$.next(path);
    };
    ApplicationNavigationService.prototype.setCanNavigateTo = function (value) {
        if (value === void 0) { value = true; }
        this.subject$.next(value);
    };
    ApplicationNavigationService.ngInjectableDef = i0.defineInjectable({ factory: function ApplicationNavigationService_Factory() { return new ApplicationNavigationService(); }, token: ApplicationNavigationService, providedIn: "root" });
    return ApplicationNavigationService;
}());
export { ApplicationNavigationService };
