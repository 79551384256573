import { ButtonActions, RuleExprOp } from 'src/app/features/cvFormBuilder/shared/cvFormBuilder.enums';
import { AssetTypeEnum, GiftandgrantsrelationshipEnum, CPOSPageValidatorsEnum } from 'src/app/shared/models';

export const assetsPages: CvFormBuilder.Pages = {
  guidPageA: {
    title: 'Assets Intro Section',
    titleShow: false,
    fullscreen: true,
    pageId: 'start',
    showButtonNext: false,
    showButtonBack: false,
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'start',
        data: {
          sectionLabel: 'Assets',
          sectionTitle: 'Next, details about your <strong>financial assets</strong>.',
          sectionImage: `/assets/img/application/assets.png`,
        },
      },
    ],
  }, // end page

  guidPageB: {
    title: 'Please select any <strong>assets</strong> you would like to add',
    pageId: 'asset-type',
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `Why do you need to know about my assets?`,
          body: `
            <p>To check your qualification for loan products, we need to know that you have sufficient assets for the down payment (home purchase) and closing costs.</p>
          `,
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.assetType',
        placeholder: null,
        class: 'icon-group-4 assets-assetType',
        formFieldType: 'iconGroup',
        formFieldData: [
          {
            label: 'Bank &amp;<br/> Financial Assets',
            value: 0,
          },
          {
            label: 'Gifts or Grants',
            value: 1,
          },
          {
            label: 'Other Assets',
            value: 2,
          },
          {
            label: `I don't have any assets`,
            value: 3,
          },
        ],
        visible: {
          rules: [
            {
              field: 'config.config.area.otherassets.enabled',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.assetType',
        placeholder: null,
        class: 'icon-group-4 assets-assetType',
        formFieldType: 'iconGroup',
        formFieldData: [
          {
            label: 'Bank &amp;<br/> Financial Assets',
            value: 0,
          },
          {
            label: 'Gifts or Grants',
            value: 1,
          },
          {
            label: `I don't have any assets`,
            value: 3,
          },
        ],
        visible: {
          rules: [
            {
              field: 'config.config.area.otherassets.enabled',
              operator: RuleExprOp.NE,
              value: true,
            },
          ],
        },
        validators: {
          required: true,
        },
      },
    ],
  },

  guidPageC: {
    title: 'Please enter information about your <strong>financial assets</strong>',
    pageId: 'financial-assets',
    validatorId: CPOSPageValidatorsEnum.assets,
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'asset-autocomplete',
        data: {
          field: 'loan.$$custom.loan.assets[].institiutionContactInfo.companyName',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.assets[].institiutionContactInfo.companyName',
            validators: {
              required: true,
            },
          },
        ],
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.assets[].ownerId',
        placeholder: 'Account Belongs to:',
        formFieldType: 'select',
        dataField: 'borrowers',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
    ],
  }, // end page

  guidPageC2: {
    title:
      'Please choose to <strong>automatically</strong> link your account or <strong>manually</strong> input your account information',
    pageId: 'financial-assets-details',
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `How does secure linking work?`,
          body: `
            <h3>What is Secure Bank Linking?</h3>
            <p>Secure Bank Linking is powered by AccountChek, a third-party data provider that allows us to retrieve digital financial account statements. The service allows you to securely sign-in to over 5000 banking and financial institutions. The connection uses industry standard encryption to access your account statements. Your login and password will NOT be stored or shared with us, but we will be provided with of up to 12 months of electronic financial statements on your behalf.</p>
            <h3>Why does your lender need to view your financial account statements?</h3>
            <p>To check your qualification for loan products, we need to know that you have sufficient assets for the down payment and closing costs.</p>
            <h3>Faster way to verify your assets</h3>
            <p>Secure Bank Linking quickly, automatically and accurately analyzes your account data and securely transmits the results in a digital report to us. This lets us make better and faster decisions, which can speed up the loan process, helping you to quickly get the mortgage loan you want.</p>
            <p><a href="https://verifier.accountchek.net/tos" target="_blank">Learn more about AccountChek</a></p>
          `,
        },
      }, // end
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.isEVerifyAsset',
        formFieldType: 'iconGroup',
        size: 'large',
        formFieldData: [
          {
            label: 'Verify Assets Securely',
            description: 'Quickly link your account(s) to speed up your loan process',
            value: true,
          },
          {
            label: 'Manually Enter Your Asset',
            description: 'This method will require you to upload your statements',
            value: false,
          },
        ],
        validators: {
          required: true,
        },
      },
    ],
  }, // end page

  guidPageC3: {
    title: 'Assets eVerification',
    titleShow: false,
    pageId: 'financial-assets-evoa',
    isLastPage: false,
    showButtonNext: false,
    showButtonBack: false,
    content: [
      <CvFormBuilder.Html>{
        type: 'html',
        html: `<p>This iframe contains content that is provided by a third party that may not be ADA compliant. 
        If you need assistance, please email itsupport@cloudvirga.com. </p>`,
      },
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'evoa',
      },
    ],
  }, // end page

  guidPageC4: {
    title: 'Please enter information about your <strong>financial assets</strong>',
    pageId: 'financial-assets-manual',
    validatorId: CPOSPageValidatorsEnum.assets,
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `What are bank and financial assets?`,
          body: `
            <h3>Bonds</h3>
            <p>A bond is a fixed-income instrument generally provided by corporations and government.</p>
            <h3>Bridge Loan Proceeds</h3>
            <p>A short term loan to finance the purchase of a new property.</p>
            <h3>Certificate of Deposit</h3>
            <p>Also known as a CD, it is a product offered by banks and credit unions that offers a higher interest rate compared to saving accounts, where the deposited amount remains untouched for a specified period of time.</p>
            <h3>Checking</h3>
            <p>This is a deposit account that allows for frequent withdrawals and deposits. Checking accounts can be personal or joint accounts.</p>
            <h3>Individual Development Account</h3>
            <p>This is an asset building tool to enables families to save towards a target amount aimed at obtaining home ownership.</p>
            <h3>Life Insurance Cash Value</h3>
            <p>Generally, these are cash value accounts that earn a modest rate of interest from either Whole Life, Variable Life, or Universal Life insurance accounts. Term Life Insurance accounts do not have cash value you can access.</p>
            <h3>Money Market</h3>
            <p>This is an interest-bearing savings account offered by banks and credit unions. These accounts usually earn higher interest than regular savings accounts, but have higher balance requirements.</p>
            <h3>Mutual Fund</h3>
            <p>These are professionally managed investments that pool your money together with other investors to purchase shares of a collection of stocks, bonds, or other securities, referred to as a portfolio.</p>
            <h3>Retirement (e.g. 401K, IRA)</h3>
            <p>A 401(k) is a tax-deferred retirement savings account offered by employers that may be a SEP (Simplified Employee Pension) or SIMPLE (Savings Incentive Match Plan for Employees) type of IRA. Individual Retirement Account (IRA) is a tax-deferred retirement held by banks or brokerages.</p>
            <h3>Savings</h3>
            <p>A savings account is an interest-bearing deposit account held at a bank or other financial institution.</p>
            <h3>Stock Options</h3>
            <p>This is a contract that gives you the right to buy or sell a company provided stock at an agreed price and date.</p>
            <h3>Stock</h3>
            <p>Shares, often called stocks or shares of stock, represent the equity ownership of a corporation divided up into units, so that multiple people can own a percentage of a business.</p>
            <h3>Trust Account</h3>
            <p>An account with funds and assets held by a trustee for the benefit of the beneficiary. Trust funds can hold a variety of assets such as money, property, stock, a business, or a combination.</p>
          `,
        },
      }, // end
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'asset-autocomplete',
        data: {
          field: 'loan.$$custom.loan.assets[].institiutionContactInfo.companyName',
        },
        fields: [
          {
            field: 'loan.$$custom.loan.assets[].institiutionContactInfo.companyName',
            validators: {
              required: true,
            },
          },
        ],
      },
      /**
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.assets[].institiutionContactInfo.companyName',
        placeholder: 'Name of Financial Institution',
        formFieldType: 'text', // TODO: Convert to custom control
        // dataField: 'df-institutions',
        validators: {
          required: true,
        },
      },
       */
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.assets[].assetType',
        placeholder: 'Account Type',
        formFieldType: 'select',
        formFieldData: [
          {
            value: AssetTypeEnum.Bonds,
            label: 'Bonds',
          },
          {
            value: AssetTypeEnum.BridgeLoan,
            label: 'Bridge Loan Proceeds',
          },
          {
            value: AssetTypeEnum.CD,
            label: 'Certificate of Deposit',
          },
          {
            value: AssetTypeEnum.Checking,
            label: 'Checking',
          },
          {
            value: AssetTypeEnum.IndividualDevelopmentAccount,
            label: 'Individual Development Account',
          },
          {
            value: AssetTypeEnum.LifeInsuranceCashValue,
            label: 'Life Insurance Cash Value',
          },
          {
            value: AssetTypeEnum.MoneyMarket,
            label: 'Money Market',
          },
          {
            value: AssetTypeEnum.MutualFunds,
            label: 'Mutual Fund',
          },
          {
            value: AssetTypeEnum.RetirementFund,
            label: 'Retirement Fund (e.g., 401k, IRA)',
          },
          {
            value: AssetTypeEnum.Savings,
            label: 'Savings',
          },
          {
            value: AssetTypeEnum.StockOptions,
            label: 'Stock Options',
          },
          {
            value: AssetTypeEnum.Stocks,
            label: 'Stocks',
          },
          {
            value: AssetTypeEnum.TrustFunds,
            label: 'Trust Account',
          },
        ],
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.assets[].accountNumber',
        placeholder: 'Account Number',
        formFieldType: 'text',
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.assets[].monthlyAmount',
        prefix: '<i class="fa fa-dollar fa-lg" aria-hidden="true"></i>',
        placeholder: 'Amount',
        formFieldType: 'currency',
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.assets[].ownerId',
        placeholder: 'Account Belongs to:',
        formFieldType: 'select',
        dataField: 'borrowers',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },

      <CvFormBuilder.Buttons>{
        type: 'button',
        buttons: [
          {
            label: 'Delete this asset',
            class: 'mat-danger delete-btn',
            actions: [
              {
                type: ButtonActions.modalDelete,
                params: {
                  type: 'asset',
                  field: 'loan.$$custom.loan.assets[]',
                  featureId: 'asset-delete',
                  routeGoTo: 'guidRouteZ',
                },
              },
            ],
          },
        ],
        visible: {
          rules: [
            {
              field: 'loan.$$custom.isEditing',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
    ],
  }, // end page

  guidPageD: {
    title: 'Please provide information for your <strong>gifts or grants</strong>',
    pageId: 'gifts-grants',
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `What are gifts and grants?`,
          body: `
            <h3>Cash Gift</h3>
            <p>Non-borrowed gift money which is given to you from a donor (relative, benefactor, etc). If you have a cash gift and intend to use it as a down-payment (for a purchase) and your closing costs, we will need to know if it has already been deposited into your banking/financial account.</p>
            <h3>Gift of Equity</h3>
            <p>A gift of equity generally involves the sale of a property to a family member or someone with whom the seller has a close relationship, at a price below the current market value.</p>
            <h3>Grant</h3>
            <p>A financial award that can be granted to you by a company, foundation, government agency, nonprofit organization, or a relative. If you intend to use your grant money to pay for your down-payment (for a purchase) and closing costs, we will need to know if it has already been deposited into your banking/financial account.</p>
          `,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.assets[].assetType',
        placeholder: 'Gift Funds & Grant Type',
        formFieldType: 'select',
        formFieldData: [
          {
            label: 'Cash Gift',
            value: AssetTypeEnum.GiftFunds,
          },
          {
            label: 'Gift of Equity',
            value: AssetTypeEnum.GiftOfEquity,
          },
          {
            label: 'Grant',
            value: AssetTypeEnum.Grant,
          },
        ],
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.assets[].relationshipType',
        placeholder: 'Gift Source',
        formFieldType: 'select',
        formFieldData: [
          {
            label: 'Community Nonprofit',
            value: GiftandgrantsrelationshipEnum.CommunityNonprofit,
          },
          {
            label: 'Employer',
            value: GiftandgrantsrelationshipEnum.Employer,
          },
          {
            label: 'Federal Agency',
            value: GiftandgrantsrelationshipEnum.FederalAgency,
          },
          {
            label: 'Lender',
            value: GiftandgrantsrelationshipEnum.Lender,
          },
          {
            label: 'Local Agency',
            value: GiftandgrantsrelationshipEnum.LocalAgency,
          },
          {
            label: 'Relative',
            value: GiftandgrantsrelationshipEnum.Relative,
          },
          {
            label: 'Religious Nonprofit',
            value: GiftandgrantsrelationshipEnum.ReligiousNonprofit,
          },
          {
            label: 'State Agency',
            value: GiftandgrantsrelationshipEnum.StateAgency,
          },
          {
            label: 'Unmarried Partner',
            value: GiftandgrantsrelationshipEnum.UnmarriedPartner,
          },
          {
            label: 'Other',
            value: GiftandgrantsrelationshipEnum.Other,
          },
        ],
        validators: {
          //required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.assets[].description',
        placeholder: 'Description for Other',
        hint: 'Please describe',
        formFieldType: 'text',
        validators: {
          //required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.assets[].relationshipType',
              operator: RuleExprOp.EQ,
              value: GiftandgrantsrelationshipEnum.Other,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.assets[].monthlyAmount',
        prefix: '$',
        placeholder: 'Amount',
        formFieldType: 'currency',
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.assets[].giftOrGrantDeposited',
        placeholder: 'The amount has been deposited to a financial account',
        formFieldType: 'checkbox',
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.assets[].assetType',
              operator: RuleExprOp.NE,
              value: AssetTypeEnum.GiftOfEquity,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.assets[].ownerId',
        placeholder: 'Gift or Granted to:',
        formFieldType: 'select',
        dataField: 'borrowers',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Buttons>{
        type: 'button',
        buttons: [
          {
            label: 'Delete this asset',
            class: 'mat-danger delete-btn',
            actions: [
              {
                type: ButtonActions.modalDelete,
                params: {
                  type: 'asset',
                  field: 'loan.$$custom.loan.assets[]',
                  featureId: 'asset-delete',
                  routeGoTo: 'guidRouteZ',
                },
              },
            ],
          },
        ],
        visible: {
          rules: [
            {
              field: 'loan.$$custom.isEditing',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
    ],
  },

  guidPageE: {
    title: 'Please provide information for your <strong>additional assets</strong>',
    pageId: 'other-assets',
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `What are other assets?`,
          body: `
            <h3>Earnest Money</h3>
            <p>In a home purchase, earnest money is a deposit made to a seller that represents a buyer's good faith to buy a home.</p>
            <h3>Employer Assistance</h3>
            <p>Employer assistance can also be used for financial reserves for all types of assistance with the exception of unsecured loans (which may only be used for the down payment and closing costs). It can be a grant, a direct and fully repayable second mortgage, or unsecured loan.</p>
            <h3>Lot Equity</h3>
            <p>The difference betweeen the appraised land value and the loan to be paid off on the land.</p>
            <h3>Other Liquid Asset</h3>
            <p>Any asset that can readily be converted to cash while retaining its market value. This can be stocks, investments, and bonds, or access to your checking or savings account.</p>
            <h3>Other Non-Liquid Asset</h3>
            <p>Assets such as land and real estate investments that may take months to convert to cash.</p>
            <h3>Proceeds from a Property Sale</h3>
            <p>Money or other property received as the result of a sale that will be used to finance your new loan.</p>
            <h3>Proceeds from Sale of Non-Real Estate Asset</h3>
            <p>The sale of assets such as stocks or mutual funds that will be used to finance your new loan.</p>
            <h3>Relocation Funds</h3>
            <p>These are funds generally provided from a employer for relocating to a new city.</p>
            <h3>Rent Credit</h3>
            <p>Credit for the down payment is determined by calculating the difference between the market rent and the actual rent paid for the last 12 months. The market rent is determined by the appraiser in the appraisal for the subject property.</p>
            <h3>Secured Borrower Funds</h3>
            <p>Secured debt is money borrowed that is guaranteed (or secured) by the borrower's funds or assets and held by the lender in an interest-bearing account. These are an acceptable source of funds for the down payment, closing costs, and reserves. Assets that may be used to secure funds include automobiles, artwork, collectibles, real estate, or financial assets.</p>
            <h3>Sweat Equity</h3>
            <p>A non-monetary investment that you have contributed to a business venture in return for company stock options, restricted stock units and performance shares. However, sweat equity can only be used for specific transactions.</p>
            <h3>Trade Equity</h3>
            <p>The equity contribution is determined by subtracting the outstanding mortgage balance of the property being traded, plus any transfer costs, from the lesser of either the property's appraised value or the trade-in value agreed to by both parties.</p>
            <h3>Unsecured Borrower Funds</h3>
            <p>Funds that lack collateral, such as signature loans, lines of credit on credit cards, and overdraft protection on checking accounts.</p>
          `,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.assets[].assetType',
        placeholder: 'Other Asset Types',
        formFieldType: 'select',
        formFieldData: [
          {
            label: 'Earnest Money',
            value: AssetTypeEnum.EscrowDeposit,
          },
          {
            label: 'Employer Assistance',
            value: AssetTypeEnum.EmployerAssistance,
          },
          {
            label: 'Lot Equity',
            value: AssetTypeEnum.LotEquity,
          },
          {
            label: 'Other Liquid Assets',
            value: AssetTypeEnum.OtherLiquidAsset,
          },
          {
            label: 'Other Non-Liquid Assets',
            value: AssetTypeEnum.OtherNonLiquidAsset,
          },
          {
            label: 'Proceeds from a Property Sale',
            value: AssetTypeEnum.ProceedsfromaPropertySale,
          },
          {
            label: 'Proceeds from Sale of Non-Real Estate Asset',
            value: AssetTypeEnum.ProceedsFromSaleOfNonRealEstateAsset,
          },
          {
            label: 'Relocation Funds',
            value: AssetTypeEnum.RelocationFunds,
          },
          {
            label: 'Rent Credit',
            value: AssetTypeEnum.RentCredit,
          },
          {
            label: 'Secured Borrowed Funds',
            value: AssetTypeEnum.SecuredBorrowerFundsNotDeposited,
          },
          {
            label: 'Sweat Equity',
            value: AssetTypeEnum.SweatEquity,
          },
          {
            label: 'Trade Equity',
            value: AssetTypeEnum.TradeEquity,
          },
          {
            label: 'Unsecured Borrowed Funds',
            value: AssetTypeEnum.UnsecuredBorrowedFunds,
          },
        ],
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.assets[].description',
        placeholder: 'Description for Other',
        formFieldType: 'text',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.assets[].assetType',
              operator: RuleExprOp.EQ,
              value: AssetTypeEnum.OtherNonLiquidAsset,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.assets[].description',
        placeholder: 'Description for Other',
        formFieldType: 'text',
        validators: {
          required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.loan.assets[].assetType',
              operator: RuleExprOp.EQ,
              value: AssetTypeEnum.OtherLiquidAsset,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.assets[].monthlyAmount',
        prefix: '$',
        placeholder: 'Amount',
        formFieldType: 'currency',
        validators: {
          required: true,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.$$custom.loan.assets[].ownerId',
        placeholder: 'Account Belongs to:',
        formFieldType: 'select',
        dataField: 'borrowers',
        validators: {},
        visible: {
          rules: [
            {
              field: 'loan.transactionInfo.loanApplications[0].isSpouseOnTheLoan',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.Buttons>{
        type: 'button',
        buttons: [
          {
            label: 'Delete this asset',
            class: 'mat-danger delete-btn',
            actions: [
              {
                type: ButtonActions.modalDelete,
                params: {
                  type: 'asset',
                  field: 'loan.$$custom.loan.assets[]',
                  featureId: 'asset-delete',
                  routeGoTo: 'guidRouteZ',
                },
              },
            ],
          },
        ],
        visible: {
          rules: [
            {
              field: 'loan.$$custom.isEditing',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
    ],
  },

  guidPageZ: {
    title: 'Assets Review Section',
    titleShow: false,
    pageId: 'summary',
    isLastPage: true,
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'summary-assets',
      },
    ],
  },
};
