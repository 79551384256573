var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Subject } from 'rxjs';
import { ObjectUtils } from '$utils';
import { AppSettings } from '../app.settings';
import * as i0 from "@angular/core";
import * as i1 from "../app.settings";
var PostMessageService = /** @class */ (function () {
    function PostMessageService(settings) {
        this.settings = settings;
        /** Postmessage response */
        this.postMessage$ = new Subject();
        /** Generate a random number to identify this app. Used to drop same domain postmessages */
        this.appId = Math.floor(Math.random() * 10000000);
    }
    /**
     * Activates the post message listener
     * @param allowedDomains - Allowable domains to whitelist message responses. Based on window.location.origin
     */
    PostMessageService.prototype.listenForMessages = function (allowedDomains) {
        // Set allowed domains to receive messages from
        if (allowedDomains) {
            this.allowedDomains = allowedDomains;
        }
        if (this.settings.isBrowser) {
            // If not IE
            if (window.addEventListener) {
                this.postMessageListener = window.addEventListener('message', this.messageReceived.bind(this), false);
            }
            else {
                // If IE
                this.postMessageListener = window.attachEvent('onmessage', this.messageReceived.bind(this), false);
            }
        }
        return this.postMessage$;
    };
    /**
     * Stop listening for postmessage events
     */
    PostMessageService.prototype.stopListening = function () {
        if (this.settings.isBrowser) {
            window.removeEventListener('message', this.postMessageListener);
        }
    };
    /**
     * Post a message from an embedded iframe to its parent
     * @param message - The message payload
     * @param urlTarget - If the target url is known, only post to that domain. Otherwise its *
     */
    PostMessageService.prototype.postMessageToParent = function (message, urlTarget) {
        if (urlTarget === void 0) { urlTarget = '*'; }
        if (this.settings.isBrowser) {
            if (window.parent) {
                window.parent.postMessage(this.addMetadata(message), urlTarget);
            }
        }
    };
    /**
     * Post a message to an embedded iframe
     * @param id - a CSS ID of the iframe. IE 'messageTarget' of <iframe id="messageTarget"></iframe>
     * @param message - The message payload
     * @param urlTarget  - If the target url is known, only post to that domain. Otherwise its *
     */
    PostMessageService.prototype.postMessageToIframe = function (id, message, urlTarget) {
        if (urlTarget === void 0) { urlTarget = '*'; }
        if (this.settings.isBrowser) {
            // Make sure the element is on the DOM
            if (document.getElementById(id) && document.getElementById(id).contentWindow) {
                document.getElementById(id).contentWindow.postMessage(this.addMetadata(message), urlTarget);
            }
        }
    };
    /**
     * Post a message to window object reference
     * @param reference - A window reference either created by window.open or if existing, window.opened
     * @param message - The message payload
     * @param urlTarget  - If the target url is known, only post to that domain. Otherwise its *
     */
    PostMessageService.prototype.postMessageToWindow = function (reference, message, urlTarget) {
        if (urlTarget === void 0) { urlTarget = '*'; }
        reference.postMessage(this.addMetadata(message), urlTarget);
    };
    /**
     * When a message was received via the postMessage event listener
     * @param event - The event passed from the event listener
     */
    PostMessageService.prototype.messageReceived = function (event) {
        // Scrub webpackOk events and same appId origination
        if (event.data && event.data.type !== 'webpackOk' && event.data.appId !== this.appId) {
            // Sanitize incoming payload
            var msg = ObjectUtils.sanitize(event.data);
            // Check if allowable domains
            if ((this.allowedDomains && this.allowedDomains.indexOf(event.origin) !== -1) || !this.allowedDomains) {
                this.postMessage$.next(msg);
            }
            else {
                console.error('Message from unauthorized source');
            }
        }
    };
    /**
     * Add metadata to the postmessage payload. IE the token and appId
     * @param msg
     */
    PostMessageService.prototype.addMetadata = function (msg) {
        return __assign({}, ObjectUtils.sanitize(msg), { appId: this.appId, token: this.settings.token });
    };
    PostMessageService.ngInjectableDef = i0.defineInjectable({ factory: function PostMessageService_Factory() { return new PostMessageService(i0.inject(i1.AppSettings)); }, token: PostMessageService, providedIn: "root" });
    return PostMessageService;
}());
export { PostMessageService };
