<app-header-simple></app-header-simple>

<main class="container" *ngIf="(config$ | async) as config" automationid="Page_UserLogin">
  <div class="row">
    <div class="col col-12 col-lg-8 offset-lg-2">
      <div class="card">
        <div class="card-body py-5 px-md-5">
          <div class="row">
            <div class="col-md-6">
              <div class="h1 text-center mb-4" *ngIf="!loggedout">
                <div *ngIf="config && config['clover.account.login.page.title'];else loginTitle" [innerHtml]="config['clover.account.login.page.title'].value"></div>
                <ng-template #loginTitle>Welcome back!</ng-template>
              </div>
              <div class="image-container" *ngIf="!loggedout">
                  <img *ngIf="config && config['clover.account.login.page.image'];else loginImage" [src]="config['clover.account.login.page.image'].value" alt=""/>
                  <ng-template #loginImage><img src="/assets/img/start-home.png" alt=""/></ng-template>
              </div>
              <div class="h1 text-center mb-4" *ngIf="loggedout">
                <div *ngIf="config && config['clover.account.logout.page.title'];else logoutTitle" [innerHtml]="config['clover.account.logout.page.title'].value"></div>
                <ng-template #logoutTitle>Come back soon!</ng-template>
              </div>
              <div class="image-container" *ngIf="loggedout">
                <img *ngIf="config && config['clover.account.logout.page.image'];else logoutImage" [src]="config['clover.account.logout.page.image'].value" alt=""/>
                <ng-template #logoutImage><img src="/assets/img/sign-out.png" alt=""/></ng-template>
            </div>
            </div>
            <div class="col-md-6">
              <form
                [formGroup]="formMain"
                novalidate
                (ngSubmit)="onLogin(formMain)"
                aria-live="assertive"
                aria-atomic="true"
                automationid="Section_Login"
              >
                <div class="form-heading mb-5" *ngIf="isRegisterationAvailable()">
                  <h1>Sign-in</h1>
                  <a *ngIf="isLsidAvailable" routerLink="/register" queryParamsHandling="merge">Create an account</a>
                </div>

                <div role="alert" class="alert alert-warning icon mb-4 green" *ngIf="loggedout">
                  You have successfully logged out.
                </div>
                <div role="alert" class="alert alert-danger icon mb-4" *ngIf="errorApi">
                  {{errorApi.errorMsg}}
                </div>
                <div role="alert" class="alert alert-danger icon mb-4" *ngIf="sessionExpired">
                  Session expired, please log in again.
                </div>

                <!--<p>Please enter your username and password.</p>-->

                <mat-form-field>
                  <input
                    matInput
                    placeholder="Email"
                    type="text"
                    id="login"
                    formControlName="userName"
                    autocomplete="username"
                    required
                    automationid="Email"
                  >
                  <mat-error>Email is required</mat-error>
                </mat-form-field>
                <div class="password-container">
                  <mat-form-field>
                    <input
                      matInput
                      placeholder="Password"
                      [type]="showPassword ? 'text' : 'password'"
                      id="password"
                      formControlName="password"
                      autocomplete="current-password"
                      required
                      automationid="Password"
                    >
                    <mat-error>Valid password is required</mat-error>
                  </mat-form-field>
                  <button
                    class="toggle-show-password"
                    type="button"
                    (click)="showPassword = !showPassword"
                    [attr.aria-pressed]="!!showPassword"
                    automationid="BtnShowPassword"
                  >
                    <i
                      class="fa"
                      [class.fa-eye]="!showPassword"
                      [class.fa-eye-slash]="showPassword"
                      aria-label="View and hide password toggle button"
                    ></i>
                  </button>
                </div>

                <div class="forgot mb-4">
                  <a routerLink="/forgot-password" [queryParams]="{hideInputs: true}" queryParamsHandling="merge">Forgot my password</a>
                </div>
                <div>
                  <button
                    mat-flat-button
                    color="accent"
                    class="sign-in"
                    type="submit"
                    [disabled]="waiting"
                    [ngClass]="{ 'btn-waiting': waiting }"
                    aria-live="assertive"
                    automationid="BtnSubmit"
                  >
                    <ng-container *ngIf="!waiting; else showLoading">
                      Sign in
                    </ng-container>
                    <ng-template #showLoading>
                      <i class="fa fa-spinner fa-spin"></i>
                      Loading, please wait
                    </ng-template>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<app-footer></app-footer>
