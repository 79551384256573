var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { OnInit, OnDestroy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators, FormBuilder } from '@angular/forms';
import { AppSettings, AuthService, AnalyticsService } from '$shared';
import { ApiService } from '$api';
import { switchMap, take } from 'rxjs/operators';
import { AuthorizationFormValidators, RepeatPasswordErrorStateMatcher } from '../../shared/validators/authorization-form.validators';
import { UIStoreService } from '$ui';
import { combineLatest } from 'rxjs';
import { TermsAndPrivacyService } from '../../shared/services/terms-and-privacy.service';
import { Title } from '@angular/platform-browser';
import { AuthenticationRequestTypeEnum, AuthenticationStatusEnum } from 'src/app/shared/models';
var ActivationComponent = /** @class */ (function () {
    function ActivationComponent(route, router, fb, authService, api, ref, ui, termsAndPrivacyService, title, settings, analytics) {
        this.route = route;
        this.router = router;
        this.fb = fb;
        this.authService = authService;
        this.api = api;
        this.ref = ref;
        this.ui = ui;
        this.termsAndPrivacyService = termsAndPrivacyService;
        this.title = title;
        this.settings = settings;
        this.analytics = analytics;
        this.config$ = this.ui.select.config$;
        this.isFormContentLoading = false;
        this.showNewPassword = false;
        this.showConfirmPassword = false;
        /** Show confirm password errors */
        this.passwordErrorMatcher = new RepeatPasswordErrorStateMatcher();
        /** Toggle password requirements */
        this.showPasswordRequirements = false;
    }
    ActivationComponent.prototype.ngOnInit = function () {
        this.formMain = this.fb.group({
            userName: [null, [Validators.required, AuthorizationFormValidators.validEmail]],
            securityAnswer: [null, [Validators.required]],
            password: [null, [
                    Validators.required,
                    AuthorizationFormValidators.oneLowercase,
                    AuthorizationFormValidators.oneUppercase,
                    AuthorizationFormValidators.oneDigit,
                    AuthorizationFormValidators.specialCharacters,
                    AuthorizationFormValidators.eightCharacters,
                ]],
            passwordConfirm: [null, [Validators.required]],
            loanId: [null],
            securityQuestionId: [null],
        }, { validator: AuthorizationFormValidators.samePasswords });
        this.checkUserActivation();
        this.populateForm();
    };
    ActivationComponent.prototype.ngAfterViewInit = function () {
        // Add company name to the title of this page
        var newTitle = this.settings.clientName
            ? this.title.getTitle() + " - " + this.settings.clientName
            : this.title.getTitle();
        this.title.setTitle(newTitle);
    };
    /**
     * Handle terms of use link click
     */
    ActivationComponent.prototype.onTermsClick = function () {
        return this.termsAndPrivacyService.viewTermsOfUse();
    };
    /**
     * Handle privacy policy link click
     */
    ActivationComponent.prototype.onPrivacyClick = function () {
        return this.termsAndPrivacyService.viewPrivacyPolicy();
    };
    /**
     * Check for a token in the URL and redirect to the activation screen if the user is already activated
     */
    ActivationComponent.prototype.checkUserActivation = function () {
        var _this = this;
        var activationToken = this.route.snapshot.queryParams['token'];
        if (!activationToken)
            return;
        this.authService.checkUserActivationByToken(activationToken)
            .pipe(take(1))
            .subscribe(function (activationResponse) {
            // If account is already active
            if (activationResponse && activationResponse.isUserAccountActive === true) {
                _this.router.navigate(['/login'], { queryParamsHandling: 'merge' });
            }
        });
    };
    /**
     * Get borrower information to pre-populate the form
     */
    ActivationComponent.prototype.populateForm = function () {
        var _this = this;
        var token = this.route.snapshot.queryParams['token'];
        // Do not fill out the form if not query params were provided
        if (!token) {
            return;
        }
        this.setFormContentLoading();
        var securityQuestionFields$ = this.api.dataFields.get(211);
        var accountInfo$ = this.api.userAccount.getAccountInfo(token);
        combineLatest(securityQuestionFields$, accountInfo$).subscribe(function (_a) {
            var _b = __read(_a, 2), securityQuestions = _b[0], accountInfoApiResponse = _b[1];
            _this.setFormContentLoading(false);
            var accountInfo = accountInfoApiResponse.response;
            if (!accountInfo) {
                _this.error = "Could not find your account. Please contact your LO for assistance.";
                _this.ref.markForCheck();
                return;
            }
            _this.formMain.patchValue({
                userName: accountInfo.signinUserEmailAddress,
                loanId: accountInfo.loanId,
                securityQuestionId: accountInfo.signinSecurityQuestionId,
            });
            _this.setSecurityQuestion(securityQuestions, accountInfo.signinSecurityQuestionId);
        }, function () {
            _this.setFormContentLoading(false);
            _this.error = "A problem while loading your account information. Please try again.";
        });
    };
    /**
     * Set the security question based on the value chosen by the LO in Loan Center
     * @param securityQuestions List of possible security questions
     * @param questionId ID for desired security question
     */
    ActivationComponent.prototype.setSecurityQuestion = function (securityQuestions, questionId) {
        var filteredQuestions = securityQuestions.filter(function (question) { return question.enumValue === questionId; });
        if (filteredQuestions && filteredQuestions.length) {
            this.securityQuestion = filteredQuestions[0].text;
        }
        else {
            this.securityQuestion = 'Security question answer:';
        }
        this.ref.markForCheck();
    };
    ActivationComponent.prototype.setFormContentLoading = function (loading) {
        if (loading === void 0) { loading = true; }
        this.isFormContentLoading = loading;
        this.ref.markForCheck();
    };
    /**
     * Submit the form
     */
    ActivationComponent.prototype.onActivation = function (form) {
        var _this = this;
        // Do nothing if form is invalid
        if (form.invalid)
            return;
        this.waiting = true;
        this.error = null;
        var formData = form.value;
        var request = {
            authenticationRequestViewModel: {
                authentication: {
                    loanId: formData.loanId,
                    password: formData.password,
                    securityAnswer: formData.securityAnswer,
                    securityQuestionId: formData.securityQuestionId,
                    userAccountName: formData.userName,
                },
                authenticationRequestType: AuthenticationRequestTypeEnum.Activate
            }
        };
        this.api.userAccount.updateAccount(request).pipe(switchMap(function () {
            // Prepare data to log in
            var logInFormData = {
                userName: formData.userName,
                password: formData.password,
                loanId: formData.loanId,
            };
            // Switch to login API
            return _this.authService.logIn(logInFormData);
        }))
            .subscribe(function (bodyResponse) {
            _this.waiting = false;
            var mfaToken = bodyResponse.mfaToken;
            var mfaRequired = !!mfaToken;
            if (mfaRequired) {
                _this.router.navigate(['/mfa'], {
                    queryParams: {
                        userName: form.value.userName,
                        mfaToken: mfaToken,
                    },
                    queryParamsHandling: 'merge'
                });
            }
            else if (bodyResponse && bodyResponse.succeeded) {
                // In order to view the series of mixpanel events triggered in Dev Console.
                _this.analytics.mixpanelSetDebugTrue(true);
                // If successfully logged in
                _this.analytics.mixpanelAlias(_this.settings.userId);
                _this.analytics.trackEvent('Activate Account BC');
                _this.router.navigate(['/'], { queryParamsHandling: 'merge' });
            }
            else if (bodyResponse.authenticationStatus === AuthenticationStatusEnum.AccountLocked) {
                _this.error = "Your account has been locked as a result of too many unsuccessful login attempts. Please contact your loan officer to unlock your account.";
            }
            else {
                _this.error = "Incorrect email or security answer.";
            }
            _this.ref.markForCheck();
        }, function (error) {
            // Show error message for API errors
            _this.error = 'Error logging in.';
            if ((error.statusText === 'Unauthorized')) {
                _this.error = 'Invalid username or password, please try again.';
            }
            _this.waiting = false;
            _this.ref.markForCheck();
        });
    }; // end onActivation
    // Required for AutoUnsubscribe
    ActivationComponent.prototype.ngOnDestroy = function () { };
    return ActivationComponent;
}());
export { ActivationComponent };
