/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirmation-new-loan-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/directives/focus-contain.directive";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "@angular/material/dialog";
import * as i9 from "./confirmation-new-loan-modal.component";
import * as i10 from "../../../shared/stores/api/api.store.service";
var styles_ConfirmationNewLoanModalComponent = [i0.styles];
var RenderType_ConfirmationNewLoanModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmationNewLoanModalComponent, data: {} });
export { RenderType_ConfirmationNewLoanModalComponent as RenderType_ConfirmationNewLoanModalComponent };
export function View_ConfirmationNewLoanModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "modal"], ["role", "dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 18, "div", [["class", "modal-dialog modal-dialog-centered"], ["role", "document"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 17, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "modal-header color-primary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h1", [["class", "page-title text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" APPLY FOR NEW LOAN"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["aria-label", "close"], ["class", "close"], ["data-dismiss", "modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["aria-hidden", "false"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["automationid", "Section_ModalBody"], ["class", "modal-body"], ["focusContain", ""], ["id", "modal-body"]], null, [[null, "keydown"]], function (_v, en, $event) { var ad = true; if (("keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, null, 0, i2.FocusContainDirective, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "p", [["class", "body-content-1 "]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["It looks like we don't have a Loan Team Member for you to create a loan."])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please contact the Loan Team you're working with and ask them to send you a new applicaton invite."])), (_l()(), i1.ɵeld(15, 0, null, null, 4, "div", [["automationid", "Section_Footer"], ["class", "modal-footer justify-content-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 3, "button", [["aria-label", "Close"], ["automationid", "BtnClose"], ["class", "dismiss color-primary"], ["color", "primary-outline"], ["mat-dialog-close", ""], ["mat-flat-button", ""], ["tabindex", "1"], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 18).dialogRef.close(i1.ɵnov(_v, 18).dialogResult) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.submit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(17, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(18, 606208, null, 0, i8.MatDialogClose, [[2, i8.MatDialogRef], i1.ElementRef, i8.MatDialog], { ariaLabel: [0, "ariaLabel"], dialogResult: [1, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, [" Close "]))], function (_ck, _v) { var currVal_3 = "primary-outline"; _ck(_v, 17, 0, currVal_3); var currVal_4 = "Close"; var currVal_5 = ""; _ck(_v, 18, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 17).disabled || null); var currVal_1 = (i1.ɵnov(_v, 17)._animationMode === "NoopAnimations"); var currVal_2 = (i1.ɵnov(_v, 18).ariaLabel || null); _ck(_v, 16, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_ConfirmationNewLoanModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirmation-new-loan-modal", [], null, null, null, View_ConfirmationNewLoanModalComponent_0, RenderType_ConfirmationNewLoanModalComponent)), i1.ɵdid(1, 49152, null, 0, i9.ConfirmationNewLoanModalComponent, [i10.ApiService, i8.MatDialogRef], null, null)], null, null); }
var ConfirmationNewLoanModalComponentNgFactory = i1.ɵccf("app-confirmation-new-loan-modal", i9.ConfirmationNewLoanModalComponent, View_ConfirmationNewLoanModalComponent_Host_0, {}, {}, []);
export { ConfirmationNewLoanModalComponentNgFactory as ConfirmationNewLoanModalComponentNgFactory };
