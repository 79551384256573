<div id="modal" focusContain automationid="Page_ConsentModal"> 

  <ng-container *ngIf="step === 1">
    <h1 class="color-static border-color-static page-title m-5">E-Consent Disclosure</h1>

    <div class="modal-body">
      <app-econsent-form
        [borrower]="borrower"
        [isForcedSingleBorrowerMode]="isForcedSingleBorrowerMode"
        (eConsentResult)="handleEconsentResult($event)"
      ></app-econsent-form>
    </div>
  </ng-container>

  <ng-container *ngIf="step === 2">
    <h1 class="color-static border-color-static page-title mt-5 mx-5">Declined E-Consent Disclosure</h1>

    <div class="modal-body px-5">
      <h2 class="subtitle text-center mb-4">Are you sure you want to <strong>decline</strong>?</h2>
      <div class="d-md-flex images mb-4">
        <div class="flex-grow-1 text-center">
          <img class="mw-100 mb-3" src="/assets/img/econsent/secure-documents.png" alt="Secure documents online">
          <ul class="text-left">
            <li>Faster loan process</li>
            <li>Docs securely stored</li>
            <li>Easily access to loan status and info</li>
          </ul>
        </div>
        <div class="vs d-flex align-items-center justify-content-center color-primary">vs</div>
        <div class="flex-grow-1 text-center">
          <img class="mw-100 mb-3" src="/assets/img/econsent/mailbox.png" alt="Documents in mailbox">
          <ul class="text-left">
            <li>Longer loan process</li>
            <li>Several documents will be mailed</li>
            <li>Need to keep and store your docs</li>
          </ul>
        </div>
      </div>

      <p>By <strong>declining</strong> you will be filing your loan application with paper documents which will be a longer process than electronic communication. Clicking "Yes, I Decline" will sign you out of the system.</p>

    </div>

    <div role="alert" class="alert alert-danger" *ngIf="error">{{error}}</div>

    <div class="modal-footer text-center" style="justify-content: center;" automationid="Section_Footer">
      <button
        type="button"
        mat-flat-button
        color="accent"
        (click)="onCancel()"
        automationid="BtnCancel"
      >
        Cancel
      </button>
      <button
        type="button"
        mat-flat-button
        color="primary-outline"
        (click)="confirmDecline()"
        automationid="Confirm"
      >
        Yes, I Decline
      </button>
    </div>
  </ng-container>

</div>
