import { OnInit, ElementRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { objectTraverser } from 'src/app/features/cvFormBuilder/shared/utils/objectTraverser.utils';
import { tap } from 'rxjs/operators';
import { CPOSdateTypeEnum } from 'src/app/shared/models';
import { LoanModelService } from 'src/app/routes/application/shared/services/loan-model.service';
import { isLeapYear, isStartDateValid, isEndDateValid, isValidDobPurchaseDate, isProjectedEndOfServiceValid, isAgeLessThan18, isPreferredDateValid } from 'src/app/routes/application/shared/utils/dates.util';
var DateControlComponent = /** @class */ (function () {
    function DateControlComponent(ref, loanModelService) {
        this.ref = ref;
        this.loanModelService = loanModelService;
    }
    DateControlComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.element.data.dateType === CPOSdateTypeEnum.appraisal) {
            this.dateFormControl = this.formControlRef;
        }
        else {
            this.dateFormControl = objectTraverser(this.element.fields[0].field, this.form, this.indexes.value, 'controls');
            if (this.element && this.element.data) {
                var firstName = this.element.data.firstName ? objectTraverser(this.element.data.firstName, this.form, this.indexes.value, 'controls').value : null;
                var lastName = this.element.data.lastName ? objectTraverser(this.element.data.lastName, this.form, this.indexes.value, 'controls').value : null;
                this.fullName = "" + (firstName ? firstName + ' ' : '') + (lastName ? lastName : '');
            }
        }
        var date = this.dateFormControl.value ? this.getDateObj(this.dateFormControl.value) : null;
        // Create the Form Group
        this.dateFormGroup = new FormGroup({
            month: new FormControl((date && !isNaN(date.month)) ? "" + ('0' + date.month).slice(-2) : null, this.monthValidation.bind(this)),
            day: new FormControl((date && !isNaN(date.day)) ? "" + ('0' + date.day).slice(-2) : null, this.dayValidation.bind(this)),
            year: new FormControl((date && !isNaN(date.year)) ? date.year : null, this.yearValidation.bind(this))
        }, this.dateValidator.bind(this));
        // Check if the date FormControl is Valid or not and update the date FormGroup status
        if (this.isRequired && (!this.dateFormControl.value || this.dateFormControl.value === '')) {
            this.dateFormControl.setErrors({ 'invalid': true });
            this.dateFormGroup.setErrors({ 'invalid': true });
        }
        else {
            this.dateFormControl.setErrors(null);
            this.dateFormGroup.setErrors(null);
        }
        // Check the value changes in Form Group and update the date FormControl status
        this.dateFormGroup.valueChanges.pipe(tap(function (dateObj) {
            for (var key in dateObj) {
                if (!dateObj[key] || dateObj[key] === 0) {
                    dateObj[key] = null;
                }
            }
            // Check if date is not required but only some fields are provided not all of them
            if (!_this.isRequired && !_this.allFieldsAreProvided(dateObj)) {
                _this.dateFormGroup.setErrors({ 'invalid': true });
            }
            // If date FormGroup is valid patch the value
            _this.dateFormControl.patchValue(_this.getDateStringFormat(dateObj.month, dateObj.day, dateObj.year));
            if (!_this.dateFormGroup.valid) {
                _this.dateFormControl.setErrors({ 'invalid': true });
            }
            else {
                _this.dateFormControl.setErrors(null);
            }
        })).subscribe();
        // When the Next button is clicked we should check if there's any field which is required and not provided
        this.dateFormControl.statusChanges.pipe(tap(function () {
            if (!_this.dateFormGroup.valid &&
                _this.dateFormControl.touched &&
                _this.isRequired &&
                !_this.dateFormGroup.get('month').value &&
                !_this.dateFormGroup.get('day').value &&
                !_this.dateFormGroup.get('year').value) {
                _this.monthFormControl.markAsTouched();
                _this.dayFormControl.markAsTouched();
                _this.yearFormControl.markAsTouched();
                _this.dateFormGroup.setErrors({ 'showDateIsRequired': true });
                _this.ref.markForCheck();
            }
            else if (_this.dateFormControl.touched && !_this.isRequired && !_this.isOptionalDateValid) {
                _this.dateFormGroup.setErrors({ 'invalidOptionalDate': true });
                _this.ref.markForCheck();
            }
        })).subscribe();
        // Check the value changes in month Form Control
        this.dateFormGroup.get('month').valueChanges.pipe(tap(function (value) {
            if (value && !isNaN(value) && value !== '') {
                var monthValue = value.trim().replace(/\D/g, '');
                if (monthValue.length > 2) {
                    monthValue = monthValue.replace(monthValue.substring(2), '');
                }
                _this.dateFormGroup.get('month').patchValue(monthValue, { emitEvent: false });
                _this.monthFn(monthValue);
            }
            else {
                _this.dateFormGroup.get('month').patchValue(null, { emitEvent: false });
            }
        })).subscribe();
        // Check the value changes in day Form Control
        this.dateFormGroup.get('day').valueChanges.pipe(tap(function (value) {
            if (value && !isNaN(value) && value !== '') {
                var dayValue = value.trim().replace(/\D/g, '');
                if (dayValue.length > 2) {
                    dayValue = dayValue.replace(dayValue.substring(2), '');
                }
                _this.dateFormGroup.get('day').patchValue(dayValue, { emitEvent: false });
                _this.dayFn(dayValue);
            }
            else {
                _this.dateFormGroup.get('day').patchValue(null, { emitEvent: false });
            }
        })).subscribe();
        // Check the value changes in year Form Control
        this.dateFormGroup.get('year').valueChanges.pipe(tap(function (value) {
            if (value && !isNaN(value) && value !== '') {
                var yearValue = value.trim().replace(/\D/g, '');
                if (yearValue.length > 4) {
                    yearValue = yearValue.replace(yearValue.substring(4), '');
                }
                _this.dateFormGroup.get('year').patchValue(yearValue, { emitEvent: false });
                _this.yearFn(yearValue);
            }
            else {
                _this.dateFormGroup.get('year').patchValue(null, { emitEvent: false });
            }
            _this.dateFormGroup.get('day').updateValueAndValidity();
        })).subscribe();
        if (this.element.data.dateType === CPOSdateTypeEnum.end) {
            var startDateFormControl = objectTraverser(this.element.data.startDate, this.form, this.indexes.value, 'controls');
            startDateFormControl.valueChanges.pipe(tap(function () {
                var endDateValid = _this.dateFormGroup.validator(_this.dateFormGroup);
                _this.dateFormGroup.setErrors(endDateValid ? { 'invalidEndDate': true } : null);
                _this.ref.markForCheck();
            })).subscribe();
        }
    };
    Object.defineProperty(DateControlComponent.prototype, "requiredErrorPosition", {
        get: function () {
            if (this.element && this.element.data) {
                if (this.element.data.dateType === CPOSdateTypeEnum.appraisal) {
                    return 'col text-left';
                }
                else {
                    return 'col text-center';
                }
            }
            else {
                return 'col text-left';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateControlComponent.prototype, "lessThan18ErrorMsg", {
        get: function () {
            return (this.fullName && this.fullName !== '') ?
                this.fullName + ", to qualify for a loan, you must be 18 years or older." :
                'To qualify for a loan, you must be 18 years or older.';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateControlComponent.prototype, "dateType", {
        get: function () {
            switch (this.element.data.dateType) {
                case CPOSdateTypeEnum.start:
                    return 'Start Date';
                case CPOSdateTypeEnum.end:
                    return 'End Date';
                case CPOSdateTypeEnum.dob:
                    return 'Date of Birth';
                case CPOSdateTypeEnum.originalPurchaseDate:
                    return 'Purchase Date';
                case CPOSdateTypeEnum.appraisal:
                    return 'Preferred Date';
                default:
                    return '';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateControlComponent.prototype, "isRequired", {
        get: function () {
            switch (this.element.data.dateType) {
                case CPOSdateTypeEnum.dob:
                    return this.loanModelService.isDobRequired();
                case CPOSdateTypeEnum.appraisal:
                    return this.element.data.required;
                default:
                    return this.element.fields[0].validators.required;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateControlComponent.prototype, "dayFormControl", {
        get: function () {
            return this.dateFormGroup.get('day');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateControlComponent.prototype, "monthFormControl", {
        get: function () {
            return this.dateFormGroup.controls.month;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateControlComponent.prototype, "yearFormControl", {
        get: function () {
            return this.dateFormGroup.controls.year;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateControlComponent.prototype, "isYearLessThan1900", {
        get: function () {
            return this.dateFormGroup.get('year').value &&
                parseInt(this.dateFormGroup.get('year').value.length) === 4 &&
                parseInt(this.dateFormGroup.get('year').value) < 1900;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateControlComponent.prototype, "isYearGreaterThan2100", {
        get: function () {
            return this.dateFormGroup.get('year').value &&
                parseInt(this.dateFormGroup.get('year').value.length) === 4 &&
                parseInt(this.dateFormGroup.get('year').value) > 2099;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateControlComponent.prototype, "yearHas4Digits", {
        get: function () {
            return parseInt(this.dateFormGroup.get('year').value.length) === 4;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateControlComponent.prototype, "allFieldsValid", {
        get: function () {
            return !this.dateFormGroup.get('month').errors &&
                !this.dateFormGroup.get('day').errors &&
                !this.dateFormGroup.get('year').errors;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateControlComponent.prototype, "daysNum", {
        get: function () {
            return parseInt(this.dateFormGroup.get('day').value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateControlComponent.prototype, "monthName", {
        get: function () {
            switch (parseInt(this.dateFormGroup.get('month').value)) {
                case 1:
                    return 'January';
                case 2:
                    return 'February';
                case 3:
                    return 'March';
                case 4:
                    return 'April';
                case 5:
                    return 'May';
                case 6:
                    return 'June';
                case 7:
                    return 'July';
                case 8:
                    return 'August';
                case 9:
                    return 'September';
                case 10:
                    return 'October';
                case 11:
                    return 'November';
                case 12:
                    return 'December';
                default:
                    return 'This month';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateControlComponent.prototype, "moreThanMaxDaysInMonth", {
        get: function () {
            return this.dateFormGroup.get('day').value > this.getMaxDaysInMonth();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateControlComponent.prototype, "isOptionalDateValid", {
        get: function () {
            var _this = this;
            var counter = 0;
            var keys = Object.keys(this.dateFormGroup.value);
            keys.forEach(function (key) {
                if (!_this.dateFormGroup.value[key]) {
                    counter += 1;
                }
            });
            if (counter === 3 || counter === 0) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    DateControlComponent.prototype.getMaxDaysInMonth = function () {
        var daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        var maxDaysInMonth = daysInMonth[this.dateFormGroup.get('month').value - 1];
        if (this.dateFormGroup.get('year').value && parseInt(this.dateFormGroup.get('month').value) === 2 && isLeapYear(this.dateFormGroup.get('year').value)) {
            maxDaysInMonth = 29;
        }
        return maxDaysInMonth;
    };
    DateControlComponent.prototype.getDateObj = function (date) {
        // check if there's no -
        var dateArr = date.split('-').map(function (value) { return parseInt(value); });
        return {
            year: dateArr[0],
            month: dateArr[1],
            day: dateArr[2]
        };
    };
    DateControlComponent.prototype.getDateStringFormat = function (month, day, year) {
        var dateStr = (year ? year : '') + "-" + (month ? ('0' + month).slice(-2) : '') + "-" + (day ? ('0' + day).slice(-2) : '');
        if (dateStr.replace('--', '') === '') {
            return null;
        }
        return dateStr;
    };
    DateControlComponent.prototype.allFieldsAreProvided = function (dateObj) {
        var counter = 0;
        var keys = Object.keys(dateObj);
        keys.forEach(function (key) {
            if (dateObj[key]) {
                counter += 1;
            }
        });
        if (counter > 0 && counter < keys.length) {
            return false;
        }
        return true;
    };
    DateControlComponent.prototype.onKeydownTabMonth = function (value, event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            this.dateFormGroup.get('month').patchValue(this.getMonthValueOnTabBlur(value));
            this.tabFn();
        }
    };
    DateControlComponent.prototype.onBlurMonth = function (value) {
        if (value && value !== '') {
            this.dateFormGroup.get('month').patchValue(this.getMonthValueOnTabBlur(value), { emitEvent: false });
        }
        else {
            this.dateFormGroup.get('month').patchValue(null, { emitEvent: false });
        }
        this.dateFormGroup.get('day').updateValueAndValidity();
    };
    DateControlComponent.prototype.onKeydownTabDay = function (value, event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            this.dateFormGroup.get('day').patchValue(this.getDayValueOnTabBlur(value));
            this.yearEl.nativeElement.focus();
        }
    };
    DateControlComponent.prototype.onBlurDay = function (value) {
        if (value && value !== '') {
            this.dateFormGroup.get('day').patchValue(this.getDayValueOnTabBlur(value), { emitEvent: false });
        }
        else {
            this.dateFormGroup.get('day').patchValue(null, { emitEvent: false });
        }
    };
    DateControlComponent.prototype.onKeydownYear = function (value, event) {
        if (event.key === 'Tab' && value && parseInt(value) === 0) {
            this.dateFormGroup.get('year').patchValue(null);
        }
    };
    DateControlComponent.prototype.onBlurYear = function (value) {
        if (value && parseInt(value) === 0) {
            this.dateFormGroup.get('year').patchValue(null);
        }
    };
    DateControlComponent.prototype.getDayValueOnTabBlur = function (value) {
        if (parseInt(value) > 3 || (parseInt(value) > 0 && value[0] === '0')) {
            return value;
        }
        else if (parseInt(value) > 0) {
            return "0" + value;
        }
        else {
            return null;
        }
    };
    DateControlComponent.prototype.getMonthValueOnTabBlur = function (value) {
        if (value === '1') {
            return '01';
        }
        else if (parseInt(value) === 0) {
            return null;
        }
        else {
            return value;
        }
    };
    DateControlComponent.prototype.tabFn = function () {
        this.dayEl.nativeElement.focus();
    };
    DateControlComponent.prototype.monthFn = function (value) {
        if (parseInt(value) > 9 && (parseInt(value) < 13) || value === '01') {
            this.tabFn();
        }
        else if (parseInt(value) > 1 && parseInt(value) < 10) {
            if (value[0] !== '0') {
                this.dateFormGroup.get('month').patchValue("0" + value, { emitEvent: false });
            }
            this.tabFn();
        }
    };
    DateControlComponent.prototype.dayFn = function (value) {
        if (parseInt(value) > 9 && parseInt(value) <= this.getMaxDaysInMonth()) {
            this.yearEl.nativeElement.focus();
        }
        else if (parseInt(value) > 3 && parseInt(value) < 10) {
            if (value[0] !== '0') {
                this.dateFormGroup.get('day').patchValue("0" + value, { emitEvent: false });
            }
            this.yearEl.nativeElement.focus();
        }
        else if (parseInt(value) > 0 && parseInt(value) < 4 && value[0] === '0') {
            this.yearEl.nativeElement.focus();
        }
    };
    DateControlComponent.prototype.yearFn = function (value) {
        if ((value.length && (parseInt(value[0]) > 2 || parseInt(value[0]) === 0)) ||
            (value.length > 1 && ((value[0] === '1' && parseInt(value[1]) < 9) ||
                (value[0] === '2' && parseInt(value[1]) > 0)))) {
        }
        else if (this.dateFormGroup.get('year').value &&
            this.dateFormGroup.get('year').valid &&
            this.dateFormGroup.get('month').value &&
            parseInt(this.dateFormGroup.get('month').value) === 2 &&
            !isLeapYear(this.dateFormGroup.get('year').value) &&
            parseInt(this.dateFormGroup.get('day').value) === 29) {
            this.dateFormGroup.get('day').patchValue(28, { emitEvent: false });
        }
    };
    DateControlComponent.prototype.monthValidation = function (ac) {
        if (parseInt(ac.value) > 12 || parseInt(ac.value) === 0) {
            return { 'invalidMonth': true };
        }
        if (this.dateFormGroup) {
            this.dateFormGroup.get('day').setErrors(this.dayValidation(this.dateFormGroup.get('day')));
        }
        return null;
    };
    DateControlComponent.prototype.dayValidation = function (ac) {
        if (this.dateFormGroup && (parseInt(ac.value) > this.getMaxDaysInMonth() || parseInt(ac.value) === 0)) {
            return { 'invalidDay': true };
        }
        if (this.dateFormGroup && this.dateFormGroup.get('year').value &&
            this.dateFormGroup.get('year').valid &&
            this.dateFormGroup.get('month').value &&
            parseInt(this.dateFormGroup.get('month').value) === 2 &&
            !isLeapYear(this.dateFormGroup.get('year').value) &&
            parseInt(ac.value) === 29) {
            return { 'invalidDay': true };
        }
        return null;
    };
    DateControlComponent.prototype.yearValidation = function (ac) {
        if (this.dateFormGroup && ac.value) {
            if ((ac.value.length && (parseInt(ac.value[0]) > 2 || parseInt(ac.value[0]) === 0)) ||
                (ac.value.length > 1 && ((ac.value[0] === '1' && parseInt(ac.value[1]) < 9) ||
                    (ac.value[0] === '2' && parseInt(ac.value[1]) > 0)))) {
                return { 'invalidYear': true };
            }
            this.dateFormGroup.get('day').setErrors(this.dayValidation(this.dateFormGroup.get('day')));
        }
        return null;
    };
    DateControlComponent.prototype.dateValidator = function (ac) {
        var date = this.getDateStringFormat(ac.get('month').value, ac.get('day').value, ac.get('year').value);
        switch (this.element.data.dateType) {
            case CPOSdateTypeEnum.start:
                return isStartDateValid(date) ? null : { 'invalidStartDate': true };
            case CPOSdateTypeEnum.end:
                var startDate = objectTraverser(this.element.data.startDate, this.form, this.indexes.value, 'controls').value;
                return isEndDateValid(startDate, this.getDateStringFormat(ac.get('month').value, ac.get('day').value, ac.get('year').value)) ?
                    null : { 'invalidEndDate': true };
            case CPOSdateTypeEnum.originalPurchaseDate:
                return isValidDobPurchaseDate(date, 125, 1900) ? null : { 'invalidPurchaseDate': true };
            case CPOSdateTypeEnum.dob:
                return isAgeLessThan18(date) ? null : { 'ageLessThan18': true };
            case CPOSdateTypeEnum.projectedEndOfService:
                return isProjectedEndOfServiceValid(date) ? null : { 'invalidProjectedEndOfServiceDate': true };
            case CPOSdateTypeEnum.appraisal:
                return isPreferredDateValid(date) ? null : { 'invalidAppraisalDate': true };
            default:
                console.error('date type is not specified.');
        }
    };
    DateControlComponent.prototype.ngOnDestroy = function () {
        if (!this.dateFormGroup.valid || (!this.isRequired && !this.isOptionalDateValid)) {
            this.dateFormControl.patchValue(null);
        }
    };
    return DateControlComponent;
}());
export { DateControlComponent };
