import { AppSettings } from '../app.settings';
import { AuthService } from '../services/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "../app.settings";
import * as i2 from "../services/auth.service";
//import { ModalsService } from '$modals';
var AuthGuard = /** @class */ (function () {
    function AuthGuard(settings, auth) {
        this.settings = settings;
        this.auth = auth;
    }
    AuthGuard.prototype.canActivate = function (route) {
        if (
        // A token and an api url are present
        this.settings.token && this.settings.apiUrl) {
            /** TEMPORARY CODE FOR SHOWING NEW DESIGN MODAL */
            //if (!this.settings.hasSeenNewDesignModal) {
            //  this.modals.open('NewDesignModalComponent', false, 360, null);
            //  this.settings.hasSeenNewDesignModal = true;
            //} /** END OF TEMPORARY CODE FOR SHOWING NEW DESIGN MODAL */
            return true; // logged in and has apiUrl so set true
        }
        this.auth.redirectToAuthenticate(false, route.queryParams);
        return false;
    };
    AuthGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.inject(i1.AppSettings), i0.inject(i2.AuthService)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
