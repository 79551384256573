/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./logout-modal.component";
var styles_LogoutModalComponent = [];
var RenderType_LogoutModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LogoutModalComponent, data: {} });
export { RenderType_LogoutModalComponent as RenderType_LogoutModalComponent };
export function View_LogoutModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["automationid", "Section_LogoutHeader"], ["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h3", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Warning"])), (_l()(), i0.ɵeld(3, 0, null, null, 3, "button", [["aria-label", "Close"], ["automationid", "BtnClose"], ["class", "close"], ["mat-dialog-close", ""], ["tabindex", "1"], ["type", "button"]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).dialogRef.close(i0.ɵnov(_v, 4).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 606208, null, 0, i1.MatDialogClose, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], { ariaLabel: [0, "ariaLabel"], dialogResult: [1, "dialogResult"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(7, 0, null, null, 5, "div", [["automationid", "Section_LogoutBody"], ["class", "modal-body"], ["id", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Your session is about to expire in "])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "span", [["id", "the_time"]], null, null, null, null, null)), (_l()(), i0.ɵted(11, null, ["", ""])), (_l()(), i0.ɵted(-1, null, [" seconds, do you want to continue working?"])), (_l()(), i0.ɵeld(13, 0, null, null, 9, "div", [["automationid", "Section_LogoutFooter"], ["class", "modal-footer"], ["style", "justify-content: space-between;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 3, "button", [["aria-label", "Close"], ["automationid", "BtnLogOut"], ["class", "float-left pull-left"], ["color", "primary"], ["mat-flat-button", ""], ["tabindex", "1"], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i0.ɵdid(15, 180224, null, 0, i3.MatButton, [i0.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i0.ɵeld(16, 0, null, 0, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-power-off"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, 0, [" Log Out "])), (_l()(), i0.ɵeld(18, 0, null, null, 4, "button", [["automationid", "BtnContinueWorking"], ["color", "accent"], ["mat-dialog-close", ""], ["mat-flat-button", ""], ["tabindex", "1"], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 20).dialogRef.close(i0.ɵnov(_v, 20).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i0.ɵdid(19, 180224, null, 0, i3.MatButton, [i0.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i0.ɵdid(20, 606208, null, 0, i1.MatDialogClose, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i0.ɵted(-1, 0, [" Continue Working "])), (_l()(), i0.ɵeld(22, 0, null, 0, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-angle-double-right"]], null, null, null, null, null))], function (_ck, _v) { var currVal_1 = "Close"; var currVal_2 = ""; _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_6 = "primary"; _ck(_v, 15, 0, currVal_6); var currVal_10 = "accent"; _ck(_v, 19, 0, currVal_10); var currVal_11 = ""; _ck(_v, 20, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵnov(_v, 4).ariaLabel || null); _ck(_v, 3, 0, currVal_0); var currVal_3 = _co.counter; _ck(_v, 11, 0, currVal_3); var currVal_4 = (i0.ɵnov(_v, 15).disabled || null); var currVal_5 = (i0.ɵnov(_v, 15)._animationMode === "NoopAnimations"); _ck(_v, 14, 0, currVal_4, currVal_5); var currVal_7 = (i0.ɵnov(_v, 19).disabled || null); var currVal_8 = (i0.ɵnov(_v, 19)._animationMode === "NoopAnimations"); var currVal_9 = (i0.ɵnov(_v, 20).ariaLabel || null); _ck(_v, 18, 0, currVal_7, currVal_8, currVal_9); }); }
export function View_LogoutModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-logout-modal", [], null, null, null, View_LogoutModalComponent_0, RenderType_LogoutModalComponent)), i0.ɵdid(1, 245760, null, 0, i7.LogoutModalComponent, [i1.MatDialogRef, i1.MAT_DIALOG_DATA, i1.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogoutModalComponentNgFactory = i0.ɵccf("app-logout-modal", i7.LogoutModalComponent, View_LogoutModalComponent_Host_0, {}, {}, []);
export { LogoutModalComponentNgFactory as LogoutModalComponentNgFactory };
