<div id="modal" aria-modal="true" aria-describedby="modal-title" role="alertdialog" focusContain>
  <div class="modal-header" style="justify-content: center;"  automationid="Section_Header">
    <h3 class="modal-title color-primary">WE HAVE UPDATED YOUR APPLICATION</h3>
    <!--
    <button type="button" class="close" aria-label="Close" mat-dialog-close tabindex="1">
      <span aria-hidden="true">&times;</span>
    </button>
    -->
  </div>

  <div class="modal-body" style="max-width: 55vw !important; padding: 1rem 2rem 1rem 2rem !important;" id="urla-modal-body">
    <span id="primary-info-text" class="info-text" >
      We have slightly updated the questions asked in your application since you last logged in.
      Don't worry, we have saved all the information you have previously provided,
      but you may want to re-confirm each page before submitting.
    </span>
  </div>

  <div class="modal-footer" style="justify-content: center;" *ngIf="appState$ | async as appState">
    <button type="button" mat-flat-button color="accent" class="ml-auto" (click)="submit(appState)" tabindex="1" aria-label="Close">
      <i class="fa fa-check" aria-hidden="true"></i> Got it!
    </button>
  </div>
</div>
