var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { ApiStoreActions } from './api.actions';
import { isType } from 'typescript-fsa';
export function ApiReducer(state, action) {
    // console.log('ApiReducer', action, ApiStoreActions);
    if (state === void 0) { state = {}; }
    if (isType(action, ApiStoreActions.RESET)) {
        state = {};
    }
    // Loading
    if (isType(action, ApiStoreActions.STATE_LOADING)) {
        state[action.payload.apiMap.storeProperty] = __assign({}, action.payload.apiMap.entity.initialState, state[action.payload.apiMap.storeProperty], { loading: true, error: false });
    }
    // Modifying, IE put, post or delete
    if (isType(action, ApiStoreActions.STATE_MODIFYING)) {
        state[action.payload.apiMap.storeProperty] = __assign({}, action.payload.apiMap.entity.initialState, state[action.payload.apiMap.storeProperty], { modifying: true, errorModifying: false, success: false });
    }
    // On error, either from loading or modifying
    if (isType(action, ApiStoreActions.STATE_ERROR_GET)) {
        state[action.payload.apiMap.storeProperty] = __assign({}, action.payload.apiMap.entity.initialState, state[action.payload.apiMap.storeProperty], { loading: false, error: action.payload.data });
    }
    // On error, either from loading or modifying
    if (isType(action, ApiStoreActions.STATE_ERROR_MODIYFING)) {
        state[action.payload.apiMap.storeProperty] = __assign({}, action.payload.apiMap.entity.initialState, state[action.payload.apiMap.storeProperty], { modifying: false, errorModifying: action.payload.data, success: false });
    }
    /*
    * Get complete
    */
    if (isType(action, ApiStoreActions.GET_COMPLETE)) {
        // If response is an array/collection and has an entityAdapter, convert to ngrx entities
        if (Array.isArray(action.payload.data) && typeof action.payload.data === 'object' && action.payload.apiMap.entity.adapter) {
            // Ensure initial state is set and current state is merged down on top of that
            state[action.payload.apiMap.storeProperty] = __assign({}, action.payload.apiMap.entity.initialState, state[action.payload.apiMap.storeProperty]);
            // After successful get, clear out all data currently in this store
            state[action.payload.apiMap.storeProperty] = action.payload.apiMap.entity.adapter.removeAll();
            // Update record/s in collection
            state[action.payload.apiMap.storeProperty] = action.payload.apiMap.entity.adapter.addMany(action.payload.data, state[action.payload.apiMap.storeProperty]);
            // After update, get new array and set to data property
            state[action.payload.apiMap.storeProperty].data = action.payload.apiMap.entity.adapter
                .getSelectors()
                .selectAll(state[action.payload.apiMap.storeProperty]);
        }
        else {
            // For all other types of data, load straight into data property
            try {
                state[action.payload.apiMap.storeProperty].data = action.payload.data;
            }
            catch (e) {
                console.warn("Could not save data to store: ", {
                    error: e,
                    payloadStoreProperty: action
                        && action.payload
                        && action.payload.apiMap
                        && action.payload.apiMap.storeProperty || 'Cannot determine storeProperty'
                });
            }
        }
        // Set load complete state
        state[action.payload.apiMap.storeProperty] = __assign({}, state[action.payload.apiMap.storeProperty], { loading: false, error: false });
    }
    /*
    * Post, put and upsert complete
    */
    if (isType(action, ApiStoreActions.POST_COMPLETE) ||
        isType(action, ApiStoreActions.PUT_COMPLETE) ||
        isType(action, ApiStoreActions.UPSERT_COMPLETE)) {
        // If the destination is an ngrx entity type
        if (state[action.payload.apiMap.storeProperty].entities.adapter) {
            // If response is a collection, use addMany
            if (Array.isArray(action.payload.data) && typeof action.payload.data === 'object') {
                state[action.payload.apiMap.storeProperty] = action.payload.apiMap.entity.adapter.upsertMany(action.payload.data, state[action.payload.apiMap.storeProperty]);
            }
            else {
                // If response is a single object, use addOne
                state[action.payload.apiMap.storeProperty] = action.payload.apiMap.entity.adapter.upsertOne(action.payload.data, state[action.payload.apiMap.storeProperty]);
            }
            // After update, get new array and set to data property
            state[action.payload.apiMap.storeProperty].data = action.payload.apiMap.entity.adapter
                .getSelectors()
                .selectAll(state[action.payload.apiMap.storeProperty]);
        }
        else {
            // All other types, send repsonse straight to data property
            state[action.payload.apiMap.storeProperty].data = action.payload.data;
        }
        // Set state
        state[action.payload.apiMap.storeProperty] = __assign({}, state[action.payload.apiMap.storeProperty], { loading: false, error: false, modifying: false, success: true });
    }
    /*
    * Delete complete
    */
    if (isType(action, ApiStoreActions.DELETE_COMPLETE)) {
        // If the destination is an ngrx entity type
        if (state[action.payload.apiMap.storeProperty].entities) {
            // If response is a collection, use addMany
            if (Array.isArray(action.payload.data) && typeof action.payload.data === 'object') {
                // Get array of unique IDs for delete collection
                var deleteIds = action.payload.data.reduce(function (a, b) { return __spread(a, [b[action.payload.apiMap.entity.uniqueId]]); }, []);
                // If response is a collection, use removeMany
                state[action.payload.apiMap.storeProperty] = action.payload.apiMap.entity.adapter.removeMany(deleteIds, state[action.payload.apiMap.storeProperty]);
            }
            else {
                // If response is a single object, use removeOne
                state[action.payload.apiMap.storeProperty] = action.payload.apiMap.entity.adapter.removeOne(action.payload.data[action.payload.apiMap.entity.uniqueId], state[action.payload.apiMap.storeProperty]);
            }
            // After update, get new array and set to data property
            state[action.payload.apiMap.storeProperty].data = action.payload.apiMap.entity.adapter
                .getSelectors()
                .selectAll(state[action.payload.apiMap.storeProperty]);
        }
        else {
            // All other types, send repsonse straight to data property
            state[action.payload.apiMap.storeProperty].data = action.payload.data;
        }
        // Update State
        state[action.payload.apiMap.storeProperty] = __assign({}, state[action.payload.apiMap.storeProperty], { loading: false, error: false, modifying: false, success: true });
    }
    return state;
}
