import { AppSettings } from '../app.settings';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '$env';
import { ModalsService } from '$modals';
import * as i0 from "@angular/core";
import * as i1 from "../app.settings";
import * as i2 from "@angular/common/http";
import * as i3 from "../../components/modals/modals.service";
var VersionManagementService = /** @class */ (function () {
    function VersionManagementService(settings, http, modals) {
        this.settings = settings;
        this.http = http;
        this.modals = modals;
        /** Notify subscribers of update available */
        this.hasUpdate$ = new BehaviorSubject(false);
        /** Update at this frequency */
        this.pollInterval = 5 * 60 * 1000; // 5 minutes
    }
    /**
     * Start version checking
     */
    VersionManagementService.prototype.versionCheckStart = function () {
        this.versionCheck(this.http.get(this.settings.apiUrl + environment.endpoints.version), this.settings);
    };
    /**
     * Recusive method to poll for version changes
     * If found, updates settings and notifies subscribers of update available which promps to refresh app
     * @param appVersionLocations
     */
    VersionManagementService.prototype.versionCheck = function (versionHttp, settings, pollInterval) {
        var _this = this;
        if (pollInterval === void 0) { pollInterval = this.pollInterval; }
        var sub = versionHttp.subscribe(function (res) {
            var version = res.version;
            var hasUpdate = false;
            // If app version has not been set
            if (!settings.version) {
                settings.version = version;
            }
            else if (settings.version !== version) {
                // New version found, update version in settings and set hasupdate flag
                settings.version = version;
                hasUpdate = true;
            }
            // If no update is found, poll
            if (!hasUpdate) {
                setTimeout(function () {
                    _this.versionCheck(versionHttp, settings);
                }, pollInterval);
            }
            else {
                // If update found, stop polling and update behavior subject
                _this.hasUpdate$.next(true);
                // Pop modal to ask user to refresh app
                _this.modalOpen();
            }
            sub.unsubscribe();
        }, 
        // On error, resume polling anyway
        function () {
            setTimeout(function () {
                _this.versionCheck(versionHttp, settings);
            }, pollInterval);
        });
    };
    /**
     * Open a confirmation modal asking the user to reload the app
     */
    VersionManagementService.prototype.modalOpen = function () {
        var _this = this;
        this.modals
            .open('ConfirmationModalComponent', false, 'sm', 'A new version of this application has just been released, would you like to refresh?')
            .afterClosed()
            .subscribe(function (closed) {
            if (closed) {
                // Reset ui state to clear out any breaking changes
                _this.settings.ui = null;
                // Reload page
                location.reload();
            }
        });
    };
    VersionManagementService.ngInjectableDef = i0.defineInjectable({ factory: function VersionManagementService_Factory() { return new VersionManagementService(i0.inject(i1.AppSettings), i0.inject(i2.HttpClient), i0.inject(i3.ModalsService)); }, token: VersionManagementService, providedIn: "root" });
    return VersionManagementService;
}());
export { VersionManagementService };
