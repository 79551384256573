var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { ApiHttpService } from './api.http.base.service';
import { ApiSelectorsService } from './api.selectors.service';
import { ApiStoreActions } from './api.actions';
import { ApiMap } from './api.map';
import { AppSettings } from '../../../shared/app.settings';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, of as ObservableOf } from 'rxjs';
import { AnalyticsService } from '../../services/analytics.service';
import { LoanUtils } from '../../utils/loan-utils';
import { AppConfigService } from '../../services/app-config.service';
import { sections2009 } from 'src/assets/config/2009/01741/src/sections';
import { sections2020 } from 'src/assets/config/2020/01741/src/sections';
import { URLAFormTypeEnum, EmploymentVerificationTypeEnum, AppraisalRequestTypeEnum } from 'src/app/shared/models';
import { constants, api } from 'src/app/shared/global';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "@angular/common/http";
import * as i3 from "../../app.settings";
import * as i4 from "./api.selectors.service";
import * as i5 from "../../services/analytics.service";
import * as i6 from "../../services/app-config.service";
// TODO: Add dynamic urls to caching Api
var timeStamp = new Date().getTime();
var ApiService = /** @class */ (function (_super) {
    __extends(ApiService, _super);
    function ApiService(store, http, settings, 
    /** API Store Selectors */
    select, analytics, config) {
        var _this = _super.call(this, http, store) || this;
        _this.store = store;
        _this.http = http;
        _this.settings = settings;
        _this.select = select;
        _this.analytics = analytics;
        _this.config = config;
        // remarkably, the system needs this exact user account id for several services (this is dev@meandmyloan.com)
        //private static readonly serviceAcctId = 74391;
        _this.appState$ = new BehaviorSubject({
            loaded: false,
            form1003: {
                isActive: null,
                isStarted: null,
                completedDate: null,
                state: [],
                indexes: {},
                pagePath: [],
                urlaModalShown: null
            },
            dashboard: {
                isActive: null,
                isStarted: null,
                completedDate: null,
            },
        });
        /** BaseLoan endpoint */
        _this.baseLoan = {
            get: function (update) {
                var loanId = _this.settings.loanId;
                var borrowerUserAccountId = _this.settings.userId;
                var loUserAccountId = _this.settings.lsidUserId;
                var userAccountId = constants.serviceaccountid.toString();
                var endpoint = "" + _this.settings.apiUrl + ApiMap.baseLoan.endpoint;
                var params = {
                    borrowerUserAccountId: borrowerUserAccountId,
                    loanId: loanId,
                    loUserAccountId: loUserAccountId,
                    systemSource: 1,
                    userAccountId: userAccountId,
                };
                return _this.getStore(endpoint, ApiMap.baseLoan, update, params).pipe(tap(function (loan) {
                    if (loan) {
                        // TODO: This should be part of the base loan endpoint, but adding this here for now for expediency
                        loan.transactionInfo.borrowers[0].declarationsInfo.usCitizenIndicator = null;
                        loan.transactionInfo.borrowers[0].declarationsInfo.permanentResidentAlienIndicator = null;
                        loan.transactionInfo.borrowers[0].isPrimaryBorrower = true;
                        loan.transactionInfo.borrowers[1].declarationsInfo.usCitizenIndicator = null;
                        loan.transactionInfo.borrowers[1].declarationsInfo.permanentResidentAlienIndicator = null;
                        _this.cacheSet("" + _this.settings.apiUrl + ApiMap.megaLoan.endpoint, loan);
                    }
                }));
            },
        };
        _this.client1003Config = {
            get: function (urla) {
                if (urla === void 0) { urla = URLAFormTypeEnum.URLA2020; }
                _this.select.client1003Config$.next({
                    data: urla === URLAFormTypeEnum.URLA2009 ? sections2009 : sections2020,
                });
                return _this.select.client1003Config$;
            },
        };
        /** MegaLoan endpoint */
        _this.megaLoan = {
            saveToSettings: function (loan) {
                if (loan && loan.transactionInfo) {
                    if (loan.transactionInfo.loanApplications &&
                        loan.transactionInfo.loanApplications.length &&
                        loan.transactionInfo.loanApplications[0].loanApplicationId) {
                        _this.settings.loanId = loan.loanId;
                        _this.settings.loanApplicationId = loan.transactionInfo.loanApplications[0].loanApplicationId;
                    }
                    _this.settings.borrowerId = LoanUtils.getBorrowerIdByUserAccountId(loan, _this.settings.userId);
                    if (!_this.settings.borrowerId) {
                        console.warn("No matching borrower ID was found. This may cause problems within the application");
                    }
                }
                // TFS 316267: LO Reassignment Enhancement
                // Store original LO ID for zip lookup
                if (!_this.settings.loUserIdOriginal && loan && loan.conciergeId) {
                    _this.settings.loUserIdOriginal = "" + loan.conciergeId;
                }
            },
            get: function (update, isInitial) {
                var callTimestamp = timeStamp;
                var loanId = _this.settings.loanId;
                var userAccountId = _this.settings.userId;
                var endpoint = "" + _this.settings.apiUrl + ApiMap.megaLoan.endpoint;
                var params = {
                    client_id: 'client',
                    loanId: loanId,
                    redirect_uri: 'https:%2F%2Fexample.com',
                    response_type: 'token',
                    scope: 'scope',
                    userAccountId: userAccountId,
                    userId: constants.User,
                    callTimestamp: callTimestamp,
                    isInitial: isInitial,
                };
                return _this.getStore(endpoint, ApiMap.megaLoan, update, params).pipe(switchMap(function (loan) {
                    // Check for valid loan
                    if (!(loan && loan.transactionInfo)) {
                        return _this.baseLoan.get(true);
                    }
                    else {
                        return ObservableOf(loan);
                    }
                }), tap(function (loan) {
                    // Determine URLA type
                    _this.settings.urla = loan.urlaFormType === URLAFormTypeEnum.URLA2009 ? URLAFormTypeEnum.URLA2009 : URLAFormTypeEnum.URLA2020;
                    // Check if loan is from EagerLoad API or BaseLoan API
                    if (!LoanUtils.isBaseLoan(loan)) {
                        _this.settings.canSaveAppState = true;
                    }
                    // Save properties from the loan
                    _this.megaLoan.saveToSettings(loan);
                }));
            },
            save: function (megaLoan) {
                var userAccountId = constants.serviceaccountid; // this.settings.userId;
                var endpoint = _this.settings.apiUrl + "/" + api.root + "/" + api.megasave + "?userAccountId=" + userAccountId;
                return _this.http.post(endpoint, megaLoan).pipe(tap(function () {
                    _this.settings.canSaveAppState = true;
                })); // , ApiMap.megaLoan
            },
            submit: function () {
                var loanId = _this.settings.loanId;
                var userAccountId = _this.settings.userId;
                var endpoint = _this.settings.apiUrl + "/" + api.root + "/" + api.consumerappsubmit;
                var params = {
                    loanId: loanId,
                    userAccountId: userAccountId,
                };
                return _this.http.post(endpoint, null, { params: params });
            },
        };
        // `${this.settings.apiUrl}${ApiMap.config1003.endpoint}`
        // `https://dev04loancenter.cloudvirga.com/${api.rootapi}/Configuration/GetCloverConfiguration?leadSourceId=1693&keyName=clover.config.default`
        _this.config1003 = {
            get: function (lsidSrc) {
                var lsid = lsidSrc ? lsidSrc : constants.defaultlsid; // Default setting if lsid is null
                var keyName = _this.settings.urla === URLAFormTypeEnum.URLA2009 ? constants.config2009 : constants.config2020;
                return _this.getStore("" + _this.settings.apiUrl + ApiMap.config1003.endpoint + "?leadSourceId=" + lsid + "&keyName=" + keyName, ApiMap.config1003);
            },
        };
        _this.dataFields = {
            get: function (enumId) {
                var timestamp = new Date().getTime();
                var userAccountId = _this.settings.userId;
                return _this.http
                    .get(_this.settings.apiUrl + "/" + api.root + "/" + api.getlookupbylookupcategory + "?callTimestamp=" + timestamp + "&lookupCategoryEnum=" + enumId + "&userId=" + userAccountId)
                    .pipe(map(function (res) { return res.response; }));
            },
        };
        _this.credit = {
            run: function () {
                var url = _this.settings.apiUrl + "/" + api.root + "/" + api.credit;
                var params = {
                    areSixPiecesCompleted: 'false',
                    borrowerId: '00000000-0000-0000-0000-000000000000',
                    creditReportIdentifier: '',
                    loanApplicationId: _this.settings.loanApplicationId,
                    paidOffFreeAndClear: 'false',
                    previousActiveSystem: '2',
                    requestType: '1',
                    userAccountId: constants.serviceaccountid.toString(),
                };
                return _this.http.get(url, { params: params });
            },
            get: function () {
                var url = _this.settings.apiUrl + "/" + api.root + "/" + api.credit;
                var params = {
                    accountId: constants.serviceaccountid.toString(),
                    applicationId: _this.settings.loanApplicationId,
                };
                return _this.http.get(url, { params: params });
            },
        };
        /** Borrower needs list endpoint */
        _this.bnl = {
            get: function (update) {
                var loanId = _this.settings.loanId;
                var loanAppId = _this.settings.loanApplicationId;
                var endpoint = "" + _this.settings.apiUrl + ApiMap.bnl.endpoint;
                var params = {
                    loanAppId: loanAppId,
                    loanId: loanId,
                    userId: constants.User,
                };
                return _this.getStore(endpoint, ApiMap.bnl, update, params);
            },
            generate: function (update) {
                var loanId = _this.settings.loanId;
                var loanAppId = _this.settings.loanApplicationId;
                var userAccountId = _this.settings.userId;
                var timestamp = new Date().getTime();
                var endpoint = _this.settings.apiUrl + "/" + api.root + "/" + api.borrowerneedsservicegenerate;
                var params = {
                    callTimestamp: timestamp,
                    loanAppId: loanAppId,
                    loanId: loanId,
                    userId: constants.User,
                    userAccountId: userAccountId,
                };
                return _this.getStore(endpoint, ApiMap.bnl, update, params);
            },
        };
        /** Borrower documents endpoint */
        _this.documents = {
            get: function (update) {
                var timestamp = new Date().getTime();
                var loanId = _this.settings.loanApplicationId;
                var endpoint = "" + _this.settings.apiUrl + ApiMap.documents.endpoint;
                var params = {
                    callTimestamp: timestamp,
                    loanId: loanId,
                    userId: constants.User,
                };
                return _this.getStore(endpoint, ApiMap.documents, update, params);
            },
        };
        /** All borrower loans endpoint */
        _this.activeLoanSnapshots = {
            get: function (update) {
                var timestamp = new Date().getTime();
                var userAccountId = _this.settings.userId;
                var endpoint = "" + _this.settings.apiUrl + ApiMap.activeLoanSnapshots.endpoint + "?callTimestamp=" + timestamp + "&userAccountId=" + userAccountId + "&userId=User";
                return _this.getStore(endpoint, ApiMap.activeLoanSnapshots, update);
            },
        };
        /** Borrower eVerify endpoint */
        _this.everify = {
            getIframeUrl: function (loanId, borrowerId, assetInfoId) {
                var callTimestamp = new Date().getTime().toString();
                var endpoint = _this.settings.apiUrl + "/" + api.root + "/" + api.enrollandgetwidget;
                return _this.http.get(endpoint, {
                    params: {
                        assetInfoId: assetInfoId,
                        borrowerId: borrowerId,
                        callTimestamp: callTimestamp,
                        loanId: loanId,
                        userId: constants.User,
                    },
                });
            },
            getAccountList: function (loanId, borrowerId, financialInstitutionId) {
                var callTimestamp = new Date().getTime().toString();
                var endpoint = _this.settings.apiUrl + "/" + api.root + "/" + api.getverifiedaccountlist;
                return _this.http.get(endpoint, {
                    params: {
                        borrowerId: borrowerId,
                        callTimestamp: callTimestamp,
                        financialInstitutionId: "" + financialInstitutionId,
                        loanId: loanId,
                        userId: constants.User,
                    },
                });
            },
            processAccounts: function (data) {
                _this.analytics.trackEvent('Secure Account Linking Login', {
                    'Account Login': 'Yes',
                });
                _this.analytics.trackEvent('Secure Account Linking Review', {
                    'Accounts sent to Lender': 'Yes',
                });
                var callTimestamp = new Date().getTime().toString();
                var endpoint = _this.settings.apiUrl + "/api/EVerifyAssetService/ProcessVerifiedAccounts";
                return _this.http.post(endpoint, data, {
                    params: {
                        callTimestamp: callTimestamp,
                        userId: constants.User,
                    },
                });
            },
        };
        /** Fidning LO for new loan added to the same useraccount */
        _this.leadSourceForNewLoan = {
            get: function () {
                var endpoint = _this.settings.apiUrl + "/" + api.root + "/" + api.getleadsourceforborrower + "?url=" + encodeURIComponent(_this.settings.landingUrl) + "&borrowerAccountId=" + _this.settings.userId;
                var params = {
                    CallTimestamp: new Date().getTime().toString(),
                    UserID: _this.settings.userId
                };
                return _this.getStore(endpoint, ApiMap.broker, false, params).pipe(tap(function (broker) {
                    if (!broker)
                        return;
                }), map(function (broker) { return broker && broker.leadSourceId ? broker : null; }));
            },
        };
        /** Borrower eVerification of income and assets endpoint */
        _this.evoievoe = {
            runEmploymentVerification: function (type, loan, borrowerId) {
                var endpoint = _this.settings.apiUrl + "/" + api.root + "/" + api.runemploymentverification;
                return _this.http.post(endpoint, loan, {
                    params: {
                        borrowerId: borrowerId,
                        userAccountId: _this.settings.userId,
                        employmentVerificationType: type === EmploymentVerificationTypeEnum.eVOI ? '1' : '2',
                    },
                });
            },
        };
        /** Update user account */
        _this.saveUserAccount = {
            save: function (request) {
                var endpoint = _this.settings.apiUrl + "/" + api.root + "/" + api.saveuseraccount;
                return _this.http.post(endpoint, request);
            }
        };
        _this.user = {
            getUserAccountById: function (userAccountId) {
                var endpoint = _this.settings.apiUrl + "/" + api.root + "/" + api.getuseraccountbyid;
                var params = {
                    userAccountId: "" + userAccountId,
                };
                return _this.http.get(endpoint, { params: params });
            },
            saveUserAccount: _this.saveUserAccount.save,
        };
        /** Borrower eSign endpoint */
        _this.esign = {
            getVendorForLoanByProduct: function (loanId, productId) {
                var callTimestamp = new Date().getTime().toString();
                var endpoint = _this.settings.apiUrl + "/" + api.root + "/" + api.getvendorforloanbyproduct;
                return _this.http.get(endpoint, {
                    params: {
                        callTimestamp: callTimestamp,
                        userId: constants.User,
                        loanId: loanId,
                        productId: productId.toString(),
                    },
                });
            },
            getIframeUrl: function (request) {
                var callTimestamp = new Date().getTime().toString();
                var endpoint = _this.settings.apiUrl + "/" + api.root + "/" + api.getsigningroom;
                return _this.http.post(endpoint, request, {
                    params: {
                        callTimestamp: callTimestamp,
                        userId: constants.User,
                    },
                });
            },
            confirmSignature: function (params) {
                var endpoint = _this.settings.apiUrl + "/" + api.root + "/" + api.onsignatureevent;
                return _this.http.get(endpoint, { responseType: 'text', params: params });
            },
            setDocumentPreview: function (loanApplicationId, borrowerId, documentId) {
                var endpoint = _this.settings.apiUrl + "/" + api.root + "/" + api.setdocumentpreview;
                var params = {
                    loanApplicationId: loanApplicationId,
                    borrowerId: borrowerId,
                    documentId: documentId
                };
                return _this.http.get(endpoint, { params: params });
            }
        };
        /** Appraisals endpoints */
        _this.appraisal = {
            saveAppraisal: function (data) {
                var callTimestamp = new Date().getTime().toString();
                var endpoint = _this.settings.apiUrl + "/" + api.root + "/" + api.saveappraisal;
                return _this.http.post(endpoint, data, {
                    params: {
                        callTimestamp: callTimestamp,
                        userId: constants.User,
                    },
                })
                    .pipe(map(function (response) {
                    // Catch error messages
                    if (!response || !response.response || !!response.errorMsg) {
                        throw new Error(response.errorMsg);
                    }
                }));
            },
            submitAppraisalRequest: function (loanNumber) {
                var callTimestamp = new Date().getTime().toString();
                var endpoint = _this.settings.apiUrl + "/" + api.root + "/" + api.submitappraisalrequest;
                return _this.http.get(endpoint, {
                    params: {
                        appraisalRequestType: AppraisalRequestTypeEnum.RequestAppraisal.toString(),
                        consumerSubmitted: 'true',
                        loanId: _this.settings.loanId,
                        loanNumber: loanNumber,
                        userAccountId: _this.settings.userId,
                        nonce: '',
                        callTimestamp: callTimestamp,
                        userId: constants.User,
                    },
                });
            },
            markAsComplete: function (documentId, borrowerId) {
                var callTimestamp = new Date().getTime().toString();
                var loanId = _this.settings.loanId;
                var loanApplicationId = _this.settings.loanApplicationId;
                var endpoint = _this.settings.apiUrl + "/" + api.root + "/" + api.documentsviewingcomplete;
                return _this.http.get(endpoint, {
                    params: {
                        callTimestamp: callTimestamp,
                        loanId: loanId,
                        loanApplicationId: loanApplicationId,
                        documentId: documentId,
                        borrowerId: borrowerId,
                        userId: constants.User,
                    },
                });
            },
            getProductsAndFees: function (data) {
                var callTimestamp = new Date().getTime().toString();
                var endpoint = _this.settings.apiUrl + "/" + api.root + "/" + api.getappraisalproductsandfees;
                return _this.http.post(endpoint, data, {
                    params: {
                        callTimestamp: callTimestamp,
                        userId: constants.User,
                    },
                });
            },
        };
        /** Borrower user account endpoints */
        _this.userAccount = {
            getAccountInfo: function (token) {
                var callTimestamp = new Date().getTime().toString();
                var endpoint = _this.settings.apiUrl + "/" + api.root + "/" + api.getloancontext;
                return _this.http.get(endpoint, {
                    params: {
                        callTimestamp: callTimestamp,
                        token: token,
                        userId: constants.User,
                    },
                });
            },
            updateAccount: function (data) {
                var callTimestamp = new Date().getTime().toString();
                var endpoint = _this.settings.apiUrl + "/" + api.root + "/" + api.authenticate;
                return _this.http.post(endpoint, data, {
                    params: {
                        callTimestamp: callTimestamp,
                        userId: constants.User,
                    },
                });
            },
            /**
             * Note: As this is not very intuitive, I should point out that the
             * boolean response answers the question 'This account is already taken?'
             */
            checkAvailability: function (userName) {
                var callTimestamp = new Date().getTime().toString();
                // Username must be added to the URL in this manner due to the bug mentioned here:
                // https://github.com/angular/angular/issues/18261
                var encodedUserName = encodeURIComponent(userName);
                var endpoint = _this.settings.apiUrl + "/" + api.root + "/" + api.isvaliduseraccountbyusername + "?userName=" + encodedUserName;
                return _this.http.get(endpoint, {
                    params: {
                        callTimestamp: callTimestamp,
                        userId: constants.User,
                    },
                });
            },
            checkActivationByUserName: function (userName) {
                // Username must be added to the URL in this manner due to the bug mentioned here:
                // https://github.com/angular/angular/issues/18261
                var encodedUserName = encodeURIComponent(userName);
                var endpoint = _this.settings.apiUrl + "/" + api.root + "/" + api.isvaliduserandactivebyusername + "?userName=" + encodedUserName;
                return _this.http.get(endpoint);
            },
            isExistingUser: function (userName, firstName, lastName) {
                var endpoint = _this.settings.apiUrl + "/" + api.root + "/UserService/IsExistingUser";
                return _this.http.get(endpoint, {
                    params: {
                        userName: userName,
                        firstName: firstName,
                        lastName: lastName,
                    },
                });
            },
            sendTemporaryPassword: function (userName) {
                var callTimestamp = new Date().getTime().toString();
                var loAffinityId = _this.settings.lsid;
                // Username must be added to the URL in this manner due to the bug mentioned here:
                // https://github.com/angular/angular/issues/18261
                var encodedUserName = encodeURIComponent(userName);
                var endpoint = _this.settings.apiUrl + "/" + api.root + "/" + api.generatetemporarypasswordandsendemail + "?userName=" + encodedUserName;
                // const
                return _this.http.get(endpoint, {
                    params: {
                        callTimestamp: callTimestamp,
                        loAffinityId: loAffinityId,
                        userId: constants.User,
                    },
                });
            },
            resetPassword: function (data) {
                var callTimestamp = new Date().getTime().toString();
                var endpoint = _this.settings.apiUrl + "/" + api.root + "/" + api.authenticate;
                return _this.http.post(endpoint, data, {
                    params: {
                        callTimestamp: callTimestamp,
                        userId: constants.User,
                    },
                });
            },
            sendActivationEmail: function (userName) {
                var callTimestamp = new Date().getTime().toString();
                var endpoint = _this.settings.apiUrl + "/" + api.root + "/" + api.resendsecurelinkemail;
                return _this.http.post(endpoint, null, {
                    params: {
                        callTimestamp: callTimestamp,
                        email: userName,
                        userId: constants.User,
                    },
                });
            },
        };
        /** Loan Contacts endpoint */
        _this.loanContacts = {
            get: function (update) {
                var timestamp = new Date().getTime();
                var loanId = _this.settings.loanId;
                var userAccountId = _this.settings.userId;
                var endpoint = "" + _this.settings.apiUrl + ApiMap.loanContacts.endpoint + "?callTimestamp=" + timestamp + "&loanId=" + loanId + "&userAccountId=" + userAccountId + "&userId=User";
                if (!loanId) {
                    return ObservableOf([]);
                }
                return _this.getStore(endpoint, ApiMap.loanContacts, update).pipe(tap(function (contacts) {
                    if (contacts && contacts.length) {
                        var lo = contacts.filter(function (contact) { return contact.loanContactType === 1; })[0];
                        if (lo && lo.userAccountId) {
                            _this.settings.loUserId = String(lo.userAccountId);
                            // Add LO name to mixpanel super props
                            _this.analytics.mixpanelSuperProps({
                                'Loan Officer Name': lo.name,
                            });
                        }
                    }
                }));
            },
            sendLOReassignmentEmail: function (prevLoanOfficerId, newLoanOfficerId) {
                var loanId = _this.settings.loanId;
                var userAccountId = _this.settings.userId;
                var endpoint = _this.settings.apiUrl + "/" + api.root + "/" + api.loreassigned;
                var params = {
                    loanId: loanId,
                    userAccountId: userAccountId,
                    prevLoanOfficerId: "" + prevLoanOfficerId,
                    newLoanOfficerId: "" + newLoanOfficerId,
                };
                return _this.http.post(endpoint, null, { params: params });
            },
        };
        /** Current Company Profile endpoint */
        _this.currentCompanyProfile = {
            get: function (affinityId, userAccountId, update) {
                var callTimestamp = new Date().getTime();
                var loanId = _this.settings.loanId;
                // const userAccountId = this.settings.userId;
                var endpoint = "" + _this.settings.apiUrl + ApiMap.currentCompanyProfile.endpoint;
                var params = {
                    affinityId: affinityId,
                    callTimestamp: callTimestamp,
                    loanId: loanId,
                    userAccountId: userAccountId,
                    userId: constants.User,
                };
                return _this.getStore(endpoint, ApiMap.currentCompanyProfile, update, params).pipe(map(function (profile) {
                    if (profile) {
                        // Update super props in analytics
                        _this.analytics.mixpanelSuperProps({
                            'Branch Id': profile.branchId,
                            'Company Id': profile.companyId,
                            'Channel Id': profile.channelId,
                            'Division Id': profile.divisionId,
                            'NMLS Number': profile.nmlsNumber,
                            'Company Name': profile.companyBrandingName || profile.companyName,
                        });
                        _this.settings.clientName = profile.companyBrandingName || profile.companyName;
                        // Update the branch ID if none exists
                        if (!_this.settings.branchId) {
                            _this.settings.branchId = profile.branchId;
                        }
                    }
                    return profile;
                }));
            },
        };
        /** Company Basic Info endpoint */
        _this.companyBasicInfo = {
            get: function () {
                var callTimestamp = new Date().getTime().toString();
                var endpoint = _this.settings.apiUrl + "/" + api.root + "/" + api.getcompanybasicinfo;
                var params = {
                    callTimestamp: callTimestamp,
                    userId: constants.User,
                };
                return _this.http.get(endpoint, { params: params });
            },
            getForUser: function (leadSourceId) {
                if (leadSourceId === void 0) { leadSourceId = _this.settings.lsid; }
                var endpoint = _this.settings.apiUrl + "/" + api.root + "/" + api.getcompanybasicinfoforuser;
                var params = {
                    leadSourceId: leadSourceId,
                };
                return _this.http.get(endpoint, { params: params });
            },
        };
        /** Broker endpoint */
        _this.broker = {
            get: function (leadSourceId, update) {
                if (leadSourceId === void 0) { leadSourceId = _this.settings.lsid; }
                if (update === void 0) { update = false; }
                var callTimestamp = new Date().getTime().toString();
                var endpoint = _this.settings.apiUrl + "/" + api.root + "/" + api.getleadsourcebasicinfo + "?leadSourceId=" + leadSourceId + "&validate=true";
                var params = {
                    callTimestamp: callTimestamp,
                };
                return _this.getStore(endpoint, ApiMap.broker, update, params).pipe(tap(function (broker) {
                    if (!broker)
                        return;
                    // If the broker ID is different that the LSID (set on insantiation), update WP config
                    if (broker.leadSourceId !== _this.lsidBroker) {
                        _this.config.loadConfig(broker.leadSourceId);
                        _this.lsidBroker = broker.leadSourceId;
                    }
                    // Set loan officer super prop
                    _this.analytics.mixpanelSuperProps({
                        'Loan Officer': broker.firstName + ' ' + broker.lastName,
                    });
                }), map(function (broker) { return broker && broker.emailAddress ? broker : null; }));
            },
        };
        /** Audit Log endpoint */
        _this.auditLog = {
            save: function (auditLog) {
                var userAccountId = constants.serviceaccountid;
                var endpoint = _this.settings.apiUrl + "/" + api.root + "/" + api.createauditLog + "?userAccountId=" + userAccountId + "&loanId=" + auditLog.loanId;
                return _this.http.post(endpoint, auditLog);
            }
        };
        _this.voaConfiguration = {
            get: function () {
                var endpoint = _this.settings.apiUrl + "/" + api.root + "/" + api.getvoaconfiguration;
                return _this.http.get(endpoint)
                    .pipe(map(function (apiRes) { return apiRes.response; }));
            }
        };
        /** App State, IE are they on the 1003 or bnl section. Also keeps track of 1003 progress */
        _this.appState = {
            get: function () {
                var endpoint = "" + _this.settings.apiUrl + ApiMap.appState.endpoint + "/" + _this.settings.borrowerId + "/" + api.appstate + "?loanid=" + (_this.settings.loanApplicationId || _this.settings.loanId) + "&userAccountID=" + _this.settings.userId + "&userId=User";
                return _this.http.get(endpoint).pipe(map(function (appStateNew) {
                    // Set default state. On initial load appstate comes back null
                    var state = appStateNew && appStateNew.sessionData
                        ? JSON.parse(appStateNew.sessionData)
                        : { form1003: {}, dashboard: {} };
                    var appState = {
                        loaded: true,
                        form1003: __assign({ isActive: null, isStarted: null, completedDate: null, state: [], indexes: {}, pagePath: [] }, state.form1003),
                        dashboard: __assign({ isActive: null, isStarted: null, completedDate: null }, state.dashboard),
                    };
                    _this.appState$.next(appState);
                    return appState;
                }));
            },
            set: function (appState) {
                var endpoint = "" + _this.settings.apiUrl + ApiMap.appState.endpoint + "/" + _this.settings.borrowerId + "/" + api.appstate + "?loanid=" + (_this.settings.loanApplicationId || _this.settings.loanId) + "&userAccountID=" + _this.settings.userId + "&userId=User";
                var val = {
                    sessionData: JSON.stringify(appState),
                };
                _this.appState$.next(appState);
                // Check if appState can be saved before calling API
                if (!_this.settings.canSaveAppState) {
                    return ObservableOf(null);
                }
                return _this.http.put(endpoint, val);
            },
        };
        _this.services = {
            /**
             * Get a loan contacts picture
             */
            pictureGetUrl: function (pictureId) { return _this.settings.apiUrl + "/" + api.root + "/" + api.userpicture + "?pictureId=" + pictureId; },
            /**
             * Get an array of guids
             */
            guidsGet: function (count) {
                if (count === void 0) { count = 1000; }
                return _this.http
                    .get(_this.settings.apiUrl + "/" + api.root + "/" + api.getguids + "?count=" + count)
                    .pipe(map(function (res) { return res.Response; }));
            },
            /**
             * Get address information from a zipcode
             */
            zipCodeLookup: function (zipcode) {
                return _this.http
                    .post(_this.settings.apiUrl + "/" + api.root + "/" + api.getzipdata + "?callTimestamp=1554313152566&userId=User", {
                    zipDataRequest: {
                        isLicensedStates: true,
                        zipCode: zipcode,
                        checkUserAndBranchLicenseForState: _this.services.getCheckUserAndBranchLicenseForState(),
                        branchId: _this.settings.branchId,
                        userAccountId: parseFloat(_this.settings.loUserId),
                    },
                })
                    .pipe(map(function (res) { return res.response; }));
            },
            /**
             * Get array of address information from a zipcode
             */
            usZipCodeLookup: function (zipCode) {
                var url = _this.settings.apiUrl + "/" + api.root + "/" + api.getuszipdata;
                var callTimestamp = new Date().getTime().toString();
                var params = {
                    callTimestamp: callTimestamp,
                    userId: constants.User,
                };
                return _this.http
                    .post(url, {
                    zipDataRequest: {
                        branchId: _this.settings.branchId,
                        checkUserAndBranchLicenseForState: _this.services.getCheckUserAndBranchLicenseForState(),
                        isLicensedStates: true,
                        stateName: null,
                        userAccountId: parseFloat(_this.settings.loUserIdOriginal),
                        zipCode: zipCode,
                    },
                }, { params: params })
                    .pipe(map(function (res) { return (res && res.response) || null; }));
            },
            /**
             * Autocomplete lookup for financial instutition
             */
            financialInstitutionLookup: function (institutionName) {
                return _this.http
                    .get(_this.settings.apiUrl + "/" + api.root + "/" + api.searchfinancialinstitution + "?callTimestamp=1554313152566&institutionName=" + encodeURIComponent(institutionName) + "&requestedCount=5&userId=User")
                    .pipe(map(function (res) { return res.response; }));
            },
            /**
             * Retrieve the closing date for new loans
             */
            getClosingDate: function (loanPurpose) {
                var params = { loanPurpose: loanPurpose };
                return _this.http.get(_this.settings.apiUrl + "/" + api.root + "/" + api.defaultclosingdate, { params: params });
            },
            /**
             * Check if state licensure rules are enabled
             */
            getCheckUserAndBranchLicenseForState: function () {
                var checkUserAndBranchLicenseForState = true;
                if (_this.settings.config && _this.settings.config[constants.configareastatelicensurerulesenabled]) {
                    checkUserAndBranchLicenseForState = _this.settings.config[constants.configareastatelicensurerulesenabled].value;
                }
                return checkUserAndBranchLicenseForState;
            }
        };
        _this.lsidBroker = _this.settings.lsid;
        return _this;
    }
    /**
     * Get a unique Guid
  
    public guidGet() {
      if (this._guids) {
        return this._guids.shift();
      }
    }
    */
    /**
     * Reset the store, clear out all held state and data
     */
    ApiService.prototype.resetStore = function () {
        this.cacheClear(); // Clear cache
        this.store.dispatch(ApiStoreActions.RESET(null));
    };
    /**
     * Fix a bug with TS where super calls don't count as usage
     */
    ApiService.prototype.fixTS = function () {
        console.log(this.http);
    };
    ApiService.prototype.getApiStoreData = function (apiStore$) {
        return apiStore$.pipe(filter(function (apiState) { return apiState && !!apiState.data; }), map(function (apiState) { return apiState && apiState.data; }));
    };
    ApiService.ngInjectableDef = i0.defineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.inject(i1.Store), i0.inject(i2.HttpClient), i0.inject(i3.AppSettings), i0.inject(i4.ApiSelectorsService), i0.inject(i5.AnalyticsService), i0.inject(i6.AppConfigService)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}(ApiHttpService));
export { ApiService };
