var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, mapTo, switchMap, tap, throttleTime } from 'rxjs/operators';
import { merge, of, forkJoin } from 'rxjs';
import { environment } from '$env';
import { ApiService } from '$api';
import { AppSettings } from '../app.settings';
import { ModalsService } from 'src/app/components/modals/modals.service';
import { throwError, fromEvent } from 'rxjs';
import { AnalyticsService } from './analytics.service';
import { UIStoreService } from '$ui';
import { ApplicationNavigationService } from './application-navigation.service';
import { AuditLogCategoryEnum, AuditLogLevelEnum, AuditLogTypeEnum, CPOSIncomeTypeEnum } from 'src/app/shared/models';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "../app.settings";
import * as i4 from "../../components/modals/modals.service";
import * as i5 from "../stores/api/api.store.service";
import * as i6 from "./analytics.service";
import * as i7 from "../stores/ui/ui.store.service";
import * as i8 from "./application-navigation.service";
var AuthService = /** @class */ (function () {
    function AuthService(http, router, settings, modals, api, analytics, ui, appNavService) {
        this.http = http;
        this.router = router;
        this.settings = settings;
        this.modals = modals;
        this.api = api;
        this.analytics = analytics;
        this.ui = ui;
        this.appNavService = appNavService;
        /** Is user logged in */
        this.isLoggedIn = false;
        /** Is session expired */
        this.sessionExpired = false;
        /** How long to show the modal window in seconds */
        this.modalDuration = 60;
        /** Holds the logout session timer */
        this.sessionTimer = null; //
        /** If a no user activity is detected in this amount of time,
         * show log out modal and eventually log the user out automatically */
        this.setTimerDefaultSeconds = 60 * 58; // tokens last 60 minutes, back off 2 minutes to handle server latency
        /** Actions that must be performed prior to logging out */
        this.preLogOutActions = [];
        /** Holds the minutes given from Session Expiration Config */
        this.configuredMinutes = null;
        /** Minimum allowed configurable minutes **/
        this.minimumMinutes = 5;
        /** Maximum allowed configurable minutes **/
        this.maximumMinutes = 200;
        // On service load, if auth is enabled and a token is present start timer
        // This accomodates reloading the app
        if (environment.settings.enableAuth && this.settings.token) {
            // If reloading and not logging in
            this.keepAlive();
        }
    }
    AuthService.prototype.getFormDataForAuth = function () {
        var formData = new FormData();
        formData.append('client_id', "MmlOAuthClientDescription");
        formData.append('redirect_uri', "https://qa01concierge.newleaflending.com");
        formData.append('scope', "scope-mml-lc");
        formData.append('response_type', "token");
        return formData;
    };
    /**
     * Log the user in
     * @param data
     */
    AuthService.prototype.logIn = function (data) {
        var _this = this;
        // Error handling
        if (!(data && data.userName && data.password)) {
            return;
        }
        // Prepare data for second API call
        // OAuth endpoint must be sent x-www-form-urlencoded data
        var formData = this.getFormDataForAuth();
        formData.append('username', data.userName);
        formData.append('password', data.password);
        formData.append('leadSourceId', this.settings.lsid);
        formData.append('loanId', data.loanId);
        // Auth point is configured
        var url = this.settings.apiUrl + '/api' + environment.endpoints.authLogin;
        return this.http
            .post(url, formData, { observe: 'response' })
            .pipe(tap(function (response) {
            if (response) {
                _this.handleAuthResponseHeaders(response);
                if (response.body && response.body.response) {
                    _this.handleAuthResponseBody(response.body.response);
                }
            }
        }), switchMap(function (response) { return _this.validateLsid(response); }), map(function (response) {
            var serverResponse = response && response.body && response.body.response || {};
            return __assign({}, serverResponse, { mfaToken: response.headers.get('x-cv-mfa-token') });
        }), 
        // We need to wait for to the loan to finish loading, but return
        // the API response of the Auth service
        switchMap(function (responseBody) {
            if (_this.settings.loanId) {
                return _this.api.megaLoan.get().pipe(mapTo(responseBody));
            }
            return of(responseBody);
        }));
    }; // end LogIn
    /**
     * There are situations where we need to validate the LSID being saved in localStorage
     * Here, we check to see if those conditions are true, make a synchronous API call, and
     * always return the data that originally passed into this method
     * @param response Original response from the API
     */
    AuthService.prototype.validateLsid = function (response) {
        var _this = this;
        // If LSID needs validated...
        if (response
            && response.body
            && response.body.response
            && response.body.response.authentication
            && response.body.response.authentication.registeredLeadSourceId
            && (this.settings.isDefaultCorporateLeadSource
                || !this.settings.lsid)) {
            // Synchronous API call
            return this.api.broker.get(response.body.response.authentication.registeredLeadSourceId)
                .pipe(tap(function (leadSourceInfo) {
                if (leadSourceInfo.leadSourceId) {
                    _this.settings.lsid = leadSourceInfo.leadSourceId;
                }
                if (leadSourceInfo.userAccountId) {
                    _this.settings.lsidUserId = leadSourceInfo.userAccountId.toString();
                }
            }), 
            // Still, we return the original data passed in
            mapTo(response));
        }
        // ... otherwise, simply pass the data on through
        return of(response);
    };
    AuthService.prototype.createLoginAuditLog = function (loanId, borrowerFullName) {
        var auditLog = {
            category: AuditLogCategoryEnum.UserAccount,
            level: AuditLogLevelEnum.Loan,
            loanId: loanId,
            loanApplicationId: loanId,
            message: borrowerFullName + ' has successfully logged into the Consumer Portal',
            title: 'Borrower Login',
            type: AuditLogTypeEnum.BorrowerLogin,
            userAccountId: +this.settings.userId
        };
        return auditLog;
    }; // end create login audit log
    /**
     * Log the user in via MFA
     * @param username Account username to login
     * @param mfaCode MFA code from form
     * @param mfaToken MFA token from API
     */
    AuthService.prototype.logInMfa = function (username, mfaCode, mfaToken) {
        var _this = this;
        // Error handling
        if (!(username && mfaCode && mfaToken)) {
            return of({});
        }
        // Auth point is configured
        var headersObj = new HttpHeaders();
        headersObj = headersObj.set('x-cv-mfa-token', mfaToken);
        var url = this.settings.apiUrl + '/api' + environment.endpoints.authLoginMfa + ("/?userName=" + username + "&mfaCode=" + mfaCode);
        return this.http
            .post(url, null, { observe: 'response', headers: headersObj })
            .pipe(tap(function (response) {
            if (response) {
                _this.handleAuthResponseHeaders(response);
                if (response.body && response.body.response) {
                    _this.handleAuthResponseBody(response.body.response);
                }
            }
        }), switchMap(function (response) { return _this.validateLsid(response); }), map(function (response) {
            return response.body.response;
        }), 
        // We need to wait for to the loan to finish loading, but return
        // the API response of the Auth service
        switchMap(function (responseBody) {
            if (_this.settings.loanId) {
                return _this.api.megaLoan.get().pipe(mapTo(responseBody));
            }
            return of(responseBody);
        }));
    }; // end LogIn
    /**
     * Request a new MFA code
     * @param username
     * @param mfaToken
     */
    AuthService.prototype.requestNewMfa = function (username, mfaToken) {
        var _this = this;
        // Error handling
        if (!(username && mfaToken)) {
            return of({});
        }
        // Auth point is configured
        var headersObj = new HttpHeaders();
        headersObj = headersObj.set('x-cv-mfa-token', mfaToken);
        var url = this.settings.apiUrl + '/api' + environment.endpoints.authRequestNewMfa + ("/?userName=" + username);
        return this.http
            .post(url, null, { observe: 'response', headers: headersObj })
            .pipe(tap(function (response) {
            if (response) {
                _this.handleAuthResponseHeaders(response);
                if (response.body && response.body.response) {
                    _this.handleAuthResponseBody(response.body.response);
                }
            }
        }), switchMap(function (response) { return _this.validateLsid(response); }), map(function (response) {
            var serverResponse = response && response.body && response.body.response || {};
            return __assign({}, serverResponse, { mfaToken: response.headers.get('x-cv-mfa-token') });
        }));
    }; // end requestNewMfa
    /**
     * Used to create a new user and loan
     * @param data
     */
    AuthService.prototype.registerNewUser = function (data) {
        var _this = this;
        var userAccountId = this.settings.lsidUserId;
        var callTimestamp = new Date().getTime().toString();
        var registerUrl = this.settings.apiUrl + "/api/users/register";
        var requestBody = {
            authenticationRequestViewModel: {
                authenticationRequestType: 10,
                authentication: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    userAccountName: data.email,
                    password: data.password,
                }
            }
        };
        var params = {
            userAccountId: userAccountId,
            callTimestamp: callTimestamp,
        };
        return this.http.post(registerUrl, requestBody, { observe: 'response', params: params }).pipe(tap(function (response) {
            if (response) {
                _this.handleAuthResponseHeaders(response);
                if (response.body) {
                    _this.handleAuthResponseBody(response.body);
                }
            }
        }), map(function (response) {
            var serverResponse = response && response.body || {};
            return __assign({}, serverResponse, { mfaToken: response.headers.get('x-cv-mfa-token') });
        }), catchError(function () {
            // Reformat all errors to a single string here:
            return throwError("Unfortunately an error has occurred. Please try again.");
        }));
    };
    /**
     * Used by both login and register to handle an authenticated response
     * @param response
     */
    AuthService.prototype.handleAuthResponseHeaders = function (response) {
        // Store the auth token
        this.settings.token = response.headers.get('authorization');
        this.isLoggedIn = true;
    };
    /**
     * Used by both login and register to handle an authenticated response
     * @param response
     */
    AuthService.prototype.handleAuthResponseBody = function (response) {
        // console.log('handleAuthResponseBody', this.settings.userId);
        if (!(response && response.authentication)) {
            console.error("AUTH ENDPOINT DID NOT RETURN AUTHENTICATION DATA");
        }
        // Store the loan ID when first logging in
        this.settings.loanId = response.authentication.loanId || null;
        // Store the user ID when first logging in
        this.settings.userId =
            (response.authentication.accountIds &&
                response.authentication.accountIds.length &&
                response.authentication.accountIds[0] &&
                response.authentication.accountIds[0].toString &&
                response.authentication.accountIds[0].toString()) ||
                null;
        // Store the userName when first logging in
        this.settings.userName = response.authentication.userAccountName || null;
        this.settings.userFullName = response.authentication.firstName + ' ' + response.authentication.lastName || null;
        this.analytics.mixpanelSuperProps({ 'Loan Id': this.settings.loanId });
        this.analytics.mixpanelSuperProps({ 'CV Guid': this.settings.loanId });
        this.keepAlive();
        this.sessionExpired = false;
    };
    /**
     * Set a countdown timer that pops a modal window when the user is close to session timeout
     * @param expirationSeconds Seconds before automatically logging out user
     */
    AuthService.prototype.setTimer = function (expirationSeconds) {
        var _this = this;
        clearTimeout(this.sessionTimer);
        if (this.settings.token) {
            this.sessionTimer = setTimeout(function () {
                _this.sessionExpired = true;
                _this.launchLogoutModal();
                // Show the modal (10 seconds + modalDuration) before time runs out.
            }, (expirationSeconds - this.modalDuration - 10) * 1000);
        }
    }; // end SetTimer
    /**
     * Notify the server that the session is active by polling based on user interaction events
     */
    AuthService.prototype.keepAlive = function () {
        var _this = this;
        this.keepAliveSub = merge(fromEvent(document, 'mousemove'), // On mouse move
        fromEvent(document, 'touchmove'), // On touch move
        fromEvent(document, 'keyup'))
            .pipe(throttleTime(60 * 5 * 1000)) // ping every 5 minutes
            .subscribe(function () {
            // Send ping to the the back-end
            if (_this.settings.token) {
                var formData = _this.getFormDataForAuth();
                formData.append('loanId', _this.settings.loanApplicationId);
                _this.http
                    .post(_this.settings.apiUrl + '/api/SecurityService/Ping', formData, { observe: 'response' })
                    .pipe(tap(function (response) {
                    if (response && response.headers.has('authorization')) {
                        _this.handleAuthResponseHeaders(response);
                        //Session Expiration Configuration, 5 < minutes < 200, otherwise default 5||200
                        if (_this.settings.config["cPOS Session Expiration (Minutes)"]) {
                            _this.configuredMinutes = _this.settings.config["cPOS Session Expiration (Minutes)"].value;
                            _this.setTimerDefaultSeconds = Math.min(Math.max(_this.configuredMinutes, _this.minimumMinutes), _this.maximumMinutes) * 60;
                        }
                        // Reset automatic logout modal timer
                        _this.setTimer(_this.setTimerDefaultSeconds);
                    }
                }), catchError(function () {
                    // TODO: Dev only
                    return throwError("Unfortunately an error has occurred. Please try again.");
                }))
                    .subscribe();
            }
        });
    };
    /**
     * Launch a modal window which gives the user a chance to continue working
     */
    AuthService.prototype.launchLogoutModal = function () {
        var _this = this;
        clearTimeout(this.sessionTimer);
        // Open log out modal window
        this.modals
            .open('LogoutModalComponent', false, 'sm', this.modalDuration)
            .afterClosed()
            .subscribe(function (reason) {
            if (reason !== true) {
                _this.setTimer(_this.setTimerDefaultSeconds);
            }
            else {
                _this.logOut();
            }
        });
    };
    /**
     * Clear stored data
     */
    AuthService.prototype.resetStoreAndSettings = function () {
        this.settings.token = null;
        this.settings.borrowerId = null;
        this.settings.loanId = null;
        this.settings.loanApplicationId = null;
        this.settings.userId = null;
        this.settings.loUserIdOriginal = null;
        this.ui.form1003 = null;
        this.settings.userName = null;
        this.settings.canSaveAppState = null;
        this.api.appState$.next(null);
        if (this.keepAliveSub && this.keepAliveSub.unsubscribe) {
            this.keepAliveSub.unsubscribe();
        }
        this.api.resetStore(); // Clear out all API data on log out for security
    };
    /**
     * Log the user out
     */
    AuthService.prototype.logOut = function (showLogoutMessage, forcedQueryParams) {
        var _this = this;
        if (showLogoutMessage === void 0) { showLogoutMessage = false; }
        if (forcedQueryParams === void 0) { forcedQueryParams = {}; }
        if (this.isLoggedIn !== true) {
            this.postLogOutProcess(showLogoutMessage, forcedQueryParams);
        }
        else {
            this.isLoggedIn = false;
            // Resets the Mixpanel Distinct ID - Feature 378152
            this.analytics.mixpanelReset();
            ;
            this.adjustAppState().pipe(switchMap(function (appState) {
                if (appState) {
                    _this.attachPreLogOutAction(function () { return _this.api.appState.set(appState); });
                }
                return _this.performPreLogOutActions();
            })).subscribe(function () {
                _this.postLogOutProcess(showLogoutMessage, forcedQueryParams);
            });
        }
    };
    AuthService.prototype.postLogOutProcess = function (showLogoutMessage, forcedQueryParams) {
        if (showLogoutMessage === void 0) { showLogoutMessage = false; }
        if (forcedQueryParams === void 0) { forcedQueryParams = {}; }
        this.preLogOutActions = [];
        this.redirectToAuthenticate(showLogoutMessage, forcedQueryParams, '/login');
        this.appNavService.appRouting$.next({ sectionId: null, pageId: null });
    };
    /**
     * To prevent having appstate index higher than no of assets stored in the database.
     */
    AuthService.prototype.adjustAppState = function () {
        if (this.ui && this.ui.form1003) {
            var allAssets = this.ui.form1003.get('loan.$$custom.loan.assets') && this.ui.form1003.get('loan.$$custom.loan.assets').value;
            var validAssets_1 = allAssets && allAssets.filter(function (assetType) { return assetType.assetType; });
            var allEmployments = this.ui.form1003.get('loan.$$custom.loan.employments') && this.ui.form1003.get('loan.$$custom.loan.employments').value;
            var validEmployments_1 = allEmployments && allEmployments.filter(function (e) {
                return ((e.cvIncomeTypeId === CPOSIncomeTypeEnum.Employed && e.employerInfo && e.employerInfo.name) ||
                    e.cvIncomeTypeId === CPOSIncomeTypeEnum.MilitaryPay ||
                    e.cvIncomeTypeId === CPOSIncomeTypeEnum.SocialSecurity ||
                    (e.cvIncomeTypeId === CPOSIncomeTypeEnum.PensionRetirement && e.income.retirementStartDate && e.income.monthlyPension) ||
                    e.cvIncomeTypeId === CPOSIncomeTypeEnum.SelfEmployed ||
                    e.cvIncomeTypeId === CPOSIncomeTypeEnum.OtherUnemployed ||
                    e.cvIncomeTypeId === CPOSIncomeTypeEnum.NoIncome);
            });
            return this.api.appState.get().pipe(map(function (appState) {
                if (validAssets_1 && appState.form1003.indexes['loan.$$custom.loan.assets'] && appState.form1003.indexes['loan.$$custom.loan.assets'] >= validAssets_1.length) {
                    //updating the index
                    appState.form1003.indexes['loan.$$custom.loan.assets'] = validAssets_1.length > 0 ? validAssets_1.length - 1 : null;
                    //update appState lastPageId
                    for (var i = 0; i < appState.form1003.state.length; i++) {
                        if (appState.form1003.state[i].sectionId === 'assets') {
                            appState.form1003.state[i].lastPageId = 'summary';
                            break;
                        }
                    }
                }
                if (validEmployments_1 && appState.form1003.indexes['loan.$$custom.loan.employments'] && appState.form1003.indexes['loan.$$custom.loan.employments'] >= validEmployments_1.length) {
                    //updating the index
                    appState.form1003.indexes['loan.$$custom.loan.employments'] = validEmployments_1.length > 0 ? validEmployments_1.length - 1 : null;
                    //update appState lastPageId
                    for (var i = 0; i < appState.form1003.state.length; i++) {
                        if (appState.form1003.state[i].sectionId === 'income') {
                            appState.form1003.state[i].lastPageId = 'summary';
                            break;
                        }
                    }
                }
                return appState;
            }));
        }
        return of(null);
    };
    /**
     * Attach a callback that must be performed prior to logging out. The callback
     * must return an observable that completes. Upon log out, the
     * auth service will execute the pre log out action and wait until the observable
     * returned completes before logging out.
     *
     * Returns an unsubscribe function that can remove the pre logout action.
     */
    AuthService.prototype.attachPreLogOutAction = function (action) {
        var _this = this;
        this.preLogOutActions.push(action);
        return function () {
            _this.preLogOutActions = _this.preLogOutActions.filter(function (a) { return a !== action; });
        };
    };
    /**
     * Performs all pre logout actions in parallel and returns an observable
     * that completes when all pre logout actions have completed
     */
    AuthService.prototype.performPreLogOutActions = function () {
        var actions = this.preLogOutActions;
        if (!(actions && actions.length)) {
            return of(true);
        }
        var actionObservables = actions.map(function (fn) {
            try {
                return fn().pipe(catchError(function (err) { return of(err); }));
            }
            catch (err) {
                return of(err);
            }
        });
        return forkJoin(actionObservables);
    };
    /**
     * Clear stored data and redirect user to authenticate
     */
    AuthService.prototype.redirectToAuthenticate = function (showLogoutMessage, forcedQueryParams, forcedUrl) {
        if (showLogoutMessage === void 0) { showLogoutMessage = false; }
        if (forcedQueryParams === void 0) { forcedQueryParams = {}; }
        clearTimeout(this.sessionTimer);
        // Clear stored data
        this.resetStoreAndSettings();
        // Don't throw a redirect url if this is the dashboard since that is default on login
        var returnUrlSplit = (this.router.url && this.router.url.split && this.router.url.split('?')[0]) || '/';
        var returnUrl = returnUrlSplit !== '/' && returnUrlSplit !== '/login' && returnUrlSplit !== '/register' ? returnUrlSplit : null;
        // Determine whether or not to show the log out message
        var newQueryParams = showLogoutMessage
            ? { returnUrl: returnUrl, session: 'loggedout' }
            : { returnUrl: returnUrl };
        if (this.sessionExpired == true) {
            newQueryParams = { returnUrl: returnUrl, session: 'expired' };
        }
        var queryParams = __assign({}, forcedQueryParams, newQueryParams);
        // Use forced URL if specified
        var url = !!forcedUrl
            ? forcedUrl
            : this.settings.lsid
                ? '/register'
                : '/login';
        this.router.navigate([url], { queryParams: queryParams, queryParamsHandling: 'merge' });
    }; // end LogOut
    /**
     * Determine if a user account has been activated. Return activation status and username if one was found
     * @param token SecureLinkId token
     */
    AuthService.prototype.checkUserActivationByToken = function (token) {
        var _this = this;
        return this.api.userAccount.getAccountInfo(token).pipe(switchMap(function (apiResponse) {
            var userName = (apiResponse && apiResponse.response && apiResponse.response.signinUserEmailAddress) || '';
            return _this.api.userAccount.checkActivationByUserName(userName).pipe(map(function (activationResponse) {
                if (activationResponse && activationResponse.response) {
                    activationResponse.response.loanId = apiResponse.response.loanId;
                    _this.settings.loanApplicationId = apiResponse.response.loanApplicationId;
                }
                return activationResponse && activationResponse.response;
            }));
        }));
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.HttpClient), i0.inject(i2.Router), i0.inject(i3.AppSettings), i0.inject(i4.ModalsService), i0.inject(i5.ApiService), i0.inject(i6.AnalyticsService), i0.inject(i7.UIStoreService), i0.inject(i8.ApplicationNavigationService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
