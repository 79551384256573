var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, of } from 'rxjs';
import { debounceTime, switchMap, filter, catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
/**
 * A logging service that queues log events and sends them to the backend on an interval

 * TODO:
 * Switch to logs being passed through the observable instead of on the class property
 * Queued observables get out of order when checking from the stream instead of the class
 */
var LoggingService = /** @class */ (function () {
    function LoggingService(http) {
        this.http = http;
        /** Holds the log entries in queue so they can be sent as batches */
        this.queue = [];
        /** Super props are appended to every log entry and are only needed to be sent once */
        this.superProps = {};
        /** Manage the flow of data through the app including sending to the webapi */
        this.queue$ = new BehaviorSubject([]);
        this.savingInProgress = false;
    }
    /**
     * Start sending logs
     * @param path Path to logging webapi
     * @param delay How long to queue/buffer log requests
     */
    LoggingService.prototype.start = function (path, delay) {
        var _this = this;
        if (delay === void 0) { delay = 5 * 1000; }
        // Create sub
        this.sub = this.queue$
            .pipe(
        // Throttle how often the web api is hit
        debounceTime(delay), 
        // Only pass if queue has entries
        filter(function (queue) { return !!queue.length && !_this.savingInProgress; }), 
        // Make HTTP call
        switchMap(function (queue) {
            _this.savingInProgress = true;
            // Store reference to queue
            var queueSrc = __spread(queue);
            // Clear out current queue so that new entries are added and will be sent with a subsequent call
            _this.queue = [];
            // Post to backend
            return _this.http.post(path, queueSrc).pipe(catchError(function (err) {
                // On error, prepend log entries back into the queue so they will be sent with the next post
                _this.queue = __spread(queueSrc, _this.queue);
                return of(err);
            }));
        }))
            .subscribe(function () { return (_this.savingInProgress = false); });
    };
    /**
     * Add a log entry to be sent to the backend. Log entries are queued and sent in batches
     * @param logEntry
     */
    LoggingService.prototype.track = function (logEntry) {
        if (!this.sub) {
            console.warn('Logging has not yet been started');
        }
        this.queue = __spread(this.queue, [__assign({}, this.superProps, logEntry)]);
        if (!this.savingInProgress) {
            this.queue$.next(this.queue);
        }
    };
    /**
     * Add or change a global super property which is appended to every log request
     * @param props
     */
    LoggingService.prototype.superPropsAdd = function (props) {
        this.superProps = __assign({}, this.superProps, props);
    };
    /**
     * Stop sending log request
     */
    LoggingService.prototype.stop = function () {
        if (this.sub) {
            this.sub.unsubscribe();
            this.sub = undefined;
        }
    };
    /**
     * Reset the log queue and super props
     */
    LoggingService.prototype.reset = function () {
        this.queue = [];
        this.superProps = {};
    };
    LoggingService.ngInjectableDef = i0.defineInjectable({ factory: function LoggingService_Factory() { return new LoggingService(i0.inject(i1.HttpClient)); }, token: LoggingService, providedIn: "root" });
    return LoggingService;
}());
export { LoggingService };
