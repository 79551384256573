var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '$api';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { LoanUtils, ProcessingStateEnum } from '../../../shared/utils/loan-utils';
import { AppSettings, AnalyticsService } from '$shared';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { HeartbeatService } from 'src/app/shared/services/heartbeat.service';
import { LoanContactType, BusinessContactPhoneTypeEnum } from 'src/app//shared/models';
var SidebarRightComponent = /** @class */ (function () {
    function SidebarRightComponent(api, settings, ref, analytics, heartbeatService) {
        var _this = this;
        this.api = api;
        this.settings = settings;
        this.ref = ref;
        this.analytics = analytics;
        this.heartbeatService = heartbeatService;
        this.beforeProcessing = true;
        this.isCommNavOpen = true;
        this.appState$ = this.api.appState$;
        this.loanContactsState$ = this.api.select.loanContacts$;
        this.pictureUrlDefault = 'assets/img/agent.png';
        this.pictureUrl = this.pictureUrlDefault;
        this.loAddress = {};
        this.loanContactType = LoanContactType;
        this.loanContacts$ = combineLatest(this.api.getApiStoreData(this.loanContactsState$).pipe(debounceTime(1000), distinctUntilChanged(function (a, b) { return JSON.stringify(a) === JSON.stringify(b); })), this.api.broker.get()).pipe(map(function (_a) {
            var _b = __read(_a, 2), loanContacts = _b[0], leadSourceInfo = _b[1];
            loanContacts = loanContacts.length ? loanContacts : [];
            var hasProcessor = loanContacts.some(function (contact) {
                return contact.loanContactType === LoanContactType.Processor;
            });
            var loanContactsFiltered = loanContacts
                .filter(function (contact) {
                // always hide 'other'
                if (contact.loanContactType === LoanContactType.Other)
                    return false;
                if (_this.beforeProcessing) {
                    // if before processing, always hide processor
                    if (contact.loanContactType === LoanContactType.Processor)
                        return false;
                }
                else {
                    // if in processing or later, hide loan officer assistant (but only if no processor)
                    if (contact.loanContactType === LoanContactType.LoanOfficerAssistant && hasProcessor)
                        return false;
                }
                return true;
            })
                .sort(function (a, b) {
                if (a.loanContactType === LoanContactType.LoanOfficer)
                    return -1;
                if (b.loanContactType === LoanContactType.LoanOfficer)
                    return 1;
                if (a.loanContactType === LoanContactType.LoanOfficerAssistant)
                    return -1;
                if (b.loanContactType === LoanContactType.LoanOfficerAssistant)
                    return 1;
                if (a.loanContactType === LoanContactType.Processor)
                    return -1;
                if (b.loanContactType === LoanContactType.Processor)
                    return 1;
            });
            if (loanContactsFiltered.length) {
                // If any loan officers were found,
                // use the LSID to get the company information
                var loanOfficer = loanContactsFiltered.filter(function (contact) { return contact.loanContactType === LoanContactType.LoanOfficer; })[0];
                if (loanOfficer) {
                    _this.setLOAndCompanyInfo(loanOfficer);
                }
                else {
                    _this.setLOAndCompanyInfo();
                }
                // If there are loan contacts that matched the above criteria,
                // show these contacts in the sidebar
                return loanContactsFiltered;
            }
            else if (leadSourceInfo) {
                // If no loan contacts were found (perhaps this is a newly registered borrower?)
                // Pull loan contact info from LeadSourceBasicInfo API
                var leadSourcePhones = leadSourceInfo.phones && leadSourceInfo.phones.length ? leadSourceInfo.phones : [];
                var phoneHash = leadSourcePhones.reduce(function (accumulator, phone) {
                    accumulator[phone.type] = phone.cleanNumber;
                    return accumulator;
                }, {});
                var leadSourcePhone = phoneHash[BusinessContactPhoneTypeEnum.Cell] || phoneHash[BusinessContactPhoneTypeEnum.Work] || null;
                var loanOfficer = leadSourcePhone ? { phone: leadSourcePhone } : null;
                _this.setLOAndCompanyInfo(loanOfficer);
                return [
                    {
                        pictureId: leadSourceInfo.pictureId ? leadSourceInfo.pictureId : null,
                        email: leadSourceInfo.emailAddress,
                        loanContactType: LoanContactType.LoanOfficer,
                        name: leadSourceInfo.firstName + " " + leadSourceInfo.lastName,
                        nmlsNumber: leadSourceInfo.nmlsNumber,
                        phone: leadSourceInfo.phones && leadSourceInfo.phones.length ? leadSourceInfo.phones[0].cleanNumber : null,
                        title: leadSourceInfo.jobTitle,
                        userAccountId: leadSourceInfo.userAccountId,
                    },
                ];
            }
            else {
                _this.setLOAndCompanyInfo();
                // If there is no data to display, return an empty array
                return [];
            }
        }));
    }
    SidebarRightComponent.prototype.ngOnInit = function () {
        this.setPictureUrl();
        this.heartbeatService.confirmReleaseOnLogOut();
    };
    /** Needed for until destroyed */
    SidebarRightComponent.prototype.ngOnDestroy = function () { };
    SidebarRightComponent.prototype.setLOAddress = function (loAddress) {
        this.loAddress = loAddress;
        this.ref.markForCheck();
    };
    SidebarRightComponent.prototype.setPictureUrl = function () {
        var _this = this;
        // Make sure the store contacts contact data
        this.appState$
            .pipe(untilDestroyed(this), 
        // Filter valid form1003 objects
        filter(function (appState) { return appState && !!appState.form1003; }), 
        // Check to see if form1003 has changed
        map(function (appState) { return JSON.stringify(appState.form1003); }), distinctUntilChanged(), 
        // Switch to loan
        switchMap(function () { return _this.api.getApiStoreData(_this.api.select.megaLoan$); }), 
        // Used to determine which contact to show in the UI
        tap(function (loan) {
            var milestoneState = LoanUtils.getProcessingState(loan.currentMilestone);
            _this.beforeProcessing = milestoneState === ProcessingStateEnum.Preprocessing;
        }), 
        // Use loan to get loanContacts from API
        switchMap(function () { return _this.api.loanContacts.get(); }), 
        // Use consolidated and filtered loanContacts$ to find picture ID
        switchMap(function () {
            return (_this.loanContacts$
                // Map down to a single picture URL
                .pipe(take(1), map(function (contacts) {
                var loanContact = contacts.filter(function (contact) { return contact.pictureId && contact.loanContactType === LoanContactType.LoanOfficer; });
                return loanContact && loanContact.length
                    ? _this.api.services.pictureGetUrl(loanContact[0].pictureId)
                    : _this.pictureUrlDefault;
            })));
        }), 
        // Filter out same picture URLs
        distinctUntilChanged())
            .subscribe(function (pictureUrl) {
            if (pictureUrl) {
                _this.pictureUrl = pictureUrl;
                _this.ref.markForCheck();
            }
        });
    };
    SidebarRightComponent.prototype.setLOAndCompanyInfo = function (loanOfficer, setLOAddress) {
        var _this = this;
        if (loanOfficer === void 0) { loanOfficer = {}; }
        if (setLOAddress === void 0) { setLOAddress = true; }
        // Use loan officer LSID if found
        var lsid = loanOfficer && loanOfficer.leadSourceId || this.settings.lsid;
        this.api.companyBasicInfo.getForUser(lsid).subscribe(function (apiResponse) {
            // Error handling
            if (!(apiResponse && apiResponse.response))
                return;
            var company = apiResponse.response;
            var channel = company.channels
                && Array.isArray(company.channels)
                && company.channels[0]
                ? company.channels[0]
                : {};
            var division = channel
                && channel.divisions
                && Array.isArray(channel.divisions)
                && channel.divisions[0]
                ? channel.divisions[0]
                : {};
            var branch = division
                && division.branches
                && Array.isArray(division.branches)
                && division.branches[0]
                ? division.branches[0]
                : {};
            var isWholesale = channel && channel.isWholesale;
            if (setLOAddress) {
                // Set LO Address
                var loStreetAddress = branch.streetAddress || division.streetAddress || channel.streetAddress || company.streetAddress;
                var loStreetAddress2 = null;
                var loCity = branch.city || division.city || channel.city || company.city;
                var loState = branch.state || division.state || channel.state || company.state;
                var loZip = branch.zip || division.zip || channel.zip || company.zipCode;
                var loPhone = (loanOfficer && loanOfficer.phone) || branch.phone || division.phone || channel.phone || company.phone;
                _this.setLOAddress({ street: loStreetAddress, street2: loStreetAddress2, city: loCity, state: loState, zip: loZip, phone: loPhone, });
            }
            // Set company info
            _this.lenderName = (!isWholesale && branch.disclosuresLenderName) || division.disclosuresLenderName || channel.disclosuresLenderName || company.disclosuresLenderName;
            _this.companyPhone = (!isWholesale && branch.phone) || division.phone || channel.phone || company.phone;
            _this.companyNmls = !isWholesale ? company.nmlsNumber : division.nmlsNumber || channel.nmlsNumber || company.nmlsNumber;
            // Set Client Name
            if (branch.displayName) {
                _this.settings.clientName = branch.displayName;
            }
            // Analytics
            if (branch.branchId) {
                _this.settings.branchId = branch.branchId;
                _this.analytics.mixpanelSuperProps({ 'Branch Id': branch.branchId });
            }
            if (division.divisionId) {
                _this.analytics.mixpanelSuperProps({ 'Branch Id': division.divisionId });
            }
            if (channel.channelId) {
                _this.analytics.mixpanelSuperProps({ 'Branch Id': channel.channelId });
            }
            _this.analytics.mixpanelSuperProps({ 'NMLS Number': _this.companyNmls });
            // Update UI
            _this.ref.markForCheck();
        });
    };
    /**
     * Handle issues when an image cannot successfully load
     * @param event Image event
     */
    SidebarRightComponent.prototype.onImageError = function (event) {
        event.target.src = this.pictureUrlDefault;
    };
    /**
     * Open address in new window
     * @param address
     */
    SidebarRightComponent.prototype.openAddress = function (address) {
        if (!address)
            return;
        var addressString = address.street ? "" + address.street : '';
        addressString += address.street2 ? " " + address.street2 : '';
        addressString += address.city ? " " + address.city : '';
        addressString += address.state ? " " + address.state : '';
        addressString += address.zip ? " " + address.zip : '';
        window.open("https://www.google.com/maps/place/" + encodeURI(addressString) + "/", '_blank');
        return false;
    };
    return SidebarRightComponent;
}());
export { SidebarRightComponent };
