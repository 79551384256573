import { environment } from '$env';
import { StringUtils } from '$utils';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
// Enum of app setting properties. Only needed if using the propGet and propSet methods in this file
export var AppSettingsProps;
(function (AppSettingsProps) {
    AppSettingsProps["token"] = "token";
    AppSettingsProps["mfatoken"] = "mfatoken";
    AppSettingsProps["apiUrl"] = "apiUrl";
    AppSettingsProps["userName"] = "userName";
    AppSettingsProps["userFullName"] = "userFullName";
    AppSettingsProps["branchId"] = "branchId";
    AppSettingsProps["config"] = "config";
    AppSettingsProps["ui"] = "ui";
    AppSettingsProps["version"] = "version";
    AppSettingsProps["loanId"] = "loanId";
    AppSettingsProps["loanApplicationId"] = "loanApplicationId";
    AppSettingsProps["userId"] = "userId";
    AppSettingsProps["loUserId"] = "loUserId";
    AppSettingsProps["loUserIdOriginal"] = "loUserIdOriginal";
    AppSettingsProps["temporaryUserId"] = "temporaryUserId";
    AppSettingsProps["lsid"] = "lsid";
    AppSettingsProps["isDefaultCorporateLeadSource"] = "isDefaultCorporateLeadSource";
    AppSettingsProps["borrowerId"] = "borrowerId";
    AppSettingsProps["clientId"] = "clientId";
    AppSettingsProps["clientName"] = "clientName";
    AppSettingsProps["hasSeenNewDesignModal"] = "hasSeenNewDesignModal";
    AppSettingsProps["canSaveAppState"] = "canSaveAppState";
    AppSettingsProps["mixpanelKey"] = "mixpanelKey";
    AppSettingsProps["viewedAppraisalDocIds"] = "viewedAppraisalDocIds";
    AppSettingsProps["landingUrl"] = "landingUrl";
    AppSettingsProps["urla"] = "urla";
})(AppSettingsProps || (AppSettingsProps = {}));
// Getter/setters for app settings. Will read/write to app settings and on app load will try to reload from localstorage/sessionstorage
var AppSettings = /** @class */ (function () {
    function AppSettings(platformId) {
        this.platformId = platformId;
        /** Property to store app settings in local or session storage */
        this.localProp = 'settings-app-' + environment.properties.appName.length;
        /** If obfusicated, pad settings with this many characters */
        this.pad = 100;
        this.localStorage = {};
        this.sessionStorage = {};
        /** Property to store app settings in local or session storage */
        this.isBrowser = isPlatformBrowser(this.platformId);
        /** API token */
        this._token = null;
        /** Loan ID */
        this._loanId = null;
        /** Loan Application ID */
        this._loanApplicationId = null;
        /** User ID */
        this._userId = null;
        /** User ID */
        this._loUserId = null;
        /** User ID */
        this._loUserIdOriginal = null;
        /** Temporary User ID */
        this._lsidUserId = null;
        /** UserName */
        this._userName = null;
        /** UserName */
        this._userFullName = null;
        /** LSID */
        this._lsid = null;
        /** LSID */
        this._isDefaultCorporateLeadSource = null;
        /** Borrower ID */
        this._borrowerId = null;
        /** API token */
        this._ui = null;
        /** User */
        this._version = null;
        /** Brand styling */
        this._config = null;
        /** Which client ID */
        this._branchId = null;
        /** URL of web api */
        this._apiUrl = null;
        /** The id of the client using this app */
        this._clientId = null;
        /** The name of the client using this app */
        this._clientName = null;
        /** Used after initial launch of the app to show a new design modal */
        this._hasSeenNewDesignModal = null;
        /** Determines if app state API can be called */
        this._canSaveAppState = null;
        /** Mixpanel key to identify prod or lower environments */
        this._mixpanelKey = null;
        /** Local cache of viewed Appraisal documents */
        this._viewedAppraisalDocIds = null;
        /** When user lands on the Clover*/
        this._landingUrl = null;
        /** Determine if the email page and security question page should be shown or not */
        this._coborrowerAccount = null;
        /** Used by the error interceptor to pass global error messages to app.component.html */
        this.error$ = new BehaviorSubject(null);
        if (environment.production) {
            if (this.isBrowser && window.sessionStorage.getItem(this.localProp)) {
                this.sessionStorage = this.settingsRestore(window.sessionStorage.getItem(this.localProp));
            }
            if (this.isBrowser && window.localStorage.getItem(this.localProp)) {
                this.localStorage = this.settingsRestore(window.localStorage.getItem(this.localProp));
            }
        }
        else {
            this.sessionStorage = {};
            this.localStorage = {};
        }
    }
    Object.defineProperty(AppSettings.prototype, "mixpanelKey", {
        /** Mixpanel Key */
        get: function () {
            return this._mixpanelKey || this.propGet(AppSettingsProps.mixpanelKey);
        },
        set: function (value) {
            this._mixpanelKey = value;
            this.propSet(AppSettingsProps.mixpanelKey, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSettings.prototype, "urla", {
        /** Urla version */
        get: function () {
            return this._urla != null ? this._urla : JSON.parse(this.propGet(AppSettingsProps.urla));
        },
        set: function (value) {
            this._urla = value;
            this.propSet(AppSettingsProps.urla, JSON.stringify(value));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSettings.prototype, "ui", {
        /** UI State */
        get: function () {
            return this._ui || this.propGet(AppSettingsProps.ui);
        },
        set: function (value) {
            this._ui = value;
            this.propSet(AppSettingsProps.ui, value);
        },
        enumerable: true,
        configurable: true
    });
    /** Get the UI state directly from localstorage and not from memory */
    AppSettings.prototype.uiState = function () {
        // Reload session and localstorage
        this.sessionStorage = this.settingsRestore(window.sessionStorage.getItem(this.localProp));
        this.localStorage = this.settingsRestore(window.localStorage.getItem(this.localProp));
        return this.propGet(AppSettingsProps.ui);
    };
    Object.defineProperty(AppSettings.prototype, "branchId", {
        /** Branch Id */
        get: function () {
            return this._branchId || this.propGet(AppSettingsProps.branchId);
        },
        set: function (value) {
            this._branchId = value;
            this.propSet(AppSettingsProps.branchId, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSettings.prototype, "config", {
        /** Configuration */
        get: function () {
            if (this._config)
                return this._config;
            var config = this.propGet(AppSettingsProps.config);
            try {
                return JSON.parse(config);
            }
            catch (e) {
                // stored config was prior to settings typing, reset it
                this.propSet(AppSettingsProps.config, null);
                return null;
            }
        },
        set: function (value) {
            this._config = value;
            this.propSet(AppSettingsProps.config, JSON.stringify(value));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSettings.prototype, "apiUrl", {
        /** API URL location */
        get: function () {
            return this._apiUrl || this.propGet(AppSettingsProps.apiUrl);
        },
        set: function (value) {
            this._apiUrl = value;
            this.propSet(AppSettingsProps.apiUrl, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSettings.prototype, "token", {
        /** API token */
        get: function () {
            return this._token || this.propGet(AppSettingsProps.token, 'sessionStorage');
        },
        set: function (value) {
            this._token = value;
            this.propSet(AppSettingsProps.token, value, 'sessionStorage');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSettings.prototype, "loanId", {
        /** Loan ID */
        get: function () {
            return this._loanId || this.propGet(AppSettingsProps.loanId);
        },
        set: function (value) {
            this._loanId = value;
            this.propSet(AppSettingsProps.loanId, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSettings.prototype, "loanApplicationId", {
        /** Loan ID */
        get: function () {
            return this._loanApplicationId || this.propGet(AppSettingsProps.loanApplicationId);
        },
        set: function (value) {
            this._loanApplicationId = value;
            this.propSet(AppSettingsProps.loanApplicationId, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSettings.prototype, "userId", {
        /** User ID */
        get: function () {
            return this._userId || this.propGet(AppSettingsProps.userId);
        },
        set: function (value) {
            this._userId = value;
            this.propSet(AppSettingsProps.userId, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSettings.prototype, "loUserId", {
        /** Loan officer User ID */
        get: function () {
            return this._loUserId || this.propGet(AppSettingsProps.loUserId);
        },
        set: function (value) {
            this._loUserId = value;
            this.propSet(AppSettingsProps.loUserId, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSettings.prototype, "loUserIdOriginal", {
        /** Original Loan officer User ID on login */
        get: function () {
            return this._loUserIdOriginal || this.propGet(AppSettingsProps.loUserIdOriginal, 'sessionStorage');
        },
        set: function (value) {
            this._loUserIdOriginal = value;
            this.propSet(AppSettingsProps.loUserIdOriginal, value, 'sessionStorage');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSettings.prototype, "lsidUserId", {
        /** Temporary User ID used for registering new accounts */
        get: function () {
            return this._lsidUserId || this.propGet(AppSettingsProps.temporaryUserId);
        },
        set: function (value) {
            this._lsidUserId = value;
            this.propSet(AppSettingsProps.temporaryUserId, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSettings.prototype, "userName", {
        /** UserName */
        get: function () {
            return this._userName || this.propGet(AppSettingsProps.userName);
        },
        set: function (value) {
            this._userName = value;
            this.propSet(AppSettingsProps.userName, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSettings.prototype, "userFullName", {
        /** UserFullName */
        get: function () {
            return this._userFullName || this.propGet(AppSettingsProps.userFullName);
        },
        set: function (value) {
            this._userFullName = value;
            this.propSet(AppSettingsProps.userFullName, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSettings.prototype, "lsid", {
        /** LSID */
        get: function () {
            return this._lsid || this.propGet(AppSettingsProps.lsid);
        },
        set: function (value) {
            this._lsid = value;
            this.propSet(AppSettingsProps.lsid, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSettings.prototype, "isDefaultCorporateLeadSource", {
        /** LSID */
        get: function () {
            return this._isDefaultCorporateLeadSource != null
                ? this._isDefaultCorporateLeadSource
                : JSON.parse(this.propGet(AppSettingsProps.isDefaultCorporateLeadSource));
        },
        set: function (value) {
            this._isDefaultCorporateLeadSource = value;
            this.propSet(AppSettingsProps.isDefaultCorporateLeadSource, JSON.stringify(value));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSettings.prototype, "borrowerId", {
        /** Borrower ID */
        get: function () {
            return this._borrowerId || this.propGet(AppSettingsProps.borrowerId);
        },
        set: function (value) {
            this._borrowerId = value;
            this.propSet(AppSettingsProps.borrowerId, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSettings.prototype, "clientId", {
        /** Client Id */
        get: function () {
            return this._clientId || this.propGet(AppSettingsProps.clientId);
        },
        set: function (value) {
            this._clientId = value;
            this.propSet(AppSettingsProps.clientId, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSettings.prototype, "clientName", {
        /** Client Name */
        get: function () {
            return this._clientName || this.propGet(AppSettingsProps.clientName);
        },
        set: function (value) {
            this._clientName = value;
            this.propSet(AppSettingsProps.clientName, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSettings.prototype, "hasSeenNewDesignModal", {
        /** Should show the new design modal */
        get: function () {
            return this._hasSeenNewDesignModal || this.propGet(AppSettingsProps.hasSeenNewDesignModal);
        },
        set: function (value) {
            this._hasSeenNewDesignModal = value;
            this.propSet(AppSettingsProps.hasSeenNewDesignModal, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSettings.prototype, "canSaveAppState", {
        /** Determines if app state API can be called */
        get: function () {
            return this._canSaveAppState != null
                ? this._canSaveAppState
                : JSON.parse(this.propGet(AppSettingsProps.canSaveAppState));
        },
        set: function (value) {
            this._canSaveAppState = value;
            this.propSet(AppSettingsProps.canSaveAppState, JSON.stringify(value));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSettings.prototype, "version", {
        /** App version */
        get: function () {
            return this._version || this.propGet(AppSettingsProps.version);
        },
        set: function (value) {
            this._version = value;
            this.propSet(AppSettingsProps.version, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSettings.prototype, "coborrowerAccount", {
        get: function () {
            return this._coborrowerAccount;
        },
        set: function (value) {
            this._coborrowerAccount = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSettings.prototype, "viewedAppraisalDocIds", {
        /** App version */
        get: function () {
            return this._viewedAppraisalDocIds || JSON.parse(this.propGet(AppSettingsProps.viewedAppraisalDocIds)) || [];
        },
        set: function (value) {
            this._viewedAppraisalDocIds = value;
            this.propSet(AppSettingsProps.viewedAppraisalDocIds, JSON.stringify(value));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSettings.prototype, "landingUrl", {
        get: function () {
            return this._landingUrl || this.propGet(AppSettingsProps.landingUrl);
        },
        set: function (url) {
            this._landingUrl = url;
            this.propSet(AppSettingsProps.landingUrl, url);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Clear session storage
     */
    AppSettings.prototype.sessionStorageClear = function () {
        if (this.isBrowser) {
            window.sessionStorage.clear();
        }
    };
    /**
     * Return a property. Loads it from this service first if available, if not looks in localstorage, if not there either return null
     * @param prop - App settings property
     * @param location - Location of locally stored prop, either sessionStorage or localStorage
     */
    AppSettings.prototype.propGet = function (propKey, location) {
        if (location === void 0) { location = 'localStorage'; }
        if (this.isBrowser) {
            try {
                if (location === 'sessionStorage' && this.sessionStorage[propKey]) {
                    return this.sessionStorage[propKey];
                }
                else if (this.localStorage[propKey]) {
                    return this.localStorage[propKey];
                }
            }
            catch (err) {
                console.log(err);
                window.sessionStorage.clear();
                window.localStorage.clear();
            }
        }
        return null;
    };
    /**
     * Set an app settings property. Write to localstorage if present, delete from localstorage if null
     * @param prop - App settings property
     * @param location - Location of locally stored prop, either sessionStorage or localStorage
     */
    AppSettings.prototype.propSet = function (propKey, value, location) {
        if (location === void 0) { location = 'localStorage'; }
        if (this.isBrowser) {
            if (location === 'sessionStorage') {
                this.sessionStorage[propKey] = value;
                try {
                    window.sessionStorage.setItem(this.localProp, this.settingsSave(this.sessionStorage));
                }
                catch (err) {
                    console.log(err);
                }
            }
            else {
                this.localStorage[propKey] = value;
                try {
                    window.localStorage.setItem(this.localProp, this.settingsSave(this.localStorage));
                }
                catch (err) {
                    console.log(err);
                }
            }
        }
    };
    /**
     * Return an object that has been obfuscated into a string
     * @param state
     */
    AppSettings.prototype.settingsSave = function (state) {
        try {
            if (state) {
                var str = JSON.stringify(state);
                if (environment.settings.obfuscate) {
                    str = StringUtils.pad(str, this.pad, this.pad);
                    str = StringUtils.obfuscateAdd(str);
                }
                return str;
            }
        }
        catch (err) {
            return null;
        }
    };
    /**
     * Return an object that has been de-obfuscated
     * @param state
     */
    AppSettings.prototype.settingsRestore = function (state) {
        try {
            if (state) {
                var str = state;
                if (environment.settings.obfuscate) {
                    str = StringUtils.obfuscateRemove(state);
                    str = StringUtils.trim(str, this.pad, this.pad);
                }
                var obj = JSON.parse(str);
                return obj;
            }
        }
        catch (err) {
            return null;
        }
    };
    return AppSettings;
}());
export { AppSettings };
