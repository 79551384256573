import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, ElementRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { objectTraverser } from 'src/app/features/cvFormBuilder/shared/utils/objectTraverser.utils';
import { tap } from 'rxjs/operators';
import { CPOSdateTypeEnum } from 'src/app/shared/models';
import { LoanModelService } from 'src/app/routes/application/shared/services/loan-model.service';
import {
  isLeapYear,
  isStartDateValid,
  isEndDateValid,
  isValidDobPurchaseDate,
  isProjectedEndOfServiceValid,
  isAgeLessThan18,
  isPreferredDateValid
 } from 'src/app/routes/application/shared/utils/dates.util';

@Component({
  selector: 'app-date-control',
  templateUrl: './date-control.component.html',
  styleUrls: ['./date-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateControlComponent implements OnInit, OnDestroy {

  @Input() element: CvFormBuilder.Feature;
  @Input() form: FormGroup;
  @Input() indexes: FormGroup;
  @Input() formControlRef: FormControl;

  @ViewChild('month') monthEl: ElementRef;
  @ViewChild('day') dayEl: ElementRef;
  @ViewChild('year') yearEl: ElementRef;

  public fullName: string;
  public dateFormGroup: FormGroup;
  public dateFormControl: FormControl;

  constructor(private ref: ChangeDetectorRef, private loanModelService: LoanModelService) { }

  ngOnInit() {
    if (this.element.data.dateType === CPOSdateTypeEnum.appraisal) {
      this.dateFormControl = this.formControlRef;
    } else {
      this.dateFormControl = objectTraverser(this.element.fields[0].field, this.form, this.indexes.value, 'controls');
      
      if (this.element && this.element.data) {
        const firstName = this.element.data.firstName ? objectTraverser(this.element.data.firstName, this.form, this.indexes.value, 'controls').value : null;
        const lastName = this.element.data.lastName ? objectTraverser(this.element.data.lastName, this.form, this.indexes.value, 'controls').value : null;
        this.fullName = `${firstName ? firstName + ' ' : ''}${lastName ? lastName : ''}`;
      }
    }

    const date = this.dateFormControl.value ? this.getDateObj(this.dateFormControl.value) : null;

    // Create the Form Group
    this.dateFormGroup = new FormGroup({
      month: new FormControl((date && !isNaN(date.month)) ? `${('0' + date.month).slice(-2) }` : null, this.monthValidation.bind(this)),
      day: new FormControl((date && !isNaN(date.day)) ? `${ ('0' + date.day).slice(-2) }` : null, this.dayValidation.bind(this)),
      year: new FormControl((date && !isNaN(date.year)) ? date.year : null, this.yearValidation.bind(this))
    }, this.dateValidator.bind(this));


    // Check if the date FormControl is Valid or not and update the date FormGroup status
    if (this.isRequired && (!this.dateFormControl.value || this.dateFormControl.value === '')) {
      this.dateFormControl.setErrors({ 'invalid': true });
      this.dateFormGroup.setErrors({ 'invalid': true });
    } else {
      this.dateFormControl.setErrors(null);
      this.dateFormGroup.setErrors(null);
    }

    // Check the value changes in Form Group and update the date FormControl status
    this.dateFormGroup.valueChanges.pipe(
      tap((dateObj: any) => {

        for (const key in dateObj) {
          if (!dateObj[key] || dateObj[key] === 0) {
            dateObj[key] = null;
          }
        }

        // Check if date is not required but only some fields are provided not all of them
        if (!this.isRequired && !this.allFieldsAreProvided(dateObj)) {
          this.dateFormGroup.setErrors({ 'invalid': true });
        }

        // If date FormGroup is valid patch the value
        this.dateFormControl.patchValue(this.getDateStringFormat(dateObj.month, dateObj.day, dateObj.year));

        if (!this.dateFormGroup.valid) {
          this.dateFormControl.setErrors({ 'invalid': true });
        } else {
          this.dateFormControl.setErrors(null);
        }
      })
    ).subscribe();

    // When the Next button is clicked we should check if there's any field which is required and not provided
    this.dateFormControl.statusChanges.pipe(
      tap(() => {
        if (
              !this.dateFormGroup.valid &&
              this.dateFormControl.touched && 
              this.isRequired && 
              !this.dateFormGroup.get('month').value &&
              !this.dateFormGroup.get('day').value &&
              !this.dateFormGroup.get('year').value) {
          this.monthFormControl.markAsTouched();
          this.dayFormControl.markAsTouched();
          this.yearFormControl.markAsTouched();
          this.dateFormGroup.setErrors({ 'showDateIsRequired': true });
          this.ref.markForCheck();
        } else if (this.dateFormControl.touched && !this.isRequired && !this.isOptionalDateValid) {
          this.dateFormGroup.setErrors({ 'invalidOptionalDate': true });
          this.ref.markForCheck();
        }
      })
    ).subscribe();

    // Check the value changes in month Form Control
    this.dateFormGroup.get('month').valueChanges.pipe(
      tap((value) => {
        if (value && !isNaN(value) && value !== '') {
          let monthValue = value.trim().replace(/\D/g, '');
          if (monthValue.length > 2) {
            monthValue = monthValue.replace(monthValue.substring(2), '');
          }
          this.dateFormGroup.get('month').patchValue(monthValue, {emitEvent: false});
          this.monthFn(monthValue);
        } else {
          this.dateFormGroup.get('month').patchValue(null, {emitEvent: false});
        }
      })
    ).subscribe();

    // Check the value changes in day Form Control
    this.dateFormGroup.get('day').valueChanges.pipe(
      tap((value) => {
        if (value && !isNaN(value) && value !== '') {
          let dayValue = value.trim().replace(/\D/g, '');
          if (dayValue.length > 2) {
            dayValue = dayValue.replace(dayValue.substring(2), '');
          }
          this.dateFormGroup.get('day').patchValue(dayValue, {emitEvent: false});
          this.dayFn(dayValue);
        } else {
          this.dateFormGroup.get('day').patchValue(null, {emitEvent: false});
        }
        
      })
    ).subscribe();

    // Check the value changes in year Form Control
    this.dateFormGroup.get('year').valueChanges.pipe(
      tap((value) => {
        if (value && !isNaN(value) && value !== '') {
          let yearValue = value.trim().replace(/\D/g, '');
          if (yearValue.length > 4) {
            yearValue = yearValue.replace(yearValue.substring(4), '');
          }
          this.dateFormGroup.get('year').patchValue(yearValue, {emitEvent: false});
          this.yearFn(yearValue);
        } else {
          this.dateFormGroup.get('year').patchValue(null, {emitEvent: false});
        }

        this.dateFormGroup.get('day').updateValueAndValidity();
      })
    ).subscribe();

    if (this.element.data.dateType === CPOSdateTypeEnum.end) {
      const startDateFormControl = objectTraverser(this.element.data.startDate, this.form, this.indexes.value, 'controls');
      startDateFormControl.valueChanges.pipe(
        tap(() => {
          const endDateValid = this.dateFormGroup.validator(this.dateFormGroup);
          this.dateFormGroup.setErrors(endDateValid ? { 'invalidEndDate': true } : null);
          this.ref.markForCheck();
        })
      ).subscribe();
    }
  }

  get requiredErrorPosition(): string {
    if (this.element && this.element.data) {
      if (this.element.data.dateType === CPOSdateTypeEnum.appraisal) {
        return 'col text-left';
      } else {
        return 'col text-center';
      }
    } else {
      return 'col text-left';
    }
  }

  get lessThan18ErrorMsg(): string {
    return (this.fullName && this.fullName !== '') ? 
        `${this.fullName}, to qualify for a loan, you must be 18 years or older.` :
        'To qualify for a loan, you must be 18 years or older.';
  }

  get dateType(): string {
    switch (this.element.data.dateType) {
      case CPOSdateTypeEnum.start:
        return 'Start Date';
      case CPOSdateTypeEnum.end:
        return 'End Date';
      case CPOSdateTypeEnum.dob:
        return 'Date of Birth';
      case CPOSdateTypeEnum.originalPurchaseDate:
        return 'Purchase Date';
      case CPOSdateTypeEnum.appraisal:
        return 'Preferred Date';
      default:
        return '';
    }
  }

  get isRequired(): boolean {
    switch (this.element.data.dateType) {
      case CPOSdateTypeEnum.dob:
        return this.loanModelService.isDobRequired();
      case CPOSdateTypeEnum.appraisal:
        return this.element.data.required;
      default:
        return this.element.fields[0].validators.required; 
    }
  }

  get dayFormControl(): AbstractControl {
    return this.dateFormGroup.get('day');
  }

  get monthFormControl(): AbstractControl {
    return this.dateFormGroup.controls.month;
  }

  get yearFormControl(): AbstractControl {
    return this.dateFormGroup.controls.year;
  }

  get isYearLessThan1900(): boolean {
    return this.dateFormGroup.get('year').value && 
           parseInt(this.dateFormGroup.get('year').value.length) === 4 && 
           parseInt(this.dateFormGroup.get('year').value) < 1900;
  }

  get isYearGreaterThan2100(): boolean {
    return this.dateFormGroup.get('year').value && 
           parseInt(this.dateFormGroup.get('year').value.length) === 4 && 
           parseInt(this.dateFormGroup.get('year').value) > 2099;
  }

  get yearHas4Digits(): boolean {
    return parseInt(this.dateFormGroup.get('year').value.length) === 4;
  }

  get allFieldsValid(): boolean {
    return !this.dateFormGroup.get('month').errors && 
           !this.dateFormGroup.get('day').errors && 
           !this.dateFormGroup.get('year').errors;
  }

  get daysNum(): number {
    return parseInt(this.dateFormGroup.get('day').value);
  }

  get monthName(): string {
    switch (parseInt(this.dateFormGroup.get('month').value)) {
      case 1:
        return 'January';
      case 2:
        return 'February';
      case 3:
        return 'March';
      case 4:
        return 'April';
      case 5:
        return 'May';
      case 6:
        return 'June';
      case 7:
        return 'July';
      case 8:
        return 'August';
      case 9:
        return 'September';
      case 10:
        return 'October';
      case 11:
        return 'November';
      case 12:
        return 'December';
      default:
        return 'This month';
    }
  }

  get moreThanMaxDaysInMonth(): boolean {
    return this.dateFormGroup.get('day').value > this.getMaxDaysInMonth();
  }

  get isOptionalDateValid(): boolean {
    let counter = 0;
    const keys = Object.keys(this.dateFormGroup.value);
    keys.forEach((key: string) => {
      if (!this.dateFormGroup.value[key]) {
        counter += 1;
      }
    });

    if (counter === 3 || counter === 0) {
      return true;
    }

    return false;
  }


  private getMaxDaysInMonth(): number {
    const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    let maxDaysInMonth = daysInMonth[this.dateFormGroup.get('month').value - 1];
    if (this.dateFormGroup.get('year').value && parseInt(this.dateFormGroup.get('month').value) === 2 && isLeapYear(this.dateFormGroup.get('year').value)) {
      maxDaysInMonth = 29;
    }
    return maxDaysInMonth;
  }

  private getDateObj(date: string): { year: number, month: number, day: number } {
    // check if there's no -
    const dateArr = date.split('-').map(value => parseInt(value));
    return {
      year: dateArr[0],
      month: dateArr[1],
      day: dateArr[2]
    };
  }

  private getDateStringFormat(month: number, day: number, year: number): string {
    const dateStr = `${year ? year : ''}-${month ? ('0' + month).slice(-2) : ''}-${day ? ('0' + day).slice(-2) : ''}`;
    if (dateStr.replace('--', '') === '') {
      return null;
    }

    return dateStr;
  }

  private allFieldsAreProvided(dateObj: any): boolean {
    let counter = 0;
    const keys = Object.keys(dateObj);
    keys.forEach((key: string) => {
      if (dateObj[key]) {
        counter += 1;
      }
    });

    if (counter > 0  && counter < keys.length) {
      return false;
    }

    return true;
  }

  public onKeydownTabMonth(value: string, event: KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      this.dateFormGroup.get('month').patchValue(this.getMonthValueOnTabBlur(value));
      this.tabFn();
    }
  }

  public onBlurMonth(value: string) {
    if (value && value !== '') {
      this.dateFormGroup.get('month').patchValue(this.getMonthValueOnTabBlur(value), { emitEvent: false });
    } else {
      this.dateFormGroup.get('month').patchValue(null, { emitEvent: false });
    }

    this.dateFormGroup.get('day').updateValueAndValidity();
  }

  public onKeydownTabDay(value: string, event: KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      this.dateFormGroup.get('day').patchValue(this.getDayValueOnTabBlur(value));
      this.yearEl.nativeElement.focus();
    }
  }

  public onBlurDay(value: string) {
    if (value && value !== '') {
      this.dateFormGroup.get('day').patchValue(this.getDayValueOnTabBlur(value), { emitEvent: false });
    } else {
      this.dateFormGroup.get('day').patchValue(null, { emitEvent: false });
    }
  }

  public onKeydownYear(value: string, event: KeyboardEvent) {
    if (event.key === 'Tab' && value && parseInt(value) === 0) {
      this.dateFormGroup.get('year').patchValue(null);
    }
  }

  public onBlurYear(value: string) {
    if (value && parseInt(value) === 0) {
      this.dateFormGroup.get('year').patchValue(null);
    }
  }


  private getDayValueOnTabBlur(value: string): string {
    if (parseInt(value) > 3 || (parseInt(value) > 0 && value[0] === '0')) {
      return value;
    } else if (parseInt(value) > 0) {
      return `0${value}`;
    } else {
      return null;
    }
  }

  private getMonthValueOnTabBlur(value: string): string {
    if (value === '1') {
      return '01';
    } else if (parseInt(value) === 0) {
      return null;
    } else {
      return value;
    }
  }

  private tabFn() {
    this.dayEl.nativeElement.focus();
  }

  private monthFn(value: string): void {
    if (parseInt(value) > 9 && (parseInt(value) < 13) || value === '01') {
      this.tabFn();
    } else if (parseInt(value) > 1 && parseInt(value) < 10) {
      if (value[0] !== '0') {
        this.dateFormGroup.get('month').patchValue(`0${value}`, {emitEvent: false});
      }
      this.tabFn();
    }
  }

  private dayFn(value: string): void {
    if (parseInt(value) > 9 && parseInt(value) <= this.getMaxDaysInMonth()) {
      this.yearEl.nativeElement.focus();
    } else if (parseInt(value) > 3 && parseInt(value) < 10) {
      if (value[0] !== '0') {
        this.dateFormGroup.get('day').patchValue(`0${value}`, {emitEvent: false});
      }
      this.yearEl.nativeElement.focus();
    } else if (parseInt(value) > 0 && parseInt(value) < 4 && value[0] === '0') {
      this.yearEl.nativeElement.focus();
    }
  }

  private yearFn(value: string): void {
    if ((value.length && (parseInt(value[0]) > 2 || parseInt(value[0]) === 0)) ||
        (value.length > 1 && ((value[0] === '1' && parseInt(value[1]) < 9) || 
        (value[0] === '2' && parseInt(value[1]) > 0)))) {
    } else if (this.dateFormGroup.get('year').value && 
               this.dateFormGroup.get('year').valid &&
               this.dateFormGroup.get('month').value &&
               parseInt(this.dateFormGroup.get('month').value) === 2 && 
               !isLeapYear(this.dateFormGroup.get('year').value) &&
               parseInt(this.dateFormGroup.get('day').value) === 29) {
        this.dateFormGroup.get('day').patchValue(28, {emitEvent: false});
    }
  }

  private monthValidation(ac: AbstractControl): {[error: string]: boolean} {
    if (parseInt(ac.value) > 12 || parseInt(ac.value) === 0) {
      return { 'invalidMonth': true };
    }

    if (this.dateFormGroup) {
      this.dateFormGroup.get('day').setErrors(this.dayValidation(this.dateFormGroup.get('day')));
    }

    return null;
  }

  private dayValidation(ac: AbstractControl): { [error: string]: boolean } {
    if (this.dateFormGroup && (parseInt(ac.value) > this.getMaxDaysInMonth() || parseInt(ac.value) === 0)) {
      return { 'invalidDay': true };
    }
    if (this.dateFormGroup && this.dateFormGroup.get('year').value && 
        this.dateFormGroup.get('year').valid &&
        this.dateFormGroup.get('month').value &&
        parseInt(this.dateFormGroup.get('month').value) === 2 && 
        !isLeapYear(this.dateFormGroup.get('year').value) &&
        parseInt(ac.value) === 29) {
          return { 'invalidDay': true };
        }
    return null;
  }

  private yearValidation(ac: AbstractControl): { [error: string]: boolean } {
    if (this.dateFormGroup && ac.value) {
      if ((ac.value.length && (parseInt(ac.value[0]) > 2 || parseInt(ac.value[0]) === 0)) ||
                      (ac.value.length > 1 && ((ac.value[0] === '1' && parseInt(ac.value[1]) < 9) || 
                      (ac.value[0] === '2' && parseInt(ac.value[1]) > 0)))) {

          return { 'invalidYear': true };
      }

      this.dateFormGroup.get('day').setErrors(this.dayValidation(this.dateFormGroup.get('day')));
    }
    
    return null;
  }

  private dateValidator(ac: AbstractControl): {[s: string]: boolean} {

    const date: string = this.getDateStringFormat(ac.get('month').value, ac.get('day').value, ac.get('year').value);

    switch (this.element.data.dateType) {
      case CPOSdateTypeEnum.start:
        return isStartDateValid(date) ? null : { 'invalidStartDate': true };
      case CPOSdateTypeEnum.end:
        const startDate = objectTraverser(this.element.data.startDate, this.form, this.indexes.value, 'controls').value;
        return isEndDateValid(startDate, this.getDateStringFormat(ac.get('month').value, ac.get('day').value, ac.get('year').value)) ?
          null : { 'invalidEndDate': true };
      case CPOSdateTypeEnum.originalPurchaseDate:
        return isValidDobPurchaseDate(date, 125, 1900) ? null : { 'invalidPurchaseDate': true };
      case CPOSdateTypeEnum.dob:
        return isAgeLessThan18(date) ? null : { 'ageLessThan18': true };
      case CPOSdateTypeEnum.projectedEndOfService:
        return isProjectedEndOfServiceValid(date) ? null : { 'invalidProjectedEndOfServiceDate': true };
      case CPOSdateTypeEnum.appraisal:
        return isPreferredDateValid(date) ? null : { 'invalidAppraisalDate': true };
      default:
        console.error('date type is not specified.');
    }
  }

  ngOnDestroy() {
    if (!this.dateFormGroup.valid || (!this.isRequired && !this.isOptionalDateValid)) {
      this.dateFormControl.patchValue(null);
    }
  }
}
