import { OnInit, OnDestroy, OnChanges, ChangeDetectorRef, ElementRef, AfterViewInit, } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SlugPipe } from '$shared';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { autoCompleteSuggest, isRequired, isBrowserChrome, isBrowserFirefox, isBrowserIE, isBrowserMobile, inputMaskCreate, } from './utils/form-field.utils';
import { fromEvent } from 'rxjs';
/**
 * A component abstraction for mat-form-field. Adds better design and validation states
 * USAGE:
 * <app-form-field [formControlRef]="formLoan.controls.nameFirst" type="text" placeholder="First Name"></app-form-field>
 */
var FormFieldComponent = /** @class */ (function () {
    function FormFieldComponent(ref, slugPipe) {
        this.ref = ref;
        this.slugPipe = slugPipe;
        /** Angular materials appearance type */
        this.appearance = 'standard';
        /** Any css classes */
        this.class = '';
        this.parentClass = '';
        /** Is disabled */
        this.disabled = false;
        /** Form field type */
        this.type = 'text';
        /** If field type is text area, use this many columns */
        this.rows = 4;
        /** Show success icon when valid */
        this.showSuccess = false;
        /** Show error icon when invalid */
        this.showError = true;
        /** Show custom error message */
        this.errorCustom = false;
        /** Allow iconGroup radio buttons to be de-selectable */
        this.isDeselectable = false;
        /** Is this field required */
        this.required = false;
        /** Is the browser internet explorer? */
        this.isChrome = isBrowserChrome();
        /** Is the browser mobile? */
        this.isFirefox = isBrowserFirefox();
        /** Is the browser mobile? */
        this.isIE = isBrowserIE();
        /** Is the browser chrome? */
        this.isMobile = isBrowserMobile();
        /** Prevent infinite loops when updating currency controls */
        // private valueUpdating = false;
        /** Is this control loaded */
        this.loaded = false;
    }
    FormFieldComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Use text-field for date inputs for all browsers except desktop Chrome and Firefox
        if (this.type === 'date' && !((this.isChrome || this.isFirefox) && !this.isMobile)) {
            this.type = 'dateText';
        }
        // If name not supplied, autogenerate one from the placeholder
        if (!this.name && this.placeholder) {
            this.name = this.slugPipe.transform(this.placeholder);
        }
        // If autocomplete not supplied, try to guess the value based on the field name
        if (!this.autocomplete) {
            this.autocomplete = autoCompleteSuggest(this.formControlRef);
        }
        // Update the select label
        if (this.type === 'select') {
            this.formControlRef.valueChanges.pipe(untilDestroyed(this)).subscribe(function () {
                _this.labelCustom = _this.labelSelectCustomCreate();
            });
        }
        // Update the label for real date fields
        if (this.type === 'date') {
            this.labelCustom = this.placeholder + ': ' + this.formControlRef.value;
            this.formControlRef.valueChanges.pipe(untilDestroyed(this)).subscribe(function (date) {
                _this.labelCustom = _this.placeholder + ': ' + date;
                if (date && (_this.isChrome || _this.isFirefox)) {
                    // Fix bug with google chrome where it allows years with more than 4 characters
                    var dateArray = date.split('-');
                    if (dateArray[0] && dateArray[0].length > 4) {
                        dateArray[0] = dateArray[0].slice(0, 4);
                        _this.formControlRef.patchValue(dateArray.join('-'));
                    }
                }
            });
        }
        this.controlCustom = new FormControl(null);
        this.setInitialState();
    };
    FormFieldComponent.prototype.ngAfterViewInit = function () {
        // Disable input calendar dropdown for FF
        if (this.type === 'date') {
            this.fieldRef.nativeElement.addEventListener('click', function (event) { return event.preventDefault(); }, false);
        }
        // If form field type is custom control
        if (['currency', 'number', 'phoneNumber', 'ssn'].includes(this.type)) {
            this.controlCustom = inputMaskCreate(this.formControlRef, this.type, this, this.fieldRef ? fromEvent(this.fieldRef.nativeElement, 'keyup') : null, this.format);
            this.ref.detectChanges();
        }
        // Set date format for "date" text fields
        if (this.type === 'dateText') {
            this.controlCustom = inputMaskCreate(this.formControlRef, 'dateText', this, this.fieldRef ? fromEvent(this.fieldRef.nativeElement, 'keyup') : null);
            this.ref.detectChanges();
        }
        this.loaded = true;
    };
    FormFieldComponent.prototype.ngOnChanges = function () {
        if (this.loaded) {
            this.setInitialState();
        }
    };
    /**
     * Determine the initial state of this form field, IE set required and disabled flags
     */
    FormFieldComponent.prototype.setInitialState = function () {
        var _this = this;
        // Check if required, set required flag
        this.required = isRequired(this.formControlRef);
        // If this is a checkbox and it is required then the user is required to check to proceed
        if (this.required && this.type === 'checkbox') {
            // Watch value changes
            this.formControlRef.valueChanges.pipe(untilDestroyed(this)).subscribe(function (val) {
                // If checkbox value is true, remove errors, otherwise set errors
                if (val === true) {
                    _this.formControlRef.setErrors(null);
                }
                else {
                    _this.formControlRef.setErrors({ required: true });
                }
                // Set touched and fire change detection
                _this.formControlRef.markAsTouched();
                _this.ref.markForCheck();
            });
        }
        // Easily disable control
        if (this.disabled) {
            this.formControlRef.disable();
        }
        // Custom label for select
        if (this.type === 'select') {
            this.labelCustom = this.labelSelectCustomCreate();
        }
    };
    /**
     * Change type of input control
     * IE does not support this so check for that and ignore
     *
     * @param event
     * @param to
     */
    FormFieldComponent.prototype.inputChangeType = function (event, to) {
        if (!this.isIE) {
            event.target.type = to;
        }
    };
    /**
     * Speak the value of the current select box for screen readers
     */
    FormFieldComponent.prototype.labelSelectCustomCreate = function () {
        var _this = this;
        var label = this.placeholder;
        if (this.optionsLabel && this.options && this.options.length) {
            var optionSelected = this.options.filter(function (option) { return option.value === _this.formControlRef.value; })[0];
            if (optionSelected) {
                label += ': ' + optionSelected.label;
            }
        }
        else {
            label += ': ' + this.formControlRef.value;
        }
        return label;
    };
    /**
     * When the number control changes and a max length has been specified. Cap the number at the specified length
     * Chrome does not support maxLength for type=number inputs
     * @param event
     */
    FormFieldComponent.prototype.numberChange = function (event) {
        var val = event.target.value;
        if (this.maxlength && val.length > this.maxlength) {
            this.controlCustom.patchValue(parseInt(val.slice(0, this.maxlength)));
        }
    };
    FormFieldComponent.prototype.ngOnDestroy = function () { };
    Object.defineProperty(FormFieldComponent.prototype, "isInputmask", {
        get: function () {
            return !!this.type.match(/^mask-\S+$/);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormFieldComponent.prototype, "inputmask", {
        get: function () {
            return this.type.match(/^mask-(\S+)$/)[1];
        },
        enumerable: true,
        configurable: true
    });
    return FormFieldComponent;
}());
export { FormFieldComponent };
