import { take } from 'rxjs/operators';
import { UIStoreService } from '$ui';
import { ModalsService } from '$modals';
import * as i0 from "@angular/core";
import * as i1 from "../stores/ui/ui.store.service";
import * as i2 from "../../components/modals/modals.service";
var TermsAndPrivacyService = /** @class */ (function () {
    function TermsAndPrivacyService(ui, modals) {
        this.ui = ui;
        this.modals = modals;
        // config API
        this.config$ = this.ui.select.config$;
        this.setValues();
    }
    /**
     * Create variables from WordPress configuration
     */
    TermsAndPrivacyService.prototype.setValues = function () {
        var _this = this;
        this.config$.pipe(take(2)).subscribe(function (config) {
            _this.privacyUrl = config
                && config['Legal.PrivacyPolicy.URL.CS']
                && config['Legal.PrivacyPolicy.URL.CS'].value
                || '#';
            _this.privacyHtml = config
                && config['Legal.PrivacyPolicy.Manual.CS']
                && config['Legal.PrivacyPolicy.Manual.CS'].value
                || null;
            _this.termsUrl = config
                && config['Legal.TermsOfUse.URL.CS']
                && config['Legal.TermsOfUse.URL.CS'].value
                || '#';
            _this.termsHtml = config
                && config['Legal.TermsOfUse.Manual.CS']
                && config['Legal.TermsOfUse.Manual.CS'].value
                || null;
            _this.thirdPartyUrl = config
                && config['Legal.ThirdPartySuppliers.URL.CS']
                && config['Legal.ThirdPartySuppliers.URL.CS'].value
                || '#';
            _this.thirdPartyHtml = config
                && config['Legal.ThirdPartySuppliers.Manual.CS']
                && config['Legal.ThirdPartySuppliers.Manual.CS'].value
                || null;
        });
    };
    /**
     * View terms of use in modal or new window
     */
    TermsAndPrivacyService.prototype.viewTermsOfUse = function () {
        return this.openModalOrNewWindow(this.termsHtml, 'Terms of Use', this.termsUrl);
    };
    /**
     * View Privacy Policy in modal or new window
     */
    TermsAndPrivacyService.prototype.viewPrivacyPolicy = function () {
        return this.openModalOrNewWindow(this.privacyHtml, 'Privacy Policy', this.privacyUrl);
    };
    /**
     * View Third Party Suppliers in modal or new window
     */
    TermsAndPrivacyService.prototype.viewThirdPartySuppliers = function () {
        return this.openModalOrNewWindow(this.thirdPartyHtml, 'Third Party Suppliers', this.thirdPartyUrl);
    };
    /**
     * Generic function for opening a new window or modal
     * @param modalHtml HTML that will appear inside the modal
     * @param modalTitle The title of the modal
     * @param newWindowUrl The URL for the new window
     */
    TermsAndPrivacyService.prototype.openModalOrNewWindow = function (modalHtml, modalTitle, newWindowUrl) {
        if (modalHtml) {
            this.modals.open('HintModalComponent', false, 'lg', {
                title: modalTitle,
                body: modalHtml,
            });
        }
        else {
            window.open(newWindowUrl, '_blank');
        }
        return;
    };
    TermsAndPrivacyService.ngInjectableDef = i0.defineInjectable({ factory: function TermsAndPrivacyService_Factory() { return new TermsAndPrivacyService(i0.inject(i1.UIStoreService), i0.inject(i2.ModalsService)); }, token: TermsAndPrivacyService, providedIn: "root" });
    return TermsAndPrivacyService;
}());
export { TermsAndPrivacyService };
