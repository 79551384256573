import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-new-design-modal',
  templateUrl: './new-design-modal.component.html',
  styleUrls: ['./new-design-modal.component.scss'],
})
export class NewDesignModalComponent {
  constructor(
    public dialogRef: MatDialogRef<any>,
  ) {}

  /**
   * Submit the form
   */
  public submit() {
    this.dialogRef.close();
  }
}
