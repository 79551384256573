var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { Title } from '@angular/platform-browser';
// import { environment } from '$env';
import { Validators, FormBuilder, FormControl } from '@angular/forms';
import { BehaviorSubject, Subject, merge } from 'rxjs';
import { ApiService } from '$api';
import { debounceTime, switchMap, tap, startWith } from 'rxjs/operators';
import { AuthService, AppSettings } from '$shared';
import { guidsAdd } from '../utils/guidsAdd.util';
import { escape } from 'lodash';
import { mapFormBuilderToLoanModel } from '../utils/mapFormBuilderToLoan.util';
import { cloneDeep, isArray, isObject } from 'lodash';
import { defaultModels } from '../../shared/models.defaults';
import { LoanPurposeTypeEnum } from 'src/app/shared/models';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../shared/stores/api/api.store.service";
import * as i4 from "../../../../shared/services/auth.service";
import * as i5 from "../../../../shared/app.settings";
var ApplicationService = /** @class */ (function () {
    function ApplicationService(title, fb, api, auth, settings) {
        this.title = title;
        this.fb = fb;
        this.api = api;
        this.auth = auth;
        this.settings = settings;
        /** megaSave queue (1 item long) */
        this.loanToSave = null;
        /** Is a save currently in progress */
        this._isSaving = false;
        this.isSaving$ = new BehaviorSubject(false);
        this.dataFields$ = new BehaviorSubject({});
        this.dataFields = {};
        /** Any time a megasave completes, the api response will be passed into this subject  */
        this.megaSaveResponse$ = new Subject();
        /** Fires when all saving is complete including this.loanToSave  */
        this.saveComplete$ = new Subject();
        /** Used to prevent infinite patch loops with multiple valueChanges  */
        this.isChangingData = false;
        this.models = defaultModels;
    }
    Object.defineProperty(ApplicationService.prototype, "hasLoanQueued", {
        get: function () {
            return !!this.loanToSave;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApplicationService.prototype, "isSaving", {
        get: function () {
            return this._isSaving;
        },
        set: function (value) {
            this.isSaving$.next(value);
            this._isSaving = value;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Generate state for the 1003
     * @param sections
     */
    ApplicationService.prototype.createForm1003State = function (sections, appState, activePageId) {
        if (!sections) {
            return;
        }
        var sectionState = {};
        appState.form1003.state.forEach(function (section) { return (sectionState[section.sectionId] = section); });
        return sections.map(function (section) {
            var result = {
                sectionId: section.sectionId,
                lastPageId: section.isActive ? activePageId : null,
                isComplete: section.isComplete,
                isActive: section.isActive,
                hasSummary: section.hasSummary,
                title: section.title,
            };
            if (sectionState && sectionState[section.sectionId] && sectionState[section.sectionId].isComplete) {
                result.isComplete = true;
            }
            // Inherit lastpageId if not overridden by argument, use original
            if (!result.lastPageId && sectionState[section.sectionId] && sectionState[section.sectionId].lastPageId) {
                result.lastPageId = sectionState[section.sectionId].lastPageId;
            }
            return result;
        });
    };
    /**
     * Prepare loan for saving
     */
    ApplicationService.prototype.prepareLoanForSaving = function (form1003) {
        // Add guids to all models that are missing them
        // Add them to the reactive form to ensure they are only set once and do not change each save
        guidsAdd(form1003.get('loan'), this.api.guids);
        return mapFormBuilderToLoanModel(form1003.getRawValue().loan, this.models, this.settings.urla);
    };
    /**
     * Save the megaloan.
     * Supports queueing so only one loan can be saved at a time and if one is in queue it will save after the current one completes
     *
     * @param megaLoan
     */
    ApplicationService.prototype.megaLoanSave = function (megaLoan) {
        var _this = this;
        // Is a save currently in progress
        if (this.isSaving) {
            // Set this loan to save next
            this.loanToSave = cloneDeep(megaLoan);
        }
        else {
            this.isSaving = true;
            // Start loan saving
            var megaSave$ = this.api.megaLoan.save(megaLoan);
            // If closing date is missing or unset, but Loan Purpose IS set...
            if ((!(megaLoan
                && megaLoan.closingDate
                && megaLoan.closingDate.dateValue)
                || megaLoan.closingDate.dateValue === '0001-01-01T00:00:00')
                && !!megaLoan.loanPurposeType) {
                var loanPurpose = megaLoan.loanPurposeType === LoanPurposeTypeEnum.Purchase ? 'Purchase' : 'Refinance';
                megaSave$ = this.api.services.getClosingDate(loanPurpose).pipe(switchMap(function (closingDate) {
                    var updatedClosingDate = __assign({}, megaLoan.closingDate, { dateValue: closingDate.dateValue });
                    var updatedMegaLoan = __assign({}, megaLoan, { closingDate: updatedClosingDate });
                    return _this.api.megaLoan.save(updatedMegaLoan);
                }));
            }
            megaSave$.subscribe(function (megaLoanResponse) {
                // On complete
                _this.isSaving = false;
                _this.megaSaveResponse$.next(megaLoanResponse);
                // If a loan is queued, immediate start saving it
                if (_this.loanToSave) {
                    _this.megaLoanSave(_this.loanToSave);
                    _this.loanToSave = null; // After passing loan to method, set to null
                }
                else {
                    // Current loan completes and no loan is queued
                    _this.saveComplete$.next(true);
                }
            }, function (error) {
                // On error
                _this.isSaving = false;
                _this.saveComplete$.error(error);
                console.error('Megasave fail', error);
                if (error.status === 401) {
                    _this.auth.logOut();
                }
            });
        }
    };
    /**
     * Change the page title
     */
    ApplicationService.prototype.pageTitleUpdate = function (page, section) {
        var _this = this;
        var sanitizeText = function (text, keepPunctuation) {
            if (!text) {
                return text;
            }
            return keepPunctuation
                ? escape(text)
                : text.replace(/<(?:.|\n)*?>/gm, '').replace(/[.,\/#!$%\^&\*?;:{}=_`~()]/gi, '');
        };
        var title = '';
        if (page) {
            var pageTitle = sanitizeText(page.htmlTitle || page.title, page.htmlTitlePunctuation);
            if (pageTitle) {
                title += pageTitle;
            }
        }
        if (!!title) {
            title += ' - ';
        }
        if (section) {
            var sectionTitle = sanitizeText(section.htmlTitle || section.title, section.htmlTitlePunctuation);
            if (sectionTitle) {
                title += sectionTitle;
            }
        }
        // title += environment.properties.appName;
        // Add delay to override default title service in app.component
        setTimeout(function () { return _this.title.setTitle(title); });
    };
    /**
     * Load all the enumerations/datafields needed by a specific section
     * @param sectionId
     */
    ApplicationService.prototype.sectionGetDataFields = function (sectionId) {
        var _this = this;
        var dataFields = [];
        switch (sectionId) {
            case 'loan-purpose':
                dataFields = [140, 108];
                break;
            case 'property':
                dataFields = [89, 192, 219];
                break;
            case 'personal':
                dataFields = [42, 150, 219];
                break;
            case 'assets':
                dataFields = [26];
                break;
            case 'income':
                dataFields = [91, 219];
                break;
            case 'demographics':
                dataFields = [95, 196, 495, 496, 498];
                break;
        }
        // 150 - married
        // 179 - Home/Cell/work
        // 185 - primary residence/investment/second home
        // 219 - States
        // 199 - Base pay, overtime pay, bonuses, commissions
        // 154 - Military pay
        if (dataFields.length) {
            dataFields.forEach(function (num) { return _this.dataFieldsGet(num); });
        }
    };
    /**
     * Load a datafield into the dictionary
     * TODO: Flatten with combineLatest?
     * @param id
     */
    ApplicationService.prototype.dataFieldsGet = function (id) {
        var _this = this;
        //  | number[]
        var slug = 'df-';
        // const dfArray = Array.isArray(id) ? id : [id];
        if (this.dataFields[slug + id]) {
            return;
        }
        // Get datafield from web api
        this.api.dataFields.get(id).subscribe(function (res) {
            // Map to format needed by the dictionary
            var value = res
                .filter(function (val) {
                if (id === 26 && val.orderValue < 0) {
                    // filter out 'Not Required' for asset type
                    return false;
                }
                return true;
            })
                .map(function (val) {
                // Use text labels instead of values
                if ([219, 495, 496, 498].includes(id)) {
                    return __assign({}, val, { value: val.text, label: val.text });
                }
                else {
                    return __assign({}, val, { value: val.enumValue, label: val.text });
                }
                /**
              switch (id) {
                // States
                case 219:
                  return {
                    ...val,
                    value: val.text,
                    label: val.text,
                  };
                // Everything else
                default:
                  return {
                    ...val,
                    value: val.enumValue,
                    label: val.text,
                  };
              }
               */
            });
            // Add to dictionary
            _this.dataFieldsAdd(slug + id, value);
        });
        return this.dataFields$;
    };
    /**
     * Add a datafield entry to the dictionary and observable
     * @param id
     * @param value
     */
    ApplicationService.prototype.dataFieldsAdd = function (id, value, cache) {
        if (cache === void 0) { cache = true; }
        // console.log('dataFieldsAdd', id, value, cache);
        // If already exists, do nothing
        if (this.dataFields[id] && cache) {
            return;
        }
        var dataFields = __assign({}, this.dataFields);
        dataFields[id] = __spread(value);
        this.dataFields = dataFields;
        this.dataFields$.next(dataFields);
    };
    /**
     * Automatically generate a form group complete with controls. Will recurse through nested objects/arrays.
     * @param model - An object or JSON of the model. Can contain nested objects and arrays
     * @param defaultRequired - Should all fields be required. Default is false
     */
    ApplicationService.prototype.formGroupCreate = function (model, defaultRequired) {
        var _this = this;
        if (defaultRequired === void 0) { defaultRequired = false; }
        // console.log('formGroupCreate', model);
        var formModel = {};
        // Loop through all props in the model
        Object.keys(model).forEach(function (key) {
            // Delete props from loan modal that cause saving to fail
            if (typeof model[key] === 'object' && Array.isArray(model[key]) && !model[key].length) {
                // model[key] = null;
            }
            if (typeof model[key] === 'object' &&
                !Array.isArray(model[key]) &&
                model[key] &&
                !Object.keys(model[key]).length) {
                // model[key] = null;
            }
            if (key === 'interview' ||
                key === 'mainLoanApplication' ||
                key === 'propertyExpenses' ||
                key === 'closingDateHistories') {
                // model[key] = null;
            }
            if (model[key] === null) {
                // console.log('Deleting', key, model[key])
                // delete model[key];
            }
            // If this is a nested object, recurse to create form group
            if (model[key] && typeof model[key] === 'object' && !Array.isArray(model[key])) {
                formModel[key] = _this.formGroupCreate(model[key]);
            }
            else if (model[key] && typeof model[key] === 'object' && Array.isArray(model[key])) {
                // If this is an array, recurse to create a form array
                var formArray_1 = [];
                model[key].forEach(function (item) { return formArray_1.push(_this.formGroupCreate(item)); });
                formModel[key] = _this.fb.array(formArray_1);
            }
            else {
                // Standard value
                formModel[key] = defaultRequired ? [null, [Validators.required]] : [null, []];
            }
        });
        // If iterating inside an array of primitives, return a form control for the primitive
        if (typeof model === 'string' || typeof model === 'number' || typeof model === 'boolean') {
            return this.fb.control(null);
        }
        return this.fb.group(formModel);
    };
    // The FormGroup generated breaks certain fields defined in the sections, need to fix them
    ApplicationService.prototype.fixFormGroup = function (formGroup, sections) {
        var walk = function (object, cb) {
            var _walk = function (obj, path, parent) {
                cb(obj, path, parent);
                if (isArray(obj)) {
                    obj.forEach(function (value, index) { return _walk(value, __spread(path, [index]), obj); });
                }
                else if (isObject(obj)) {
                    Object.entries(obj).forEach(function (_a) {
                        var _b = __read(_a, 2), key = _b[0], value = _b[1];
                        return _walk(value, __spread(path, [key]), obj);
                    });
                }
            };
            _walk(object, [], null);
        };
        var forEachContent = function (obj, cb) {
            return walk(obj, function (o, p) {
                if (isArray(o) && p[p.length - 1] === 'content') {
                    o.forEach(function (c) { return cb(c); });
                }
            });
        };
        forEachContent(sections, function (c) {
            if (c.type === 'formField' && c.formFieldType === 'select' && c.multiple) {
                var _a = __read(c.field.match(/(.+)\.([^.]+)/), 3), parentName = _a[1], controlName = _a[2];
                var parent_1 = formGroup.get(parentName);
                var _b = formGroup.get(c.field), validator = _b.validator, asyncValidator = _b.asyncValidator, updateOn = _b.updateOn;
                parent_1.setControl(controlName, new FormControl({ validator: validator, asyncValidator: asyncValidator, updateOn: updateOn }));
            }
        });
    };
    /**
     * Generate a datafield from borrower information
     * @param form
     * @param subs
     */
    ApplicationService.prototype.borrowerDatafieldAdd = function (form, subs) {
        var _this = this;
        var borrowerPrimary = form
            .get('loan')
            .get('transactionInfo')
            .get('borrowers')
            .get('0');
        var borrowerSecondary = form
            .get('loan')
            .get('transactionInfo')
            .get('borrowers')
            .get('1');
        // When a borrower name changes, update borrowers datafield
        var borrowers = merge(borrowerPrimary.get('firstName').valueChanges, borrowerPrimary.get('lastName').valueChanges, borrowerSecondary.get('firstName').valueChanges, borrowerSecondary.get('lastName').valueChanges);
        subs.push(borrowers.pipe(debounceTime(250)).subscribe(function () {
            _this.dataFieldsAdd('borrowers', [
                {
                    value: borrowerPrimary.value.borrowerId,
                    label: borrowerPrimary.value.firstName + ' ' + borrowerPrimary.value.lastName,
                },
                {
                    value: borrowerSecondary.value.borrowerId,
                    label: borrowerSecondary.value.firstName + ' ' + borrowerSecondary.value.lastName,
                },
                {
                    value: 'joint',
                    label: 'Joint Account',
                },
            ], false);
        }));
    };
    /**
     * Handle all the one off form changes
     * @param loanForm
     * @param subs
     */
    ApplicationService.prototype.sideEffects = function (loanForm, subs) {
        // If a user changes their marital status from married/separated to unmarried, reset isSpouseOnTheLoan
        subs.push(loanForm.get('loan.transactionInfo.borrowers.0.maritalStatus').valueChanges.subscribe(function (val) {
            if (val === 2) {
                loanForm.get('loan.transactionInfo.loanApplications.0.isSpouseOnTheLoan').patchValue(false);
            }
        }));
        // Hold a record of assets organized by assetId
        // This record is used to check for changes to the original ownerId
        var assetsRecord = {};
        // Hold reference to assets form array
        var assetsControl = loanForm.get('loan.$$custom.loan.assets');
        subs.push(assetsControl.valueChanges.pipe(debounceTime(100), startWith(loanForm.get('loan.$$custom.loan.assets').value)).subscribe(function (assets) {
            // Loop through all assets
            assets.forEach(function (asset, i) {
                // Get previous asset record to compare against
                var assetPrev = assetsRecord[asset.assetId];
                // If the previous asset has a different asset than the new asset then the owner changed
                if (assetPrev && (assetPrev.ownerId !== asset.ownerId) && !asset.voaBalanceDate) {
                    var assetControl = assetsControl.get(String(i));
                    // If this form control does not exist, add it
                    if (!assetControl.get('previousOwnerId')) {
                        assetControl.addControl('previousOwnerId', new FormControl());
                    }
                    // Update previous owner to old owner
                    assetControl.get('previousOwnerId').patchValue(assetPrev.ownerId, { emitEvent: false });
                }
            });
            // Reset record for any changes
            assetsRecord = {};
            assetsControl.value.forEach(function (asset) { return (assetsRecord[asset.assetId] = asset); });
        }));
    };
    /**
     * Automatically prefill city/state when the zip code is filled out
     * Adds a subscriber to all zipcodes that uses a lookup service
     * TODO: Get rid of this function and use a feature component
     * @param loanForm
     */
    ApplicationService.prototype.zipCodeAutofill = function (loanForm, subs) {
        var _this = this;
        // Get all properties
        var propLocation = loanForm.get('loan.$$custom.loan');
        // Watch changes to primary borrower current address
        // If isSameAsPrimaryBorrowerCurrentAddress is set to true, update secondary borrower current address
        subs.push(propLocation.get('borrowerPrimary.addressCurrent').valueChanges.pipe(tap(function () {
            var coBorrowerSameAddress = loanForm.getRawValue().loan.$$custom.loan.borrowerSecondary
                .isAddressSameAsPrimaryBorrower;
            // const coBorrowerAddress = loanForm.getRawValue().loan.$$custom.loan.borrowerSecondary.addressCurrent;
            if (coBorrowerSameAddress) {
                _this.spouseAddressPatchValue(loanForm);
            }
        })).subscribe());
        // let incomeProps: CvEmployment[] = [];
        /**
         * Manage zip code lookup for incomes
         * Incomes is more complex because of a constantly changing array any
    
        propLocation.get('employments').valueChanges.subscribe((incomes: CvEmployment[]) => {
          // console.warn('incomeProps', incomeProps);
          incomes.forEach((income, i) => {
            if (
              income &&
              incomeProps[i] &&
              income.employerInfo.zip !== incomeProps[i].employerInfo.zip &&
              income.employerInfo.zip.length === 5
            ) {
              this.api.services.zipCodeLookup(income.employerInfo.zip, this.settings.loUserId).subscribe(res => {
                if (res) {
                  // The zip lookup response is necessary to check for servicable zip codes
                  if (!this.isChangingData) {
                    this.isChangingData = true;
                    const property = propLocation.get('employments').get(String(i));
                    property.get('employerInfo.city').patchValue(res.cityName, { emitEvent: false });
                    property.get('employerInfo.state').patchValue(res.stateName, { emitEvent: false });
                    setTimeout(() => (this.isChangingData = false), 300);
                  }
                }
              });
            }
          });
    
          incomeProps = incomes.map(income => {
            return {
              ...income,
            };
          });
        });
     */
        // Replace by custom zip component, ok to remove after confirming no regressions there
        /**
        const properties = [
          propLocation.get('addressSubject'),
          propLocation.get('borrowerPrimary').get('addressCurrent'),
          propLocation.get('borrowerPrimary').get('addressMailing'),
          propLocation.get('borrowerPrimary').get('addressHistory'),
          propLocation.get('borrowerSecondary').get('addressCurrent'),
          propLocation.get('borrowerSecondary').get('addressMailing'),
          propLocation.get('borrowerSecondary').get('addressHistory'),
        ];
    
        // Loop through all props
        properties.forEach(property => {
    
          // When state name changes, empty out zipcode and city name
          subs.push(
            property
              .get('stateName')
              .valueChanges.pipe(
                debounceTime(300),
                distinctUntilChanged(),
              )
              .subscribe(() => {
                console.warn('test');
                if (!this.isChangingData) {
                  this.isChangingData = true;
                  property.get('zipCode').setValue(null);
                  // property.get('cityName').setValue(null);
                  // property.get('countyName').setValue(null);
                  setTimeout(() => (this.isChangingData = false), 300);
                }
              }),
          );
           */
        /**
          // When zip code changes, pull lookup service and patch in results
          subs.push(
            property
              .get('zipCode')
              .valueChanges.pipe(
                debounceTime(300),
                filter(zipcode => (zipcode && zipcode.length === 5)),
              )
              .subscribe(zipcode => {
                this.api.services.zipCodeLookup(zipcode, this.settings.loUserIdOriginal).subscribe(res => {
                  if (res) {
                    // TODO: Add some defensive coding around checking for subject address in different state than current address when reloading app and current address already filled out
                    // The zip lookup response is necessary to check for servicable zip codes
                    if (!this.isChangingData) {
                      this.isChangingData = true;
                      loanForm
                        .get('loan')
                        .get('$$custom')
                        .get('zipLookupResponse')
                        .patchValue(res);
                      property.get('cityName').patchValue(res.cityName, { emitEvent: false });
                      property.get('stateName').patchValue(res.stateName);
                      property.get('countyName').patchValue(res.countyName, { emitEvent: false });
                      setTimeout(() => (this.isChangingData = false), 300);
                    }
                  }
                });
              }),
          );
    
        });
         */
    };
    /**
     * Automatically prefill city/state when the zip code is filled out
     * Adds a subscriber to all zipcodes that uses a lookup service
     * @param loanForm
     */
    ApplicationService.prototype.financialInstutionAutoFill = function (loanForm, subs) {
        // Get all properties
        var borrowers = loanForm
            .get('loan')
            .get('transactionInfo')
            .get('borrowers');
        borrowers.controls.forEach(function (borrower) {
            var assets = borrower.get('assets');
            console.log('borrower', assets);
            subs.push(assets.valueChanges.subscribe(function (res) {
                console.log('Changes to assets', res);
            }));
        });
    };
    /**
     * Manages the spousal current address toggle
     * @param loanForm
     * @param subs
     */
    ApplicationService.prototype.spouseAddressAutoFill = function (loanForm, subs) {
        var _this = this;
        // Get is same address property
        var isSameAddress = loanForm.get('loan.$$custom.loan.borrowerSecondary.isAddressSameAsPrimaryBorrower');
        // Get secondary borrower prop
        var spouseAddress = loanForm.get('loan.$$custom.loan.borrowerSecondary.addressCurrent');
        // When the same address prop changes
        subs.push(isSameAddress.valueChanges.subscribe(function (val) {
            if (val) {
                // Patch primary borrower address in and disable control
                _this.isChangingData = true;
                _this.spouseAddressPatchValue(loanForm);
                spouseAddress.disable();
                setTimeout(function () { return (_this.isChangingData = false); }, 300);
            }
            else {
                spouseAddress.enable();
            }
        }));
        // we registered a change handler... but we need to actually initialize the fields
        isSameAddress.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    };
    ApplicationService.prototype.spouseAddressPatchValue = function (loanForm) {
        // Get primary borrower prop
        var borrowerAddress = loanForm
            .get('loan')
            .get('$$custom')
            .get('loan')
            .get('borrowerPrimary')
            .get('addressCurrent');
        // Get secondary borrower prop
        var spouseAddress = loanForm
            .get('loan')
            .get('$$custom')
            .get('loan')
            .get('borrowerSecondary')
            .get('addressCurrent');
        var borrowerAddressValue = borrowerAddress.value;
        var spouseAddressValue = loanForm.getRawValue().loan.$$custom.loan.borrowerSecondary.addressCurrent;
        spouseAddress.patchValue(__assign({}, spouseAddressValue, { streetName: borrowerAddressValue.streetName, unitNumber: borrowerAddressValue.unitNumber, cityName: borrowerAddressValue.cityName, stateName: borrowerAddressValue.stateName, zipCode: borrowerAddressValue.zipCode, countyName: borrowerAddressValue.countyName }), { emitEvent: false });
    };
    /**
     * Manage logic toggle SSNs
     * @param loanForm
     * @param subs
     */
    ApplicationService.prototype.ssnValidation = function (loanForm, subs) {
        var ssn1 = loanForm.get('loan.$$custom.ssn');
        var ssn1Confirm = loanForm.get('loan.transactionInfo.borrowers.0.ssn');
        var creditAuth = loanForm.get('loan.transactionInfo.loanApplications.0.isRunCreditAuthorized');
        var eVoiVoeAuth = loanForm.get('loan.$$custom.isRunEvoiEvoeAuthorized');
        var ssn2 = loanForm.get('loan.transactionInfo.borrowers.1.ssn');
        var ssn2Confirm = loanForm.get('loan.$$custom.ssnSpouse');
        var creditAuth2 = loanForm.get('loan.$$custom.isRunCreditAuthorizedSpouse');
        var eVoiVoeAuth2 = loanForm.get('loan.$$custom.isRunEvoiEvoeAuthorizedSpouse');
        creditAuth.disable();
        creditAuth2.disable();
        eVoiVoeAuth.disable();
        eVoiVoeAuth2.disable();
        // On load, determine whether or not credit should be enabled or not
        if (ssn1.value && ssn1.value.length === 9 && ssn1.value === ssn1Confirm.value) {
            creditAuth.enable();
            eVoiVoeAuth.enable();
            eVoiVoeAuth.markAsUntouched();
        }
        if (ssn2.value && ssn2.value.length === 9 && ssn2.value === ssn2Confirm.value) {
            creditAuth2.enable();
            eVoiVoeAuth2.enable();
            eVoiVoeAuth2.markAsUntouched();
        }
        subs.push(merge(ssn1.valueChanges, ssn1Confirm.valueChanges).subscribe(function () {
            if (ssn1.value && ssn1.value.length === 9 && ssn1.value === ssn1Confirm.value) {
                creditAuth.enable(); // If both SSN's match, enable control
                eVoiVoeAuth.enable();
                eVoiVoeAuth.markAsUntouched();
            }
            else {
                creditAuth.disable(); // Keep disabled if ssn's do not match
                // On any ssn changes, reset credit auth
                creditAuth.patchValue(null);
                eVoiVoeAuth.disable();
                eVoiVoeAuth.patchValue(null);
            }
        }), merge(ssn2.valueChanges, ssn2Confirm.valueChanges).subscribe(function () {
            if (ssn2.value && ssn2.value.length === 9 && ssn2.value === ssn2Confirm.value) {
                creditAuth2.enable(); // If both SSN's match, enable control
                eVoiVoeAuth2.enable();
                eVoiVoeAuth2.markAsUntouched();
            }
            else {
                creditAuth2.disable(); // Keep disabled if ssn's do not match
                // On any ssn changes, reset credit auth
                creditAuth2.patchValue(null);
                eVoiVoeAuth2.disable();
                eVoiVoeAuth2.patchValue(null);
            }
        }));
    };
    ApplicationService.ngInjectableDef = i0.defineInjectable({ factory: function ApplicationService_Factory() { return new ApplicationService(i0.inject(i1.Title), i0.inject(i2.FormBuilder), i0.inject(i3.ApiService), i0.inject(i4.AuthService), i0.inject(i5.AppSettings)); }, token: ApplicationService, providedIn: "root" });
    return ApplicationService;
}());
export { ApplicationService };
