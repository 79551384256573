import { Router } from '@angular/router';
import { AppSettings } from '../app.settings';
import * as i0 from "@angular/core";
import * as i1 from "../app.settings";
import * as i2 from "@angular/router";
var RegisterGuard = /** @class */ (function () {
    function RegisterGuard(settings, router) {
        this.settings = settings;
        this.router = router;
    }
    RegisterGuard.prototype.canActivate = function (route) {
        if (this.settings && this.settings.clientId && this.settings.clientId.toLowerCase() !== 'arv') {
            return true;
        }
        this.router.navigateByUrl('/login', { queryParams: route.queryParams, queryParamsHandling: 'preserve' });
        return false;
    };
    RegisterGuard.ngInjectableDef = i0.defineInjectable({ factory: function RegisterGuard_Factory() { return new RegisterGuard(i0.inject(i1.AppSettings), i0.inject(i2.Router)); }, token: RegisterGuard, providedIn: "root" });
    return RegisterGuard;
}());
export { RegisterGuard };
