/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/expansion";
import * as i3 from "../../../../node_modules/@angular/material/expansion/typings/index.ngfactory";
import * as i4 from "@angular/cdk/collections";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "./error.component";
var styles_ErrorComponent = [i0.styles];
var RenderType_ErrorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorComponent, data: {} });
export { RenderType_ErrorComponent as RenderType_ErrorComponent };
function View_ErrorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close float-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.error = null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"]))], null, null); }
function View_ErrorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "d-inline"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["An error has occurred."]))], null, null); }
function View_ErrorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "hr", [["class", "my-2 mx-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "pre", [["class", "mb-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error; _ck(_v, 3, 0, currVal_0); }); }
function View_ErrorComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "mat-accordion", [["class", "mat-accordion"]], null, null, null, null, null)), i1.ɵdid(2, 1720320, null, 1, i2.MatAccordion, [], null, null), i1.ɵqud(603979776, 1, { _headers: 1 }), i1.ɵprd(2048, null, i2.MAT_ACCORDION, null, [i2.MatAccordion]), (_l()(), i1.ɵeld(5, 16777216, null, null, 12, "mat-expansion-panel", [["class", "mat-expansion-panel"]], [[2, "mat-expanded", null], [2, "_mat-animation-noopable", null], [2, "mat-expansion-panel-spacing", null]], null, null, i3.View_MatExpansionPanel_0, i3.RenderType_MatExpansionPanel)), i1.ɵdid(6, 1753088, null, 1, i2.MatExpansionPanel, [[3, i2.MAT_ACCORDION], i1.ChangeDetectorRef, i4.UniqueSelectionDispatcher, i1.ViewContainerRef, i5.DOCUMENT, [2, i6.ANIMATION_MODULE_TYPE], [2, i2.MAT_EXPANSION_PANEL_DEFAULT_OPTIONS]], null, null), i1.ɵqud(335544320, 2, { _lazyContent: 0 }), i1.ɵprd(256, null, i2.MAT_ACCORDION, undefined, []), (_l()(), i1.ɵeld(9, 0, null, 0, 6, "mat-expansion-panel-header", [["class", "mat-expansion-panel-header"], ["role", "button"]], [[1, "id", 0], [1, "tabindex", 0], [1, "aria-controls", 0], [1, "aria-expanded", 0], [1, "aria-disabled", 0], [2, "mat-expanded", null], [40, "@expansionHeight", 0]], [[null, "click"], [null, "keydown"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10)._toggle() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10)._keydown($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_MatExpansionPanelHeader_0, i3.RenderType_MatExpansionPanelHeader)), i1.ɵdid(10, 180224, [[1, 4]], 0, i2.MatExpansionPanelHeader, [i2.MatExpansionPanel, i1.ElementRef, i7.FocusMonitor, i1.ChangeDetectorRef, [2, i2.MAT_EXPANSION_PANEL_DEFAULT_OPTIONS]], null, null), i1.ɵpod(11, { collapsedHeight: 0, expandedHeight: 1 }), i1.ɵpod(12, { value: 0, params: 1 }), (_l()(), i1.ɵeld(13, 0, null, 0, 2, "mat-panel-title", [["class", "mat-expansion-panel-header-title"]], null, null, null, null, null)), i1.ɵdid(14, 16384, null, 0, i2.MatExpansionPanelTitle, [], null, null), (_l()(), i1.ɵted(-1, null, [" See error details "])), (_l()(), i1.ɵeld(16, 0, null, 1, 1, "pre", [["class", "mb-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(17, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 6).expanded; var currVal_1 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); var currVal_2 = i1.ɵnov(_v, 6)._hasSpacing(); _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i1.ɵnov(_v, 10).panel._headerId; var currVal_4 = (i1.ɵnov(_v, 10).disabled ? (0 - 1) : 0); var currVal_5 = i1.ɵnov(_v, 10)._getPanelId(); var currVal_6 = i1.ɵnov(_v, 10)._isExpanded(); var currVal_7 = i1.ɵnov(_v, 10).panel.disabled; var currVal_8 = i1.ɵnov(_v, 10)._isExpanded(); var currVal_9 = _ck(_v, 12, 0, i1.ɵnov(_v, 10)._getExpandedState(), _ck(_v, 11, 0, i1.ɵnov(_v, 10).collapsedHeight, i1.ɵnov(_v, 10).expandedHeight)); _ck(_v, 9, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = _co.error; _ck(_v, 17, 0, currVal_10); }); }
function View_ErrorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["automationid", "Section_Error"], ["class", "alert alert-danger icon"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorComponent_2)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, [["ref", 1]], null, 1, "div", [["class", "d-inline"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorComponent_3)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorComponent_4)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorComponent_5)), i1.ɵdid(11, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.canClose; _ck(_v, 2, 0, currVal_0); var currVal_1 = !i1.ɵnov(_v, 3).innerHTML.trim(); _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.showError; _ck(_v, 9, 0, currVal_2); var currVal_3 = !_co.showError; _ck(_v, 11, 0, currVal_3); }, null); }
export function View_ErrorComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ErrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-error", [], null, null, null, View_ErrorComponent_0, RenderType_ErrorComponent)), i1.ɵdid(1, 114688, null, 0, i8.ErrorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorComponentNgFactory = i1.ɵccf("app-error", i8.ErrorComponent, View_ErrorComponent_Host_0, { error: "error", canClose: "canClose", showError: "showError" }, {}, ["*"]);
export { ErrorComponentNgFactory as ErrorComponentNgFactory };
