var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '$api';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthorizationFormValidators, RepeatPasswordErrorStateMatcher } from '../../shared/validators/authorization-form.validators';
import { AppSettings } from '$shared';
import { AuthenticationRequestTypeEnum } from 'src/app/shared/models';
var MyAccountComponent = /** @class */ (function () {
    function MyAccountComponent(api, fb, ref, settings) {
        this.api = api;
        this.fb = fb;
        this.ref = ref;
        this.settings = settings;
        /** Loading indicator */
        this.isLoading = false;
        /** Toggle password visibility */
        this.showCurrentPassword = false;
        this.showNewPassword = false;
        this.showConfirmPassword = false;
        /** Show confirm password errors */
        this.passwordErrorMatcher = new RepeatPasswordErrorStateMatcher();
        /** Toggle password requirements */
        this.showPasswordRequirements = false;
    }
    MyAccountComponent.prototype.ngOnInit = function () {
        // Save these for later
        this.userNameValidators = [Validators.required, AuthorizationFormValidators.validEmail];
        // Store to compare when username changes
        this.currentUserName = this.settings.userName;
        // Create form and set default values
        this.formMain = this.fb.group({
            userName: [this.settings.userName, this.userNameValidators],
            password: [null, [
                    AuthorizationFormValidators.oneLowercase,
                    AuthorizationFormValidators.oneUppercase,
                    AuthorizationFormValidators.oneDigit,
                    AuthorizationFormValidators.specialCharacters,
                    AuthorizationFormValidators.eightCharacters,
                ]],
            passwordConfirm: [null],
            passwordCurrent: [null, Validators.required],
        }, { validator: AuthorizationFormValidators.samePasswords });
    };
    /**
     * Check the API to see if the username is already being used
     */
    MyAccountComponent.prototype.checkExistingUserName = function (userName) {
        var _this = this;
        // Do not check if the field is invalid
        if (this.formMain.get('userName').invalid)
            return;
        // Do not check if the field is the same as when the page first loaded
        if (userName === this.currentUserName)
            return;
        this.api.userAccount.checkAvailability(userName).subscribe(function (apiResponse) {
            if (apiResponse.response) {
                _this.markUserNameInvalid(userName);
            }
        });
    };
    MyAccountComponent.prototype.markUserNameInvalid = function (userName) {
        var userNameControl = this.formMain.get('userName');
        // Add a validator that does not allow the username
        userNameControl.setValidators(__spread(this.userNameValidators, [
            AuthorizationFormValidators.doesNotEqual(userName),
        ]));
        // Update validity
        userNameControl.updateValueAndValidity();
    };
    /**
     * Called when form is submitted / save button is clicked
     * @param data
     */
    MyAccountComponent.prototype.updateMyAccount = function (formMain) {
        var _this = this;
        if (formMain.invalid)
            return;
        var formMainValues = formMain.value;
        // Clear messages
        this.setError();
        this.setInfo();
        this.setSuccess();
        var updatedUserAccountName = this.currentUserName && this.currentUserName !== formMainValues.userName
            ? formMainValues.userName
            : null;
        // Check if borrower is attempting to update username
        // or password. If neither, do nothing at all
        if (!(formMainValues.password || updatedUserAccountName)) {
            this.setInfo("No changes have been made to your account.");
            return;
        }
        // Prepare API request
        var authRequest = {
            authenticationRequestViewModel: {
                authenticationRequestType: AuthenticationRequestTypeEnum.UpdateAccount,
                authentication: {
                    newPassword: (formMainValues.password || null),
                    password: (formMainValues.passwordCurrent || null),
                    userAccountName: (this.currentUserName || null),
                    updatedUserAccountName: updatedUserAccountName,
                }
            }
        };
        this.isWaiting = true;
        this.api.userAccount.updateAccount(authRequest).subscribe(function (apiResponse) {
            if (apiResponse
                && apiResponse.response
                && apiResponse.response.succeeded) {
                // Check to see which type of success message we should show here
                if (formMainValues.password && updatedUserAccountName) {
                    _this.setSuccess("Your email and password has been updated!");
                }
                else if (formMainValues.password) {
                    _this.setSuccess("Your password has been updated!");
                }
                else if (updatedUserAccountName) {
                    _this.setSuccess("Your email address has been updated!");
                }
                // Update saved username
                if (updatedUserAccountName) {
                    _this.currentUserName = updatedUserAccountName;
                    _this.settings.userName = updatedUserAccountName;
                }
            }
            else {
                _this.setError("An error occurred trying to access your account. Please check your password and try again.");
            }
            _this.isWaiting = false;
        }, function () {
            _this.setError("An error occurred trying to access your account. Please check your password and try again.");
            _this.isWaiting = false;
        });
    };
    MyAccountComponent.prototype.setError = function (error) {
        if (error === void 0) { error = null; }
        this.error = error;
        this.ref.markForCheck();
    };
    MyAccountComponent.prototype.setInfo = function (info) {
        if (info === void 0) { info = null; }
        this.info = info;
        this.ref.markForCheck();
    };
    MyAccountComponent.prototype.setSuccess = function (success) {
        if (success === void 0) { success = null; }
        this.success = success;
        this.ref.markForCheck();
    };
    return MyAccountComponent;
}());
export { MyAccountComponent };
