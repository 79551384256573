export namespace constants {
    export const config2009 = 'clover.config.URLA2009';
    export const config2020 = 'clover.config.URLA2020';
    export const configdefault = 'clover.config.default';
    export const defaultlsid = '1693';
    export const configareastatelicensurerulesenabled = 'config.area.state.licensure.rules.enabled';
    export const User = 'User';
    export const userName = 'userName';
    export const serviceaccountid = 74391;
}

export namespace api {
    export const root = 'api';
    export const megasave = 'LoanEx/MegaSave';
    export const consumerappsubmit = 'loan/consumer-app-submit';
    export const getlookupbylookupcategory = 'LookupsService/GetLookupByLookupCategory';
    export const credit = 'Credit';
    export const borrowerneedsservicegenerate = 'BorrowerNeedsService/Generate';
    export const enrollandgetwidget = 'EVerifyAssetService/EnrollAndGetWidget';
    export const getverifiedaccountlist = 'EVerifyAssetService/GetVerifiedAccountList';
    export const processverifiedaccounts = 'EVerifyAssetService/ProcessVerifiedAccounts';
    export const getleadsourceforborrower = 'UserService/GetLeadSourceForBorrower';
    export const runemploymentverification = 'MegaLoanService/RunEmploymentVerification';
    export const saveuseraccount = 'user/SaveUserAccount';
    export const getuseraccountbyid = 'user/GetUserAccountById';
    export const getvendorforloanbyproduct = 'VendorManagementService/GetVendorForLoanByProduct';
    export const getsigningroom = 'ESigningService/GetSigningRoom';
    export const onsignatureevent = 'ESigningService/OnSignatureEvent';
    export const setdocumentpreview = 'ESigningService/SetDocumentPreview';
    export const saveappraisal = 'AppraisalService/SaveAppraisal';
    export const submitappraisalrequest = 'AppraisalService/SubmitAppraisalRequest';
    export const documentsviewingcomplete = 'DocumentsService/ViewingComplete';
    export const getappraisalproductsandfees = 'AppraisalService/GetAppraisalProductsAndFees';
    export const getloancontext = 'SecurityService/GetLoanContext';
    export const authenticate = 'SecurityService/Authenticate';
    export const isvaliduseraccountbyusername = 'UserService/IsValidUserAccountByUsername';
    export const isvaliduserandactivebyusername = 'UserService/IsValidUserAndActiveByUserName';
    export const isexistinguser = 'UserService/IsExistingUser';
    export const generatetemporarypasswordandsendemail = 'UserService/GenerateTemporaryPasswordAndSendEmail';
    export const resendsecurelinkemail = 'SecureLinkEmailAlternativeService/ResendSecureLinkEmail';
    export const loreassigned = 'notification/lo-reassigned';
    export const getcompanybasicinfo = 'CompanyProfileService/GetCompanyBasicInfo';
    export const getcompanybasicinfoforuser = 'CompanyProfileService/GetCompanyBasicInfoForUser';
    export const getleadsourcebasicinfo = 'UserService/GetLeadSourceBasicInfo';
    export const createauditLog = 'AuditLog/CreateAuditLog';
    export const getvoaconfiguration = 'EVerifyAssetService/GetVOAConfiguration';
    export const appstate = 'session/appstate';
    export const userpicture = 'users/picture';
    export const getguids = 'IdentityGeneratorService/GetGuids';
    export const getzipdata = 'ZipDataService/GetZipData';
    export const getuszipdata = 'ZipDataService/GetUSZipData';
    export const searchfinancialinstitution = 'EVerifyAssetService/SearchFinancialInstitution';
    export const defaultclosingdate = 'Calculator/DefaultClosingDate';
}
