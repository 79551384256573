var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import * as mixpanel from 'mixpanel-browser';
import { Title } from '@angular/platform-browser';
import { environment } from '$env';
import { AppSettings } from '../app.settings';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/platform-browser";
import * as i3 from "../app.settings";
var scriptUrls = {
    ga: '',
    omniture: '',
};
var AnalyticsService = /** @class */ (function () {
    function AnalyticsService(router, title, settings, route) {
        var _this = this;
        this.router = router;
        this.title = title;
        this.settings = settings;
        this.route = route;
        /** Reference to mixpanel object on window */
        this.mixpanel = window.mixpanel;
        this.options = {};
        this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }), map(function () { return _this.route; }), map(function (r) {
            while (r.firstChild) {
                r = r.firstChild;
            }
            return r;
        }), filter(function (r) { return r.outlet === 'primary'; }), mergeMap(function (r) { return r.data; }))
            .subscribe(function (data) {
            if (!data.disableAutoTrack) {
                setTimeout(function () { return _this.routeTrack(_this.router.url); }, 100);
            }
        });
    }
    /**
     * Start tracking analytics
     * @param options
     */
    AnalyticsService.prototype.start = function (options) {
        this.options = options;
        if (options.mixpanel) {
            // Prod: 'f526969886702f6b20f12637430a8105'
            // Dev: 'e70937746dbfc4acd3fbe8452df98497';
            mixpanel.init(this.settings.mixpanelKey || 'e70937746dbfc4acd3fbe8452df98497');
        }
        if (options.ga) {
            this.scriptLoad(scriptUrls.ga);
        }
        if (options.omniture) {
            this.scriptLoad(scriptUrls.omniture);
        }
    };
    /**
     * Load a script
     * @param url
     * @param callback
     */
    AnalyticsService.prototype.scriptLoad = function (url, callback) {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        if (callback) {
            script.onload = callback; // After load, callback
        }
        document.head.appendChild(script);
    };
    /**
     * Track an event
     * @param eventName
     * @param data
     */
    AnalyticsService.prototype.trackEvent = function (eventName, data) {
        if (data === void 0) { data = {}; }
        // console.log('trackEvent', eventName, data, JSON.stringify(data), JSON.parse(JSON.stringify(data)));
        if (!eventName) {
            console.warn('Event Name is missing from mixpanel event');
        }
        if (this.options.mixpanel) {
            mixpanel.track(eventName, __assign({ User: 'Borrower', Experience: 'Consumer' }, data));
        }
    };
    /**
     * Track route changes
     * Automatically add global and path properties
     * @param url
     */
    AnalyticsService.prototype.routeTrack = function (url) {
        var title = this.title
            .getTitle()
            .split('-')[0]
            .trim();
        var data = {
            env: environment.production ? 'prod' : 'dev',
            url: url,
        };
        // Is on application section
        if (url.indexOf('application') !== -1) {
            title += ' BLC';
            data.flow = 'L';
            // Is on dashboard section
        }
        else if (url.indexOf('dashboard') !== -1) {
            title += ' BDC';
            data.flow = 'D';
            // Everywhere else
        }
        else {
            title += ' BC';
        }
        this.trackEvent(title, data);
    };
    /**
     * Maps the currently logged in user with the Alias Id created during registration - Feature 378152
     * @param uniqueId
     */
    AnalyticsService.prototype.mixpanelIdentify = function (uniqueId) {
        mixpanel.identify(uniqueId);
    };
    /**
     * To allow for easy debugging in the dev console. This functionality will only be restricted to the Dev and QA Environments - Feature 378152
     */
    AnalyticsService.prototype.mixpanelSetDebugTrue = function (value) {
        mixpanel.set_config({ debug: value });
    };
    /**
  * Resets the distinct_id and removes existing super properties - Feature 378152
  */
    AnalyticsService.prototype.mixpanelReset = function () {
        mixpanel.reset();
    };
    /**
     * Associate a uniqueID with an existing mixpanel profile
     * @param uniqueId
     */
    AnalyticsService.prototype.mixpanelAlias = function (uniqueId) {
        mixpanel.alias(uniqueId);
    };
    /**
     * Identify the user to mixpanel with unique and consistent id
     * @param uniqueId
     */
    AnalyticsService.prototype.mixpanelPeople = function (props) {
        // console.log('mixpanelIdentify', uniqueId);
        mixpanel.people.set(props);
        // mixpanel.people.set({'User': 'Borrower'});
    };
    /**
     * Set global super properties needed by mixpanel
     */
    AnalyticsService.prototype.mixpanelSuperProps = function (props) {
        mixpanel.register(props);
    };
    /**
     *
     * @param property Unregister the property which was registered earlier
     */
    AnalyticsService.prototype.removeMixPanelProperty = function (property) {
        mixpanel.unregister(property);
    };
    AnalyticsService.ngInjectableDef = i0.defineInjectable({ factory: function AnalyticsService_Factory() { return new AnalyticsService(i0.inject(i1.Router), i0.inject(i2.Title), i0.inject(i3.AppSettings), i0.inject(i1.ActivatedRoute)); }, token: AnalyticsService, providedIn: "root" });
    return AnalyticsService;
}());
export { AnalyticsService };
