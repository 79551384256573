/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sections-config.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "@angular/common";
import * as i5 from "./sections-config.component";
import * as i6 from "@angular/router";
var styles_SectionsConfigComponent = [i0.styles];
var RenderType_SectionsConfigComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SectionsConfigComponent, data: {} });
export { RenderType_SectionsConfigComponent as RenderType_SectionsConfigComponent };
function View_SectionsConfigComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["SQL Escaped"]))], null, null); }
export function View_SectionsConfigComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "mat-card", [["class", "mat-card"]], null, null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i3.MatCard, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["Urla Version ", ""])), (_l()(), i1.ɵeld(4, 0, null, 0, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Options: "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SectionsConfigComponent_1)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, 0, 2, "pre", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "code", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.sqlEscaped; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.version; _ck(_v, 3, 0, currVal_0); var currVal_2 = _co.configJson; _ck(_v, 10, 0, currVal_2); }); }
export function View_SectionsConfigComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sections-config", [], null, null, null, View_SectionsConfigComponent_0, RenderType_SectionsConfigComponent)), i1.ɵdid(1, 245760, null, 0, i5.SectionsConfigComponent, [i6.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SectionsConfigComponentNgFactory = i1.ɵccf("app-sections-config", i5.SectionsConfigComponent, View_SectionsConfigComponent_Host_0, {}, {}, []);
export { SectionsConfigComponentNgFactory as SectionsConfigComponentNgFactory };
