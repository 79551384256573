var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { AppSettings } from '../../shared/app.settings';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material';
import { UIStoreActions } from '$ui';
import { ConfirmationModalComponent } from './confirmation/confirmation-modal.component';
import { ConfirmationNewLoanModalComponent } from './confirmation-new-loan/confirmation-new-loan-modal.component';
import { LogoutModalComponent } from './logout/logout-modal.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { HintModalComponent } from './hint/hint.component';
import { EconsentModalComponent } from './econsent/econsent-modal.component';
import { NewDesignModalComponent } from './new-design/new-design-modal.component';
import { LoanLockModalComponent } from './loan-lock/loan-lock.component';
import { ConfirmationNextModelComponent } from './confirmation-next/confirmation-next.component';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "../../shared/app.settings";
import * as i3 from "@angular/material/dialog";
var ModalsService = /** @class */ (function () {
    function ModalsService(store, settings, dialog) {
        var _this = this;
        this.store = store;
        this.settings = settings;
        this.dialog = dialog;
        /** Reference to the STORE OBSERVABLE currently open modal. This reference is used for modals persisted in the UI store */
        this.modalRef$ = new BehaviorSubject(null);
        /** List of component references of available modals */
        this.modalList = {
            ConfirmationModalComponent: ConfirmationModalComponent,
            ConfirmationNewLoanModalComponent: ConfirmationNewLoanModalComponent,
            LogoutModalComponent: LogoutModalComponent,
            FeedbackComponent: FeedbackComponent,
            HintModalComponent: HintModalComponent,
            EconsentModalComponent: EconsentModalComponent,
            NewDesignModalComponent: NewDesignModalComponent,
            LoanLockModalComponent: LoanLockModalComponent,
            ConfirmationNextModelComponent: ConfirmationNextModelComponent
        };
        // Subscribe to the modal in the store and launch store modal if data is found. Also make sure token is present
        this.store.select(function (storeElem) { return storeElem.ui.modal; }).subscribe(function (modal) {
            // Make sure modal exists AND that a token is present in app settings. This prevents a modal from persisting after logout
            if (modal && Object.keys(modal).length && _this.settings.token) {
                // Store reference to the modal instance
                var width = '720px';
                switch (modal.size) {
                    case 'sm':
                        width = '480px';
                        break;
                    case 'xl':
                        width = '1024px';
                        break;
                    case 'full':
                        width = '90%';
                        break;
                }
                var modalRef = _this.dialog.open(_this.modalList[modal.modalId], {
                    width: width,
                    data: modal.data || null,
                });
                _this.modalRef$.next(modalRef);
                _this.onClose();
            }
        });
    }
    /**
     * Open a modal window
     * @param modalId The class name of the modal window
     * @param persist Should the modal persist on reload or otherwise have its state managed by the UI store
     * @param size Modal width. String or number (of pixels)
     * @param data Primary set of data to pass to the modal
     * @param dataAlt Secondary set of data to pass to the modal
     * @param dialogConfig All other MatDialogConfig options
     */
    ModalsService.prototype.open = function (modalId, persist, size, data, dataAlt, dialogConfig) {
        if (persist === void 0) { persist = false; }
        if (size === void 0) { size = 'lg'; }
        if (dialogConfig === void 0) { dialogConfig = null; }
        var width = '720px';
        if (typeof size === 'number') {
            width = size + "px";
        }
        else {
            switch (size) {
                case 'sm':
                    width = '480px';
                    break;
                case 'xl':
                    width = '1024px';
                    break;
                case 'full':
                    width = '90%';
                    break;
            }
        }
        // If persist is set, load this modal into the store so state is managed by the UI store
        if (persist) {
            this.store.dispatch(UIStoreActions.MODAL_OPEN({
                modalId: modalId,
                options: { size: size },
                data: data,
            }));
        }
        else {
            // If persist is not set
            // this.modalRef = this.modalService.open(this.modalList[modalId], { size: <any>size, windowClass: windowClass });
            this.modalRef = this.dialog.open(this.modalList[modalId], __assign({}, dialogConfig, { width: width, data: data }));
        }
        this.modalRef.componentInstance.dataAlt = dataAlt;
        return this.modalRef;
    };
    /**
     * When the modal window is closed, remove from store
     */
    ModalsService.prototype.onClose = function () {
        var _this = this;
        this.modalRef$.subscribe(function (modal) {
            // Wait for promise that is returned when modal is closed or dismissed
            modal.result.then(function () {
                _this.store.dispatch(UIStoreActions.MODAL_UNLOAD(null));
            }, function () {
                // On modal dismiss, which is closed without performing an action
                _this.store.dispatch(UIStoreActions.MODAL_UNLOAD(null));
            });
        });
    };
    ModalsService.ngInjectableDef = i0.defineInjectable({ factory: function ModalsService_Factory() { return new ModalsService(i0.inject(i1.Store), i0.inject(i2.AppSettings), i0.inject(i3.MatDialog)); }, token: ModalsService, providedIn: "root" });
    return ModalsService;
}());
export { ModalsService };
