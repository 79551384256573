var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '$api';
import { combineLatest } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UIStoreService } from '$ui';
import { LoanUtils, MilestoneStateEnum } from '../../../../shared/utils/loan-utils';
import { AppSettings, AnalyticsService, AuthService } from '$shared';
import { flatMap } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ModalsService } from '$modals';
import { HeartbeatService } from 'src/app/shared/services/heartbeat.service';
import { environment } from '$env';
import { MatDialog } from '@angular/material';
import { UrlaChangeModalComponent } from 'src/app/components/modals/urla-change/urla-change-modal.component';
import { URLAFormTypeEnum } from 'src/app/shared/models';
var cloneDeep = require('lodash/cloneDeep');
var HomeComponent = /** @class */ (function () {
    function HomeComponent(authService, api, router, route, ui, settings, analytics, modals, heartbeatService, dialog) {
        this.authService = authService;
        this.api = api;
        this.router = router;
        this.route = route;
        this.ui = ui;
        this.settings = settings;
        this.analytics = analytics;
        this.modals = modals;
        this.heartbeatService = heartbeatService;
        this.dialog = dialog;
        this.config$ = this.ui.select.config$;
        this.appState$ = this.api.appState$;
        this.megaLoan$ = this.api.select.megaLoan$;
        /** Used in UI to compare */
        this.milestoneStateEnum = MilestoneStateEnum;
        this.isProd = environment.production;
    }
    HomeComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Check if a loan ID has been selected before loading the loan
        if (this.settings.loanId) {
            this.api.megaLoan.get(true).subscribe(function (loan) {
                _this.api.activeLoanSnapshots.get().subscribe(function (loanSnapshots) {
                    // check if selected loan is locked and display modal if needed
                    var canOpenLoanLockModal = !!loan.loanAccessByUserId && _this.settings.userId != loan.loanAccessByUserId.toString();
                    if (canOpenLoanLockModal) {
                        _this.modals.open('LoanLockModalComponent', false, 'lg', loanSnapshots.length, null, { disableClose: true })
                            .afterClosed()
                            .subscribe(function (isLogout) {
                            if (isLogout === true) {
                                _this.authService.logOut();
                            }
                        });
                    }
                    else if (loan.loanAccessByUserId && _this.settings.userId == loan.loanAccessByUserId.toString()) {
                        _this.refreshLoanLockSubsciption = _this.heartbeatService.handleLoanRefresh();
                    }
                    else if (!loan.loanAccessByUserId && _this.settings.userId) {
                        // Loan was just created and does not have a userId assigned
                        loan.loanAccessByUserId = +_this.settings.userId;
                        _this.refreshLoanLockSubsciption = _this.heartbeatService.handleLoanRefresh();
                    }
                });
                _this.api.appState.get().subscribe();
                var auditLog = _this.authService.createLoginAuditLog(_this.settings.loanId, _this.settings.userFullName);
                _this.api.auditLog.save(auditLog).subscribe();
                _this.api.client1003Config.get(_this.settings.urla).subscribe();
            });
        }
        else {
            // Otherwise, re-route borrower to the loan selection page
            this.router.navigate(['/my-loans'], { queryParamsHandling: 'merge' });
        }
        // Get company profile, needed by analytics
        this.api.broker.get().pipe(flatMap(function (broker) {
            return _this.api.currentCompanyProfile.get(broker.leadSourceId, _this.settings.userId);
        }));
        // Load guids used to generate new entities
        this.api.services.guidsGet().subscribe(function (res) { return (_this.api.guids = res); });
        this.sub = this.appState$.pipe(untilDestroyed(this)).subscribe(function (appState) { return (_this.appState = appState); });
        this.milestoneStateSub = combineLatest(this.api.getApiStoreData(this.megaLoan$), this.appState$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var _b = __read(_a, 2), loan = _b[0], appState = _b[1];
            _this.milestoneState = LoanUtils.getMilestoneState(loan.currentMilestone, appState);
            _this.handleRedirects(_this.milestoneState);
            // Check if the URLA version has changed
            if (loan.originalURLAFormType !== loan.urlaFormType &&
                appState &&
                appState.loaded &&
                !appState.form1003.urlaModalShown) {
                _this.dialog.open(UrlaChangeModalComponent, { disableClose: true });
            }
        });
    };
    /**
     * Handles redirecting when query parameters were provided that instruct the
     * app to go to a certain route or perform a specific function (e.g. tasks)
     */
    HomeComponent.prototype.handleRedirects = function (milestoneState) {
        var queryParams = this.route.snapshot.queryParams;
        if (queryParams['secureLinkId'] === '5') {
            // SecureLink 5 is "View BNL"; always redirect to BNL/tasks page
            this.router.navigate(['/tasks']);
        }
        else if (milestoneState === MilestoneStateEnum.Documentation && queryParams['token']) {
            // If secure link (token in URL) is detected and milestone state is Documentation
            // automatically forward the borrower to the tasks page
            this.router.navigate(['/tasks'], { preserveQueryParams: true });
        }
    };
    /**
     * Manage app state changes, from within a section and from section to section
     * @param section
     */
    HomeComponent.prototype.appStateChange = function (section, megaLoan) {
        var _this = this;
        // New Ref
        var appState = cloneDeep(this.appState);
        var action = function () {
            // Which section
            switch (section) {
                case 'form1003':
                    // Fire appropriate analytics event
                    if (!appState.form1003.isStarted) {
                        _this.analytics.trackEvent('Loan App Started BC', {});
                    }
                    else {
                        _this.analytics.trackEvent('Loan App Continue BC', {});
                    }
                    // Set default routing
                    var routeNext = 'loan-purpose';
                    var pageNext = '';
                    // Filter for sections that are not complete
                    var notComplete = appState.form1003.state.filter(function (sec) { return !sec.isComplete; });
                    // If result, update last page and section Id
                    if (notComplete.length) {
                        routeNext = notComplete[0].sectionId;
                        pageNext = notComplete[0].lastPageId || '';
                    }
                    // If non spouse coborrower, default to personal if loan purpose is set
                    if (megaLoan &&
                        megaLoan.transactionInfo &&
                        megaLoan.transactionInfo.loanApplications.length &&
                        megaLoan.transactionInfo.loanApplications[0].isPrimary === false) {
                        if (routeNext === 'loan-purpose') {
                            routeNext = 'personal';
                            pageNext = 'start';
                        }
                        // Check if state is present
                        // If so, always set loan purpose to complete and last page to summary
                        if (appState.form1003.state.length) {
                            // Set loan purpose to complete so that summary is always the default
                            appState.form1003.state.forEach(function (sectionState) {
                                if (sectionState.sectionId === 'loan-purpose') {
                                    sectionState.isComplete = true;
                                    sectionState.lastPageId = 'summary';
                                }
                            });
                        }
                        else {
                            // Appstate has not been generated yet, create an initial one for loan purpose to set summary and complete flags
                            appState.form1003.state.push({
                                sectionId: 'loan-purpose',
                                lastPageId: 'summary',
                                isComplete: true,
                                isActive: false,
                                hasSummary: null,
                                title: null,
                            });
                        }
                    }
                    _this.router.navigate(['/application/', routeNext, pageNext]);
                    break;
                case 'dashboard':
                    _this.analytics.trackEvent('Dashboard BC', {});
                    _this.router.navigate(['/tasks'], { queryParamsHandling: 'merge' });
                    appState[section].isStarted = true;
                    break;
            }
        };
        // Always set is started prop
        //
        // console.log('appStateChange', appState)
        this.api.appState.set(appState).subscribe(function () { return action(); }, function () { return action(); });
    };
    /***/
    HomeComponent.prototype.appStateClear = function () {
        this.api.appState
            .set({
            loaded: true,
            form1003: {
                isActive: true,
                isStarted: null,
                completedDate: null,
                state: [],
            },
            dashboard: {
                isActive: null,
                isStarted: null,
                completedDate: null,
            },
        })
            .subscribe();
    };
    HomeComponent.prototype.isURLA2020 = function () {
        return LoanUtils.isURLA2020(this.settings.urla);
    };
    /**
     *
     * @param lsid
     */
    HomeComponent.prototype.configChange = function (isURLA2020) {
        this.settings.urla = isURLA2020 ? URLAFormTypeEnum.URLA2020 : URLAFormTypeEnum.URLA2009;
        this.api.client1003Config.get(this.settings.urla).subscribe(function () { });
    };
    // Must be present even if not used for unsubs
    HomeComponent.prototype.ngOnDestroy = function () {
        this.sub.unsubscribe();
        this.milestoneStateSub.unsubscribe();
        if (this.refreshLoanLockSubsciption) {
            this.refreshLoanLockSubsciption.unsubscribe();
        }
    };
    return HomeComponent;
}());
export { HomeComponent };
