import {  Injectable} from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AppSettings } from '../app.settings';


@Injectable({
  providedIn: 'root',
})
export class RegisterGuard implements CanActivate {
  constructor(
    private settings: AppSettings,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
   if (this.settings && this.settings.clientId && this.settings.clientId.toLowerCase() !== 'arv') {
     return true;
    }
    this.router.navigateByUrl('/login', { queryParams: route.queryParams, queryParamsHandling: 'preserve'});
   return false;

  }

}
