import { RuleExprOp } from 'src/app/features/cvFormBuilder/shared/cvFormBuilder.enums';
import { HomeBuyingTypeEnum } from 'src/app/shared/models';

export const loanPurposePages: CvFormBuilder.Pages = {
  guidPageB: {
    title: 'Are you looking to <strong>purchase</strong> or <strong>refinance</strong>?',
    titleShort: 'Loan Purpose',
    pageId: 'purchase-or-refinance',
    content: [
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.loanPurposeType',
        placeholder: 'Loan Purpose',
        formFieldType: 'iconGroup',
        dataField: 'df-140',
        validators: {
          required: true,
        },
      },
    ],
  }, // end page

  //#region purchase
  guidPageC: {
    title: 'Where are you in the <strong>purchase process</strong>?',
    titleShort: 'Purchase Process',
    pageId: 'purchase-process',
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `What's the difference between shopping and buying?`,
          body: `
            <h3>Shopping for a Home</h3>
            <p>Select this option if you are currently looking for a home to buy, either casually or seriously, and have <i>not</i> yet put an offer on a property.</p>
            <h3>Found the Home I'm Buying</h3>
            <p>In this case, you have found the home you want to purchase and have made a formal or informal offer to purchase it. This doesn't necessarily mean that you have signed any paperwork yet.</p>
          `,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.homeBuyingType',
        // placeholder: 'Purchase Process',
        formFieldType: 'buttonGroup',
        formFieldData: [
          {
            label: `I'm shopping for a home`,
            labelShort: 'Get pre-approved',
            value: HomeBuyingTypeEnum.GetPreApproved,
          },
          {
            label: 'I found the home I want to buy',
            labelShort: 'Need a mortgage',
            value: HomeBuyingTypeEnum.OfferAccepted,
          },
        ],
        validators: {
          required: true,
        },
      },
    ],
  }, // end page

  guidPageC2: {
    title: 'Have you signed a <strong>purchase agreement</strong>?',
    titleShort: 'Purchase Agreement',
    pageId: 'purchase-agreement',
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `What is a Purchase Agreement?`,
          body: `
            <h3>Purchase Agreement</h3>
            <p>This is a signed agreement between you (the buyer) and the seller for the home you want to purchase. This agreement is a legal contract that states the terms and conditions for the purchase of a property (such as purchase price, down payment, contingencies and other terms).</p>
          `,
        },
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        // TODO: Change this to correct property.
        // If you change this, also change src\app\routes\application\shared\config\pages\pages-property.ts on line 67 and 76
        field: 'loan.$$custom.signedPurchaseAgreement',
        // placeholder: '',
        formFieldType: 'iconGroup',
        formFieldData: [
          {
            label: 'Yes, I signed',
            value: true,
          },
          {
            label: `No, I haven't`,
            value: false,
          },
        ],
        validators: {
          required: true,
        },
      },
    ],
  }, // end page

  guidPageC3: {
    title: 'Are you currently working with a <strong>real estate agent</strong>?',
    titleShort: 'Working with a real estate agent',
    pageId: 'have-agent',
    isLastPage: true,
    content: [
      <CvFormBuilder.FormField>{
        type: 'formField',
        class: 'assignLoanNumberFlg',
        field: 'loan.$$custom.haveREAgent', // HACK
        // placeholder: 'Have real estate agent?',
        formFieldType: 'iconGroup',
        formFieldData: [
          {
            label: 'Yes',
            value: true,
          },
          {
            label: 'No',
            value: false,
          },
        ],
        validators: {
          required: true,
        },
      },

      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Agents full name',
        field: 'loan.$$custom.agentName',
        formFieldType: 'text',
        validators: {
          // required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.haveREAgent',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: 'Agents email address',
        field: 'loan.$$custom.agentEmail',
        formFieldType: 'text',
        validators: {
          // required: true,
          email: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.haveREAgent',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        placeholder: `Agent's Phone`,
        field: 'loan.$$custom.agentPhone',
        formFieldType: 'phoneNumber',
        validators: {
          minLength: 10,
          // required: true,
        },
        visible: {
          rules: [
            {
              field: 'loan.$$custom.haveREAgent',
              operator: RuleExprOp.EQ,
              value: true,
            },
          ],
        },
      },
    ],
  }, // end page
  //#endregion

  //#region refinance
  guidPageD: {
    title: 'What is your <strong>goal</strong> for this refinance loan?',
    titleShort: 'Refinance Goals',
    pageId: 'refinance-goals',
    isLastPage: true,
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'hint',
        data: {
          title: `About Refinance Goals`,
          body: `
            <p>Understanding why you are looking to refinance your property can help your Loan Officer find the best loan to meet your needs.</p>
            <h3>Better Rate and Term</h3>
            <p>If you are looking for a lower interest rate than you currently have on your mortgage, or if you are looking to pay off your mortgage in a shorter timeframe than currently remains on your mortgage, select this option.</p>
            <h3>Consolidate Debt</h3>
            <p>Mortgage loans can leverage the existing equity that you have in your home to help pay off other debts as part of your refinance. For example, you may be able to pay off high interest debts such as credit cards by rolling this debt into your new refinanced mortgage.</p>
            <h3>Home Improvement</h3>
            <p>If you currently have equity in your home, this will allow you to refinance your mortgage at a new loan amount that will provide you with funds to pay for home improvements, remodeling or repairs.</p>
            <h3>Take Cash Out</h3>
            <p>If you currently have equity in your home, you may want to use some portion of that equity to get immediate cash to use for reasons other than home improvements or paying off high interest debt. For example, you may refinance at a new loan amount to get cash back to pay for a wedding, college, or just to have cash on hand for a rainy day.</p>
            <h3>Other</h3>
            <p>Select this if your goal for refinancing your mortgage is not covered by any of the reasons above.</p>
          `,
        },
      },
      <CvFormBuilder.FormField>{
        type: 'formField',
        field: 'loan.purposeOfRefinance',
        placeholder: 'Refinance Goal',
        formFieldType: 'iconGroup',
        formFieldData: [
          {
            label: 'Better Rate And Term',
            value: 8,
          },
          {
            label: 'Debt Consolidation',
            value: 1,
          },
          {
            label: 'Home Improvement',
            value: 2,
          },
          {
            label: 'Take Some Cash Out',
            value: 4,
          },
          {
            label: 'Other',
            value: 0,
          },
        ],
        validators: {
          required: true,
        },
      },
    ],
  }, // end page
  //#endregion

  guidPageZ: {
    title: 'Loan Purpose Review Section',
    titleShow: false,
    pageId: 'summary',
    isLastPage: true,
    content: [
      <CvFormBuilder.Feature>{
        type: 'feature',
        featureId: 'summary-loan-purpose',
      },
    ],
  }, // end page
};
