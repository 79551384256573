import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  OnDestroy,
  Input,
} from '@angular/core';
import { ApplicationNavigationService } from '../../shared/services/application-navigation.service';
import { ApiService } from '$api';
import { environment } from '$env';
import { UIStoreService } from '$ui';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { AppSettings } from '$shared';
import { LoanUtils } from 'src/app/shared/utils/loan-utils';
import { ILoanViewModel, ICPOSForm1003State, ICPOSAppState } from 'src/app/shared/models';


@Component({
  selector: 'app-application-nav',
  templateUrl: './application-nav.component.html',
  styleUrls: ['./application-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationNavComponent implements OnInit, OnDestroy {
  sections$ = this.appNavService.sections$;
  appState$ = this.api.appState$;
  @ViewChild('progressCanvas') canvasRef: ElementRef;
  activeSectionName = '';
  /** Should the application list be shown in the UI */
  @Input() showExpandArrow = false;
  /** Should the application list be shown in the UI */
  @Input() showList = true;
  /** Should the progress indicator be shown in the UI */
  @Input() showProgress = true;
  /** Radius */
  @Input() progressRadius = 41;
  /** Can the user navigate anywhere using the nav or only when certain business rules are set */
  @Input() canAccessAll = false;
  /** Is the dropdown menu open */
  @Input() isSectionNavOpen: boolean;

  public stepCurrent = 1;

  public sectionState: Record<string, ICPOSForm1003State>;
  public canSubmit = false;
  private restrictedTo: string[] = null;
  public isProd = environment.production;

  constructor(
    public settings: AppSettings, 
    private appNavService: ApplicationNavigationService,
    private api: ApiService,
    private ui: UIStoreService,
    private ref: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.appState$
      .pipe(untilDestroyed(this))
      .subscribe(appState => {
        this.updateState(appState);
      });

    // If not a prod build, access all sections
    if (!environment.production) {
      this.canAccessAll = true;
    }
  }

  ngOnDestroy() { }

  public isURLA2020(): boolean {
    return LoanUtils.isURLA2020(this.settings.urla);
  }

  isSectionAccessible(section: CvFormBuilder.SectionControl) {
    return !this.restrictedTo || this.restrictedTo.includes(section.sectionId);
  }

  /**
   * Called when the state of the 1003 / application is changed
   * @param state Updated state object
   */
  private updateState(state: ICPOSAppState) {
    // Watch for changes to the state property
    if (state && state.form1003 && state.form1003.state && state.form1003.state.length) {
      this.sectionState = {};
      state.form1003.state.forEach((section, i) => {
        this.sectionState[section.sectionId] = section;
        if (section.isActive) {
          this.stepCurrent = i + 1;
        }
      });
      this.updatePercentage(state.form1003.state);
      this.updateSectionName(state.form1003.state);

      const {
        'loan-purpose': loanPurposeState,
        'personal': personalState,
        'property': propertyState
      } = this.sectionState;
      if (this.canAccessAll) {
        this.restrictedTo = null;
      } else if (!loanPurposeState || !loanPurposeState.isComplete) {
        this.restrictedTo = ['loan-purpose'];
      } else if (!personalState || !personalState.isComplete
        || !propertyState || !propertyState.isComplete) {
        this.restrictedTo = ['loan-purpose', 'personal', 'property'];
      } else {
        this.restrictedTo = null;
      }

      const completed = state.form1003.state.filter(section => section.isComplete);
      if (completed.length + 1 === state.form1003.state.length) {
        this.canSubmit = true;
      }
      this.ref.markForCheck();
    }
  }

  /**
   * Update the percent complete. Used in the UI to show progress
   * @param state Application State
   */
  private updatePercentage(state: ICPOSForm1003State[]): void {
    const numberOfSections = state.length;
    const numberOfCompletedSections = state.reduce((a, s) => a + +(s.isComplete || s.isActive), 0);

    // Update canvas with the section information
    this.redrawCanvas(numberOfCompletedSections, numberOfSections);
  }

  /**
   * Update the progress bar icon
   * @param currentSection Number of sections to highlight
   * @param sectionCount Total number of sections
   */
  private redrawCanvas(currentSection: number, sectionCount: number): void {
    const ctx: CanvasRenderingContext2D = this.canvasRef && this.canvasRef.nativeElement.getContext('2d');
    if (!ctx) return;

    const arcGap = 0.1;
    let activeColor = '#046492';
    let inactiveColor = '#cecece';

    // Hack to get colors from CSS file
    const canvasStyles = window.getComputedStyle(this.canvasRef.nativeElement);
    if (canvasStyles) {
      activeColor = canvasStyles.borderLeftColor;
      inactiveColor = canvasStyles.borderRightColor;
    }

    // Draw background circle
    ctx.beginPath();
    ctx.arc(this.progressRadius, this.progressRadius, this.progressRadius, 0, 2 * Math.PI);
    ctx.fillStyle = 'white';
    ctx.fill();

    // Draw progress arcs
    for (let i = 0; i < sectionCount; i++) {
      const arcSize = (2 * Math.PI) / sectionCount;
      const start = arcSize * i + arcGap - ((90 * Math.PI) / 180 + arcGap / 2);
      const end = arcSize + arcSize * i - ((90 * Math.PI) / 180 + arcGap / 2);
      ctx.beginPath();
      ctx.arc(this.progressRadius, this.progressRadius, this.progressRadius * 0.85, start, end);
      ctx.lineWidth = 5;
      ctx.strokeStyle = i + 1 <= currentSection ? activeColor : inactiveColor;
      ctx.stroke();
    }
  }

  /**
   * Update the active section name. Used in the UI to show section icon
   * @param state Application State
   */
  private updateSectionName(state: ICPOSForm1003State[]): void {
    const activeSectionIndex = state.findIndex(section => section.isActive);
    this.activeSectionName = activeSectionIndex === -1 ? '' : state[activeSectionIndex].title;
    this.ref.markForCheck();
  }

  setActiveSection(section: CvFormBuilder.SectionControl) {
    this.setActiveSectionImpl(
      section.sectionId,
      this.sectionState[section.sectionId].isComplete
        ? section.hasSummary ? 'summary' : 'start'
        : null
    );
  }
  /**
   * Set the selected section ID as the active section ID
   * @param sectionId
   */
  private setActiveSectionImpl(sectionId: string, pageId?: string): void {
    // Get loan model from UI store
    const loanModel: ILoanViewModel = this.ui.form1003.getRawValue().loan;
    // Manage custom overrides of routing logic
    switch (sectionId) {
      // Property section
      case 'property':
        // If non-spouse coborrower flow, do not allow user to go to summary screen
        if (loanModel.$$custom.nonSpouseCoborrower && pageId === 'summary') {
          pageId = 'start';
        }
        break;
    }

    this.appNavService.setCurrentSection({ sectionId: sectionId, pageId: pageId });
    this.ref.markForCheck();
  }
}
