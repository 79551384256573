var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { MatDialogRef } from '@angular/material';
import { ApiService } from '$api';
import { Router } from '@angular/router';
import { ApplicationNavigationService } from 'src/app/shared/services/application-navigation.service';
var UrlaChangeModalComponent = /** @class */ (function () {
    function UrlaChangeModalComponent(dialogRef, data, dataAlt, api, router, appNav) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.dataAlt = dataAlt;
        this.api = api;
        this.router = router;
        this.appNav = appNav;
        this.appState$ = this.api.appState$;
    }
    /**
     * Submit the form
     */
    UrlaChangeModalComponent.prototype.submit = function (appState) {
        var _this = this;
        // Reset 1003 state
        appState.form1003 = __assign({}, appState.form1003, { urlaModalShown: true, state: [] });
        // Save changes then close modal window
        this.api.appState.set(appState).subscribe(function () {
            _this.appNav.setCurrentSection({ sectionId: 'loan-purpose', pageId: null });
            _this.router.navigate(['/']); // Redirect to home
            _this.dialogRef.close(_this.dataAlt || _this.data);
        });
    };
    return UrlaChangeModalComponent;
}());
export { UrlaChangeModalComponent };
